import { defineStore } from 'pinia'
import env from '~/libs/env'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'

import { useBorrowerStore } from '~/store/borrower'


export const useProfileStore = defineStore('profile', () =>  {
  const borrowerStore = useBorrowerStore()
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const contacts = ref([])
  const contactError = ref('')
  const totalPercentOwnership = ref(0)
  const borrowerNotificationSettings = ref([])

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const hasSingleOwner =  computed(() => {
    return contacts.value.length === 1
  })
  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  function updateSingleContact({ contactsIndex, contact} ) {
    //This assignment ensures that the contacts object updates correctly
    let oldContact = contacts.value[contactsIndex]
    let newKeys = Object.keys(contact)
    if(oldContact) {
      Object.keys(oldContact).filter(key => newKeys.indexOf(key) < 0).forEach(key => delete oldContact[key]);
      newKeys.forEach(key => oldContact[key] = contact[key])
    } else {
      contacts.value[contactsIndex] = contact
    }
  }

  async function getBorrowerNotificationSettings() {

    const borrower = get(borrowerStore, 'borrower.id')
    await $axios.get(`${env('apiUrl')}/borrower-notification-settings/${borrower}`)
      .then(res => {
        let settings = !res.data.data ? [] : res.data.data
        borrowerNotificationSettings.value = settings
      })
      .catch(err => {
        log.error('Error fetching Borrower Notification Settings ', err)
      })
  }

  function updateSingleSetting({settingsIndex, setting} ) {
    let oldSetting = borrowerNotificationSettings.value[settingsIndex]
    let newKeys = Object.keys(setting)
    if(oldSetting) {
      Object.keys(oldSetting).filter(key => newKeys.indexOf(key) < 0).forEach(key => delete oldSetting[key]);
      newKeys.forEach(key => oldSetting[key] = setting[key])
    } else {
      borrowerNotificationSettings.value[settingsIndex] = setting
    }
  }

  async function updateBorrowerNotificationSetting(settingBody) {
    const borrowerId = get(borrowerStore, 'borrower.id')
    let stringBody = JSON.stringify({
      'borrowerId': borrowerId,
      ...settingBody,
    })

    await $axios.post(`${env('apiUrl')}/borrower-notification-settings`, stringBody, { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        let setting = res.data.data
        let settingsIndex = borrowerNotificationSettings.value.findIndex(setting => setting.attributeId === res.data.data.attributeId)
        if (settingsIndex !== -1) {
          this.updateSingleSetting({settingsIndex, setting})
        } else {
          borrowerNotificationSettings.value.push(setting)
        }
      })
      .catch(err => {
        let errorMessage = err.response ? err.response.data.errors[0] : ''
        if (errorMessage) {
          return log.error(new Error(`${errorMessage.message}`), err)
        }
        else {
          return log.error(new Error('failed to update Borrower Setting'), err)
        }
      })
  }

    async function getAllContacts() {
      const borrower = get(borrowerStore, 'borrower.id')
      return await $axios.get(`${env('apiUrl')}/contact/?query={"criteria":{"borrowerId":{"==":${borrower}}}}`)
        .then(res => {
          let orderedContacts = orderBy(res.data.data, ({isPrimary}) => isPrimary || '', ['desc'])

          contacts.value = orderedContacts
          this.calculateTotalPercentOwnership()
          return orderedContacts
        })
        .catch(err => {
          log.error('Error fetching contacts ', err)
        })
    }

  async function saveNewContact(contactBody) {
    let borrowerId = get(borrowerStore, 'borrower.id')
    let stringBody = JSON.stringify({
      borrowerId: borrowerId,
      ...contactBody
    })

    await $axios.post(`${env('apiUrl')}/contact`, stringBody, { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        contacts.value.push(res.data.data)
        contactError.value = ''
        this.calculateTotalPercentOwnership()
      })
      .catch(err => {
        let errorMessage = get(err, 'response.data.errors[0]')
        if (errorMessage && errorMessage.code == 'EMAIL_IN_USE') {
          contactError.value = errorMessage.message
          return log.error(new Error(`${errorMessage.message}`), err)
        } else {
          contactError.value = 'Failed to save contact. Please try again'
          return log.error(new Error('failed to save contact'), err)
        }
      })
  }

  async function updateContact(contactBody) {
    let stringBody = JSON.stringify({
      ...contactBody
    })

    await $axios.put(`${env('apiUrl')}/contact/${contactBody.id}`, stringBody, { headers: { 'Content-Type': 'application/json' } })
    .then(res => {
      let contactsIndex = contacts.value.findIndex(contact => contact.id === res.data.data.id)
      let contact = res.data.data

      contactError.value = ''
      this.updateSingleContact({contactsIndex, contact})
      this.calculateTotalPercentOwnership()
    })
    .catch(err => {
      let errorMessage = err.response ? err.response.data.errors[0] : ''
      if (errorMessage && errorMessage.code == 'EMAIL_IN_USE') {
        contactError.value = errorMessage.message
        return log.error(new Error(`${errorMessage.message}`), err)
      }
      else {
        contactError.value = 'Failed to save contact. Please try again'
        return log.error(new Error('failed to save contact'), err)
      }
    })
  }

  function calculateTotalPercentOwnership() {
    let _totalPercentOwnership = 0
    contacts.value.forEach(contact => {
      if(contact.percentOwnership) {
        _totalPercentOwnership += parseInt(contact.percentOwnership)
      }
    })

    totalPercentOwnership.value = _totalPercentOwnership
  }

  return {
    // STATE
    contacts,
    contactError,
    totalPercentOwnership,
    borrowerNotificationSettings,

    //Getter
    hasSingleOwner,

    //Action
    updateSingleContact,
    getBorrowerNotificationSettings,
    updateSingleSetting,
    updateBorrowerNotificationSetting,
    getAllContacts,
    saveNewContact,
    updateContact,
    calculateTotalPercentOwnership
  }
})
