
let usStates = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    db: true,
    territory: false
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    db: true,
    territory: false
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
    db: true,
    territory: true
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    db: true,
    territory: false
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    db: true,
    territory: false
  },
  {
    name: 'California',
    abbreviation: 'CA',
    db: true,
    territory: false
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    db: true,
    territory: false
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    db: true,
    territory: false
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    db: true,
    territory: false
  },
  {
    name: 'District of Columbia',
    abbreviation: 'DC',
    db: true,
    territory: false
  },
  {
    name: 'Federated States of Micronesia',
    abbreviation: 'FM',
    db: true,
    territory: true
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    db: true,
    territory: false
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    db: true,
    territory: false
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
    db: true,
    territory: true
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    db: true,
    territory: false
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    db: true,
    territory: false
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    db: true,
    territory: false
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    db: true,
    territory: false
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    db: true,
    territory: false
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    db: true,
    territory: false
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    db: true,
    territory: false
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    db: true,
    territory: false
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    db: true,
    territory: false
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
    db: true,
    territory: true
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    db: true,
    territory: false
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    db: true,
    territory: false
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    db: true,
    territory: false
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    db: true,
    territory: false
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    db: true,
    territory: false
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    db: true,
    territory: false
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    db: true,
    territory: false
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    db: true,
    territory: false
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    db: true,
    territory: false
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    db: true,
    territory: false
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    db: true,
    territory: false
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    db: true,
    territory: false
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    db: true,
    territory: false
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    db: true,
    territory: false
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    db: true,
    territory: false
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
    db: true,
    territory: false
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    db: true,
    territory: false
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    db: true,
    territory: false
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    db: true,
    territory: false
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
    db: true,
    territory: true
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    db: true,
    territory: false
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
    db: true,
    territory: true
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    db: true,
    territory: false
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    db: true,
    territory: false
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    db: true,
    territory: false
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    db: true,
    territory: false
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    db: true,
    territory: false
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    db: true,
    territory: false
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    db: true,
    territory: false
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
    db: true,
    territory: true
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    db: true,
    territory: false
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    db: true,
    territory: false
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    db: true,
    territory: false
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    db: true,
    territory: false
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    db: true,
    territory: false
  }
]

const optionToCreditScoreMap = [
  { id: '878', min: 300, max: 499 },
  { id: '880', min: 500, max: 599 },
  { id: '101', min: 600, max: 649 },
  { id: '100', min: 650, max: 679 },
  { id: '99', min: 680, max: 719 },
  { id: '98', min: 720, max: 850 }
]

const optionToTimeInBusinessMap = [
  { id: '109', min: -Infinity, max: 0 },
  { id: '1170', min: 1, max: 2 },
  { id: '110', min: 3, max: 5 },
  { id: '111', min: 6, max: 11 },
  { id: '112', min: 12, max: 23 },
  { id: '862', min: 24, max: 35 },
  { id: '864', min: 36, max: 59 },
  { id: '183', min: 60, max: Infinity }
]

const selectCreditScoreMap = [
  { value: '499', min: 100, max: 499 },
  { value: '599', min: 500, max: 599 },
  { value: '649', min: 600, max: 649 },
  { value: '679', min: 650, max: 679 },
  { value: '719', min: 680, max: 719 },
  { value: '850', min: 720, max: 990 },
]
let lsUtils = {
  creditScoreToOptionId: function creditScoreToOptionId (creditScore) {
    creditScore = parseInt(creditScore, 10)

    if (isNaN(creditScore)) {
      throw new Error('Invalid credit score')
    }

    return (optionToCreditScoreMap.find((range) => {
      return creditScore >= range.min && creditScore <= range.max
    }) || {}).id
  },

  optionIdToCreditScore: function optionIdToCreditScore (optionId) {
    optionId = parseInt(optionId, 10)

    if (isNaN(optionId)) {
      throw new Error('Invalid option id')
    }

    return (optionToCreditScoreMap.find((range) => {
      return parseInt(range.id, 10) === optionId
    }) || {})
  },

  getSelectionValue(creditScoreStr) {
    const creditScore = parseInt(creditScoreStr, 10)
    if (isNaN(creditScore) || creditScore < 100 || creditScore > 990) {
      return null
    }
    return (selectCreditScoreMap.find((range) => {
      return creditScore >= range.min && creditScore <= range.max
    })).value
  },

  timeInBusinessToOptionId: function timeInBusinessToOptionId (timeInBusiness) {
    timeInBusiness = parseInt(timeInBusiness, 10)

    if (isNaN(timeInBusiness)) {
      throw new Error('Invalid time in business')
    }

    return (optionToTimeInBusinessMap.find((range) => {
      return timeInBusiness >= range.min && timeInBusiness <= range.max
    }) || {}).id
  },

  optionIdToTimeInBusiness: function optionIdToTimeInBusiness (optionId) {
    optionId = parseInt(optionId, 10)

    if (isNaN(optionId)) {
      throw new Error('Invalid option id')
    }

    var range = Object.assign({}, (optionToTimeInBusinessMap.find((range) => {
      return parseInt(range.id, 10) === optionId
    }) || {}))

    if (range.max === Infinity) {
      range.max = range.min
    }

    if (range.min === -Infinity) {
      range.min = 0
    }

    return range
  },

  /**
   * @method getUsStates
   * @desc Return the list of US states.
   * @returns {Array}
   */
  getUsStates: () => {
    return usStates
  },

  getUsStateOptions: function () {
    return this.getUsStates().filter((state) => state.db && !state.territory).map(function (state) {
      return {
        label: state.name,
        value: state.abbreviation
      }
    })
  },
  getUsStateOptionsWithTerritories: function () {
    return this.getUsStates().filter((state) => state.db).map(function (state) {
      return {
        label: state.name,
        value: state.abbreviation
      }

    })
  },

  isMobile: () => {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
  }
}

export { lsUtils }
