import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'

export default defineNuxtRouteMiddleware(async (to) => {
  if (!process.server) {
    return
  }
  const nuxtApp = useNuxtApp()
  const { $pinia } = nuxtApp
  const req = nuxtApp.ssrContext.event.node.req
  const res = nuxtApp.ssrContext.event.node.res
  const affiliateCustomizationStore = useAffiliateCustomizationStore($pinia)

  if (to && req && res) {
    if (!req.headers.referer || !to.path.startsWith('/embedded')) {
      res.setHeader('X-Frame-Options', 'SAMEORIGIN')
      return
    }
    await affiliateCustomizationStore.getActiveAffiliateCustomizations()
    const whitelist = affiliateCustomizationStore.getHostUrls
    res.setHeader('Content-Security-Policy', `frame-ancestors ${whitelist.join(' ')}`)
  }
})
