// HELPER FUNCTIONS for increment route actions in the progress store.
import { differenceInMonths } from 'date-fns'
import env from './env'

/**
 * Calculate months in business to ensure the business has been in long enough to
 * have three months of bank statements
 * @param {DateTime} businessStartDate
 * @returns
 */
export function businessStartToMonthsInBusiness (businessStartDate) {
  if (businessStartDate && businessStartDate.value) {
    return differenceInMonths(
      new Date(),
      new Date(businessStartDate.value.split("T")[0])
    )
  }
  return 0
}

/**
 * Returns whether the borrower passed is gold plus and should always see documents
 * @param {string} mineralGroup
 * @returns
 */
export function alwaysDocuments (mineralGroup) {
  if (!mineralGroup || !/true/.exec(env('gpAlwaysDocs'))) {
    return false
  }
  if (['Gold', 'Platinum', 'Diamond'].indexOf(mineralGroup) > -1) {
    return true
  }
  return false
}

export default {
  businessStartToMonthsInBusiness,
  alwaysDocuments
}
