import { parseISO } from 'date-fns'
import { useBorrowerStore } from '~/store/borrower'
import { useRootStore } from '~/store/root'

function isExpired (dateSigned) {
  if (!dateSigned) {
    return false
  }
  const parsedDate = parseISO(dateSigned)
  // Handle the eSignature saving and invalidating if old, and TOS
  // 30 days
  let EXPIRATION_TIME = 30 * 24 * 60 * 60 * 1000
  return (new Date() - parsedDate) > EXPIRATION_TIME
}

export default defineNuxtPlugin(({ $pinia }) => {
  const rootStore = useRootStore($pinia)
  const isEmbeddedRoute = useRoute().path.startsWith('/embedded')
  const embeddedCheck = rootStore.isEmbedded || isEmbeddedRoute

  // Using an afterEach makes it actually check after each route change
  useRouter().afterEach(() => {
    if (!rootStore.isLoggedIn && !embeddedCheck) {
      return
    }
    // Do NOT block the render by waiting for these results
    useBorrowerStore($pinia).getBorrowerValues().then(borrowerValues => {
      if (!borrowerValues) return
      const dateSigned = borrowerValues['date_signed'].value
      const ipAddress = borrowerValues['ip_address'].value
      const missingIpOrDate = !ipAddress || !dateSigned
      if (missingIpOrDate || isExpired(dateSigned)) {
        useBorrowerStore($pinia).$patch({ signatureExpired: true })
      }
    })
  })
})
