import { defineStore } from 'pinia'

import {
  ownersTemplate,
} from '~/components/groupedRelationshipBoxTemplates/rowTemplates'

import env from '~/libs/env'
import { compatibleDate } from '~/libs/date-helpers'
import get from 'lodash/get'
import { useBorrowerStore } from '~/store/borrower'

export const useContactsStore = defineStore('contacts', () => {
  const borrowerStore = useBorrowerStore()
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

  const ownersLoaded = ref(false)
  const owners = ref([])
  const unmodifiedOwners = ref([])
  const primaryContactOwner = ref({})
  const ownersUnsavedAnswers = ref({})
  const lastOwnerModifiedDate = ref(null)
  const _ownersTemplate =ref(ownersTemplate)

 /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  const ownerRows = computed(() => {
    return owners.value.map((owner) => {
      const ownersTemplateQuestions = ownersTemplate.questions.map(
        (question) => {
          const newQuestion = { ...question }
          if (newQuestion.alias == 'owners.first') {
            newQuestion.value = owner.first
          }
          if (newQuestion.alias == 'owners.last') {
            newQuestion.value = owner.last
          }
          if (newQuestion.alias == 'owners.percentOwnership') {
            newQuestion.value = owner.percentOwnership
          }
          if (newQuestion.alias == 'owners.email') {
            newQuestion.value = owner.email
          }
          return newQuestion
        }
      )

      return {
        ...ownersTemplate,
        // If id isn't 'new' it's a preloaded owner. Setting both of these
        // to same value for better readability (isPrimary check).
        isPrimary: !!owner.isPrimary,
        storeRow: owner.id.toString().substring(0, 3) !== 'new',
        rowId: owner.id,
        questions: ownersTemplateQuestions
      }
    })
  })

  const savedAndUnsavedOwners = computed(() => {
    const allOwners = []
    for (let owner of owners.value) {
      const changes = {}
      if (ownersUnsavedAnswers.value.hasOwnProperty(owner.id)) {
        const dirtyFields = Object.entries(ownersUnsavedAnswers.value[owner.id])
        for (const [key, value] of dirtyFields) {
          const newKey = key.replace('owners.', '')
          changes[newKey] = value
        }
      }

      allOwners.push({ ...owner, ...changes })
    }
    return allOwners;
  })

  const ownersRows = computed(() => {
    return owners.value.map((owner) => {
      const ownersTemplateQuestions = ownersTemplate.questions.map(
        (question) => {
          const newQuestion = { ...question }
          if (newQuestion.alias == 'owners.first') {
            newQuestion.value = owner.first
          }
          if (newQuestion.alias == 'owners.last') {
            newQuestion.value = owner.last
          }
          if (newQuestion.alias == 'owners.percentOwnership') {
            newQuestion.value = owner.percentOwnership
          }
          if (newQuestion.alias == 'owners.email') {
            newQuestion.value = owner.email
          }
          return newQuestion
        }
      )

      return {
        ...ownersTemplate,
        // If id isn't 'new' it's a preloaded owner. Setting both of these
        // to same value for better readability (isPrimary check).
        isPrimary: !!owner.isPrimary,
        storeRow: owner.id.toString().substring(0, 3) !== 'new',
        rowId: owner.id,
        questions: ownersTemplateQuestions
      }
    })
  })


  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  function updateUnsavedOwner(index, prop, value) {
    ownersUnsavedAnswers.value = {...ownersUnsavedAnswers.value}
    ownersUnsavedAnswers.value[index][prop] = value
    ownersUnsavedAnswers.value[index][prop] = value
  }

  async function getOwnersByBorrowerId(borrowerId) {
    const url = `${env(
      'apiUrl'
    )}/borrower/${borrowerId}/contacts`
    const response = await $axios.get(url).catch((e) => {
      console.log(e)
    })
    const allOwners = get(response, 'data.data')
    primaryContactOwner.value = allOwners.find(x => x.isPrimary)
    owners.value = allOwners
      // TODO: confirm showing primary with Tyler B. Email is disabled.
      // .filter(x => !x.isPrimary)
      .map(contact => {
        if (contact.isPrimary) {
          // See if we have the ownership percent in borrower values.
          const bvOwnership = get(borrowerStore, 'borrowerValues.percentOwnership.value')
          if (bvOwnership != null) {
            contact.percentOwnership = bvOwnership
          }
        }
        const { id, first, last, email, percentOwnership, isPrimary } = contact
        return { id, first, last, email, percentOwnership, isPrimary}
      })
    unmodifiedOwners.value = [...owners.value]
    lastOwnerModifiedDate.value = (allOwners || [])
      .filter(x => x.contractSigner && x.modified)
      .map(x => x.modified)
      .sort((a, b) => {
        return new Date(compatibleDate(b)).getTime() - new Date(compatibleDate(a)).getTime();
      })?.[0] ?? null
    ownersLoaded.value = true
  }

  async function postOwners(borrowerId) {
    const modifiedOwners = []

    // Go through each owner that was entered in the UI form
    // when page was submitted. Apply the updates we tracked
    // in 'ownersUnsavedAnswers', and mark each as contract signers
    for (let owner of owners.value) {
      const changes = {}
      if (ownersUnsavedAnswers.value.hasOwnProperty(owner.id)) {
        const dirtyFields = Object.entries(ownersUnsavedAnswers.value[owner.id])
        for (const [key, value] of dirtyFields) {
          const newKey = key.replace('owners.', '')
          changes[newKey] = value
        }
      }
      modifiedOwners.push({...owner, ...changes, contractSigner: true, borrowerId: borrowerId })
    }

    // For any owner retrieved from backend, check to see if they are still included in the form.
    // If they aren't (i.e. they were deleted from the form or their email was modified) mark them
    // contractSigner=false but don't update any other info.
    for (let owner of unmodifiedOwners.value) {
      const overwrite = modifiedOwners.find(x => x.email?.toLowerCase() == owner.email?.toLowerCase())
      if (!overwrite) {
        owner['contractSigner'] = false,
        owner['borrowerId'] = borrowerId
        modifiedOwners.push(owner)
      }
    }

    const url = `${env(
      'apiUrl'
    )}/contact/upsert-contract-signers`
    const response = await $axios.post(url, modifiedOwners)
  }

  return {
    //STATE
    ownersUnsavedAnswers,
    ownersTemplate: _ownersTemplate,
    ownersLoaded,
    owners,
    unmodifiedOwners,
    primaryContactOwner,
    lastOwnerModifiedDate,

    //Getters
    ownerRows,
    ownersRows,
    savedAndUnsavedOwners,

    //Actions
    updateUnsavedOwner,
    getOwnersByBorrowerId,
    postOwners,
  }
})
