import { useRootStore } from '~/store/root'

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()
  const { $lendioCookies, $pinia } = nuxtApp
  const isEmbeddedRoute = to.fullPath.includes('/embedded')
  const isEmbeddedCookie = $lendioCookies.get('isEmbedded')
  const isEmbedded = isEmbeddedCookie || isEmbeddedRoute
  if (process.server || isEmbedded) return
  const rootStore = useRootStore($pinia)
  if (rootStore.expiryTimeoutSet) return 
  rootStore.setupTokenExpirationTimeout()
})
