import { defineStore } from 'pinia'
import NAV_LIST_CONFIG from '~/libs/navListConfig'
import { MBOX_DETAILS } from '~/libs/adobe-target'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy.js'
import isNil from 'lodash/isNil.js'
import env from '~/libs/env'

import { isAfter, sub, format } from 'date-fns'

import { localStorageService } from '~/libs/local-storage.service'
import { timestampToTimezoneDate } from '~/libs/date-helpers'

import { useAppAnalyticsStore } from '~/store/app-analytics'
import { useApplicationConfigStore } from '~/store/application-config'
import { useBorrowerStore } from '~/store/borrower'
import { useExperienceStore } from '~/store/experience'
import { useMatchingStore } from '~/store/matching'
import { useNavListConfigStore } from '~/store/nav-list-config'
import { useProgressCardStore } from '~/store/progress-card'
import { useRootStore } from '~/store/root'
import { useUserStore } from '~/store/user'
import appNameFromRoute from '~/libs/app-name-from-route'
import { compatibleDate } from '~/libs/date-helpers'
import { addDays} from 'date-fns'

/**
 * This store tracks application progress in three ways:
 * 1. appProgress state - In memory
 * 2. localStorage - Persists across page refreshes. Used for anonymous users
 *                   since we don't have a borrower ID yet to save via api/DB.
 * 3. api - ApplicationProgress lib via BorrowerApplicationController. Once a
 *          user is authenticated, this replaces localStorage for persisting.
 */

const getCreatedDateTime = (dt) => {
  const createdTimestamp = dt.getTime()
  const created = format(dt, 'yyyy-MM-dd HH:mm:ss')
  return {
    created,
    createdTimestamp,
  }
}

const EXPIRY_MODAL_KEY = 'has_seen_expiry_modal'

export const useProgressStore = defineStore('progress', () => {
  const nuxtApp = useNuxtApp()
  const { $axios, $lendioCookies } = nuxtApp
  const currentRoute = useRoute()
  const router = useRouter()
  const navListConfigStore = useNavListConfigStore()
  const borrowerStore = useBorrowerStore()
  const userStore = useUserStore()
  const rootStore = useRootStore()
  const matchingStore = useMatchingStore()
  const experienceStore = useExperienceStore()
  const appAnalyticsStore = useAppAnalyticsStore()
  const appConfigStore = useApplicationConfigStore()
  const progressCardStore = useProgressCardStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const appProgress = ref({})
  const applications = ref([])
  const borrowerApplicationProgression = ref({})
  const navigating = ref(null)
  const appLoad = ref({})
  const visitedRouteList = ref([])
  const progressFetched = ref(false)
  const appNavConfig = ref(NAV_LIST_CONFIG)
  const rowAnswerSets = ref([])
  const hasRowAnswerSets = ref(false)
  const reversing = ref(false)
  const _hasSeenExpiryModal = ref(null)
  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  const checkNavListConfigBoolean = computed(() => {
    return navListConfigStore.checkConfigBoolean
  })

  const sortedApplications = computed(() => {
    return applications.value
      .slice()
      .filter((app) => !/^ppp/.test(app.type))
      .sort((a, b) => {
        if ((!a.deleted && !b.deleted) || (a.deleted && b.deleted)) {
          return a.lastInteracted == b.lastInteracted
            ? 0
            : a.lastInteracted > b.lastInteracted
            ? -1
            : 1
        } else if (b.deleted) {
          return -1
        } else {
          return 1
        }
      })
  })

  /**
   * Returns an object representing the current app (examples: 'marketplace', 'embedded', 'sba-complete', etc).
   *
   * If on a whitelisted route, the app name is simply determined by that alone.
   * Otherwise, fallback to borrowerApplications, or 'marketplace'.
   *
   * @returns { type: string }
   */
  const currentApp = computed(() => {
    const nameFromRoute = appNameFromRoute(currentRoute) ?? 'marketplace'
    return { type: nameFromRoute }
  })

  const isCurrentAppSBA = computed(() => {
    return currentApp.value.type === 'sba'
  })

  const mpAppExpirationDate = computed(() => {
    const mpApp = sortedApplications.value.find((a) => a.type === 'marketplace')

    if (!mpApp?.completed) {
      return null
    }

    const completedApp = new Date(mpApp.completed * 1000)
    const mpAppExpirationDate = addDays(completedApp, 30)
    return mpAppExpirationDate
  })

  const appRoutePrefix = computed(() => {
    const name = appNameFromRoute(currentRoute);
    return name !== 'marketplace' ? name : '';
  })

  const isSbaCompleteAppComplete = computed(() => {
    const sbaCompleteApp = sortedApplications.value.find((a) => a.type === 'sba-complete')
    return sbaCompleteApp && sbaCompleteApp.completed
  })

  const lastInteractedApp = computed(() => {
    return sortedApplications.value[0] ?? { type: 'marketplace' }
  })

  const resolveAppNavList = computed(() => {
    if (!process.client) {
      return []
    }
    const nameFromRoute = appNameFromRoute(currentRoute)
    const navListForApp = appNavLists.value[nameFromRoute]
    return navListForApp
  })

  const appNavListCompleted = computed(() => {
    const navList = resolveAppNavList.value
    if (navList.length <= 1) {
      return false
    }
    return resolveAppNavList.value.slice(0, -1).every(function (navItem) {
      return navItem.isComplete
    })
  })

  const marketplaceNavList = computed(() => {
    const mpNavList = appNavLists.value['marketplace']
    return mpNavList
  })

  const lastBorrowerApplicationForCurrentApp = computed(() => {
    const type = currentApp.value.type
    return sortedApplications.value.find(application => application.type === type) ?? null
  })

  const lastBorrowerApplicationForMarketplaceApp = computed(() => {
    return sortedApplications.value.find(application => application.type === 'marketplace') ?? null
  })

  const isRenewalAppComplete = computed(() => {
    const _sortedApplications = sortedApplications.value
    const renewalApp = _sortedApplications.find((a) => a.type === 'renewal')
    return renewalApp ? renewalApp.completed !== null : false
  })

  const isEmbeddedAppComplete = computed(() => {
    const _sortedApplications = sortedApplications.value
    const embeddedApp = _sortedApplications.find((a) => a.type === 'embedded')
    return embeddedApp && embeddedApp.completed
  })

  const isEmbeddedAppExpired = computed(() => {
    const _sortedApplications = sortedApplications.value
    const embeddedApp = _sortedApplications.find((a) => a.type === 'embedded')
    const thirtyDaysAgo = sub(new Date(), { days: 30 })
    return embeddedApp
      ? isAfter(thirtyDaysAgo, new Date(compatibleDate(embeddedApp.created)))
      : false
  })

  const hasEmbeddedApp = computed(() => {
    const _sortedApplications = sortedApplications.value
    return (
      _sortedApplications.reduce(
        (p, c) => (c.type === 'embedded' ? p + 1 : p),
        0
      ) > 0
    )
  })

  const renewalApps = computed(() => {
    const _sortedApplications = sortedApplications.value
    return _sortedApplications.filter((a) => a.type === 'renewal')
  })

  const dnqReturnApp = computed(() => {
    return lastInteractedApp.value.type == 'dnqReturn' == 'dnqReturn'
  })

  const returnAppExperience = computed(() => {
    return (
      lastInteractedApp.value.type == 'dnqReturn' || applications.value.length > 1
    )
  })

  /**
   * For an app, allows a list of required actions that must take place before each increment route
   */
  const requiredActions = computed(() => {
    const _appNavConfig = appNavConfig.value
    const _checkNavListConfigBoolean = checkNavListConfigBoolean.value
    return Object.keys(_appNavConfig).reduce((actionLists, appName) => {
      const config = _appNavConfig[appName]
      // Grab all the required actions on sections and routes
      const actions = config.flatMap((section) => {
        const sectionActions = section.requiredActions || []
        return sectionActions.concat(
          section.routes.flatMap((route) => route.requiredActions || [])
        )
      })
      // Allow for actions to be filtered based on preReq conditions
      const filteredActions = actions
        .filter((requiredAction) => {
          return requiredAction.hasOwnProperty('filterP') ||
            requiredAction.hasOwnProperty('filter')
            ? _checkNavListConfigBoolean(
                requiredAction.hasOwnProperty('filterP')
                  ? requiredAction.filterP
                  : requiredAction.filter
              )
            : true
        })
        .flatMap((requiredAction) => requiredAction.actionsP)
      // return the unique actions
      actionLists[appName] = Object.values(
        filteredActions
          .filter((action) => action.length == 2)
          .reduce((carry, action) => {
            let key = `${action[0]}/${action[1]}`
            carry[key] = action
            return carry
          }, {})
      ).concat(filteredActions.filter((action) => action.length > 2))
      return actionLists
    }, {})
  })

  const enabledNavLists = computed(() => {
    const _appNavConfig = appNavConfig.value
    const _checkNavListConfigBoolean = checkNavListConfigBoolean.value
    return Object.keys(_appNavConfig).reduce((navLists, appName) => {
      navLists[appName] = _appNavConfig[appName]
        .map((s) => {
          const section = { ...s, routes: s.routes || [] }
          // section is enabled
          if (
            section.hasOwnProperty('enabledP') ||
            section.hasOwnProperty('enabled')
          ) {
            section.enabled = _checkNavListConfigBoolean(
              section.hasOwnProperty('enabledP')
                ? section.enabledP
                : section.enabled
            )
          }
          section.routes = section.routes.map((route) => ({
            ...route,
            enabled:
              route.hasOwnProperty('enabledP') ||
              route.hasOwnProperty('enabled')
                ? _checkNavListConfigBoolean(
                    route.hasOwnProperty('enabledP')
                      ? route.enabledP
                      : route.enabled
                  )
                : true,
            enabledP:
              route.hasOwnProperty('enabledP') ||
              route.hasOwnProperty('enabled')
                ? _checkNavListConfigBoolean(
                    route.hasOwnProperty('enabledP')
                      ? route.enabledP
                      : route.enabled
                  )
                : true,
          }))
          // filter routes to be active routes
          section.routes = section.routes.filter((r) => r.enabled)
          // Don't have enabled sections without active routes
          const activeRoutes = Boolean(section.routes.length)
          section.enabled =
            typeof section.enabled == 'boolean'
              ? section.enabled && activeRoutes
              : activeRoutes
          section.enabledP = section.enabled
          return section
        })
        .filter((s) => s.enabled)
      return navLists
    }, {})
  })

  const visitedNavLists = computed(() => {
    const _enabledNavLists = enabledNavLists.value
    const _checkNavListConfigBoolean = checkNavListConfigBoolean.value
    const _hasVisited = hasVisited.value
    return Object.keys(_enabledNavLists).reduce((navLists, appName) => {
      // is visited
      navLists[appName] = _enabledNavLists[appName].map((s) => {
        const section = { ...s }
        section.routes = section.routes.map((route) => ({
          ...route,
          visited:
            route.hasOwnProperty('visitedP') || route.hasOwnProperty('visited')
              ? _checkNavListConfigBoolean(
                  route.hasOwnProperty('visitedP')
                    ? route.visitedP
                    : route.visited
                )
              : _hasVisited({ route: route.route, appName }),
          visitedP:
            route.hasOwnProperty('visitedP') || route.hasOwnProperty('visited')
              ? _checkNavListConfigBoolean(
                  route.hasOwnProperty('visitedP')
                    ? route.visitedP
                    : route.visited
                )
              : _hasVisited({ route: route.route, appName }),
        }))
        section.visited =
          section.hasOwnProperty('visitedP') ||
          section.hasOwnProperty('visited')
            ? _checkNavListConfigBoolean(
                section.hasOwnProperty('visitedP')
                  ? section.visitedP
                  : section.visited
              )
            : section.routes.some((r) => r.visited)
        section.visitedP = section.visited
        return section
      })
      return navLists
    }, {})
  })

  const hasVisited = computed(() => {
    const hasOptimusUser = userStore.hasOptimusUser
    return ({ route, appName = null } = {}) =>
      appName && hasOptimusUser
        ? visitedForApp.value[appName].indexOf(route) > -1
        : visitedRoutes.value.indexOf(route) > -1
  })

  const hasVisitedAppConfig = computed(() => {
    return visitedRoutes.value.some(route => {
      return route.includes(appConfigStore.appIdentifierRoute)
    })
  })

  const visitedRoutes = computed(() => {
    return visitedRouteList.value.map(({ route }) => route)
  })

  const hasAdvanced = computed(() => {
    return (route, appName) => {
      const visitedRoutes = visitedRouteLists.value[appName]
      const _nextRoute = nextRoute.value({ currentPath: route, appName })
      return visitedRoutes.indexOf(_nextRoute) > -1
    }
  })

   /**
     * Returns a boolean if the borrower has seen owner-info according to local storage or visited routes
     * @returns
     */
   const basicInfoComplete = computed(() => {
    const _hasVisited = hasVisited.value
    const _hasVisitedAppConfig = hasVisitedAppConfig.value
    return (
      _hasVisitedAppConfig ||
      _hasVisited({ route: '/funding-needs', appName: currentApp.value.type })
      || _hasVisited({ route: '/owner-info', appName: 'marketplace' }) ||
      _hasVisited({
        route: '/owner-info/owner-info-2',
        appName: 'marketplace'
      }) ||
      _hasVisited({
        route: '/preapproval-app/owner-info-2',
        appName: 'marketplace'
      }) ||
      _hasVisited({
        route: '/marketplace-app/owner-info-2',
        appName: 'marketplace'
      }) ||
      _hasVisited({ route: '/newpass' })
    )
  })

  /**
     * Returns a boolean if the borrower has seen business-info according to visited routes
     * @returns
     */
  const ownerInfoComplete = computed(() => {
    const _hasVisited = hasVisited.value
    return (
      _hasVisited({ route: '/marketplace-app/business-info', appName: 'marketplace' }) ||
      _hasVisited({ route: '/preapproval-app/business-info', appName: 'marketplace' }) ||
      _hasVisited({ route: '/business-info', appName: 'marketplace' })
    )
  })

  const businessInfoComplete = computed(() => {
    const _hasVisited = hasVisited.value
    return (
      _hasVisited({ route: '/marketplace-app/documents', appName: 'marketplace' }) ||
      _hasVisited({ route: '/submit', appName: 'marketplace' }) ||
      _hasVisited({ route: '/marketplace-app/submit', appName: 'marketplace' })
    )
  })

  const documentsComplete = computed(() => {
    const _hasVisited = hasVisited.value
    return _hasVisited({ route: '/marketplace-app/documents', appName: 'marketplace' })
  })

  const appNavLists = computed(() => {
    const _visitedNavLists = visitedNavLists.value
    const _checkNavListConfigBoolean = checkNavListConfigBoolean.value
    return Object.keys(_visitedNavLists).reduce((navLists, appName) => {
      // is completed
      navLists[appName] = _visitedNavLists[appName].map((s) => {
        const section = { ...s }
        section.routes = section.routes.map((route) => ({
          ...route,
          isComplete:
            route.hasOwnProperty('isCompleteP') ||
            route.hasOwnProperty('isComplete')
              ? _checkNavListConfigBoolean(
                  route.hasOwnProperty('isCompleteP')
                    ? route.isCompleteP
                    : route.isComplete
                )
              : route.visited && hasAdvanced.value(route.route, appName),
          isCompleteP:
            route.hasOwnProperty('isCompleteP') ||
            route.hasOwnProperty('isComplete')
              ? _checkNavListConfigBoolean(
                  route.hasOwnProperty('isCompleteP')
                    ? route.isCompleteP
                    : route.isComplete
                )
              : route.visited && hasAdvanced.value(route.route, appName),
        }))
        section.isComplete =
          section.hasOwnProperty('isCompleteP') ||
          section.hasOwnProperty('isComplete')
            ? _checkNavListConfigBoolean(
                section.hasOwnProperty('isCompleteP')
                  ? section.isCompleteP
                  : section.isComplete
              )
            : section.routes.every((r) => r.isComplete)
        section.isCompleteP = section.isComplete
        return section
      })
      return navLists
    }, {})
  })


  const orderedRouteLists = computed(() => {
    const _enabledNavLists = enabledNavLists.value
    return Object.keys(_enabledNavLists).reduce((routeLists, appName) => {
      routeLists[appName] = _enabledNavLists[appName].flatMap((s) =>
        s.routes.map((r) => r.route)
      )
      return routeLists
    }, {})
  })

  const visitedRouteLists = computed(() => {
    const _visitedNavLists = visitedNavLists.value
    return Object.keys(_visitedNavLists).reduce((routeLists, appName) => {
      routeLists[appName] = _visitedNavLists[appName].flatMap((s) =>
        s.routes.filter((r) => r.visited).map((r) => r.route)
      )
      return routeLists
    }, {})
  })

  const completedRouteLists = computed(() => {
    const _appNavLists = appNavLists.value
    return Object.keys(_appNavLists).reduce((routeLists, appName) => {
      routeLists[appName] = _appNavLists[appName].flatMap((s) =>
        s.routes.filter((r) => r.isComplete).map((r) => r.route)
      )
      return routeLists
    }, {})
  })

  /**
   * Return the routes that should be visited per app config
   * @returns
   */
  const visitedForApp = computed(() => {
    const routeTimes = visitedRouteList.value.reduce(
      (carry, { route, createdTimestamp }) => {
        if (!carry[route] || carry[route] < createdTimestamp) {
          carry[route] = createdTimestamp
        }
        return carry
      },
      {}
    )

    const visitedRoutes = sortedApplications.value
      .filter(({ deleted }) => !deleted)
      .reduce((carry, { type, started }) => {
        carry[type] = Object.keys(routeTimes).filter(
          (route) => routeTimes[route] > started * 1000
        )
        return carry
      }, {})
    return new Proxy(visitedRoutes, {
      get(target, appName) {
        appName = appName == 'dnqReturn' ? 'marketplace' : appName
        if (target.hasOwnProperty(appName)) {
          return target[appName]
        }
        return []
      },
    })
  })

  /**
   * Returns a boolean if the borrower has seen owner-info according to local storage or visited routes
   * @returns
   */
  const hasSeenOwnerInfo = computed(() => {
    const _hasVisited = hasVisited.value
    const _hasVisitedAppConfig = hasVisitedAppConfig.value
    return (
      _hasVisitedAppConfig ||
      _hasVisited({ route: '/owner-info', appName: currentApp.value.type }) ||
      _hasVisited({
        route: '/owner-info/owner-info-2',
        appName: currentApp.value.type,
      }) ||
      _hasVisited({
        route: '/preapproval-app/owner-info-2',
        appName: currentApp.value.type,
      }) ||
      _hasVisited({
        route: '/marketplace-app/owner-info-2',
        appName: currentApp.value.type,
      })
    )
  })

  /**
   * Returns a boolean if the borrower has seen waypoint-0 to local storage or visited routes
   * @returns
   */
  const hasSeenWaypoint0 = computed(() => {
    const _hasVisited = hasVisited.value
    return _hasVisited({ route: '/waypoint-0', appName: currentApp.value.type })
  })

  const getHasRowAnswerSets = computed(() => {
    return hasRowAnswerSets.value
  })

  /**
   * Returns a boolean if the borrower has at least one funding need in localStorage or db AND visited
   * @returns
   */
  const fundingNeedsComplete = computed(() => {
    if (getHasRowAnswerSets.value && hasSeenOwnerInfo.value) {
      return true
    }
  })

  /**
   * Gets the last route a user completed (in the application) during a
   * previous session.
   *
   * Used by resume-app.vue.
   *
   * @returns {function(null=): *} - The route string (e.g. /basic-info)
   */
  const resumeApplicationRoute = computed(() => {
    const visitedAppRoutes = visitedRouteLists.value,
      orderedAppRoutes = orderedRouteLists.value,
      currentType = lastInteractedApp.value.type
    return (type = null) => {
      type = type || currentType
      type = type == 'dnqReturn' || /ppp/.test(type) || /erc/.test(type) ? 'marketplace' : type
      const orderedRoutes = orderedAppRoutes[type],
        visited = visitedAppRoutes[type]

      const route = orderedRoutes.find((route, index) => {
        return (
          index == orderedRoutes.length - 1 ||
          !visited.includes(orderedRoutes[index + 1])
        )
      })
      return route
    }
  })

  /**
   * Get the next route in the application.
   *
   * This happens between pages (e.g. /basic-info to /owner-info), not
   * questions within a page.
   *
   * @returns {({ currentPath: string, appName: string, forward: ?boolean}) => string} - Returns a function
   *          that returns the next route path.
   */
  const nextRoute = computed(() => {
    const resumeRoute = resumeApplicationRoute.value
    return ({ currentPath, appName, forward = true }) => {
      appName = appName ?? currentApp.value.type
      if (appName == 'dnqReturn' || /ppp/.test(appName)) {
        appName = 'marketplace'
      }
      const enabledRoutes = orderedRouteLists.value[appName]
      const currentPathIndex = enabledRoutes.indexOf(currentPath)

      if (currentPathIndex == -1) {
        const route = resumeRoute(appName)
        return forward
          ? nextRoute.value({ currentPath: route, appName }) || route
          : route
      } else {
        return enabledRoutes[currentPathIndex + (forward ? 1 : -1)]
      }
    }
  })

  const basicInfoWaypoint = computed(() => {
    const _hasVisitedAppConfig = hasVisitedAppConfig.value
    return (
      rootStore.isLoggedIn &&
      matchingStore.acceptedProducts.length &&
      !experienceStore.isDNQ &&
      !hasSeenOwnerInfo.value &&
      !_hasVisitedAppConfig
    )
  })

  const lastFourDigitSSN = computed(() => {
    return (
      rootStore.isLoggedIn &&
      experienceStore.isDNQ &&
      appAnalyticsStore.hasTargetOffer(
        MBOX_DETAILS.LAST_FOUR_DIGIT_SSN.NAME,
        'lastFourDigitSSN'
      )
    )
  })

  const creditPullTest = computed(() => (location, type) => {
    // Do not show credit pull for DNQ
    if (
      !userStore.hasOptimusUser ||
      (type == 'creditPull' && experienceStore.isDNQ)
    ) {
      return false
    }
    // If not in test, default to normal experience
    const defaultExp =
      location == 'early' &&
      !appAnalyticsStore.getTargetOffer(MBOX_DETAILS.CREDIT_PULL_LOCATION.NAME)
    return (
      defaultExp ||
      appAnalyticsStore.hasTargetOffer(
        MBOX_DETAILS.CREDIT_PULL_LOCATION.NAME,
        location,
        { contains: true }
      )
    )
  })

  const hasSeenExpiryModal = computed(() => {
    if (_hasSeenExpiryModal.value === null) {
      _hasSeenExpiryModal.value = JSON.parse($lendioCookies.get(EXPIRY_MODAL_KEY) ?? 'false')
    }
    return _hasSeenExpiryModal.value
  })

  // /**
  //  * Get the next route in the application.
  //  *
  //  * This happens between pages (e.g. /basic-info to /owner-info), not
  //  * questions within a page.
  //  *
  //  * @returns {({ currentPath: string, forward: ?boolean}) => string} - Returns a function
  //  *          that returns the next route path.
  //  */
  // nextRoutePath() {
  //   return ({ currentPath, forward = true }) => {
  //     const routes = [...APP_PAGES]
  //     if (!forward) {
  //       routes.reverse()
  //     }
  //     const findNextRoute = (path) => {
  //       const index = routes.findIndex((r) => r.path === path)
  //       const segment = routes.slice(index + 1)
  //       return segment.find((r) => {
  //         if (r.mustQualify && useExperienceStore().isDNQ) {
  //           return false
  //         }
  //         if (r.notSkippable) {
  //           return true
  //         }
  //         if (r.includeGetter) {
  //           return rootGetters[r.includeGetter]
  //         }
  //         // Was erroring on .length when store didn't have a value.
  //         return r.questionsGetter && useQuestionsStore(this.$nuxt.pinia).rootGetters[r.questionsGetter]
  //           ? rootGetters[r.questionsGetter].length
  //           : true
  //       })
  //     }
  //     let nextRoute = findNextRoute(currentPath)
  //     return nextRoute.path
  //   }
  // },

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  function pushVisitedRoute(route) {
    const { created, createdTimestamp } = getCreatedDateTime(new Date())
    const visitedRoute = {
      route,
      created,
      createdTimestamp,
    }
    visitedRouteList.value.push(visitedRoute)
  }

  function setApplication(application) {
    const idx = applications.value.findIndex(
      (a) => a.type == application.type && !a.deleted
    )
    if (idx > -1 && applications.value[idx].id == application.id) {
      applications.value.splice(idx, 1, application)
    } else if (idx > -1) {
      applications.value[idx] = {
        ...applications.value[idx],
        deleted: new Date(),
      }
      applications.value.push(application)
    } else {
      applications.value.push(application)
    }
  }

  function setApplications(_applications) {
    applications.value = _applications
  }

  async function getVisitedRoutes() {
    if (rootStore.pwfSignupInProgress) {
      visitedRouteList.value = []
      return
    }

    if (!userStore.hasOptimusUser || visitedRouteList.value.length > 2) {
      return
    }
    const borrowerId = get(borrowerStore, 'borrowerId')
    if (!borrowerId) {
      return
    }

    const data = await $axios
      .get(
        `${env(
          'apiUrl'
        )}/borrower-portal-page-views/borrower/${borrowerId}/routes`
      )
      .then((response) => response.data.data)
      .catch((error) => (console.error(error), []))
    visitedRouteList.value = [
      ...data.map((route) => ({
        ...route,
        createdTimestamp: route.createdTimestamp * 1000,
      })),
    ]
  }

  async function fetchApplications() {
    if (rootStore.pwfSignupInProgress) {
      return
    }

    if (!userStore.hasOptimusUser) return

    // If we ain't got no borrower, don't proceed.
    const borrowerId = get(borrowerStore, 'borrowerId')
    if (!borrowerId) return

    const { data } = await $axios
      .get(`${env('apiUrl')}/borrower/${borrowerId}/borrower-applications`)
      .then((response) => response.data)
      .catch(
        (e) => (
          console.error('Error retrieving borrower application data', e),
          { data: null }
        )
      )
    if (data) {
      applications.value = data
    }
  }

  async function updateApplication({
    update,
    type = null,
    progression = null,
  } = {}) {
    if (!userStore.hasOptimusUser) return
    const borrowerId = get(borrowerStore, 'borrowerId')
    if (!borrowerId) return

    const _currentApp = type ?? currentApp.value.type
    const { data } = await $axios
      .post(
        `${env(
          'apiUrl'
        )}/borrower/${borrowerId}/borrower-applications/interaction`,
        {
          type: _currentApp,
          interaction: update,
          progression: progression,
        }
      )
      .then((response) => response.data)
      .catch((e) =>
        console.error('Unable to update borrower application', { data: null })
      )
    if (data && data.type) {
      this.setApplication(data)
      if (data.type === 'embedded') {
        // BorrowerApplicationProgressions are jobified.  If set via client request, let's make sure we're current
        switch (progression) {
          case 'dnq':
            borrowerApplicationProgression.value = { ...borrowerApplicationProgression.value, dnq: Date.now() }
            break
          default:
            return
        }
      }
    }
  }

  async function fetchBorrowerApplicationProgression() {
    const borrowerId = get(borrowerStore, 'borrowerId')
    if (!borrowerId) return

    const { data } = await $axios
      .get(
        `${env('apiUrl'
        )}/borrower/${borrowerId}/borrower-applications/application-progression`
      )
      .then(response => response.data)
      .catch(e => {
        console.error('Unable to fetch borrower application progression', { data: null})
      })

    if (data){
      this.setBorrowerApplicationProgression(data)
    }
  }

  async function sbaCheckAppComplete() {
    const sbaApp = sortedApplications.value.find((app) => app.type == 'sba')
    if ((sbaApp && sbaApp.completed) || progressCardStore.sbaProgress !== 100) {
      await this.updateApplication({ type: 'sba' })
    } else {
      await this.updateApplication({ type: 'sba', update: 'complete' })
    }
  }

  async function checkLocalStorage() {
    const localStorageData = localStorage.rowAnswerSets
    if (localStorageData) {
      try {
        const parsedData = JSON.parse(localStorageData)
        if (Array.isArray(parsedData) && parsedData.length > 0) {
          rowAnswerSets.value = parsedData

          hasRowAnswerSets.value = true
        } else {
          rowAnswerSets.value = []

          hasRowAnswerSets.value = false
        }
      } catch (error) {
        console.error('Error parsing localStorage.rowAnswerSets:', error)
        rowAnswerSets.value = []
        hasRowAnswerSets.value = false
      }
    } else {
      rowAnswerSets.value = []
      hasRowAnswerSets.value = false
    }
  }

  /**
   * Executes all of the configured required actions for a navListConfig to function.
   *
   * Handles string and [actionString, payload] array configs
   * @param {*} param1
   * @returns
   */
  async function executeRequiredActions({ appName, initOnly = false } = {}) {
    appName = appName || currentApp.value.type
    const appConfig = appName == 'dnqReturn' ? 'marketplace' : appName
    const _requiredActions = requiredActions.value[appConfig] || []
    if (!initOnly || !appLoad.value[appConfig]) {
      appLoad.value[appConfig] = true
      await Promise.all(
        _requiredActions.map((action) => {
          return navListConfigStore.runRequiredActions(action)
        })
      )
    }
  }

  function clearLoadedApplications() {
    appLoad.value = {}
  }

  /**
   * Navigate to the next valid app route.
   *
   * @param {string} currentPath
   * @param {boolean} forward - Whether to go forward or backward.
   */
  async function incrementRoute({
    currentPath = null,
    appName = null,
    forward = true,
  } = {}) {
    if (!currentRoute) {
      log.warning(
        '[progress] @incrementRoute: Cannot route without currentRoute defined'
      )
      return false
    }
    appName = appName ?? currentApp.value.type
    currentPath = currentPath || get(currentRoute, 'path')
    await this.executeRequiredActions({ appName })
    let path = nextRoute.value({ currentPath, forward, appName })
    let progression = null

    navigating.value = forward ? 'forward' : 'back'
    if (forward) {
      await this.updateApplication({
        type: appName,
        progression: progression,
      })
    }

    await navigateTo(path)
  }

  /**
   * Navigate to a specific app route.
   *
   * @param {string} currentPath
   * @param {vueRouter} router - The vue router.
   * @param {string} route - Route to go to
   * @param {boolean} forward - Whether to go forward or backward.
   */
  async function appNavigate({
    currentPath = null,
    route = '',
    appName = '',
    forward = true,
  } = {}) {
    appName = appName ? appName : currentApp.value.type
    currentPath = currentPath || get(currentRoute, 'path')
    await Promise.all(
      requiredActions.value[appName].map((action) => {
        return navListConfigStore.runRequiredActions(action)
      })
    )
    let path = route

    navigating.value = forward ? 'forward' : 'back'
    if (forward) {
      await this.updateApplication({ type: appName })
    }
    router.push(path)
  }

  /**
   * Save the current progress in state and localStorage. This is always
   * initiated by the user in the browser (not run on Nuxt server).
   *
   * @param {string} key - The route path key (e.g. basic-info).
   * @param {number} value - Number >= 1 indicated completed status AND
   *                         sub-path (e.g. owner-info-2) where applicable.
   */
  async function saveProgressTab({ key, value }) {
    // Load existing appProgress from localStorage to ensure it's in state
    // after page reloads.
    await this.loadLocalProgress()
    // Merge our new key/value with existing appProgress from state into a
    // new object since state is immutable.
    let currentProgress = Object.assign({}, appProgress.value, {
      [key]: value,
    })

    // Commit it to state and update localStorage.
    appProgress.value = currentProgress
    if (process.client) {
      localStorageService.setItem(
        'appProgress',
        JSON.stringify(currentProgress)
      )
    }
  }

  /**
   * Load appProgress from localStorage into state.
   *
   * Used for unauthenticated users.
   */
  async function loadLocalProgress() {
    if (process.client) {
      // Default to no progress {} if none is available.
      const currentProgress = JSON.parse(
        localStorageService.getItem('appProgress') || '{}'
      )
      appProgress.value = currentProgress
    }
  }

  /**
   * Clear out appProgress in both state and localStorage.
   */
  function clearProgress() {
    appProgress.value = {}
    progressFetched.value = false
    if (process.client) {
      localStorageService.removeItem('appProgress')
    }
  }

  /**
   * Update state with the current appProgress from localStorage or api.
   *
   * If user is not autenticated (!rootState.authUser) we grab it from
   * localStorage.
   *
   * @param {object} options described here:
   *   force - fetch from api (for authenticated users) even if we've already
   *           done it recently.
   *   params - appName. Defaults to marketplaceOld if not provided.
   *   route - used with resume-app.vue. Current $route.
   * @returns void
   */
  async function fetchProgress(options = {}) {
    let { force = false, params, route } = options
    if (progressFetched.value && !force) {
      return
    }

    // If user isn't yet authenticated, we use localStorage (not api) to
    // track progress.
    if (!rootStore.authUser) {
      return await this.loadLocalProgress()
    }

    // If we ain't got no borrower, don't proceed.
    const borrowerId = get(borrowerStore, 'borrowerId')
    if (!borrowerId) return

    const progressFetcher = await $axios
      .get(
        `${env(
          'apiUrl'
        )}/borrower/${borrowerId}/borrower-application/app-progress`,
        { params }
      )
      .catch((error) => {
        if (error && error.response && error.response.status === 500) {
          log.error('Unable to retrieve application progress', { error })
        }
      })

    // The user is authenticated so we don't depend on localStorage for
    // current progress here.
    let progress = { ...progressFetcher.data.data }
    await this.loadLocalProgress()
    this.setProgress(progress)
  }

  function setProgress(progress) {
     // Merge fetched progress with local we just put in state. Give precedence
    // to fetched when in conflict.
    progress = Object.assign({}, appProgress.value, progress)

    // Update state and local with the latest.
    if (process.client) {
      localStorageService.setItem('appProgress', JSON.stringify(progress))
    }
    appProgress.value = progress
    progressFetched.value = true
  }

  function setBorrowerApplicationProgression(progression) {
    progression = omitBy(progression, isNil)
    borrowerApplicationProgression.value = { ...borrowerApplicationProgression.value, ...progression }
  }

  function getAppDate(application) {
    if (!application) {
      return null
    }

    const appDate = ((application?.completed || application?.started || 0) * 1000) || application?.created
    if (!appDate) {
      return null
    }

    return typeof appDate === 'number' ? timestampToTimezoneDate(appDate) : new Date(compatibleDate(appDate))
  }
  function dismissExpiryModal() {
    $lendioCookies.set(EXPIRY_MODAL_KEY, 'true')
    _hasSeenExpiryModal.value = true;
  }

  return {
    // STATE
    applications,
    appLoad,
    appNavConfig,
    appProgress,
    appRoutePrefix,
    borrowerApplicationProgression,
    hasRowAnswerSets,
    lastInteractedApp,
    navigating,
    reversing,
    progressFetched,
    rowAnswerSets,
    visitedRouteList,

    // GETTERS
    appNavLists,
    basicInfoComplete,
    basicInfoWaypoint,
    checkNavListConfigBoolean,
    completedRouteLists,
    creditPullTest,
    currentApp,
    dnqReturnApp,
    enabledNavLists,
    fundingNeedsComplete,
    getHasRowAnswerSets,
    hasAdvanced,
    hasEmbeddedApp,
    hasSeenOwnerInfo,
    hasSeenExpiryModal,
    hasSeenWaypoint0,
    hasVisited,
    hasVisitedAppConfig,
    isEmbeddedAppComplete,
    isEmbeddedAppExpired,
    isRenewalAppComplete,
    isSbaCompleteAppComplete,
    lastBorrowerApplicationForCurrentApp,
    lastBorrowerApplicationForMarketplaceApp,
    lastFourDigitSSN,
    mpAppExpirationDate,
    nextRoute,
    orderedRouteLists,
    ownerInfoComplete,
    businessInfoComplete,
    renewalApps,
    requiredActions,
    resolveAppNavList,
    appNavListCompleted,
    resumeApplicationRoute,
    returnAppExperience,
    sortedApplications,
    visitedForApp,
    visitedNavLists,
    visitedRouteLists,
    visitedRoutes,
    marketplaceNavList,
    isCurrentAppSBA,
    documentsComplete,

    // ACTIONS
    appNavigate,
    checkLocalStorage,
    loadLocalProgress,
    clearProgress,
    clearLoadedApplications,
    executeRequiredActions,
    fetchApplications,
    fetchProgress,
    getAppDate,
    getVisitedRoutes,
    incrementRoute,
    pushVisitedRoute,
    saveProgressTab,
    sbaCheckAppComplete,
    setApplication,
    setApplications,
    setBorrowerApplicationProgression,
    fetchBorrowerApplicationProgression,
    setProgress,
    updateApplication,
    dismissExpiryModal,
  }
})
