// 'USE OF FUNDS', 'BANK VERIFICATION' other stipulations we will be using
const textStipulationFilters = ['VALID PHONE NUMBER', 'ALTERNATIVE PHONE NUMBER', 'USE OF FUNDS']

export function filterTextStipulations(textStipulations, dealId) {
    return textStipulations.filter(textStip => {
        if (textStipulationFilters.includes(textStip.name.toUpperCase()) && textStip.type === 'textRequest' && textStip.dealId === dealId) {
            return textStip;
        }
    });
}

export default {
  filterTextStipulations
}
