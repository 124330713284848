import { differenceInMonths } from 'date-fns'
import get from 'lodash/get'

const mineralTypes = {
  diamond: 'Diamond',
  platinum: 'Platinum',
  gold: 'Gold',
  silver: 'Silver',
  coal: 'Coal',
}

function getMineral (answers, borrower = { borrower: {} }) {
  let mineral = null
  const mineralQuestions = {
    creditScore: get(borrower, 'borrowerValues.creditScore.value', null),
    business_start_date: get(borrower, 'borrowerValues.business_start_date.value', null),
    average_monthly_sales: get(borrower, 'borrowerValues.average_monthly_sales.value', null),
  }

  for (const question in mineralQuestions) {
    // Set the mineralQuestion to the answer that we have
    if (answers.hasOwnProperty(question) && answers[question].value) {
      mineralQuestions[question] = answers[question].value
    }
    if (!mineralQuestions[question]) {
      return mineral
    }
  }

  // Calculate and return mineral
  const monthsInBusiness = differenceInMonths(new Date(), new Date(mineralQuestions.business_start_date))
  const monthlySales = parseInt(mineralQuestions.average_monthly_sales)

  // All mineral groups require 6+ months in business and a +500 credit score. Return coal if borrower does not meet these requirements
  if (monthsInBusiness < 6 || mineralQuestions.creditScore < 500) {
    mineral = mineralTypes.coal
  // Return mineral based on monthly sales
  } else if (monthlySales > 100000) {
    mineral = mineralTypes.diamond
  } else if (monthlySales > 50000) {
    mineral = mineralTypes.platinum
  } else if (monthlySales > 20000) {
    mineral = mineralTypes.gold
  } else if (monthlySales > 8000) {
    mineral = mineralTypes.silver
  } else {
    mineral = mineralTypes.coal
  }

  return mineral
}

export { getMineral, mineralTypes }
