export default class Log {
  constructor (rollbar, verbose = false) {
    this.rollbar = rollbar
    this.verbose = verbose
  }

  critical (message, data = null) {
    this.write('critical', message, data)
  }

  error (message, data = null) {
    this.write('error', message, data)
  }

  warning (message, data = null) {
    this.write('warning', message, data)
  }

  info (message, data = null) {
    this.write('info', message, data)
  }

  debug (message, data = null) {
    this.write('debug', message, data)
  }

  /**
   * Internal write method. Use one of critical, error, warning, info, debug methods instead.
   *
   * @private
   * @param {string} level
   * @param {string} message
   * @param {any} data
   */
  write (level, message, data = null) {


    // first log to console
    try {
      this.writeConsole(level, message, data)
    } catch (e) {
      console.error('Error Logging to Console', e)
    }

    // then log to rollbar
    try {
      this.writeRollbar(level, message, data)
    } catch (e) {
      console.error('Error Logging to Rollbar', e)
    }
  }

  writeConsole(level, message, data) {
    const consoleMethod = {
      'critical': 'error',
      'error': 'error',
      'warning': 'warn',
      'info': 'info',
      'debug': 'log'
    }[level]

    const messageStr = this.messageString(message)

    console[consoleMethod](`${level.toUpperCase()}: ${messageStr}`, {
      message,
      data,
    })
  }

  writeRollbar(level, message, data) {
    if (!this.rollbar) {
      return
    }

    if (this.verbose) {
      console.log('ROLLBAR sending item ...', {
        level,
        message,
        data,
      })
    }

    // define callback
    const cb = (err) => { // callback
      if (err) {
        console.error('Error sending item to rollbar', {
          level,
          message,
          data,
          err
        })
        return
      }

      if (!this.verbose) {
        return
      }

      if (this.verbose) {
        console.log('ROLLBAR item sent', {
          level,
          message,
          data,
        })
      }
    }


    message = this.rollbarStringOrError(message)

    // build args array
    // @see https://docs.rollbar.com/docs/rollbarjs-configuration-reference#rollbarlog
    // @see https://docs.rollbar.com/docs/nodejs#server-usage
    // message: String - The message to send to Rollbar.
    // err: Exception - The exception object to send.
    // custom: Object - The custom payload data to send to Rollbar.
    // callback: Function - The function to call once the message has been sent to Rollbar.
    const args = [
      message, // should be string or Error instance
      data,
      cb
    ].filter(x => !!x) // avoid null or missing args error

    // call rollbar method
    this.rollbar[level].apply(this.rollbar, args)
  }

  rollbarStringOrError(message) {
    if (typeof message === 'string') {
      return message
    }

    if (message instanceof Error) {
      return message
    }

    const error = new Error(message.message ?? 'Log without message', {
      cause: message
    })

    if (typeof message.stack === 'string') {
      error.stack = this.stripStackHtml(message.stack)
    }

    return error
  }

  stripStackHtml(stack) {
    if (!stack.startsWith('<')) {
      return stack
    }

    const pattern = />([^<]+)</g // match text content
    const matches = stack.matchAll(pattern)
    return '\n' + [...matches]
      .map(x => x[1])
      .join('')
  }

  messageString(message) {
    if (typeof message === 'string') {
      return message
    }

    if (typeof message.message === 'string') {
      return message.message
    }

    return 'Log without message'
  }
}
