import { defineStore } from 'pinia'

import env from '~/libs/env'
import get from 'lodash/get'

export const useBusinessListingsStore = defineStore('businessListings', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
const businessCategories = ref([])
/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
async function getBusinessCategories () {
  if (businessCategories.value.length > 0) return businessCategories.value
  const categories = await $axios.get(`${env('apiUrl')}/b2b-directory/categories`)
    .then((response) => {
      return get(response, 'data.data')
    })
  businessCategories.value = categories
  return categories
}

  return {
   //STATE
   businessCategories,

   //GETTERS

   //ACTIONS
   getBusinessCategories,
  }
})
