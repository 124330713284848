import { defineStore } from "pinia";
import env from "~/libs/env";
import { ref, computed } from "vue";

import { useRootStore } from "~/store/root";

export const useAffiliateStore = defineStore("affiliate", () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const affiliateId = ref(null);
  const affiliate = ref(null);
  const affiliateProductIds = ref([]);

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  const getAffiliateId = computed(() => {
    return affiliateId.value;
  });

  const getAffiliateProductIds = computed(() => {
    return affiliateProductIds.value;
  });

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  async function getAffiliate(_affiliateId = null) {
    if (_affiliateId) {
      affiliateId.value = _affiliateId;
    } else if (!affiliateId.value) {
      const rootStore = useRootStore();
      affiliateId.value = rootStore.getAffiliateId;
    }

    if (affiliateId.value) {
      const affiliateRequest = await $axios
        .get(`${env("apiUrl")}/affiliate/${affiliateId.value}`)
        .catch((e) => log.error("Unable to get affiliate", e.message));

      const affiliateResponse = await affiliateRequest;
      affiliate.value = affiliateResponse.data.data;
      affiliateProductIds.value = affiliate.value.loanProductIds || [];
    }
  }

  return {
    // STATE
    affiliate,
    affiliateId,
    affiliateProductIds,
    // GETTERS
    getAffiliateId,
    getAffiliateProductIds,
    // ACTIONS
    getAffiliate,
  };
});
