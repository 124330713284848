import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { addSeconds } from 'date-fns'
import env from '~/libs/env'
import { useRootStore } from '../store/root'

export const trackingCookies = [
  'capturepage',
  'landing_page',
  'referral_url',
  'affiliate',
  'medium',
  'source',
  'ref',
  'campaign',
  'subid',
  'skipMarketingRecord',
  'adgroup',
  'term',
  'keywords',
  'interest',
]

/**
 * @param {*} numDays Number of days you want to get the expiration date for
 * @returns Date in the future numDays out
 */
const calcExpirationDate = (numDays) => {
  const today = new Date()
  const daysTilCookieExpires = numDays
  const secondsInADay = 86400
  const expirationDate = addSeconds(today, daysTilCookieExpires * secondsInADay)
  return expirationDate
}

/**
 * Class uses nuxt context to serve and retrieve cookies on both client and server
 * depends on the `cookie-universal` node module.
 */
export class NuxtCookieService {
  constructor ($cookies, cookiesDomain = '.lendio.com', $pinia) {
    if (!$cookies) {
      throw new Error('NuxtCookieService requires $cookies object on instantiation')
    }
    this.$cookies = $cookies
    this.$pinia = $pinia

    const defaultOptions = {
      path: '/',
      domain: cookiesDomain,
      expires: 7, // Default, cookie expires after 7 days
      secure: true,
      sameSite: 'None'
    }
    this.defaultOptions = {
      ...defaultOptions,
    }

    this.trackingCookies = trackingCookies

    this._responseCookies = {}
  }

  getTrackingCookies () {
    var cookies = {}
    this.trackingCookies.forEach((cookie) => {
      const value = this.get(cookie)
      if (value && !/^(null|undefined|NaN)$/i.exec(value)) {
        cookies[cookie] = value
      }
    })
    return cookies
  }

  clearTrackingCookies () {
    const excludedCookies = ['dreamsActive']
    this.trackingCookies.filter(cookie => !excludedCookies.includes(cookie))
      .forEach((cookie) => this.remove(cookie))
  }

  setCookiesFromParams (params, options = {}) {
    this.trackingCookies.forEach(cookie => {
      if (Object.keys(params).some((k) => k.includes(`utm_${cookie}`))) {
        params[cookie] = params[`utm_${cookie}`]
      }
      if (params[cookie]) {
        this.set(cookie, params[cookie], options)
      }
    })
  }

  /**
   * @param {*} key string | Cookie name
   * @param {*} opts object |
   *    fromRes: Get cookies from res instead of req.
   *    parseJSON: Parse json, true by default unless overridden globally or locally.
   * @returns Cookie Value
   */
  get(key) {
    key = this._prependTenantId(key)
    let value = undefined

    if (process.server) {
      value = this._responseCookieGet(key)
      if (value !== undefined) {
        return value
      }
    }

    return this.$cookies.get(key)
  }

  set (key, value, opts = {}) {
    key = this._prependTenantId(key)
    const secure = ['LIVE', 'staging', 'preview', 'alpha'].includes(env('environment'))
    const options = {
      ...this.defaultOptions,
      ...(secure ? { sameSite: 'None', secure: true } : { secure: false }),
      ...opts,
    }

    // infer cookie domain and don't use options.domain when using localhost for dev
    if (process.client && location.hostname.includes('localhost')) {
      delete options['domain']
    }

    // If a number is passed for expires, set it in days
    if (typeof options['expires'] === 'number') {
      options['expires'] = calcExpirationDate(options['expires'])
    }

    // Defaults to expires, but if maxAge is passed, remove expires
    if (opts.hasOwnProperty('maxAge')) {
      delete options['expires']
    }
    if (options.hasOwnProperty(['expires']) && !(options['expires'] instanceof Date)) {
      options['maxAge'] = 60 * 60 * 24 * options['expires']
      delete options['expires']
    }

    if (!options['secure']) {
      delete options['sameSite']
    }

    if (process.server) {
      // server side, stash response cookie for middleware
      return this._responseCookieSet(key, value, options)
    } else {
      // client side, just set the cookie
      return this.$cookies.set(key, value, options)
    }

  }

  removeAggressively(key, opts = {}) {
    key = this._prependTenantId(key)
    const defaultDeleteOptions = { path: this.defaultOptions['path'], domain: this.defaultOptions['domain'] }
    this.$cookies.remove(key, {domain: '.lendio.com', path: '/bp/'})
    this.$cookies.remove(key, {domain: '.lendio.com'})
    this.$cookies.remove(key, {}) // in case no options were passed when set
    this.$cookies.remove(key, opts)
    return this.$cookies.remove(key, defaultDeleteOptions)
  }

  remove(key, opts = {}) {
    key = this._prependTenantId(key)
    const secure = ['LIVE', 'staging', 'preview', 'alpha'].includes(env('environment'))

    opts = {
      ...this.defaultOptions,
      ...(secure ? { sameSite: 'None', secure: true } : { secure: false }),
      ...{ maxAge: -1 }
    }

    if (!opts['secure']) {
      delete opts['sameSite']
    }

    if (opts.hasOwnProperty('maxAge')) {
      delete opts['expires']
    }

    if (process.client) {
      return this.removeAggressively(key, opts)
    }

    if (typeof this.get(key) == 'string' || typeof this.get(key) == 'number') {
      if (process.server) {
        return this._responseCookieRemove(key, opts)
      } else {
        return this.$cookies.remove(key, opts)
      }
    }
  }

  delete(key, options = {}) {
    key = this._prependTenantId(key)
    return this.remove(key, options)
  }

  addResponseHeaders() {
    if (!process.server) {
      return
    }
    Object.values(this._responseCookies).forEach((cookie) => {
      if (cookie.value !== null) {
        this.$cookies.set(cookie.key, cookie.value, cookie.options)
      } else {
        this.$cookies.remove(cookie.key, cookie.options)
      }
    })
  }

  _prependTenantId(key) {
    if (key === 'tenantId') {
      return key
    }
    const tenantId = this._getTenantId()
    if (tenantId !== 1 && !key.startsWith(tenantId)) {
      key = `${tenantId}-${key}`
    }
    return key
  }

  _getTenantId() {
    // use tenantId from state
    if (useRootStore(this.$pinia).tenantId) {
      return useRootStore(this.$pinia).tenantId
    }

    // or cookie
    if (this.get('tenantId')) {
      return this.get('tenantId')
    }

    // or customizations
    if (useAffiliateCustomizationStore(this.$pinia).tenantId) {
      return useAffiliateCustomizationStore(this.$pinia).tenantId
    }

    return null
  }

  _responseCookieSet(key, value, options) {
    this._responseCookies[key] = { key, value, options }
  }

  _responseCookieGet(key) {
    return this._responseCookies[key]?.value
  }

  _responseCookieRemove(key, options) {
    this._responseCookies[key] = { key, value: null, options }
  }
}

export default NuxtCookieService
