const _appTypeRoutingList = [
  'sba-complete',
  'renewal',
  'embedded',
  'sba'
];

export default function appNameFromRoute(route) {
  if (!route || !route.path) {
    return null
  }
  const name = route.path.split('/')[1] ?? null
  if (!name) {
    return null
  }
  return _appTypeRoutingList.includes(name) ? name : 'marketplace'
}
