import env from '~/libs/env'

function getHostName(url) {
  let hostname
  try {
    let newUrl = new URL(url)
    hostname = newUrl.hostname
  } catch (err) {}
  return hostname
}

function addSafeUrl(safeUrls = [], url) {
  let hostName = getHostName(url)
  if (hostName) {
    return [...safeUrls, hostName]
  }
  return safeUrls
}

export function getSafeUrls () {
  let safeUrls = [
    'lp.lendio.com',
    'www.lendio.com',
    'docs.lendio.com',
  ]

  safeUrls = addSafeUrl(safeUrls, env('bpBaseUrl'))
  safeUrls = addSafeUrl(safeUrls, env('baseUrl'))

  return safeUrls
}

export function checkUrlSafety(url) {
  if (!url) {
    return false
  }

  if (typeof url !== 'string') {
    return false
  }

  if (url.length == 0) {
    return false
  }

  let isSafe = false
  try {
    let newUrl = new URL(url)
    const hostname = newUrl.hostname
    isSafe = getSafeUrls().some(safeUrl => hostname.startsWith(safeUrl))
  } catch (err) {}

  return isSafe
}

export default {
  getSafeUrls,
  checkUrlSafety,
}
