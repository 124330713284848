export const componentMap = {
  'password-expired': {
    component: 'LazyPasswordExpired',
    title: 'Password Expired',
    middleware: async (to, identityStore) => {
      if (!identityStore.transactionId) {
        return navigateTo(identityStore.loginUrl)
      }
      return
    },
  },
  'contact': {
    component: 'LazyContactUs',
    title: 'Contact',
    middleware: async (to, identityStore) => {
      return
    },
  },
  'forgot-password': {
    component: 'LazyForgotPasswordChallenge',
    title: 'Forgot Password',
    middleware: async (to, identityStore) => {
      return
    },
  },
  'forgot-password-verify': {
    component: 'LazyForgotPasswordVerify',
    title: 'Forgot Password',
    middleware: async (to, identityStore) => {
      if (!identityStore.forgotPasswordIdentifier || !identityStore.selectedMfaMethod) {
        return navigateTo(identityStore.loginUrl)
      }
      return
    },
  },
  'login': {
    component: 'LazyLogin',
    title: 'Login',
    middleware: async (to, identityStore) => {
      return
    },
  },
  'ssn-verification': {
    component: 'LazySsnVerification',
    title: 'SSN Verification',
    middleware: async (to, identityStore) => {
      if (!identityStore.transactionId) {
        return navigateTo(identityStore.loginUrl)
      }
      return
    },
  },
  'mfa-enroll': {
    component: 'LazyMfaEnroll',
    title: 'MFA Enroll',
    middleware: async (to, identityStore) => {
      if (!identityStore.transactionId || !identityStore.selectedMfaMethod) {
        return navigateTo(identityStore.loginUrl)
      }
      return
    },
  },
  'mfa-enroll-activate': {
    component: 'LazyMfaEnrollActivate',
    title: 'MFA Enroll Activation',
    middleware: async (to, identityStore) => {
      if (!identityStore.transactionId || !identityStore.selectedMfaMethod) {
        return navigateTo(identityStore.loginUrl)
      }
      return
    },
  },
  'mfa-methods': {
    component: 'LazyMfaMethods',
    title: 'MFA Methods',
    middleware: async (to, identityStore) => {
      if ((!identityStore.recoveringAccount && !identityStore.transactionId) || !identityStore.mfaMethods) {
        return navigateTo(identityStore.loginUrl)
      }
      return
    },
  },
  'mfa-verify': {
    component: 'LazyMfaVerify',
    title: 'MFA Verify',
    middleware: async (to, identityStore) => {
      if (!identityStore.transactionId || !identityStore.selectedMfaMethod) {
        return navigateTo(identityStore.loginUrl)
      }
      return
    },
  },
  'reset-password': {
    component: 'LazyResetPassword',
    title: 'Reset Password',
    middleware: async (to, identityStore) => {
      if (to.query?.token) {
        identityStore.setForgotPasswordJwt(to.query.token)
      }
      if (!to.query?.token && !identityStore.forgotPasswordJwt) {
        return navigateTo(identityStore.loginUrl)
      }
      return
    },
  },
}
