import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import QuestionSorter from '~/modules/Marketplace/QuestionSorter.js'
import questionWidgets from '~/libs/question-widgets'
import get from 'lodash/get'
import env from '~/libs/env'

import { useMatchingStore } from '~/store/matching'
import { useRootStore } from './root'
import { useQuestionsStore } from './questions'
import { useAffiliateStore } from './affiliate'
import { useAffiliateCustomizationStore } from './affiliate-customization'
import { useBorrowerStore } from './borrower'

const orderedBusinessInfoQuestions = [
  'borrower.name',
  'lendioIndustry',
  'entity_type',
  'federalstate_tax_id',
  'businessAddress',
  'borrower.phone',
  'financeAmount',
  'loanPurpose',
  'nonprofit',
  'business_start_date',
  'average_monthly_sales',
  'bankruptcy',
  'bankruptcy_status',
  'bankruptcy_discharged_date'
]

const orderedAdditionalLoanProductBusinessInfoQuestions = [
  'doingBusinessAsName',
  'business_location_type',
  'number_of_business_locations',
  'number_of_employees',
  'leasemortgage_payment'
]

const orderedOwnerInfoQuestions = [
  'user.first',
  'user.last',
  'contact.email',
  'contact.mobilePhone',
  'ownerBirthDate',
  'contact.ssn',
  'percentOwnership',
  'creditScore',
  'annual_personal_income',
]

const orderedAdditionalLoanProductOwnerInfoQuestions = [
  // owner street address start - do not re-order grouping
  'owner_street_address',
  'owner_city',
  'owner_state',
  'owner_zip',
  // owner street address end - do not re-order grouping
]

const _orderedCreditCheckQuestions = [
  'ownerBirthDate',
  'contact.ssn',
]

const additionalQuestions = [
  'lendioIndustry',
  'entity_type',
  'federalstate_tax_id',
  'borrower.phone',
  'financeAmount',
  'loanPurpose',
  'nonprofit',
  'business_start_date',
  'average_monthly_sales',
  'bankruptcy',
  'bankruptcy_status',
  'bankruptcy_discharged_date',
  'ownerBirthDate',
  'contact.ssn',
  'percentOwnership',
  'creditScore',
  'annual_personal_income',
  'user.first',
  'user.last',
  'borrower.name',
  'contact.email',
  'contact.mobilePhone',
  'businessAddress'
]

export const useEmbeddedStore = defineStore('embedded', () => {
  const nuxtApp = useNuxtApp()
  const { $axios, $lendioCookies } = nuxtApp

  const matchingStore = useMatchingStore()
  const rootStore = useRootStore()
  const questionsStore = useQuestionsStore()
  const affiliateStore = useAffiliateStore()
  const borrowerStore = useBorrowerStore()
  const customizationStore = useAffiliateCustomizationStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const confirmationApprovalId = ref(null)
  const orderedCreditCheckQuestions = ref(_orderedCreditCheckQuestions)
  const resubmissionSet = ref(false)
  const resubmissionEnabled = ref(false)
  const showSearchOptionsCountdown = ref(false)
/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const embeddedOwnerInfoQuestionSorter = computed(() => {
    const widgets = ['widget.creditScore', 'widget.annual_personal_income']
    const sorter = new QuestionSorter({
      aliases: orderedOwnerInfoQuestions,
      widgets: [...questionWidgets.filter((w) => widgets.includes(w.alias))]
    })
    sorter.getQuestionsWithoutWidgetChildren()
    return sorter
  })

  const embeddedFinicityDisabled = computed(() => {
    const cookieOverride = $lendioCookies.get('enableFinicityOverride')
    const _finicityEnabled = (env('embeddedFinicityEnabled') === 'true' || env('embeddedFinicityEnabled') === true || (cookieOverride === 'true' || cookieOverride === true))
    return rootStore.isEmbedded &&  !_finicityEnabled
  })

  const embeddedOwnerInfoQuestionSorterWithoutWidgets = computed(() => {
    const sorter = new QuestionSorter({
      aliases: orderedOwnerInfoQuestions
    })
    sorter.getQuestionsWithoutWidgetChildren()
    return sorter
  })

  const embeddedAdditionalOwnerInfoQuestionSorter = computed(() => {
    const sorter = new QuestionSorter({
      aliases: orderedAdditionalLoanProductOwnerInfoQuestions
    })

    sorter.getQuestionsWithoutWidgetChildren()
    return sorter
  })

  const embeddedBusinessInfoQuestionSorter = computed(() => {
    const widgets = ['widget.loanPurpose', 'widget.average_monthly_sales']
    const sorter = new QuestionSorter({
      aliases: orderedBusinessInfoQuestions,
      widgets: [...questionWidgets.filter((w) => widgets.includes(w.alias))]
    })
    sorter.getQuestionsWithoutWidgetChildren()
    return sorter
  })

  const embeddedAdditionalBusinessQuestionSorter = computed(() => {
    const sorter = new QuestionSorter({
      aliases: orderedAdditionalLoanProductBusinessInfoQuestions
    })

    sorter.getQuestionsWithoutWidgetChildren()
    return sorter
  })

  const embeddedBusinessInfoQuestionSorterWithoutWidgets = computed(() => {
    const sorter = new QuestionSorter({
      aliases: orderedBusinessInfoQuestions
    })
    sorter.getQuestionsWithoutWidgetChildren()
    return sorter
  })

  const embeddedAdditionalBusinessQuestionAliases = computed(() => {
    return matchingStore
      .fieldsForAffiliateLoanProducts()
      .map(({ alias }) => alias)
      .filter(alias => orderedAdditionalLoanProductBusinessInfoQuestions.includes(alias))
  })

  const embeddedAdditionalOwnerInfoQuestionAliases = computed(() => {
    return matchingStore
      .fieldsForAffiliateLoanProducts()
      .map(({ alias }) => alias)
      .filter(alias => orderedAdditionalLoanProductOwnerInfoQuestions.includes(alias))
  })

  const embeddedLoanProductQuestionAliases = computed(() => {
    return matchingStore
      .fieldsForEmbeddedProducts()
      .map(({ alias }) => alias)
      .concat(additionalQuestions)
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
  function setConfirmationApprovalId(id) {
    confirmationApprovalId.value = id
  }

  function embeddedResubmissionEnabled() {
    if (resubmissionSet.value) {
      return resubmissionEnabled.value
    }

    if (rootStore.isEmbedded && customizationStore.embeddedResubmissionEnabled) {
      const cookieOverride = $lendioCookies.get('embeddedResubmissionEnabled')
      if ((cookieOverride === 'true' || cookieOverride === true) && customizationStore.embeddedResubmissionEnabled) {
        return this.setEmbeddedResubmission(true, false)
      }

      if (borrowerStore.borrower?.isTest) {
        return this.setEmbeddedResubmission(false)
      }

      const isLuckyWinner = () => {
        const num = Math.random()
        const probability = customizationStore.embeddedResubmissionPercentage
        return num <= probability / 100
      };

        if (isLuckyWinner() && customizationStore.embeddedResubmissionEnabled) {
        return this.setEmbeddedResubmission(true)
      }
    }

    return this.setEmbeddedResubmission(false)
  }

  function setEmbeddedResubmission(value, setCookie = true) {
    resubmissionSet.value = true
    resubmissionEnabled.value = value

    if (setCookie && value) {
      $lendioCookies.set('embeddedResubmissionEnabled', value, { path: '/bp/embedded' })
    } else if (!value) {
      $lendioCookies.remove('embeddedResubmissionEnabled', {path: '/bp/embedded' })
    }

    return value
  }

  function setSearchOptionCountdown(status) {
    showSearchOptionsCountdown.value = status
  }

  return {
    // STATE
    confirmationApprovalId,
    orderedCreditCheckQuestions,
    resubmissionSet,
    resubmissionEnabled,
    showSearchOptionsCountdown,
    // GETTERS
    embeddedOwnerInfoQuestionSorter,
    embeddedAdditionalOwnerInfoQuestionSorter,
    embeddedOwnerInfoQuestionSorterWithoutWidgets,
    embeddedAdditionalOwnerInfoQuestionAliases,
    embeddedAdditionalBusinessQuestionAliases,
    embeddedBusinessInfoQuestionSorter,
    embeddedAdditionalBusinessQuestionSorter,
    embeddedBusinessInfoQuestionSorterWithoutWidgets,
    embeddedLoanProductQuestionAliases,
    embeddedFinicityDisabled,
    embeddedResubmissionEnabled,
    // ACTIONS
    setConfirmationApprovalId,
    setEmbeddedResubmission,
    setSearchOptionCountdown,
  }
})
