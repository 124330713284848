export const PORTAL_NAV_TITLES = {
  DASHBOARD: 'Dashboard',
  OFFERS: 'Financing Options',
  CREDIT_CARDS: 'Credit Cards',
  RESOURCES: 'Resources',
  EXPLORE: 'Explore',
  DOCUMENT_CENTER: 'Document Center',
  SETTINGS: 'Settings',
  SIGN_OUT: 'Sign Out',
  SWITCH_BUSINESS: 'Switch Business',
}

export const PORTAL_NAV_URLS = {
  DASHBOARD: '/portal',
  OFFERS: '/portal/options',
  CREDIT_CARDS: '/portal/credit-cards',
  RESOURCES: '/portal/business-resources',
  EXPLORE: '/portal/explore-other-products',
  DOCUMENT_CENTER: '/portal/documents',
  SETTINGS: '/portal/settings',
  SIGN_OUT: '/portal',
  SWITCH_BUSINESS: '/portal',
}

export const NAV_LINKS = [
  {
    title: PORTAL_NAV_TITLES.DASHBOARD,
    url: PORTAL_NAV_URLS.DASHBOARD,
    faIcon: 'house',
    iconActive: '/images/portal-dash-active.png'
  },
  {
    title: PORTAL_NAV_TITLES.OFFERS,
    url: PORTAL_NAV_URLS.OFFERS,
    faIcon: 'file-invoice-dollar',
    iconActive: '/images/portal-offers-active.png'
  },
  {
    title: PORTAL_NAV_TITLES.EXPLORE,
    url: PORTAL_NAV_URLS.EXPLORE,
    faIcon: 'sack-dollar',
    iconActive: '/images/portal-resources-active.png',
  },
  {
    title: PORTAL_NAV_TITLES.DOCUMENT_CENTER,
    url: PORTAL_NAV_URLS.DOCUMENT_CENTER,
    faIcon: 'folder',
    iconActive: '/images/portal-resources-active.png'
  },
  {
    title: PORTAL_NAV_TITLES.SETTINGS,
    url: PORTAL_NAV_URLS.SETTINGS,
    faIcon: 'user-gear',
    iconActive: 'user-gear'
  },
  {
    title: PORTAL_NAV_TITLES.SIGN_OUT,
    url: PORTAL_NAV_URLS.SIGN_OUT,
    faIcon: 'xmark',
    iconActive: '/images/portal-sign-out-active.png'
  },
  {
    title: PORTAL_NAV_TITLES.SWITCH_BUSINESS,
    url: PORTAL_NAV_URLS.SWITCH_BUSINESS,
    faIcon: ' building',
    iconActive: 'building',
  },
]

export const SBA_COMPLETE_PORTAL_NAV_TITLES = {
  APPLICATION_STATUS: 'Application Status',
  DOCUMENT_CENTER: 'Documents',
}

export const SBA_COMPLETE_NAV_URLS = {
  APPLICATION_STATUS: '/sba-complete/portal',
  DOCUMENT_CENTER: '/sba-complete/portal/documents',
}

export const SBA_COMPLETE_NAV_LINKS = [
  {
    title: SBA_COMPLETE_PORTAL_NAV_TITLES.APPLICATION_STATUS,
    url: SBA_COMPLETE_NAV_URLS.APPLICATION_STATUS,
    faIcon: 'file-invoice-dollar',
    showFlag: false,
  },
  {
    title: SBA_COMPLETE_PORTAL_NAV_TITLES.DOCUMENT_CENTER,
    url: SBA_COMPLETE_NAV_URLS.DOCUMENT_CENTER,
    faIcon: 'folder',
    showFlag: false,
  },
]

export const EMBEDDED_PORTAL_NAV_TITLES = {
  DASHBOARD: 'Dashboard',
  OFFERS: 'Financing Options',
  DOCUMENT_CENTER: 'Documents',
}

export const EMBEDDED_PORTAL_NAV_URLS = {
  DASHBOARD: '/embedded/portal/dashboard',
  OFFERS: '/embedded/portal',
  DOCUMENT_CENTER: '/embedded/portal/documents',
}

export const EMBEDDED_NAV_LINKS = [
  {
    title: EMBEDDED_PORTAL_NAV_TITLES.DASHBOARD,
    url: EMBEDDED_PORTAL_NAV_URLS.DASHBOARD,
    faIcon: 'house',
    showFlag: false,
  },
  {
    title: EMBEDDED_PORTAL_NAV_TITLES.OFFERS,
    url: EMBEDDED_PORTAL_NAV_URLS.OFFERS,
    faIcon: 'file-invoice-dollar',
    showFlag: true,
  },
  {
    title: EMBEDDED_PORTAL_NAV_TITLES.DOCUMENT_CENTER,
    url: EMBEDDED_PORTAL_NAV_URLS.DOCUMENT_CENTER,
    faIcon: 'folder',
    showFlag: false,
  },
]

export const INTELLIGENT_LENDING_PORTAL_NAV_TITLES = {
  DASHBOARD: 'Dashboard',
  OFFERS: 'Financing Options',
  DOCUMENT_CENTER: 'Documents',
  SIGN_OUT: 'Sign Out',
}

export const INTELLIGENT_LENDING_PORTAL_NAV_URLS = {
  DASHBOARD: '/intelligent-lending/portal/dashboard',
  OFFERS: '/intelligent-lending/portal',
  DOCUMENT_CENTER: '/intelligent-lending/portal/documents',
  SIGN_OUT: '/intelligent-lending/portal',
}

export const INTELLIGENT_LENDING_NAV_LINKS = [
  {
    title: INTELLIGENT_LENDING_PORTAL_NAV_TITLES.DASHBOARD,
    url: INTELLIGENT_LENDING_PORTAL_NAV_URLS.DASHBOARD,
    faIcon: 'border-all',
    showFlag: false,
  },
  {
    title: INTELLIGENT_LENDING_PORTAL_NAV_TITLES.OFFERS,
    url: INTELLIGENT_LENDING_PORTAL_NAV_URLS.OFFERS,
    faIcon: 'file-invoice-dollar',
    showFlag: true,
  },
  {
    title: INTELLIGENT_LENDING_PORTAL_NAV_TITLES.DOCUMENT_CENTER,
    url: INTELLIGENT_LENDING_PORTAL_NAV_URLS.DOCUMENT_CENTER,
    faIcon: 'folder',
    showFlag: false,
  },
  {
    title: INTELLIGENT_LENDING_PORTAL_NAV_TITLES.SIGN_OUT,
    url: INTELLIGENT_LENDING_PORTAL_NAV_URLS.SIGN_OUT,
    faIcon: 'xmark',
    iconActive: '/images/portal-sign-out-active.png'
  },
]

export const PORTAL_APP_STATE = {
  INCOMPLETE: 'INCOMPLETE',
  COMPLETE: 'COMPLETE',
  LENDER_DOCS: 'LENDER_DOCS',
  BANK_DOCS: 'BANK_DOCS',
}

export const DEAL_STATES = {
  DNQ: 'DNQ', // The customer has been disqualified from receiving a loan
  INCOMPLETE: 'Incomplete', // App incomplete
  START_APPLICATION: 'StartApplication', // App not started
  LOADING: 'Loading', // Deals haven't been retrieved yet
  HAS_DEALS_MP: 'Deals', // Customer has deals received or funded
  HAS_DEALS_FUNDED: 'Funded', // Customer has deals received or funded
  HAS_DEALS_OFFER: 'OfferReceived', // Customer has deals received or funded
  PROCESSING_DEFAULT: 'ProcessingDefault', // Kind of a catch-all for any other state
  WITHDRAWN: 'Withdrawn', // User withdrew application
  READY_CAP: 'ReadyCapital' // ReadyCapital is processing this loan,
}

// Types of applications referred to as "tabs". Not to be confused with a
// borrower application. This naming convention is confusing but used widely
// so we'll leave it.
export const APPLICATIONS = {
  MARKETPLACE: 'Marketplace'
}

export const HOURS = 1000 * 60 * 60 // MS -> Seconds -> Minutes -> Hours
export const DAYS = HOURS * 24

export const PPP_LOAN_TYPES = ['sbaPpp', 'sbaPPPSecond']
