import { defineStore } from "pinia"

import env from "~/libs/env"

import { useBorrowerStore } from "~/store/borrower"
import { useQuestionsStore } from "~/store/questions"
import { useUserStore } from "~/store/user"
import { useAffiliateStore } from "./affiliate"

/**
 * Returns a unique list of aliases that are in the list, sorted by the number of
 * occurrences within the list
 * @param {MatchField[]} fieldList
 * @returns {string[]}
 */
const uniqueAndSortFields = function (fieldList) {
  const rankedQualifiers = fieldList.reduce((carry, f) => {
    carry[f.alias] = carry[f.alias] ? carry[f.alias] + 1 : 1
    return carry
  }, {})
  const rankedList = Object.keys(rankedQualifiers).sort((a, b) => {
    return rankedQualifiers[a] == rankedQualifiers[b]
      ? 0
      : rankedQualifiers[a] > rankedQualifiers[b]
      ? -1
      : 1
  })
  return rankedList
}

const findFieldsForProducts = function (
  products,
  fieldList,
  qualifiers = false
) {
  const idMap = products.reduce(
    (carry, { id }) => ((carry[id] = true), carry),
    {}
  )
  return fieldList.filter(
    ({ loanProductId, qualifier }) =>
      idMap[loanProductId] && (!qualifiers || qualifier)
  )
}

/**
 * Returns a flattened array of the fields in a mapping, with the associated resource for each
 * mapping embedded into each field by the passed fk string
 * @param {string} fk attribute name
 * @param {MatchFieldMapping} fieldMapping a mapping of field lists, keyed by their resource id
 * @returns {MatchField[]}
 */
const flattenFields = function (fk, fieldMapping) {
  return Object.keys(fieldMapping)
    .map((id) => ({ id, fields: fieldMapping[id] }))
    .flatMap((resource) => {
      return resource.fields.map((f) => ({ ...f, [fk]: resource.id }))
    })
}

export const useMatchingStore = defineStore("matching", () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  const affiliateStore = useAffiliateStore()
  const borrowerStore = useBorrowerStore()
  const questionsStore = useQuestionsStore()
  const userStore = useUserStore()
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const loadingMatches = ref(true)
  const loanProducts = ref([])
  const borrowerClassifications = ref([])
  const loanProductCategories = ref([])
  const loanProductFields = ref([])
  const loanProductCategoryFields = ref([])
  const borrowerClassificationFields = ref([])
  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  /**
   * Checks against a borrower's matches to retrieve an array of "accepted" products
   * @returns {LoanProductMatch[]}
   */
  const acceptedProducts = computed(() => {
    return loanProducts.value.filter((p) => p.matchValue == "accepted")
  })

  /**
   * Checks against a borrower's matches to retrieve an array of "accepted" product categories
   * @returns {LoanProductCategoryMatch[]}
   */
  const acceptedProductCategories = computed(() => {
    return loanProductCategories.value.filter(
      (c) => c.matchValue == "accepted"
    )
  })

  /**
   * Checks against a borrower's matches to retrieve an array of "accepted" borrower classifications
   * @returns {BorrowerClassificationMatch[]}
   */
  const acceptedBorrowerClassifications = computed(() => {
    return borrowerClassifications.value.filter(
      (c) => c.matchValue == "accepted"
    )
  })

  /**
   * Checks against a borrower's matches for potential matches, retrieving an array of
   * "accepted", "partial", and "potential" products
   * @returns {LoanProductMatch[]}
   */
  const potentialProducts = computed(() => {
    return loanProducts.value.filter(
      (p) =>
        p.borrowerClassificationId.length &&
        ["accepted", "partial", "potential"].includes(p.matchValue)
    )
  })

  /**
   * Checks against a borrower's matches for potential SBA matches, retrieving an array of
   * "accepted", "partial", and "potential" products
   * @returns {LoanProductMatch[]}
   */
  const potentialSBAProducts = computed(() => {
    return potentialProducts.value.filter((p) => p.loanProductCategoryId === 9)
  })

  /**
   * Checks against a borrower's matches for potential matches, retrieving an array of
   * "accepted", "partial", and "potential" product categories
   * @returns {LoanProductCategoryMatch[]}
   */
  const potentialProductCategories = computed(() => {
    return loanProductCategories.value.filter((c) =>
      ["accepted", "partial", "potential"].includes(c.matchValue)
    )
  })

  /**
   * Checks against a borrower's matches for potential matches, retrieving an array of
   * "accepted", "partial", and "potential" borrower classifications
   * @returns {BorrowerClassificationMatch[]}
   */
  const potentialBorrowerClassifications = computed(() => {
    return borrowerClassifications.value.filter((c) =>
      ["accepted", "partial", "potential"].includes(c.matchValue)
    )
  })

  /**
   * Checks against a borrower's matches for rejected matches, retrieving an array of "rejected" products
   * @returns {LoanProductMatch[]}
   */
  const rejectedProducts = computed(() => {
    return loanProducts.value.filter((p) => p.matchValue == "rejected")
  })

  /**
   * Returns a true if all embedded loan products allow listed for the affiliate the borrower belongs to are rejected, false if not
   * @returns {LoanProductMatch[]}
   */
  const allAffiliateEmbeddedProductsRejected = computed(() => {
    const affiliateProductIds = affiliateStore.getAffiliateProductIds
    const borrower = borrowerStore.borrower

    if (borrower.leadSource === "Partner Import") {
      // never reject or DNQ if lead source is Partner Import
      return false
    } else {
      const embeddedLoanProducts = loanProducts.value.filter((p) => {
        return borrower.isTest
          ? p.enabledForEmbedded &&
              p.isTest &&
              affiliateProductIds.includes(p.id)
          : p.enabledForEmbedded &&
              !p.isTest &&
              affiliateProductIds.includes(p.id)
      })
      return embeddedLoanProducts.length && embeddedLoanProducts.every((p) => p.matchValue === "rejected")
    }
  })

  /**
   * Returns a true if all loan products allow listed for the lender affiliate the borrower belongs to are rejected, false if not
   * @returns {LoanProductMatch[]}
   */
  // TODO: Once decisioning is merged this will need to be used in the portal state machine
  // const allIntelligentLendingProductsRejected = computed(() => {
  //   const affiliateProductIds = affiliateStore.getAffiliateProductIds
  //   const borrower = borrowerStore.borrower

  //   const embeddedLoanProducts = loanProducts.value.filter((p) => {
  //     return borrower.isTest
  //       ? p.enabledForEmbedded &&
  //           p.isTest &&
  //           affiliateProductIds.includes(p.id)
  //       : p.enabledForEmbedded &&
  //           !p.isTest &&
  //           affiliateProductIds.includes(p.id)
  //   })
  //   return embeddedLoanProducts.every((p) => p.matchValue === "rejected")
  // })

  /**
   * Checks against a borrower's matches for rejected matches, retrieving an array of "rejected" product categories
   * @returns {LoanProductCategoryMatch[]}
   */
  const rejectedProductCategories = computed(() => {
    return loanProductCategories.value.filter(
      (c) => c.matchValue == "rejected"
    )
  })

  /**
   * Checks against a borrower's matches for rejected matches, retrieving an array of "rejected" borrower classifications
   * @returns {BorrowerClassificationMatch[]}
   */
  const rejectedBorrowerClassifications = computed(() => {
    return borrowerClassifications.value.filter(
      (c) => c.matchValue == "rejected"
    )
  })

  /**
   * Returns a function to retrieve all of the fields associated with a product, retrieved by product id
   * @returns {(id: int) => MatchField[]}
   */
  const fieldsForProduct = computed(() => {
    return (id) => {
      return loanProductFields.value.filter((f) => f.loanProductId == id)
    }
  })

  /**
   * Returns a function to retrieve all of the fields associated with all embedded loan products
   * @returns {() => MatchField[]}
   */
  const fieldsForEmbeddedProducts = computed(() => {
    return () => {
      const embeddedLoanProductIds = loanProducts.value
        .filter((p) => p.enabledForEmbedded)
        .map((p) => p.id)
      return loanProductFields.value.filter((f) =>
        embeddedLoanProductIds.includes(parseInt(f.loanProductId))
      )
    }
  })

  const fieldsForAffiliateLoanProducts = computed(() => {
    return () => {
      const affiliateLoanProductIds = affiliateStore.affiliateProductIds || []
      return loanProductFields.value.filter((f) =>
        affiliateLoanProductIds.includes(parseInt(f.loanProductId))
      )
    }
  })

  /**
   * Returns a function to retrieve all of the fields associated with a product category, retrieved by product category id
   * @returns {(id: int) => MatchField[]}
   */
  const fieldsForProductCategory = computed(() => {
    return (id) => {
      return loanProductCategoryFields.value.filter(
        (f) => f.loanProductCategoryId == id
      )
    }
  })

  /**
   * Returns a function to retrieve all of the fields associated with a borrower classification, retrieved by borrower classification id
   * @returns {(id: int) => MatchField[]}
   */
  const fieldsForBorrowerClassification = computed(() => {
    return (id) => {
      return borrowerClassificationFields.value.filter(
        (f) => f.borrowerClassificationId == id
      )
    }
  })

  /**
   * Returns a function to retrieve all of the qualifiers associated with a product, retrieved by product id
   * @returns {(id: int) => MatchField[]}
   */
  const qualifiersForProduct = computed(() => {
    return (id) => {
      return fieldsForProduct(id).value.filter((f) => f.qualifier)
    }
  })

  /**
   * Returns a function to retrieve all of the qualifiers associated with a product category, retrieved by product category id
   * @returns {(id: int) => MatchField[]}
   */
  const qualifiersForProductCategory = computed(() => {
    return (id) => {
      return fieldsForProductCategory.value(id).filter((f) => f.qualifier)
    }
  })

  /**
   * Returns a function to retrieve all of the qualifiers associated with a borrower classification, retrieved by borrower classification id
   * @returns {(id: int) => MatchField[]}
   */
  const qualifiersForBorrowerClassification = computed(() => {
    return (id) => {
      return fieldsForBorrowerClassification.value(id).filter((f) => f.qualifier)
    }
  })

  /**
   * Checks against all potential loan products and returns their associated qualifiers in a sorted list of alias strings
   * ordered by the number of products requiring them
   * @returns {string[]}
   */
  const qualifiersForPotentialProducts = computed(() => {
    return uniqueAndSortFields(
      findFieldsForProducts(
        potentialProducts.value,
        loanProductFields.value,
        true
      )
    )
  })

  /**
   * Checks against all products that are valid for their qualifiers. This enables a master list of qualifiers
   * @returns
   */
  const qualifiersForAllProducts = computed(() => {
    return uniqueAndSortFields(
      loanProductFields.value.filter(({ qualifier }) => qualifier)
    )
  })

  /**
   * Checks against all the given products for their qualifiers. This enables a master list of qualifiers
   * @returns
   */
  const qualifiersForProducts = computed(() => {
    return (products) => {
      return uniqueAndSortFields(
        findFieldsForProducts(products, loanProductFields.value, true)
      )
    }
  })

  /**
   * Checks against all potential loan product categories and returns their associated qualifiers in a sorted list of alias strings
   * ordered by the number of product categories requiring them
   * @returns {string[]}
   */
  const qualifiersForPotentialProductCategories = computed(() => {
    return uniqueAndSortFields(
      potentialProductCategories.value.flatMap((c) =>
        qualifiersForProductCategory.value(c.id)
      )
    )
  })

  /**
   * Checks against all potential borrower classifications and returns their associated qualifiers in a sorted list of alias strings
   * ordered by the number of borrower classifications requiring them
   * @returns {string[]}
   */
  const qualifiersForPotentialBorrowerClassifications = computed(() => {
    return uniqueAndSortFields(
      potentialBorrowerClassifications.value.flatMap((c) =>
        qualifiersForBorrowerClassification.value(c.id)
      )
    )
  })

  /**
   * Checks against all potential loan products and returns their associated fields in a sorted list of alias strings
   * ordered by the number of products requiring them
   * @returns {string[]}
   */
  const fieldsForPotentialProducts = computed(() => {
    return uniqueAndSortFields(
      findFieldsForProducts(potentialProducts.value, loanProductFields.value)
    )
  })

  /**
   * Checks against all potential loan product categories and returns their associated fields in a sorted list of alias strings
   * ordered by the number of product categories requiring them
   * @returns {string[]}
   */
  const fieldsForPotentialProductCategories = computed(() => {
    return uniqueAndSortFields(
      potentialProductCategories.value.flatMap((c) =>
        fieldsForProductCategory.value(c.id)
      )
    )
  })

  /**
   * Checks against all potential borrower classifications and returns their associated fields in a sorted list of alias strings
   * ordered by the number of borrower classifications requiring them
   * @returns {string[]}
   */
  const fieldsForPotentialBorrowerClassifications = computed(() => {
    return uniqueAndSortFields(
      potentialBorrowerClassifications.value.flatMap((c) =>
        fieldsForBorrowerClassification.value(c.id)
      )
    )
  })

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
  /**
   * Delegates a request for the current matches state depending on whether or not a borrower exists and is authenticated
   * @returns {Promise<boolean|null>} success or fail state of request
   */
  async function getMatches() {
    if (userStore.hasOptimusUser) {
      return await this.getBorrowerMatches()
    } else {
      return await this.getMatchesFromAnswers()
    }
  }

  /**
   * Retrieve the saved matches for a borrower from the DB
   *
   * @returns {Promise<boolean|null>} success or fail state of request
   */
  async function getBorrowerMatches() {
    if (!userStore.hasOptimusUser) return null
    const borrowerId = borrowerStore.borrowerId
    if (!borrowerId) return
    loadingMatches.value = true
    const { data } = await $axios
      .get(
        `${env("apiUrl")}/matching/v2/current-matches/borrower/${borrowerId}`
      )
      .then((response) => response.data)
      .catch(
        (error) => (
          console.error("Unable to retrieve matches for borrower", error),
          { data: null }
        )
      )
    if (data) {
      this.updateMatches(data)
    }
    loadingMatches.value = false
    return !!data
  }

  /**
   * Retrieve the matches a borrower would have if they had the values contained in their saved "currentAnswers" which would
   * leverage localStorage until the borrower is authenticated.
   *
   * @returns {Promise<boolean|null>} success or fail state of request
   */
  async function getMatchesFromAnswers() {
    loadingMatches.value = true
    const { data } = await $axios
      .get(`${env("apiUrl")}/matching/v2/current-matches`, {
        params: questionsStore.currentAnswers,
      })
      .then((response) => response.data)
      .catch(
        (error) => (
          console.error("Unable to retrieve matches from answers", error),
          { data: null }
        )
      )
    if (data) {
      this.updateMatches(data)
    }
    loadingMatches.value = false
    return !!data
  }

  /**
   * Retrieve the list of fields associated with matching resources (LoanProduct, LoanProductCategory, BorrowerClassification)
   * @returns {Promise<boolean|null>} success or fail state of request
   */
  async function getFieldsList({ forceReload = false } = {}) {
    if (loanProductFields.value.length && !forceReload) {
      return true
    }
    const { data } = await $axios
      .get(`${env("apiUrl")}/matching/v2/fields-list`)
      .then((response) => response.data)
      .catch(
        (error) => (
          console.error(
            "Unable to retrieve fields list for matching resources",
            error
          ),
          { data: null }
        )
      )
    if (!data) return null
    this.updateFieldsList(data)
    return true
  }

  /**
   * Loads matches into state
   * @param {{
   *   loanProducts: LoanProductMatch[],
   *   loanProductCategories: LoanProductCategoryMatch[],
   *   borrowerClassifications: BorrowerClassificationMatch[]
   * }} matches
   */
  function updateMatches(matches) {
    loanProducts.value = matches.loanProducts.slice()
    borrowerClassifications.value = matches.borrowerClassifications.slice()
    loanProductCategories.value = matches.loanProductCategories.slice()
  }

  /**
   * Loads matching field list into state
   * @param {{
   *   loanProducts: MatchFieldMapping,
   *   loanProductCategories: MatchFieldMapping,
   *   borrowerClassifications: MatchFieldMapping
   * }} fieldsList
   */
  function updateFieldsList(fieldsList) {
    loanProductFields.value = flattenFields(
      "loanProductId",
      fieldsList.loanProducts
    )
    loanProductCategoryFields.value = flattenFields(
      "loanProductCategoryId",
      fieldsList.loanProductCategories
    )
    borrowerClassificationFields.value = flattenFields(
      "borrowerClassificationId",
      fieldsList.borrowerClassifications
    )
  }

  return {
    // STATE
    loadingMatches,
    loanProducts,
    borrowerClassifications,
    loanProductCategories,
    loanProductFields,
    loanProductCategoryFields,
    borrowerClassificationFields,

    // GETTERS
    acceptedProducts,
    acceptedProductCategories,
    acceptedBorrowerClassifications,
    potentialProducts,
    potentialSBAProducts,
    potentialProductCategories,
    potentialBorrowerClassifications,
    rejectedProducts,
    allAffiliateEmbeddedProductsRejected,
    rejectedProductCategories,
    rejectedBorrowerClassifications,
    fieldsForProduct,
    fieldsForEmbeddedProducts,
    fieldsForAffiliateLoanProducts,
    fieldsForProductCategory,
    fieldsForBorrowerClassification,
    qualifiersForProduct,
    qualifiersForProductCategory,
    qualifiersForBorrowerClassification,
    qualifiersForPotentialProducts,
    qualifiersForAllProducts,
    qualifiersForProducts,
    qualifiersForPotentialProductCategories,
    qualifiersForPotentialBorrowerClassifications,
    fieldsForPotentialProducts,
    fieldsForPotentialProductCategories,
    fieldsForPotentialBorrowerClassifications,

    // ACTIONS
    getMatches,
    getBorrowerMatches,
    getMatchesFromAnswers,
    getFieldsList,
    updateMatches,
    updateFieldsList,
  }
})
