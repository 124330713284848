import { defineStore } from "pinia";
import FUNDING_NEEDS_CONFIG from "~/libs/funding-needs-config";
import { localStorageService } from "~/libs/local-storage.service";
import { MBOX_DETAILS } from "~/libs/adobe-target";
import lodashGet from "lodash/get";
import env from "~/libs/env";

import { useBorrowerStore } from "~/store/borrower";
import { useAppAnalyticsStore } from "~/store/app-analytics";

export const useFundingNeedsStore = defineStore("fundingNeeds", () => {
  const nuxtApp = useNuxtApp();
  const { $axios } = nuxtApp;

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

  const lendioIndustry = ref(null);
  const loading = ref(false);
  const needs = ref(FUNDING_NEEDS_CONFIG);
  // ID assigned in api when persisted or null.
  const id = ref(null);
  // Track versions of the funding needs.
  const version = ref("v1");
  // Key mapping allows us to have versions of the funding needs, by
  // category, such as industry. e.g. construction (keyMapping) v1 (version)
  // or retaurants (keyMapping), v3 (version). The default keyMapping
  // when one doesn't exist for an industry (or other driver) is 'noKey'.
  const defaultKeyMapping = ref("noKey");
  const forceDefault = ref(false);
  const rowAnswerSets = ref([{}]);
  const fundingNeedsEnabled = ref(false);

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const keyMapping = computed(() => {
    if (forceDefault.value) return defaultKeyMapping.value;
    const borrowerStore = useBorrowerStore();
    const hasBorrowerValues = !borrowerStore.borrowerValues ? false : true ;
    //Grab lendioIndustry from localStorage if not authed yet.
    const industryLocalStorage = lendioIndustry.value;
    if (!hasBorrowerValues) {
      return industryLocalStorage &&
        needs.value.hasOwnProperty(industryLocalStorage)
        ? industryLocalStorage
        : defaultKeyMapping.value;
    } else {
      const industry = lodashGet(borrowerStore, 'borrowerValues.lendioIndustry.value');
      return industry && needs.value.hasOwnProperty(industry)
        ? industry
        : defaultKeyMapping.value;
    }
  });

  const hasNeedsMapping = computed(() => {
    return keyMapping.value != "noKey";
  });

  // Only show funding needs borrower is in one of the industries that have needsMapping AND funding needs is enabled
  const showFundingNeeds = computed(() => {
    const appAnalyticsStore = useAppAnalyticsStore();
    return (
      fundingNeedsEnabled &&
      hasNeedsMapping.value &&
      appAnalyticsStore.hasTargetOffer(
        MBOX_DETAILS.TEST_FUNDING_NEEDS.NAME,
        "fundingNeeds"
      )
    );
  });

  //These categories populate the select inputs describing what funds will be used for. They're unique the borrower's selected industry.
  const needsAnalysisMappings = computed(() => {
    return needs.value[keyMapping.value];
  });

  const rowDefinitions = computed(() => {
    return rowAnswerSets.value.map(
      (answers) =>
        needsAnalysisMappings.value &&
        needsAnalysisMappings.value.fields.map((field) => ({
          ...field,
          value: answers[field.alias] || field.value,
        }))
    );
  });

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  //Once we have the industry saved in localStorage, grab it so we can determine if user should see funding-needs
  function getIndustryFromStorage() {
    const borrowerAnswers = JSON.parse(
      localStorageService.getItem("borrowerAnswers")
    );
    lendioIndustry.value = lodashGet(borrowerAnswers, 'lendioIndustry.value')
  }

  function saveToLocalStorage() {
    //Save rowAnswerSets to localStorage, will save them to store when user is authed.
    localStorageService.setItem(
      "rowAnswerSets",
      JSON.stringify(rowAnswerSets.value)
    );
  }

  function fetchFromLocalStorage() {
    //Retrieve saved rowAnswerSets from localStorage
    const fetchLocalStorageAnswers = JSON.parse(
      localStorageService.getItem("rowAnswerSets")
    );
    rowAnswerSets.value = fetchLocalStorageAnswers;
  }

  // Get borrower's funding needs from state or api. Doesn't include list of loanPurpose values (separate call).
  async function get() {
    const borrowerStore = useBorrowerStore();
    const borrowerId = borrowerStore.borrowerId;
    if (!borrowerId) return;
    // Gets the most recent funding needs for this borrower.
    loading.value = true;

    const { data } = await $axios
      .get(`${env("apiUrl")}/borrower/${borrowerId}/funding-needs`)
      .then((response) => response.data)
      .catch((e) => ({ data: { error: e } }));

    loading.value = false;
    if (!data) return;
    if (data.error) {
      return data.error;
    }
    // Set the new ID
    // As each form row (need) is added in the UI.
    if (data.id) {
      id.value = data.id;
      rowAnswerSets.value = data.needs;
    }
  }

  // Create the needs analsys in API.
  async function post() {
    const borrowerStore = useBorrowerStore();
    const borrowerId = borrowerStore.borrowerId;
    if (!borrowerId) return;

    const rowAnswerSetsLocalStorage = JSON.parse(
      localStorageService.getItem("rowAnswerSets")
    );

    const fundingNeeds = {
      version: version.value,
      keyMapping: keyMapping.value,
      needs: rowAnswerSetsLocalStorage,
    };

    const { data } = await $axios
      .post(
        `${env("apiUrl")}/borrower/${borrowerId}/funding-needs`,
        fundingNeeds
      )
      .then((response) => response.data)
      .catch((e) => ({ data: { error: e } }));
    if (!data) return;
    if (data.error) {
      return data.error;
    }

    // Set the new ID.
    // As each form row (need) is added in the UI.
    if (data.id) {
      id.value = data.id;
    }
  }

  // Put (update) the needs analsys in API.
  async function put() {
    const borrowerStore = useBorrowerStore();
    const borrowerId = borrowerStore.borrowerId;
    if (!borrowerId) return;
    //fetch rowAnswerSets saved in localStorage and push these to the funding-needs
    const rowAnswerSetsLocalStorage = JSON.parse(
      localStorageService.getItem("rowAnswerSets")
    );

    // state has current funding needs info for this borrower.
    const fundingNeeds = {
      id: id.value,
      version: version.value,
      keyMapping: keyMapping.value,
      needs: rowAnswerSetsLocalStorage,
    };

    const { data } = await $axios
      .put(
        `${env("apiUrl")}/borrower/${borrowerId}/funding-needs/${id.value}`,
        fundingNeeds
      )
      .then((response) => response.data)
      .catch((e) => ({ data: { error: e } }));
    if (!data) return;
    if (data.error) {
      return data.error;
    }
  }

  // Use this to force a certain experience once viewing the feature, no matter the industry
  function setUseDefault(value = "noKey") {
    forceDefault.value = Boolean(value) && value !== "noKey";
    defaultKeyMapping.value = value ? value : "noKey";
  }

  function addFundingNeedRow() {
    rowAnswerSets.value = [...rowAnswerSets.value, {}];
  }

  function stageAnswer(answer) {
    rowAnswerSets.value[answer.rowIndex][answer.attr.alias] = answer.attr.value;
  }

  function deleteRowAnswerSet(row) {
    rowAnswerSets.value.splice(row.rowIndex, 1);
  }

  return {
    //STATE
    lendioIndustry,
    loading,
    id,
    version,
    defaultKeyMapping,
    fundingNeedsEnabled,
    forceDefault,
    rowAnswerSets,
    needs,

    //GETTERS
    keyMapping,
    hasNeedsMapping,
    showFundingNeeds,
    needsAnalysisMappings,
    rowDefinitions,

    //ACTIONS
    getIndustryFromStorage,
    saveToLocalStorage,
    fetchFromLocalStorage,
    get,
    post,
    put,
    setUseDefault,
    addFundingNeedRow,
    stageAnswer,
    deleteRowAnswerSet,
  };
});
