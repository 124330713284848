import { useIdentityStore } from '~/store/identity';

function logCaughtError(message, context, err) {
  context.error = {
    message: err.message ?? null,
    type: err.constructor.name ?? null,
    code: err.code ?? null,
    stack: err.stack ?? null,
  }
  log.error(message, context)
}

async function iframeOnlyCheck($pinia) {
  try {
    const context = {
      server: process.server,
    }

    // in iframe?
    let iframe = false
    if (process.server) {
      // in testing/monitoring we discovered in production referer isn't always reliable and was leading to incorrect BLOCK logs
      // const { referer, host, origin } = useRequestHeaders(['referer', 'origin', 'host'])
      // context.headers = { referer, origin, host }
      // iframe = referer && !referer.includes(host)
      return
    } else {
      iframe = window.self !== window.top
    }
    context.iframe = iframe

    // allow iframe
    if (iframe) {
      log.info('iframe-only-check ALLOW: iframe', context)
      return
    }

    // allow if not iframe only origin
    let iframeOnly = false;
    try {
      iframeOnly = await useIdentityStore($pinia).isIframeOnly()
      context.iframeOnly = iframeOnly
    } catch (err) {
      logCaughtError('iframe-only-check ERROR', context, err)
      return
    }
    if (!iframeOnly) { // allow
      log.info('iframe-only-check ALLOW: !iframeOnly', context)
      return
    }

    // block, non-iframe on iframe only origin
    const host = useRequestURL().host
    log.error(`iframe-only-check BLOCK ${host}: !iframe && iframeOnly`, context)
    // TODO monitor for accuracy and enable in follow up deploy
    // return showError({
    //   statusCode: 404,
    //   statusMessage: 'Page not found'
    // })
  } catch (err) {
    logCaughtError('iframe-only-check ERROR', context, err)
  }
}

export default defineNuxtPlugin(({ hook, $pinia }) => {
  // skip if route is /healthcheck
  if (useRoute().path === '/healthcheck') {
    return
  }

  hook('page:loading:start', async () => {
    await iframeOnlyCheck($pinia)
  })
})
