/**
 * type Experiment = {
 *   id: number,
 *   name: string,
 * }
 */

export const experiments = {
  dashboardPage: {
    id: 23,
    name: 'Dashboard Page'
  },
}

export function experimentWithId(id) {
  let experiment = null
  Object.keys(experiments).forEach((key) => {
    if (experiments[key].id === id) {
      experiment = experiments[key]
    }
  })
  return experiment
}

/**
 * Checks for a cookie value on an interval until it successfully gets a value, or for at
 * most maxWaitMs milliseconds.
 * @param string cookieName
 * @param Cookies cookies
 * @param integer maxWaitMs
 */
 export async function resolveExperimentGroupFromCookie (cookieName, cookies, isClient, maxWaitMs = 100) {
  let checkInterval = null;
  let checkTimeout = null;
  return new Promise((resolve) => {
    const checkIntervalMs = 20
    const startTime = new Date()

    function resolveCookieValue (cookieValue) {
      resolve(parseInt(resolvedGroup))
    }

    // Return immediately if we can resolve it synchronously
    let resolvedGroup = cookies.get(cookieName)
    if (resolvedGroup) {
      return resolveCookieValue(resolvedGroup)
    }

    const checkCookies = () => {
      // Check cookies for group
      const resolvedGroup = cookies.get(cookieName)

      // Track execution time
      const now = new Date()
      const elapsed = now - startTime
      const reachedMaxWaitTime = elapsed >= maxWaitMs

      // got value? resolve
      if (resolvedGroup !== undefined) {
        resolveCookieValue(resolvedGroup)
        if (checkInterval) {
          clearInterval(checkInterval)
        }
        return
      }

      // wait and check again
      if (!reachedMaxWaitTime) {
        if (checkTimeout) {
          checkTimeout = setTimeout(checkCookies, maxWaitMs / 5)
        }
        return
      }

      // times up! resolve null
      if (checkInterval) {
        clearInterval(checkInterval)
      }
      resolve(null)
    }

    // Check for the cookie on an interval until we get it or hit the timeout
    if (isClient) {
      checkInterval = setInterval(checkCookies, checkIntervalMs)
    } else {
      // as of nuxt 3.11, setInterval is not allowed server side
      checkTimeout = setTimeout(checkCookies, maxWaitMs / 5)
    }
  })
}

export default {
  experiments,
  experimentWithId,
  resolveExperimentGroupFromCookie,
}
