import AnalyticsService from '~/libs/analytics-service'
import { localStorageService } from "~/libs/local-storage.service"
import { useProgressStore } from '~/store/progress'
import { useRootStore } from '~/store/root'
import { useBorrowerStore } from '~/store/borrower'
import { useExperienceStore } from '~/store/experience'
import { useAppAnalyticsStore } from '~/store/app-analytics'
import { useExperimentsStore } from '~/store/experiments'

export default defineNuxtPlugin(({ $pinia, $lendioCookies, $axios }) => {
  const appAnalyticsStore = useAppAnalyticsStore($pinia)
  const progressStore = useProgressStore($pinia)
  const borrowerStore = useBorrowerStore($pinia)
  const rootStore = useRootStore($pinia)
  const experienceStore = useExperienceStore($pinia)
  const experimentsStore = useExperimentsStore($pinia)

  const router = useRouter()


  function lendioPerformanceBeginLoadingTime(){
    if(!window.LendioPerformanceLogging){
      return
    }
    try {
      window.LendioPerformanceLogging.baseRouteChangeStart = {
        startTime: new Date(),
        logged: false
      }

      window.LendioPerformanceLogging.beginLoadingTime = window.LendioPerformanceLogging.calculateLoadTime()
    } catch (err) {
      log.error('LendioPerformanceLogging Error at router.beforeEach: ', err)
    }
  }
  function lendioPerformanceRouteChangeTime(){
    if(!window.LendioPerformanceLogging){
      return
    }
    try {
      window.LendioPerformanceLogging.routeChangeTime = window.LendioPerformanceLogging.calculateLoadTime(window.LendioPerformanceLogging.baseRouteChangeStart.startTime)
    } catch (err) {
      log.error('LendioPerformanceLogging Error at router.afterEach: ', err)
    }
  }

  // handle experience redirect
  router.beforeEach(async (to, from, next) => {
    // Log page load performance
    lendioPerformanceBeginLoadingTime()
    next()
  })

  /*
  * Every time the route changes (fired on initialization too)
  */
  router.afterEach((to, from) => {
    lendioPerformanceRouteChangeTime()
    progressStore.navigating = null
    progressStore.pushVisitedRoute(to.path.replace(/\/?$/, ''))
    borrowerStore.getBorrower().then(borrower => {
      AnalyticsService.trackRoute(borrower ? borrower.id : null, appAnalyticsStore.getLendioId, to.fullPath, $axios)
      if (borrower) {
        // Custom fields must named following the pattern 'ident_type' (https://help.fullstory.com/hc/en-us/articles/4446290296599-Setting-custom-API-properties)
        let data = {
          'displayName': borrower.name,
          'borrowerId_int': borrower.id,
          'clientId_int': borrower.clientId,
          'containerUrl_str': containerUrl(),
          'isTest_bool': !!borrower.isTest,
          'mineralGroup_str': borrower.mineralGroup ?? '',
        }

        const email = rootStore.authUser?.email
        if (email) {
          data['email'] = email
        }
        if (borrower.created) {
          data['createdDate_date'] = new Date(borrower.created)
        }
        if (experienceStore.currentExperimentName) {
          data['experience_str'] = experienceStore.currentExperimentName
        }
        if (experimentsStore.loadedActiveExperiments) {
          data['isSelfServeLoanOptions_bool'] = !!experimentsStore.activeUserExperimentByName('BP Loan Options Self Serve')
        }

        AnalyticsService.identify(appAnalyticsStore.getLendioId, data) // Identify the borrower data to Fullstory
      }
    })
    let redirect = $lendioCookies.get('redirect')
    let redirectURI = localStorageService.getItem('redirectURI')
    let path = to.path
    if (path === redirect) {
      $lendioCookies.remove('redirect')
    }
    if (path === redirectURI) {
      localStorageService.removeItem('redirectURI')
    }

  })

  /**
   * Return the "container URL". Used to enable segmentation of FullStory users in
   * different embedded contexts. For example, an Intelligent Lending user
   * on Ridgeline Bank would return "ridgelinebank.lendio.com"
   *
   * @returns String
   */
  function containerUrl() {
    return window.self === window.top
      ? window.location.host
      : window.document.referrer || 'unknown';
  }
})
