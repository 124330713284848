import { defineStore } from 'pinia'

import env from '~/libs/env'
import { useBorrowerStore } from '~/store/borrower'

export const useBorrowerUserActionStore = defineStore('borrowerUserAction', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const actionItems = ref([])
  const borrowerUserActionNeeded = ref(false)
  const actionItemsLoaded = ref(false)

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/


/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
  async function getActionItems(refresh = false) {
    actionItemsLoaded.value = false
    if (actionItems.value.length && !refresh) {
      return actionItems.value
    }

    // get borrower id
    const borrower = await useBorrowerStore().getBorrower()

    if (!borrower) return

    const res = await $axios.get(
      env('apiUrl') + '/borrower/' + borrower.id + '/user-action-items',
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }
    )

    if (!res) return []

    actionItems.value = res.data.data
    borrowerUserActionNeeded.value = actionItems.value.length > 0
    actionItemsLoaded.value = true
    return actionItems.value
  }

  function clearActionItems() {
    actionItems.value = []
  }

  return {
    // STATE
    actionItems,
    borrowerUserActionNeeded,
    actionItemsLoaded,

    // GETTERS

    // ACTIONS
    getActionItems,
    clearActionItems
  }

})
