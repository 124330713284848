import accounting from 'accounting'

const SCORE_VALUE_OPTIONS = {
  GOOD: 'GOOD',
  AVERAGE: 'AVERAGE',
  POOR: 'POOR',
  NO_DATA: 'NO DATA'
}

const URL_OPTIONS = {
  CREDIT_CARDS: '/portal/credit-cards',
  RESOURCES: '/portal/business-resources',
}

const COLOR_VAR = {
  RED: '#E50001',
  BLUE: '#05C2F0',
  GREEN: '#46C536',
  NO_DATA: '#626262',
}

const scorecards = [
  {
    title: 'Credit',
    imageUrl: 'images/portal-scorecard-credit-score.svg',
    bvAlias: 'creditScore',
    buttonText: 'More Info',
    resourceUrl: URL_OPTIONS.CREDIT_CARDS,
    qualificationAliases: ['creditScore'],
  },
  {
    title: 'Time in Business',
    imageUrl: 'images/portal-scorecard-time-in-business.svg',
    bvAlias: 'timeInBusiness',
    buttonText: 'Resources',
    resourceUrl: URL_OPTIONS.RESOURCES,
    qualificationAliases: ['timeInBusiness'],
  },
  {
    title: 'Revenue',
    imageUrl: 'images/portal-scorecard-revenue.png',
    bvAlias: 'annualRevenue',
    buttonText: 'Resources',
    resourceUrl: URL_OPTIONS.RESOURCES,
    qualificationAliases: ['annualRevenue', 'average_monthly_sales'],
  }
]

const contentOptions = {
  [SCORE_VALUE_OPTIONS.GOOD]: {
    creditScore: 'Your credit score is in good shape! Our lenders typically look for a score of 650+ or higher. Keep it up!',
    timeInBusiness: 'You have an established business! Great work. Lenders typically like to work with businesses that have been around for over 6 months.',
    annualRevenue: 'Your monthly revenue is looking good! Our lenders typically look for a monthly revenue of at least $8,000 a month.',
  },
  [SCORE_VALUE_OPTIONS.AVERAGE]: {
    creditScore: 'Your credit score has an average standing. This may qualify you for loan offers. Keep working on your score to access the best loan rates.',
    timeInBusiness: 'Your time in business is in good standing. Lenders typically like to work with businesses that have been around for over 6 months.',
    annualRevenue: 'Your monthly revenue is average! Our lenders typically look for a monthly revenue of at least $8,000 a month.',
  },
  [SCORE_VALUE_OPTIONS.POOR]: {
    creditScore: 'Your credit score needs some work. Our lenders offer the best loan rates with a score of 650 or higher.',
    timeInBusiness: 'Your business needs time to grow. Lenders typically like work with businesses that have been a minimum of 6 months in operation.',
    annualRevenue: 'Your monthly revenue needs some lifting. For best rates, our lenders typically look for a monthly revenue of at least $8,000 a month.',
  },
  [SCORE_VALUE_OPTIONS.NO_DATA]: {
    creditScore: 'Unable to access your current credit score. Our lenders offer the best loan rates with a score of 650 or higher.',
    timeInBusiness: 'Unable to gather you time in business. Lenders typically like work with businesses that have been a minimum of 6 months in operation.',
    annualRevenue: 'Unable to access your annual revenue. For best rates, our lenders typically look for a monthly revenue of at least $8,000 a month.',
  },
}

const scoreValueFunctions = {
  creditScore: function(payload) {
    const { creditScore, overrideScore } = payload
    let score = SCORE_VALUE_OPTIONS.POOR
    let cs = null

    if (overrideScore && SCORE_VALUE_OPTIONS[overrideScore]) {
      score = overrideScore
      if (score === SCORE_VALUE_OPTIONS.POOR) {
        cs = 499
      } else if (score === SCORE_VALUE_OPTIONS.AVERAGE) {
        cs = 600
      } else if (score === SCORE_VALUE_OPTIONS.GOOD) {
        cs = 750
      }
    } else {
      cs = parseInt(creditScore)
      if (!cs) {
        score = SCORE_VALUE_OPTIONS.NO_DATA
      } else if (cs > 650) {
        score = SCORE_VALUE_OPTIONS.GOOD
      } else if (cs >= 500) {
        score = SCORE_VALUE_OPTIONS.AVERAGE
      }
    }

    // ONLY IN PLACE FOR LEGAL REQUIREMENTS. REMOVE ONCE CREDIT SCORE SOURCE AVAILABLE
    const defaultPoor = "Unfortunately our financial product requirements were not met. Our lenders offer the best loan rates with a score of 650 or higher."
    const content = score === SCORE_VALUE_OPTIONS.POOR ? defaultPoor : contentOptions[SCORE_VALUE_OPTIONS.NO_DATA]['creditScore']
    score = SCORE_VALUE_OPTIONS.NO_DATA

    return {
      score,
      formattedValue: cs,
      styleColor: getColor(score),
      order: getScorecardOrder(score),
      selectedContent: content
    }
  },
  timeInBusiness: function(payload) {
    const { timeInBusiness, monthsInBusiness, overrideScore } = payload
    let monthEval = null
    let score = SCORE_VALUE_OPTIONS.POOR

    if (overrideScore && SCORE_VALUE_OPTIONS[overrideScore]) {
      score = overrideScore
      if (score === SCORE_VALUE_OPTIONS.POOR) {
        monthEval = 1
      } else if (score === SCORE_VALUE_OPTIONS.AVERAGE) {
        monthEval = 10
      } else if (score === SCORE_VALUE_OPTIONS.GOOD) {
        monthEval = 24
      }
    } else {
      const tib = timeInBusiness ? parseInt(timeInBusiness) : null
      if (tib && monthsInBusiness) {
        monthEval = monthsInBusiness >= tib ? monthsInBusiness : tib
      } else if (tib || tib === 0) {
        monthEval = tib
      } else if (monthsInBusiness || monthsInBusiness === 0) {
        monthEval = monthsInBusiness
      }

      if (!monthEval && monthEval !== 0) {
        score = SCORE_VALUE_OPTIONS.NO_DATA
      } else if (monthEval >= 24) {
        score = SCORE_VALUE_OPTIONS.GOOD
      } else if (monthEval > 6) {
        score = SCORE_VALUE_OPTIONS.AVERAGE
      }
    }

    let monthStr = + Math.floor(monthEval % 12) + 'mo'
    const year = Math.floor(monthEval / 12)
    if (year) monthStr = year + 'yr ' + monthStr

    return {
      score,
      formattedValue: monthStr,
      styleColor: getColor(score),
      order: getScorecardOrder(score),
      selectedContent: contentOptions[score]['timeInBusiness']
    }
  },
  annualRevenue: function(payload) {
    const { annualRevenue, average_monthly_sales, overrideScore } = payload
    let score = SCORE_VALUE_OPTIONS.POOR
    let revEval = null

    if (overrideScore && SCORE_VALUE_OPTIONS[overrideScore]) {
      score = overrideScore
      if (score === SCORE_VALUE_OPTIONS.POOR) {
        revEval = 1000
      } else if (score === SCORE_VALUE_OPTIONS.AVERAGE) {
        revEval = 10000
      } else if (score === SCORE_VALUE_OPTIONS.GOOD) {
        revEval = 24000
      }
    } else {
      const annualCheck = annualRevenue ? parseInt(annualRevenue) / 12 : null
      const monthly = average_monthly_sales ? parseInt(average_monthly_sales) : null
      if (annualCheck && monthly) {
        revEval = annualCheck >= monthly ? annualCheck : monthly
      } else if (annualCheck || annualCheck === 0) {
        revEval = annualCheck
      } else if (monthly || monthly === 0) {
        revEval = monthly
      }

      if (!revEval && revEval !== 0) {
        score = SCORE_VALUE_OPTIONS.NO_DATA
      }
      if (revEval >= 20000) {
        score = SCORE_VALUE_OPTIONS.GOOD
      }
      if (revEval >= 8000) {
        score = SCORE_VALUE_OPTIONS.AVERAGE
      }
    }

    return {
      score,
      formattedValue: `${accounting.formatMoney(revEval, '$', 0)}/mo`,
      styleColor: getColor(score),
      order: getScorecardOrder(score),
      selectedContent: contentOptions[score]['annualRevenue']
    }
  },
}

function getColor(score) {
  let color = COLOR_VAR.NO_DATA
  if (score === SCORE_VALUE_OPTIONS.POOR) {
    color = COLOR_VAR.RED
  } else if (score === SCORE_VALUE_OPTIONS.AVERAGE) {
    color = COLOR_VAR.BLUE
  } else if (score === SCORE_VALUE_OPTIONS.GOOD) {
    color = COLOR_VAR.GREEN
  }
  return color
}


function getScorecardOrder(score) {
  if (score === SCORE_VALUE_OPTIONS.POOR) {
    return 1
  }
  if (score === SCORE_VALUE_OPTIONS.AVERAGE) {
    return 2
  }
  if (score === SCORE_VALUE_OPTIONS.GOOD) {
    return 3
  }
  return 4
}

export { scorecards, SCORE_VALUE_OPTIONS, scoreValueFunctions, URL_OPTIONS, COLOR_VAR }
