export default [
  {
    regExp: /.*\/lender-profile\/45806.*/,
    redirect: 'loan-reviews/reliant-funding',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/224.*/,
    redirect: 'loan-reviews/ondeck',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/45474.*/,
    redirect: 'loan-reviews/forward-financing',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/45948.*/,
    redirect: 'loan-reviews/kapitus',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/1152.*/,
    redirect: 'loan-reviews/business-backer',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/46025.*/,
    redirect: 'loan-reviews/fundbox',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/44878.*/,
    redirect: 'loan-reviews/expansion-capital-group',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/44914.*/,
    redirect: 'loan-reviews/headway-capital',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/46009.*/,
    redirect: 'loan-reviews/mulligan-funding',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/45998.*/,
    redirect: 'loan-reviews/lendini',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/44886.*/,
    redirect: 'loan-reviews/quickbridge-review',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/44452.*/,
    redirect: 'loan-reviews/funding-circle',
    hpRedirect: true
  },
  {
    regExp: /.*\/lender-profile\/45996.*/,
    redirect: 'loan-reviews/monarch-commercial-capital',
    hpRedirect: true
  },
  {
    regExp: /.*\/lenders.*/,
    redirect: 'loan-reviews',
    hpRedirect: true
  },
  {
    // Redirect catch-all but ignores the ones that are defined above
    regExp: /.*\/lender-profile\/(?!45806$|224$|45474|45948$|1152$|46025$|44878$|44914$|46009$|45998$|44886$|44452$|45996$)\d*.*/,
    redirect: 'loan-reviews',
    hpRedirect: true
  }
]
