import { defineStore } from 'pinia'

import get from 'lodash/get'
import env from '~/libs/env'

import { useRootStore } from '~/store/root'

const customAffiliateWhitelist = {
  1539022715: {
    name: 'heartland',
    csPhone: '8553068062',
    affiliateId: 1539022715,
  },
  // 3153470988: {
  //     name: 'sba',
  //     csPhone: '8558536346',
  //     affiliateId: 3153470988,
  // }
}

/**
 * A function to match a more complete falsy collection including stringified falsy values of any capitalization
 * @param {*} val
 */
 const isFalsy = function (val) {
  return !val || /^\s*(null|undefined|NaN|0|false)\s*$/i.exec(val)
}

export const useCobrandStore = defineStore('cobrand', () => {
  const rootStore = useRootStore()
  const nuxtApp = useNuxtApp()
  const { $lendioCookies, $axios } = nuxtApp

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

  const loaded = ref(false)
  const config = ref(null)
  const affiliateCobrandEnabled = ref(false)
  const affiliateAccess = ref(false)
  const foundAffiliateId = ref(null)
  const customCobrandAffiliates = ref([1539022715, 3610136120])

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/


  async function load({ query }) {

    /** RETRIEVE AFFILIATE ID FOR COBRANDING **/
    // Retrieve from query params
    let affiliateId = get(query, 'affiliate', null)
    // Retrieve from affiliate cookie
    if (isFalsy(affiliateId)) affiliateId = $lendioCookies.get('affiliate')
    // Retrieve from borrower's marketing record

    if (isFalsy(affiliateId)) {
        const marketing = await rootStore.getMarketingOwnership()
        affiliateId = get(marketing, 'affiliateId', null)
    }
    // Retrieve from cobrand cookie if borrower and affiliate cookies are not returning anything
    if (isFalsy(affiliateId)) affiliateId = $lendioCookies.get('cobrand')
    // Give up on cobranding if there is still no affiliateId
    if (isFalsy(affiliateId)) return

    /** AFFILIATE ID FOUND - GET COBRAND CONFIG **/
    foundAffiliateId.value = affiliateId

    await this.getAffiliateConfig(affiliateId)

    // affiliate cookie is nuked on logout, save cobrand cookie as a fallback
    // this should probably only be updated here to prevent logging in from toggling landing page attribution
    $lendioCookies.set('cobrand', affiliateId)
  }

  async function getAffiliateConfig(affiliateId) {
    if (isFalsy(affiliateId)) return
    if (get(config.value, 'affiliateId', null) === affiliateId) return
    let _config = get(customAffiliateWhitelist, `${affiliateId}`, null)
    if (_config) {
      config.value = _config
      loaded.value = true
      return
    }

    affiliateId = +affiliateId // Make sure we only pass a number
    await $axios.get(`${env('apiUrl')}/cobrand/${affiliateId}`)
      .then(res => {
        _config = get(res.data, 'data')
        if (!_config || !Object.keys(_config).length) return
        if (_config.hasOwnProperty('logoUrl')) {
          _config.link = _config.link // Follow-up task: https://lendio.atlassian.net/browse/APPL-2257
        }
        customAffiliateWhitelist[_config.affiliateId] = _config
      })
  }

  async function setAffiliateCobrand(affiliateId) {
    if (isFalsy(affiliateId)) return
    await this.getAffiliateConfig(affiliateId)
  }

  return {
    //state
    loaded,
    config,
    affiliateCobrandEnabled,
    affiliateAccess,
    foundAffiliateId,
    customCobrandAffiliates,

    //Getters

    //Action
    load,
    getAffiliateConfig,
    setAffiliateCobrand
  }
})
