export function getEmbeddedStyleProps(customizations) {
  let defaultStyles = {
    appAmountFontSize: 32,
    appAmountFontWeight: 600,
    appAmountAlignment: 'center',
    appAnchorTagColor: '#2175EB',
    appAsideBackgroundColor:'#292D33',
    appBodyBackgroundColor: '#FFFFFF',
    appBodyHeaderFontColor: '#15171A',
    appBodyHeaderFontSize: 24,
    appBodyHeaderFontWeight: 'bold',
    appBodyPrimaryFontColor: '#15171A',
    appBodyPrimaryFontSize: 14,
    appBodyPrimaryFontWeight: 300,
    appButtonBorderRadius: 8,
    appButtonFontWeight: 500,
    appButtonPaddingX: 48,
    appButtonPaddingY: 12,
    appButtonBackgroundColor: '#2175EB',
    appButtonFontColor: '#FFFFFF',
    appButtonFontSize: 14,
    appButtonShadowVisible: false,
    appCardBorderColor: '#C4CDD9',
    appCardBorderRadius: 15,
    appCardLabelBackgroundColor: '#062248',
    appCardLabelFontColor: '#FFFFFF',
    appFontFamilyBody: 'inherit',
    appFontFamilyBold: 'inherit',
    appFormFieldBackgroundColor: '#FFFFFF',
    appFormFieldLabelColor: '#777170',
    appFormFieldValueColor:'#15171A',
    appInfoIconColor: '#2175EB',
    appInputFocusShadow: '',
    appNavTextPrimary: '#FFFFFF',
    appNavCirclePrimary: '#525A66',
    appNavCircleTextPrimary: '#FFFFFF',
    appNavTextCompleted: '#0EB80E',
    appNavCircleCompleted: '#0EB80E',
    appNavCircleTextCompleted: '#FFFFFF',
    appNavTextCompletedHover: '#3AD63A',
    appNavTextDisabled: '#96A2B2',
    appNavPoweredByLendioLogo: 'light',
    appNavPoweredByLendioFontColor: '#FFFFFF',
    appNavPoweredByLendioPseudoColor: '#7A8799',
    appNavPoweredByLendioText: 'Powered by',
    appNavCircleDisabled: '#96A2B2',
    appNavCircleTextDisabled: '#E9EDF2',
    appOutlineButtonBackgroundColor: '#FFFFFF',
    appPrimaryColor: '#2175EB',
    appSbaIntroBackgroundColor: '#FFFFFF',
    appSecondaryColor: '#FAB42D'
  };
  let embeddedStyles = customizations
    ? { ...defaultStyles, ...customizations }
    : { ...defaultStyles }

  return {
    '--app-amount-font-size': embeddedStyles.appAmountFontSize + 'px',
    '--app-amount-font-weight': embeddedStyles.appAmountFontWeight,
    '--app-amount-alignment': embeddedStyles.appAmountAlignment,
    '--app-anchor-tag-color': embeddedStyles.appAnchorTagColor,
    '--app-aside-background-color': embeddedStyles.appAsideBackgroundColor,
    '--app-body-background-color': embeddedStyles.appBodyBackgroundColor,
    '--app-body-header-font-color': embeddedStyles.appBodyHeaderFontColor,
    '--app-body-header-font-size': embeddedStyles.appBodyHeaderFontSize + 'px',
    '--app-body-header-font-weight': embeddedStyles.appBodyHeaderFontWeight,
    '--app-body-primary-font-color': embeddedStyles.appBodyPrimaryFontColor,
    '--app-body-primary-font-size': embeddedStyles.appBodyPrimaryFontSize + 'px',
    '--app-body-primary-font-weight': embeddedStyles.appBodyPrimaryFontWeight,
    '--app-nav-powered-by-lendio-logo': embeddedStyles.appNavPoweredByLendioLogo,
    '--app-nav-powered-by-lendio-font-color': embeddedStyles.appNavPoweredByLendioFontColor,
    '--app-nav-powered-by-lendio-pseudo-color': embeddedStyles.appNavPoweredByLendioPseudoColor,
    '--app-button-background-color': embeddedStyles.appButtonBackgroundColor,
    '--app-button-border-radius': embeddedStyles.appButtonBorderRadius + 'px',
    '--app-button-font-color': embeddedStyles.appButtonFontColor,
    '--app-button-font-size': embeddedStyles.appButtonFontSize + 'px',
    '--app-button-font-weight': embeddedStyles.appButtonFontWeight,
    '--app-button-padding-x': embeddedStyles.appButtonPaddingX + 'px',
    '--app-button-padding-y': embeddedStyles.appButtonPaddingY + 'px',
    '--app-button-shadow-visible': embeddedStyles.appButtonShadowVisible,
    '--app-card-border-color': embeddedStyles.appCardBorderColor,
    '--app-card-border-radius': embeddedStyles.appCardBorderRadius + 'px',
    '--app-card-label-background-color': embeddedStyles.appCardLabelBackgroundColor,
    '--app-card-label-font-color': embeddedStyles.appCardLabelFontColor,
    '--app-font-family-body': embeddedStyles.appFontFamilyBody,
    '--app-font-family-bold': embeddedStyles.appFontFamilyBold,
    '--app-form-field-background-color': embeddedStyles.appFormFieldBackgroundColor,
    '--app-form-field-label-color': embeddedStyles.appFormFieldLabelColor,
    '--app-form-field-value-color': embeddedStyles.appFormFieldValueColor,
    '--app-input-focus-shadow': embeddedStyles.appInputFocusShadow,
    '--app-info-icon-color': embeddedStyles.appInfoIconColor,
    '--app-nav-text-primary': embeddedStyles.appNavTextPrimary,
    '--app-nav-circle-primary': embeddedStyles.appNavCirclePrimary,
    '--app-nav-circle-text-primary': embeddedStyles.appNavCircleTextPrimary,
    '--app-nav-text-completed': embeddedStyles.appNavTextCompleted,
    '--app-nav-text-completed-hover': embeddedStyles.appNavTextCompletedHover,
    '--app-nav-circle-completed': embeddedStyles.appNavCircleCompleted,
    '--app-nav-circle-text-completed': embeddedStyles.appNavCircleTextCompleted,
    '--app-nav-text-disabled': embeddedStyles.appNavTextDisabled,
    '--app-nav-circle-disabled': embeddedStyles.appNavCircleDisabled,
    '--app-nav-circle-text-disabled': embeddedStyles.appNavCircleTextDisabled,
    '--app-outline-button-background-color': embeddedStyles.appOutlineButtonBackgroundColor,
    '--app-primary-color': embeddedStyles.appPrimaryColor,
    '--app-primary-400': embeddedStyles.appPrimaryColor,
    '--app-primary-300': embeddedStyles.appPrimaryColor,
    '--app-primary-200': embeddedStyles.appPrimaryColor,
    '--app-primary-100': embeddedStyles.appPrimaryColor,
    '--app-sba-intro-background-color': embeddedStyles.appSbaIntroBackgroundColor,
    '--app-secondary-color': embeddedStyles.appSecondaryColor,
    '--app-focus-color': embeddedStyles.appPrimaryColor + 80,
  }
}
export default {
  getEmbeddedStyleProps
}
