<template>
  <section>
    <BackNavAndMobileHeader
      :btnSubmit="previousPage"
      header-title="New Requirements"
      renderButton
    />
    <div class="pt-3 alert-container bg-light">
      <div class="container">
        <div class="row mx-0"><Alert class="col-12 mx-0 px-0 mb-3" @created="canShowAlert = true" ref="alert"></Alert></div>
      </div>
    </div>
    <div v-if="loadingQuestions || !loanReqQuestions.length">
      <LoadingSpinner header="Getting questions"/>
    </div>
    <section v-else>
      <h3 class="mb-5">Tell us about your new loan requirements</h3>
      <form name="renewal-verify" class="marketplace-content">
        <div class="form-section">
          <div v-for="(question, index) in loanReqQuestions" :key="question.alias" ref="form">
            <BorrowerAttribute
              :question="question"
              :update-index="index"
              @invalidity="updateValidity(question.alias, $event)"
              @answer="updateQuestion"
            >
            </BorrowerAttribute>
          </div>
        </div>
      </form>
      <QuestionNav
        :submitButton="{
          btnText: 'Continue',
          disabled: isInvalid,
        }"
        :submitting="submitting"
        @submit="saveQuestions"
        v-show="!loadingQuestions"
      />
    </section>
  </section>
</template>

<script>
import { mapState } from 'pinia'
import BorrowerAttribute from '~/components/borrowerAttributes/BorrowerAttribute.vue'
import Alert from '~/components/Alert.vue'
import LoadingSpinner from "~/components/marketplace/subComponents/LoadingSpinner"
import { renewalLoanRequirements } from '~/libs/page-helper'

import QuestionNav from "~/components/marketplace/subComponents/QuestionNav"
import BackNavAndMobileHeader from '~/components/marketplace/subComponents/BackNavAndMobileHeader.vue'
import { useProgressStore } from '~/store/progress'
import { useQuestionsStore } from '~/store/questions'
import { useBorrowerStore } from '~/store/borrower'
import { useExperienceStore } from '~/store/experience'

export default {
  name: 'RenewalNewLoanRequirements',
  components: {
    Alert,
    LoadingSpinner,
    BorrowerAttribute,
    QuestionNav,
    BackNavAndMobileHeader
  },
  data () {
    return {
      submitting: false,
      canShowAlert: undefined,
      dismissAlert: false,
      invalidity: {}
    }
  },
  async setup () {
    definePageMeta({
      layout: "renewal",
      pageTransition: {
        name: 'mp-transition'
      },
      middleware: ['transitions-helper']
    });
    const borrowerStore = useBorrowerStore()
    await borrowerStore.getBorrower({ forceReload: true })
    await Promise.all([
      borrowerStore.getBorrowerValues(),
      useQuestionsStore().getQuestions({allFields: true}),
      useExperienceStore().forceGetExperience(),
    ])
  },
  async mounted () {
    useQuestionsStore(this.$pinia).$patch({ singleForm: true })
  },
  computed: {
    ...mapState(useQuestionsStore, [
      'componentQuestions',
      'loadingQuestions',
    ]),
    loanReqQuestions () {
      return renewalLoanRequirements.map((alias) => this.componentQuestions[alias])
    },
    isInvalid() {
      const invalid = Object.values(this.invalidity).every(v => v === false)
      return !process.client || !invalid
    },
  },
  methods: {
    updateValidity (key, isInvalid) {
      this.invalidity = {
        ...this.invalidity,
        [key]: isInvalid
      }
    },

    previousPage () {
      useProgressStore(this.$pinia).incrementRoute({
        currentPath: useRoute().path,
        appName: 'renewal',
        forward: false })
    },
    async saveQuestions () {
      // Need this to keep submit button disabled until next question appears.
      this.submitting = true
      const success = await useQuestionsStore(this.$pinia).saveAnswers()
      if (!success) {
        this.submitting = false
        return
      }
      await useProgressStore(this.$pinia).incrementRoute({
        currentPath: useRoute().path,
        appName: 'renewal',
        forward: true
      })
    },
    updateQuestion({ attr, isInvalid }) {
      if (!attr || attr.alias === undefined || isInvalid) {
        return false
      }
      useQuestionsStore(this.$pinia).updateAnswer(attr)
    },
  }
}
</script>

<style scoped lang="scss">
  .alert-container {
    display:none;
  }
  .form-section {
    max-width: 365px;
  }
</style>
