export default function (key, $config) {
  let env
  if ($config) {
    env = $config.public
  } else {
    env = process.client ? window.env : global.env
  }

  if (env.debugEnabled === 'true') {
    console.log(`Environment Variable ${key} accessed:`, env[key])
  }
  return env[key] || env.public && env.public[key] || null
}
