import { defineStore } from 'pinia'

import AnalyticsService from '~/libs/analytics-service'
import env from '~/libs/env'
import {
  experiments,
  experimentWithId,
  resolveExperimentGroupFromCookie
} from '~/libs/experiments'

import { useRootStore } from '~/store/root'
import { useBorrowerStore } from '~/store/borrower'

import get from 'lodash/get'

export const useExperimentsStore = defineStore('experiments', () => {
  const nuxtApp = useNuxtApp()
  const { $axios, $lendioCookies } = nuxtApp
  const rootStore = useRootStore()
  const borrowerStore = useBorrowerStore()
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const activeUserExperiments = ref([])
  const activeExperimentNames = ref([])
  const loadedActiveExperiments = ref(false)
  const experimentGroups = ref({})

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const getExperimentGroupById = computed(() => {
    return (experimentId) => {
      return experimentGroups.value[experimentWithId(experimentId).name]
    }
  })

  const getExperimentGroupByExperimentName = computed(() => {
    return (name) => {
      return experimentGroups.value[name]
    }
  })

  const activeUserExperimentByName = computed(() => {
    return (name) => {
      const _activeUserExperiments = activeUserExperiments.value
      if (_activeUserExperiments?.length === 0) {
        return null
      }
      return _activeUserExperiments.find(experiment => {
        return experiment.name === name
      })
    }
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  /**
   * Filter out tests that aren't active/don't exist
   * @param _experimentGroups
   * @returns {Promise<void>}
   */
  async function filterActiveExperimentGroupsByName(_experimentGroups) {
    return _experimentGroups.filter((group) =>
      activeExperimentNames.value.includes(group.name)
    )
  }

  async function getActiveExperimentsByName() {
    const res = await $axios.get(`${env('apiUrl')}/experiments/active`)

    activeExperimentNames.value = res.data.data.map(
      (experiment) => experiment.name
    )
  }

  /**
   * Collects all active user experiments and posts them to the api so they are
   * recorded in the database.
   */
  async function registerAllExperimentGroups({ cookies } = {}) {
    if (!cookies) {
      cookies = $lendioCookies
    }
    const _experimentGroups = await this.resolveAllExperimentGroups({
      cookies
    })
    this.trackEventsForExperimentGroups(_experimentGroups)
    this.postExperimentGroupsToApi(_experimentGroups)
  }

  async function resolveExperimentGroup({
    cookies,
    experimentIdentifier,
    experimentGetterFn,
    cookieName,
    isServer
  }) {
    // Check for group already in store and return it if available
    const groupInStore = getExperimentGroupById.value(experimentIdentifier)
    if (typeof groupInStore !== 'undefined') {
      return groupInStore
    }
    const group = await resolveExperimentGroupFromCookie(cookieName, cookies, import.meta.client)

    // Skip committing to the store if we are resolving on the server and we failed to get
    // a valid group. This allows it to still be resolved by the client.
    if (
      isServer &&
      (group === null || typeof group === 'undefined' || isNaN(group))
    ) {
      return null
    }

    const experiment = experimentGetterFn(experimentIdentifier)
    if (group === null) {
      return
    }
    experimentGroups.value[experiment.name] = group
    return group
  }

  /**
   * Parses the document's cookies to determine which experiment group a user belongs to
   * for a given experiment identifier. Checks cookies on an interval until a timeout is
   * reached.
   * @param {*} testIdentifier
   */
  async function resolveLendioExperimentGroup({ experimentId, cookies, isServer }) {
    return this.resolveExperimentGroup({
      cookies,
      isServer,
      experimentIdentifier: experimentId,
      experimentGetterFn: experimentWithId,
      cookieName: `lendio_exp_${experimentId}`
    })
  }

  async function resolveAllExperimentGroups({ cookies } = {}) {
    const _experimentGroups = []
    await Promise.all(
      Object.keys(experiments).map(async (key) => {
        let experimentGroup = {
          id: experiments[key].id,
          name: experiments[key].name
        }
        if (experiments[key].id) {
          experimentGroup.group = await this.resolveLendioExperimentGroup({
            experimentId: experiments[key].id,
            cookies: cookies
          })
        }
        if (experimentGroup.group > 0) {
          _experimentGroups.push(experimentGroup)
        }
      })
    )
    return _experimentGroups
  }

  async function postExperimentGroupsToApi(_experimentGroups) {
    // Ensure we are logged in
    if (
      !(
        rootStore.authUser &&
        rootStore.authUser.id
      )
    ) {
      return
    }

    // Only post experiment groups if we have at least one to send
    if (_experimentGroups.length <= 0) {
      return
    }

    // POST values of each that was not already eager loaded to the user experiments api
    // endpoint
    const url = `${env('apiUrl')}/user/${
      rootStore.authUser.id
    }/experiments`
    await $axios.post(url, JSON.stringify(_experimentGroups), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  async function trackEventsForExperimentGroups(_experimentGroups) {
    _experimentGroups.forEach((experimentGroup) => {
      const groupName =
        experimentGroup.group === 1
          ? 'control group'
          : `variant group ${experimentGroup.group - 1}`
      const eventName = `Enter ${experimentGroup.name} ${groupName}`
      AnalyticsService.track(eventName)
    })
  }

  async function fetchActiveExperimentsForCurrentUser() {
    const currentUserId = rootStore.userProfile.id ?? null
    if (!currentUserId) return;
    try {
      const response = await $axios.get(`${env("apiUrl")}/user/${currentUserId}/experiments/active`)
      const result = get(response, 'data.data', [])
      activeUserExperiments.value = result
      loadedActiveExperiments.value = true
      return result
    } catch (e) {
      log.error("Unable to get borrower experiments", {
        err: e,
        errMessage: e.message,
        userId: currentUserId,
      })
    }
  }

  async function assignExperimentsIfQualified(experiments) {
    const borrowerId = borrowerStore.borrowerId

    const url = `${env('apiUrl')}/borrower/${borrowerId}/experiments/assign-qualified`
    const assignedExperiments = await $axios.post(url, JSON.stringify({ experiments }), {
      headers: {
        'Content-Type': 'application/json'
      }
    }).catch(e => log.error("Unable to get experiments on submit", { err: e }))

    return assignedExperiments?.data?.data
  }

  function checkIfInActiveExperimentByName(name) {
    if (!name) {
      return false
    }

    return activeUserExperiments.value.some((userExperiment) => {
      return userExperiment.name === name && userExperiment.group >= 1
    })
  }

  return {
    //STATE
    activeUserExperiments,
    activeExperimentNames,
    loadedActiveExperiments,
    experimentGroups,

    //GETTERS
    getExperimentGroupById,
    getExperimentGroupByExperimentName,
    activeUserExperimentByName,

    //ACTIONS
    assignExperimentsIfQualified,
    filterActiveExperimentGroupsByName,
    registerAllExperimentGroups,
    getActiveExperimentsByName,
    resolveExperimentGroup,
    resolveLendioExperimentGroup,
    resolveAllExperimentGroups,
    postExperimentGroupsToApi,
    trackEventsForExperimentGroups,
    fetchActiveExperimentsForCurrentUser,
    checkIfInActiveExperimentByName,
  }
})
