import { defineStore } from 'pinia'
import { useQuestionsStore } from '~/store/questions'


export const useBusinessSwitchStore = defineStore('businessSwitch', () => {
  const nuxtApp = useNuxtApp()
  const { $lendioCookies } = nuxtApp
  const questionsStore = useQuestionsStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
const businessSwitchModalVisible = ref(false)
const warningModalVisible = ref(false)
/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
function businessSwitchModalToggle(visibility) {
  if (questionsStore.areAnswersDirty && visibility) {
    warningModalVisible.value = visibility
  } else if(warningModalVisible.value && visibility) {
    warningModalVisible.value = !visibility
    businessSwitchModalVisible.value = visibility
  } else {
    businessSwitchModalVisible.value = visibility
  }
}

function warningModalToggle(visibility) {
  warningModalVisible.value = visibility
}

async function setBorrowerIdCookie(borrowerId) {
  await $lendioCookies.set('borrowerId', borrowerId, { path: '/bp/' })
}

return {
  //STATE
  businessSwitchModalVisible,
  warningModalVisible,

  //GETTERS

  //ACTIONS
  businessSwitchModalToggle,
  warningModalToggle,
  setBorrowerIdCookie,
}
})
