import {defineStore} from 'pinia';
import {useAffiliateCustomizationStore} from '~/store/affiliate-customization'
import {useAppAnalyticsStore} from '~/store/app-analytics';
import {useRootStore} from '~/store/root';
import {useUserStore} from '~/store/user';
import env from '~/libs/env'
import { MBOX_DETAILS } from '~/libs/adobe-target'

export const useIdentityStore = defineStore('identity', () => {
  const nuxtApp = useNuxtApp()
  const { $axios, $lendioCookies } = nuxtApp
  const rootStore = useRootStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const userStore = useUserStore()
  const appAnalyticsStore = useAppAnalyticsStore()
  const identityBaseUrl = env('identityServiceUrl')
  const authBaseUrl = `${identityBaseUrl}/auth`
  const resetPasswordApiUrl = `${identityBaseUrl}/identity/reset-password`
  const updatePasswordApiUrl = `${identityBaseUrl}/identity/update-password`
  const passthroughErrorMessages = [
    'Invalid MFA code',
    'Invalid code',
  ]
  const redirectErrors = [
    'Login attempt is not in the appropriate state',
    'Too many requests',
    'Invalid transaction',
    'Max check attempts reached',
    'Max send attempts reached',
  ]

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

  const contactUsUrl = ref('/identity/contact')
  const enrollChallengeDestination = ref(null)
  const enrolling = ref(false)
  const forgotPasswordIdentifier = ref('')
  const forgotPasswordJwt = ref('')
  const forgotPasswordUrl = ref('/identity/forgot-password')
  const forgotPasswordVerifyUrl = ref('/identity/forgot-password-verify')
  const jwt = ref('')
  const authHeader = ref(`lendio-jwt ${jwt.value}`)
  const loginUrl = ref('/identity/login')
  const maskedMfaDestination = ref(null)
  const mfaEnrollActivateUrl = ref('/identity/mfa-enroll-activate')
  const mfaEnrollUrl = ref('/identity/mfa-enroll')
  const mfaMethods = ref([])
  const mfaMethodsUrl = ref('/identity/mfa-methods')
  const mfaVerifyUrl = ref('/identity/mfa-verify')
  const passwordExpiredUrl = ref('/identity/password-expired')
  const recoveringAccount = ref(false)
  const resetPasswordUrl = ref('/identity/reset-password')
  const selectedMfaMethod = ref(null)
  const ssnVerificationUrl = ref('/identity/ssn-verification')
  const totpMfaQrCode = ref(null)
  const totpMfaSeed = ref(null)
  const transactionId = ref(null)
  const inMfaExperiment = ref(false)
  const iframeOnly = ref(null)

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/


  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  async function checkForMfaExperiment() {
    inMfaExperiment.value = await appAnalyticsStore.checkSingleTargetOffer({
      mbox: MBOX_DETAILS.TEST_IDENTITY_MULTIPLE_MFA_METHODS.NAME,
      searchTerm: 'MultipleMfaMethods',
    })
  }

  /**
   * Login the user
   * @param {string} email - User email
   * @param {string} password - User password
   * @returns {Promise<void>}
   */
  async function login(email, password) {
    try {
      const identityLoginResponse = await $axios.post(`${authBaseUrl}/sign-in`, {
        identifier: email,
        password: password,
        tenantId: affiliateCustomizationStore.tenantId,
      }, { withCredentials: true })
      await _handleIdentityTransactionResponse(identityLoginResponse)
    } catch (error) {
      throw error
    }
  }

  /**
   * Verify SSN
   * @param {string} ssnLastFour - Last four digits of the user's SSN
   * @returns {Promise<void>}
   * @throws {Error} - If an error occurs
   */
  async function verifySsn(ssnLastFour) {
    try {
      const verifySsnResponse = await $axios.post(`${authBaseUrl}/transaction/ssn-last-four-verify`, {
        ssnLastFour,
        transactionId: transactionId.value,
      })
      await _handleIdentityTransactionResponse(verifySsnResponse)
    } catch (error) {
      throw error
    }
  }

  async function selectMfaMethod(method) {
    selectedMfaMethod.value = method
    if (enrolling.value) {
      if (method === 'totp') {
        await mfaEnroll('totp')
      }
      return navigateTo(mfaEnrollUrl.value)
    } else if (recoveringAccount.value) {
      await forgotPasswordChallenge(method, forgotPasswordIdentifier.value)
    } else {
      await mfaChallenge(method)
      return navigateTo(mfaVerifyUrl.value)
    }
  }

  /**
   * Enroll in MFA
   * @param {string} method - Method used to enroll in MFA
   * @param {string} to - Destination to send the MFA challenge
   * @param {boolean} resend - If true, resends a previously sent MFA challenge
   * @returns {Promise<void>}
   */
  async function mfaEnroll(method, to = null, resend = false) {
    if (!transactionId.value) {
      return navigateTo({
        path: loginUrl.value,
        query: {error: 'MFA enrollment failed. Please try again.'}
      })
    }
    try {
      enrollChallengeDestination.value = to
      const enrollResponse = await $axios.post(`${authBaseUrl}/enroll/${method}${resend ? '?resend=true' : ''}`, {
        transactionId: transactionId.value,
        to,
      })
      if (method === 'totp') {
        totpMfaQrCode.value = enrollResponse?.data?.data?.qrCode
        totpMfaSeed.value = enrollResponse?.data?.data?.seed
      } else {
        return navigateTo(`${mfaEnrollActivateUrl.value}`)
      }
      return
    } catch (error) {
      await _handleMfaError(error, 'MFA enrollment failed. Please try again.')
    }
  }

  /**
   * Activate MFA enrollment
   * @param {string} method - Method used to enroll in MFA
   * @param {string} code - MFA code
   * @param {boolean} rememberDevice - Upon successful MFA enrollment, a cookie will be set to remember this browser and skip MFA verification for a duration determined by identity service
   * @returns {Promise<void>}
   */
  async function mfaEnrollActivate(method, code, rememberDevice) {
    try {
      const enrollResponse = await $axios.post(`${authBaseUrl}/enroll-activate/${method}`, {
        code,
        transactionId: transactionId.value,
        rememberDevice,
      }, { withCredentials: true })
      await _handleIdentityTransactionResponse(enrollResponse)
      enrolling.value = false
    } catch (error) {
      await _handleMfaError(error, 'MFA enrollment failed. Please try again.')
    }
  }

    /**
   * Send an MFA code
   * @param {boolean} resend - If true, resends a previously sent MFA code
   * @returns {Promise<string>}
   */
    async function mfaChallenge(method, resend = false) {
      try {
        const challengeResponse = await $axios.post(`${authBaseUrl}/transaction/challenge/${method}${resend ? '?resend=true' : ''}`, {
          transactionId: transactionId.value,
        })
        maskedMfaDestination.value = challengeResponse?.data?.data?.maskedDestination?.replace(/\*/g, 'X')
      } catch (error) {
        await _handleMfaError(error, 'Failed to send MFA challenge. Please try again.')
      }
    }

    /**
     * Verify MFA code
     * @param {string} code - MFA code
     * @param {boolean} rememberDevice - Upon successful MFA verification, a cookie will be set to remember this browser and skip MFA verification for a duration determined by identity service
     * @returns {Promise<void>}
     */
    async function mfaVerify(method, code, rememberDevice) {
      try {
        const verifyResponse = await $axios.post(`${authBaseUrl}/transaction/verify/${method}`, {
          code,
          transactionId: transactionId.value,
          rememberDevice,
        }, { withCredentials: true })
        await _handleIdentityTransactionResponse(verifyResponse)
      } catch (error) {
        await _handleMfaError(error, 'MFA challenge verification failed. Please try again.')
      }
    }

  /**
   * Exchange a one-time use login token for a client token
   * @returns {Promise<void>}
   */
  async function tokenLogin(token) {
    try {
      const exchangeTokenResponse = await $axios.get(`${identityBaseUrl}/auth/token-login`, {
        headers: {
          Authorization: `lendio-jwt ${token}`,
        }
      })
      const jwt = exchangeTokenResponse.data.data
      await rootStore.setAuthToken(jwt, 'LENDIO_JWT')
    } catch (error) {
      throw error
    }
  }

  /**
   * Reset the expired password
   * @param {string} oldPassword - Old password
   * @param {string} newPassword - New password
   * @param {string} confirmPassword - Confirmed password
   * @returns {Promise<void>}
   */
  async function resetExpiredPassword(oldPassword, newPassword, confirmPassword) {
    try {
      const resetExpiredPasswordResponse = await $axios.post(`${authBaseUrl}/transaction/reset-expired-password`, {
        oldPassword,
        newPassword,
        confirmPassword,
        transactionId: transactionId.value,
      })
      await _handleIdentityTransactionResponse(resetExpiredPasswordResponse)
    } catch (error) {
      if (error?.errors?.[0] === 'Invalid transaction') {
        return navigateTo({ path: loginUrl.value, query: { error: 'Transaction expired. Please log in to to try again.' } })
      }
      throw error
    }
  }

  /**
   * Retrieve the forgot password methods
   * @param {string} identifier - Identifier used to retrieve the forgot password methods
   * @returns {Promise<void>}
   * @throws {Error} - If an error occurs
   */
  async function accountRecoveryMethods(identifier) {
    try {
      const response = await $axios.post(`${identityBaseUrl}/account-recovery/methods`, {
        identifier,
        tenantId: affiliateCustomizationStore.tenantId,
      })
      forgotPasswordIdentifier.value = identifier
      mfaMethods.value = response?.data?.data
      recoveringAccount.value = true
      if (!mfaMethods.value) {
        return navigateTo(loginUrl.value)
      } else if (mfaMethods.value.length < 2) {
        await selectMfaMethod(mfaMethods.value[0] ?? 'sms')
      } else {
        return navigateTo(mfaMethodsUrl.value)
      }
    } catch (error) {
      throw new Error('Password reset request failed. Please try again')
    }
  }

  /**
   * Send a forgot password challenge
   * @param {string} method - Method used to send the forgot password challenge
   * @param {string} identifier - Identifier used to send the forgot password challenge
   * @returns {Promise<void>}
   */
    async function forgotPasswordChallenge(method) {
      try {
        const forgotPasswordChallengeResponse = await $axios.post(`${identityBaseUrl}/account-recovery/forgot-password/challenge/${method}`, {
          identifier: forgotPasswordIdentifier.value,
          tenantId: affiliateCustomizationStore.tenantId,
        })
        maskedMfaDestination.value = forgotPasswordChallengeResponse?.data?.data?.maskedDestination?.replace(/\*/g, 'X')
        if (forgotPasswordChallengeResponse?.data?.data?.defaultedToEmail) {
          return navigateTo({
            path: loginUrl.value,
            query: { success: 'Password reset request successful. Please check your email.' },
          });
        } else {
          return navigateTo(forgotPasswordVerifyUrl.value);
        }
      } catch (error) {
        const errorMessage = error?.response?.data?.errors[0] ?? 'Failed to send forgot password challenge.'
        if (errorMessage === 'Identity is not enrolled in the requested method') {
          return navigateTo(contactUsUrl.value)
        }
        throw new Error('Failed to send forgot password challenge.')
      }
    }

    /**
     * Verify the forgot password code
     * @param {string} method - Method used to send the forgot password code
     * @param {string} code - Forgot password code
     * @returns {Promise<void>}
     */
    async function forgotPasswordVerify(method, code) {
      try {
        const forgotPasswordVerifyResponse = await $axios.post(`${identityBaseUrl}/account-recovery/forgot-password/verify/${method}`, {
          identifier: forgotPasswordIdentifier.value,
          tenantId: affiliateCustomizationStore.tenantId,
          code,
        })
        if (forgotPasswordVerifyResponse?.data?.data) {
          forgotPasswordJwt.value = forgotPasswordVerifyResponse.data.data
          return navigateTo(resetPasswordUrl.value)
        } else {
          const responseErrorMessage = forgotPasswordVerifyResponse?.data?.errors?.[0]
          throw new Error(passthroughErrorMessages.includes(responseErrorMessage) ? responseErrorMessage : 'Unknown error. Please try again.')
        }
      } catch (error) {
        await _handleMfaError(error, 'MFA challenge verification failed. Please try again.')
      }
    }

    async function setForgotPasswordJwt(jwt) {
      forgotPasswordJwt.value = jwt
    }

    /**
     * Reset the forgotten password
     * @param {string} newPassword - New password
     * @param {string} confirmedPassword - Confirmed password
     * @returns {Promise<void>}
     */
    async function resetForgottenPassword(newPassword, confirmedPassword) {
      try {
        await $axios.post(`${resetPasswordApiUrl}`, {
          newPassword,
          confirmedPassword,
        }, {
          headers: {
            Authorization: `lendio-jwt ${forgotPasswordJwt.value}`,
          },
        })
        forgotPasswordJwt.value = ''
        forgotPasswordIdentifier.value = ''
        return navigateTo({ path: loginUrl.value, query: { success: 'Your password has been reset' } })
      } catch (error) {
        throw error
      }
    }

  /**
   * Logout the user
   * @returns {Promise<void>}
   */
  async function logout(path) {
    await $axios.post(`${identityBaseUrl}/logout`).catch((error) => {
      if (error?.response?.status !== 401) {
        log.warning('User Logout Errored', { error })
      }
      return
    })
    await rootStore.destroyUser()
    await userStore.clearAccessToken()
    rootStore.destroyCookiesAndLocalStorage(path)
    // IMPORTANT - hard navigate (external: true) to clear the store
    // needs /bp included since external is set to true
    return navigateTo('/bp' + loginUrl.value, { external: true, replace: true })
  }

  /**
   *
   * @param oldPassword
   * @param newPassword
   * @returns {Promise<*>}
   */
  async function updatePassword(oldPassword, newPassword) {
    try {
      return await $axios.put(`${updatePasswordApiUrl}`, {
        oldPassword,
        newPassword,
      }, {
        withCredentials: true,
      })
    } catch (error) {
      let responseErrorMessage = error?.response?.data?.errors[0];
      if (responseErrorMessage?.includes(('Unauthorized'))) {
        throw new Error('Old Password is not correct')
      } else if (responseErrorMessage?.includes(('Password must '))) {
        throw new Error(responseErrorMessage)
      }
      throw new Error('We\'re sorry! Something went wrong.')
    }
  }

  async function isIframeOnly() {
    if (iframeOnly.value !== null) {
      return iframeOnly.value
    }
    const headers = useRequestHeaders(['origin'])
    const response = await $axios.get(`${identityBaseUrl}/client/iframe-only`, { headers });
    iframeOnly.value = response.data.data !== false;
    return iframeOnly.value
  }

  /**
   * Private method for consistently handling identity service responses
   * @param {object} response - Response object
   * @returns {Promise<void>}
   * @private
  */
  async function _handleIdentityTransactionResponse(response) {
    if (response?.data?.data?.status) {
      if (response?.data?.data?.transactionId) {
        transactionId.value = response.data.data.transactionId
      }
      switch (response.data.data.status) {
        case 'success':
          await _handleLoginSuccess(response)
          break
        case 'ssnVerificationRequired':
          return navigateTo(ssnVerificationUrl.value)
          break
        case 'mfaEnroll':
          await _handleMfaEnroll(response)
          break
        case 'mfaRequired':
          await _handleMfaRequired(response)
          break
        case 'passwordExpired':
          return navigateTo(passwordExpiredUrl.value)
          break
        default:
          throw new Error('An error occurred. Please try again.')
      }
    } else {
      throw new Error('An error occurred. Please try again.')
    }
  }

  async function _handleLoginSuccess(response) {
    enrollChallengeDestination.value = null
    transactionId.value = null
    jwt.value = response.data.data.jwt
    await rootStore.setAuthToken(response.data.data.jwt, 'LENDIO_JWT')
    await $axios.post(`${env('apiUrl')}/user/has-set-password`);
    const analyticsPromises = Promise.all([
      $axios.post(`${env('apiUrl')}/user/last-login`),
      userStore.addLastTouch(), // Note: skip if mobile "exchange token" flow
    ]);
    await rootStore.getCurrentUser({forceReload: true})
    if ($lendioCookies.get('redirect')) {
      return navigateTo(
        $lendioCookies.get('redirect').startsWith('/')
          ? $lendioCookies.get('redirect')
          : '/' + $lendioCookies.get('redirect'),
        { replace: true }
      )
    } else {
      return navigateTo('/resume-app?login=true', { replace: true })
    }
    $lendioCookies.remove('redirect')
    await analyticsPromises
  }

  async function _handleMfaEnroll(response) {
    enrolling.value = true
    mfaMethods.value = inMfaExperiment.value ? response.data.data.factorsAllowed : ['sms']

    if (mfaMethods.value.length > 1) {
      return navigateTo(mfaMethodsUrl.value)
    } else {
      await selectMfaMethod(mfaMethods.value[0])
    }
  }

  async function _handleMfaRequired(response) {
    if (response.data.data.enrolledFactors.length > 1) {
      mfaMethods.value = response.data.data.enrolledFactors
      return navigateTo(mfaMethodsUrl.value)
    } else {
      selectedMfaMethod.value = response.data.data.enrolledFactors[0]
      await mfaChallenge(response.data.data.enrolledFactors[0])
      return navigateTo(mfaVerifyUrl.value)
    }
  }

  /**
   * Private method for consistently handling MFA errors
   * @param {object} error - Error object
   * @param {string} defaultMessage - Default error message
   * @returns {Promise<void>}
   * @private
   */
  async function _handleMfaError(error, defaultMessage) {
    const errorMessage = error?.response?.data?.errors[0] ?? 'MFA Failed.'
    const shouldRedirect = redirectErrors.some((error) => errorMessage.includes(error))
    if (shouldRedirect) {
      return navigateTo({
        path: loginUrl.value,
        query: {error: defaultMessage }
      })
    } else {
      const endUserErrorMessage = passthroughErrorMessages.some((error) => errorMessage.includes(error))
        ? errorMessage
        : defaultMessage
      throw new Error(endUserErrorMessage)
    }
  }

  return {
    //state
    authHeader,
    enrollChallengeDestination,
    enrolling,
    forgotPasswordIdentifier,
    forgotPasswordJwt,
    forgotPasswordUrl,
    iframeOnly,
    jwt,
    loginUrl,
    maskedMfaDestination,
    mfaEnrollUrl,
    mfaEnrollActivateUrl,
    mfaMethods,
    mfaMethodsUrl,
    mfaVerifyUrl,
    recoveringAccount,
    resetPasswordUrl,
    selectedMfaMethod,
    totpMfaQrCode,
    totpMfaSeed,
    transactionId,
    //actions
    accountRecoveryMethods,
    checkForMfaExperiment,
    forgotPasswordChallenge,
    forgotPasswordVerify,
    isIframeOnly,
    login,
    tokenLogin,
    logout,
    mfaChallenge,
    mfaEnroll,
    mfaEnrollActivate,
    mfaVerify,
    resetExpiredPassword,
    resetForgottenPassword,
    setForgotPasswordJwt,
    selectMfaMethod,
    updatePassword,
    verifySsn,
  };
});
