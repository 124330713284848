
export default class QuestionSorter {

  constructor({
    head = [],
    aliases = [],
    tail = [],
    widgets = [],
    rootGetters
  } = {}) {
    this.head = head
    this.aliases = aliases
    this.tail = tail
    this.widgets = widgets
    this.rootGetters = rootGetters
    this.cache = {}
  }

  getQuestionOrder() {
    if (this.cache['questionOrder']) return this.cache['questionOrder'].slice()
    const head = this.getHead(),
      tail = this.getTail(),
      aliases = this.getAliases(),
      alreadySeen = {}
    head.concat(tail).forEach(alias => alreadySeen[alias] = true)
    this.cache['questionOrder'] = head.concat(aliases.filter(alias => !alreadySeen[alias] ? alreadySeen[alias] = true : false)).concat(tail)
    return this.getQuestionOrder()
  }

  getQuestionsWithoutWidgetChildren() {
    if (this.cache['questionsWithoutWidgetChildren']) return this.cache['questionsWithoutWidgetChildren'].slice()
    const widgetFields = this.getWidgetFields(),
      questionOrder = this.getQuestionsWithWidgets()
    this.cache['questionsWithoutWidgetChildren'] = questionOrder.filter(alias => !widgetFields.hasOwnProperty(alias))
    return this.getQuestionsWithoutWidgetChildren()
  }

  getSortedQuestionIntersect(aliases) {
    const widgetFields = this.getWidgetFields()
    const widgetsFound = {}
    const withWidgets = aliases.map(alias => !widgetFields[alias] ? alias : (!widgetsFound[widgetFields[alias]] ? widgetsFound[widgetFields[alias]] = widgetFields[alias] : null)).filter(Boolean)
    return this.getQuestionsWithoutWidgetChildren().filter(alias => withWidgets.includes(alias))
  }

  getQuestionsWithWidgets() {
    if (this.cache['questionsWithWidgets']) return this.cache['questionsWithWidgets'].slice()
    const widgetIndices = [],
      widgetFieldFound = {},
      questionOrder = this.getQuestionOrder(),
      widgets = this.getWidgets(),
      widgetFields = this.getWidgetFields()
    questionOrder.find((alias, index) => {
      if (!widgetFields.hasOwnProperty(alias) || widgetFieldFound.hasOwnProperty(widgetFields[alias])) {
        return false
      }
      widgetIndices.push([index, widgetFields[alias]])
      widgetFieldFound[widgetFields[alias]] = true
      return Object.keys(widgetFieldFound).length == widgets.length
    })
    let widgetField
    while (widgetField = widgetIndices.pop()) {
      questionOrder.splice(widgetField[0], 0, widgetField[1])
    }
    this.cache['questionsWithWidgets'] = questionOrder
    return this.getQuestionsWithWidgets()
  }

  getWidgetFields() {
    if (this.cache['widgetFields']) return { ...this.cache['widgetFields'] }
    this.cache['widgetFields'] = this.getWidgets().map(({fields, alias}) => fields.map(field => typeof field == 'string' ? field : field.alias).reduce((carry, field) => (carry[field] = alias, carry), {}))
      .reduce((carry, widgetFields) => (carry = { ...carry, ...widgetFields }, carry), {})
    return this.getWidgetFields()
  }

  getWidgetFieldsFound() {
    if (this.cache['widgetFieldsFound']) return this.cache['widgetFieldsFound'].slice()
    const widgetAliases = this.getWidgets().map(({alias}) => alias)
    this.cache['widgetFieldsFound'] = this.getQuestionsWithWidgets().filter(alias => widgetAliases.includes(alias))
    return this.getWidgetFieldsFound()
  }

  getHead() {
    return this.getItem(this.head).slice()
  }
  getAliases() {
    return this.getItem(this.aliases).slice()
  }
  getTail() {
    return this.getItem(this.tail).slice()
  }
  getWidgets() {
    return this.getItem(this.widgets).slice()
  }

  getItem(item) {
    if (typeof item === 'string') {
      if (!this.rootGetters) {
        throw new Error('QuestionSorter requires rootGetters on instantiation if leveraging getters')
      }
      return this.rootGetters[item]
    }
    return item
  }
}
