import forOwn from 'lodash/forOwn.js'

/**
 * This adjusts the given array to store the given answer, overwriting
 * the original answer if one already exists in the array
 * @param {{alias: string, value: any}[]} answers
 * @param {{alias: string, value: any}}} param1
 */
 export const changeAnswer = function (answers, {alias, value, idx = null}) {
  idx = idx || answers.findIndex(answer => answer.alias == alias)
  if (typeof idx == 'number' && idx !== -1) {
    answers.splice(idx, 1, {alias, value})
  } else {
    answers.push({alias, value})
  }
}

/**
 * This function is leveraged to reduce an array of answers to a key-value
 * mapping based on the given answer's alias.
 *
 * @param {Object} carry
 * @param {{alias: string, value: any}} answer
 * @returns {{[string]: any}} Mapped object of answers
 */
export const reduceAnswerList = function (carry, {alias, value}) {
  carry[alias] = value
  return carry
}

const isAnswered = function ({value, type}) {
  if (value !== null && typeof value === 'object' && type === 'address') {
    let returnValue = true
    forOwn(value, (val, key) => {
      if (key === 'street' || key === 'zipId' || key === 'city' || key === 'stateId') {
        if (val === undefined || val === null || val === '') {
          returnValue = false
        }
      }
    }).value()
    return returnValue
  }
  return (
    value === 0 || Boolean(value)
  )
}

export const dependencySatisfied = function ({dependsOn, dependsExpression} = {}, lookup) {
  // True if there is no dependency
  if (!dependsOn) {
    return true
  }

  // lookup should be indexed by alias
  if (Array.isArray(lookup)) {
    lookup = lookup.reduce((obj, q) => (obj[q.alias] = q, obj), {})
  }

  const dependsOnObjects = dependsOn.split('|')

  return dependsOnObjects.some(dependsOnObject => {
    const dependsOnAliases = dependsOnObject.split(',')
    if (!dependsOnAliases) {
      return false
    }
    return dependsOnAliases.every((dependsOn, index) => {
      let dependency = lookup[dependsOn]
      // Add case for 'businessType' when 'entity_type' is depended on and not found
      if (!dependency && dependsOn === 'entity_type') {
        dependency = lookup['businessType']
      }
      if (!dependency) {
        return false
      }

      // make sure true/false is 1/0 for dependency expressions
      let dependsValue = dependency.value
      if (dependency.type === 'bool' && typeof dependsValue !== 'undefined') {
        dependsValue = +dependsValue // type cast boolean to to 1/0
      }

      let dependObjectExpression = dependsExpression
      if (dependsOnObjects.length > 1) {
        const dependsExpressions = JSON.parse(dependsExpression)
        dependObjectExpression = dependsExpressions[dependsOnObject][index]
      }
      
      const hasValidAnswer = dependObjectExpression.includes('undefined') || isAnswered(dependency)
      return (
        hasValidAnswer &&
        (new RegExp(dependObjectExpression, 'i')).test(dependsValue) &&
        dependencySatisfied(dependency, lookup)
      )
    })
  })
}

export default {
  changeAnswer,
  reduceAnswerList,
  dependencySatisfied,
}
