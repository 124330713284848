const modalContent = {
  smsOptIn: {
    header: 'Opt in to our text messaging to receive real-time updates on your loan application',
    imageUrl: '/images/storefront.svg',
    imageAlt: 'portal-sms-opt-in',
    content: `By checking this box, I consent to receive marketing calls and/or text messages, including autodialed and pre-recorded/artificial voice calls, from Lendio and its participating partners at the phone number provided above. Message and data rates may apply. I acknowledge that telephone calls to and from Lendio or its partners may be recorded. I also understand that marketing consent is not a condition of purchase or account creation, and I may opt out at any time. For more information, see Section 11 of the Terms of Use.`,
    buttonText: 'I agree',
    closeable: true
  },
  offers: {
    header: 'Application submitted!',
    imageUrl: '/images/celebrate.svg',
    imageAlt: 'offers-welcome-modal',
    linkText: 'Update settings',
    linkRoute: '/portal/settings#notifications',
    buttonText: 'Go to dashboard',
    content: `Our team is working with lenders to find the right financing options for your business! <br/><br/>In the meantime, explore the Lendio dashboard and complete any remaining action items.  We'll send you a text message on the progress of your application.`,
    closeable: false
  },
  dnq: {
    header: 'Next steps',
    imageUrl: '/images/expenses.svg',
    imageAlt: 'portal-dnq-modal',
    buttonText: 'Go to dashboard',
    content: `Unfortunately, your qualifications did not match our lender requirements. We've got some tools that might be able to help you qualify in the future. View these tools in the resources section.`,
    closeable: false
  },
  renewalAppComplete: {
    header: 'Application submitted!',
    buttonText: 'Go to dashboard',
    content: `Our team is working with lenders to find the best fit for you. We will contact you shortly with more information on potential offers.<br/><br/>You can find details about your new application, as well as details about your previous financing on the Loan Offers page in your dashboard. `,
    closeable: false
  }
}

export default modalContent
