import { defineStore } from "pinia";

export const useDarkLaunchStore = defineStore("darkLaunch", () => {
  const darkLaunchTag = "dark-launch-flags";
  const nuxtApp: any = useNuxtApp();
  const { $lendioCookies }: {$lendioCookies: {
    get: (k: string) => any,
    set: (k: string, v: any) => any
  }} = nuxtApp;
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const _darkFlags = ref<Record<string, any>>(getCookieFlags());

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
/**
 * All current dark launch flags set in the store. For any cookie-based flags created without using `setCookieFlag`, you'll need to call `getCookieFlag` to add it to the store
 */
  const darkFlags = computed(() => {
    return _darkFlags.value;
  });

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
function getCookieFlags() {
  return $lendioCookies.get(darkLaunchTag) ?? {}
}

/**
 * Pulls all dark launch flags from cookies and merges them into the store
 * @returns {Record<string, any>} All current dark launch flags
 */
  function refreshCookieFlags(): Record<string, any> {
    const flagCookie = getCookieFlags();
    _darkFlags.value = {...darkFlags.value, ...flagCookie}
    return _darkFlags.value;
  }

  /**
   * Set a dark flag in cookies and state. Usually unnecessary, as they can be set in the app state instead with `setFlag`
   * @param {string} key The name of the dark flag to set in cookies
   * @param {any} value The value of the dark flag to set in cookies
   */
  function setCookieFlag(key: string, value: any) {
    const currentCookieFlags = getCookieFlags();
    currentCookieFlags[key] = value
    $lendioCookies.set(darkLaunchTag, currentCookieFlags);
    this.refreshCookieFlags();
  }
  /**
   * Get the value of a dark flag
   * @param {string} key The name of the flag to grab
   * @returns {any} The value of the flag
   */
  function getFlag(key: string): any {
    return _darkFlags.value[key];
  }
  /**
   * Set the value of a dark flag in state
   * @param key The name of the flag to set
   * @param value The value of the flag
   * @returns The value of the flag
   */
  function setFlag(key: string, value: any): any {
    _darkFlags.value[key] = value;
  }
  /**
   * 
   * @param key The key to delete from the dark launch flags. Deletes from cookies as well
   * @returns 
   */
  function deleteFlag(key: string): any {
    const value = _darkFlags.value[key]
    const cookies = getCookieFlags();
    delete cookies[key]
    $lendioCookies.set(darkLaunchTag, cookies)
    this.refreshCookieFlags();
    delete _darkFlags.value[key]
    return value;
  }
  /**
   * Delete all dark launch flags from store and cookies
   */
  function deleteAllFlags(): void {
    $lendioCookies.set(darkLaunchTag, {})
    _darkFlags.value = {}
  }

  return {
    //STATE
    //GETTERS
    darkFlags,
    //ACTIONS
    refreshCookieFlags,
    setCookieFlag,
    getFlag,
    setFlag,
    deleteFlag,
    deleteAllFlags,
  };
});
