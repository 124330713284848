import { useTenantStore } from '~/store/tenant'
import { useBusinessSwitchStore } from '~/store/business-switch'
import { useBorrowerStore } from '~/store/borrower'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useAppAnalyticsStore } from '~/store/app-analytics'
import { useApprovalsStore } from '~/store/approvals'
import { useBusinessListingsStore } from '~/store/business-listings'
import { useCobrandStore } from '~/store/cobrand'
import { useContactsStore } from '~/store/contacts'
import { useContractsStore } from '~/store/contracts'
import { useCreditCardsStore } from '~/store/credit-cards'
import { useCreditStore } from '~/store/credit'
import { useDarkLaunchStore } from '~/store/dark-launch'
import { useDealsStore } from '~/store/deals'
import { useDebtScheduleStore } from '~/store/debt-schedule'
import { useDocumentsStore } from '~/store/documents'
import { useEmbeddedStore } from '~/store/embedded'
import { useExperienceStore } from '~/store/experience'
import { useExperimentsStore } from '~/store/experiments'
import { useFeatureFlagSettingsStore } from '~/store/feature-flag-settings'
import { useFundingNeedsStore } from '~/store/funding-needs'
import { useLoanProductLenderStore } from '~/store/loan-product-lender'
import { useMarketplaceStore } from '~/store/marketplace'
import { useMatchingStore } from '~/store/matching'
import { useModalStore } from '~/store/modal'
import { useNavListConfigStore } from '~/store/nav-list-config'
import { useOffersStore } from '~/store/offers'
import { useOptInsStore } from '~/store/opt-ins'
import { useOwnershipStore } from '~/store/ownership'
import { usePortalStore } from '~/store/portal'
import { useProfileStore } from '~/store/profile'
import { useProgressCardStore } from '~/store/progress-card'
import { useProgressStore } from '~/store/progress'
import { useQuestionsStore } from '~/store/questions'
import { useRequestsStore } from '~/store/requests'
import { useReturnUserStore } from '~/store/return-user'
import { useRootStore } from '~/store/root'
import { useSignnowStore } from '../store/signnow'
import { useSunriseFinicityStore } from '~/store/sunrise-finicity'
import { useUserStore } from '~/store/user'
import { useApplicationConfigStore } from '~/store/application-config'
import { useOfferOptionsStore } from '~/store/offer-options'
import { useRecommendedOptions } from '~/store/recommended-options'

export default defineNuxtPlugin(({ $pinia }) => {

  return {
    provide: {
      store: {
        tenant: useTenantStore($pinia),
        appAnalytics: useAppAnalyticsStore($pinia),
        approvals: useApprovalsStore($pinia),
        applicationConfig: useApplicationConfigStore($pinia),
        affiliateCustomization: useAffiliateCustomizationStore($pinia),
        borrower: useBorrowerStore($pinia),
        businessListings: useBusinessListingsStore($pinia),
        businessSwitch: useBusinessSwitchStore($pinia),
        cobrand: useCobrandStore($pinia),
        contacts: useContactsStore($pinia),
        contracts: useContractsStore($pinia),
        creditCards: useCreditCardsStore($pinia),
        credit: useCreditStore($pinia),
        darkLaunch: useDarkLaunchStore($pinia),
        deals: useDealsStore($pinia),
        debtSchedule: useDebtScheduleStore($pinia),
        documents: useDocumentsStore($pinia),
        embedded: useEmbeddedStore($pinia),
        experience: useExperienceStore($pinia),
        experiments: useExperimentsStore($pinia),
        featureFlagSettings: useFeatureFlagSettingsStore($pinia),
        fundingNeeds: useFundingNeedsStore($pinia),
        loanProductLender: useLoanProductLenderStore($pinia),
        marketplace: useMarketplaceStore($pinia),
        matching: useMatchingStore($pinia),
        modal: useModalStore($pinia),
        navListConfig: useNavListConfigStore($pinia),
        offers: useOffersStore($pinia),
        offerOptions: useOfferOptionsStore($pinia),
        optIns: useOptInsStore($pinia),
        ownership: useOwnershipStore($pinia),
        portal: usePortalStore($pinia),
        profile: useProfileStore($pinia),
        progressCard: useProgressCardStore($pinia),
        progress: useProgressStore($pinia),
        questions: useQuestionsStore($pinia),
        recommendedOptions: useRecommendedOptions($pinia),
        requests: useRequestsStore($pinia),
        returnUser: useReturnUserStore($pinia),
        root: useRootStore($pinia),
        signNow: useSignnowStore($pinia),
        sunriseFinicity: useSunriseFinicityStore($pinia),
        user: useUserStore($pinia),
      }
    }
  }
})

