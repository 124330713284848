import { defineStore } from 'pinia'
import { ref } from 'vue'

import get from 'lodash/get'
import env from '~/libs/env'
import { useBorrowerStore } from '~/store/borrower'

export const useCreditStore = defineStore('credit', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  const borrowerStore = useBorrowerStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const creditScore = ref(null)
  const connecting = ref(false)
  const loading = ref(false)
  const error = ref(null)
  const failedAttempts = ref(0)

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
  async function getCreditScore(fail = false) {
    loading.value = true
    connecting.value = true
    error.value = null
    const borrowerId = borrowerStore.borrowerId

    if (fail || failedAttempts.value >= 4) {
      connecting.value = false
      loading.value = false
      error.value = 'too many attempts'
      return
    }

    let res = await $axios
      .post(`${env('apiUrl')}/bpapi-credit-report/borrower/${borrowerId}`)
      .catch((err) => {
        connecting.value = false
        loading.value = false
        error.value = err
        failedAttempts.value = failedAttempts.value + 1
        return
      })
    if (!res) {
      return null
    } else {
      connecting.value = false
      loading.value = false
      const status = get(res, 'data.status', 'Error')
      if (status !== 'success') {
        failedAttempts.value = failedAttempts.value + 1
        error.value = get(res, 'data.data', 'error')
        return
      }
      creditScore.value = parseInt(res.data.data)
      return res.data.data
    }
  }

  return {
    // STATE
    creditScore,
    loading,
    connecting,
    error,
    failedAttempts,

    // GETTERS

    // ACTIONS
    getCreditScore,
  }
})
