import env from '~/libs/env'
import TrackingService from '~/libs/tracking-service'
import get from 'lodash/get'
import { useRootStore } from '~/store/root'
import { useBorrowerStore } from '~/store/borrower'
import { useAppAnalyticsStore } from '~/store/app-analytics'

export const testSegmentId = 'ab1cd23-4567-89e0-1f23-ab4cd56ef7a8'

function getSpecialContext () {
  let getViewportSize = () => {
    let viewPortWidth, viewPortHeight

    if (typeof window.innerWidth !== 'undefined') {
      viewPortWidth = window.innerWidth
      viewPortHeight = window.innerHeight
    } else {
      // For IE11 and older browsers
      viewPortWidth = document.getElementsByTagName('body')[0].clientWidth
      viewPortHeight = document.getElementsByTagName('body')[0].clientHeight
    }
    return {
      width: viewPortWidth,
      height: viewPortHeight
    }
  }

  let context = {
    app: {
      name: 'lendio'
    },
    library: 'analytics',
    version: window.analytics.VERSION || '3.2.5', // Add reasonable fallback
    screen: getViewportSize(),
    userAgent: window.navigator.userAgent
  }

  if (window.navigator.userLanguage) {
    context.locale = window.navigator.userLanguage
  } else if (window.navigator.language) {
    context.locale = window.navigator.language
  }
  return context
}

export function getSegmentAnonymousId () {
  return new Promise((resolve) => {
    window.analytics.ready(() => {
      const segmentAnonymousId = window.analytics.user().anonymousId() || ''

      resolve(segmentAnonymousId)
    })
  })
}

export function getTimingData () {
  // Fail safely
  if (!window.LendioPerformanceLogging) {
    return {}
  }

  window.LendioPerformanceLogging.totalTime = window.LendioPerformanceLogging.calculateLoadTime()

  let logtotals = true
  let logroute = true

  if (!window.LendioPerformanceLogging.baseTime.logged) {
    window.LendioPerformanceLogging.baseTime.logged = true
  } else {
    logtotals = false
  }

  if (!window.LendioPerformanceLogging.baseRouteChangeStart.logged) {
    window.LendioPerformanceLogging.baseRouteChangeStart.logged = true
  } else {
    logroute = false
  }

  let totalLoadTime = logtotals ? window.LendioPerformanceLogging.totalTime : null
  let preBootstrapTime = logtotals ? window.LendioPerformanceLogging.preBootstrapTime : null
  let docReadyTime = logtotals ? window.LendioPerformanceLogging.docReadyTime : null
  let beginLoadingTime = logtotals ? window.LendioPerformanceLogging.beginLoadingTime : null
  let routeChangeTime = logroute ? window.LendioPerformanceLogging.routeChangeTime : null

  return {
    totalLoadTime,
    preBootstrapTime,
    docReadyTime,
    beginLoadingTime,
    routeChangeTime
  }
}

export function trackLogin($lendioCookies, pinia) {
  const rootStore = useRootStore(pinia)
  const borrowerStore = useBorrowerStore(pinia)
  const appAnalyticsStore = useAppAnalyticsStore(pinia)

  const authUser = rootStore.authUser
  // do some marketing stuffs
  if (get(authUser, 'lastTrack') !== false) {
    $lendioCookies.set('skipMarketingRecord', true, { expires: 7 })
  }
  let borrowerId = borrowerStore.borrowerId
  // Now do some additional tracking
  TrackingService.onLogin(borrowerId, $lendioCookies)
  window.analytics.track('login', {
    label: 'login-page'
  }, getSpecialContext())
  appAnalyticsStore.adobeTrackEvent({ mbox: 'lendio_login', params: { "orderId": borrowerId } })
  appAnalyticsStore.fsTrackEvent({ name: 'lendio_login', properties: { borrowerId } })
  appAnalyticsStore.dreamsSetEvent({ name: 'lendio_login', value: true })
}

export default {
  identify: function (userId, payload = {}) {
    if (window.FS_launch && window.FS_launch.identify) {
      window.FS_launch.identify(userId, payload)
    }
  },
  track: function (eventName, payload) {
    if (process.client && window) {
      window.analytics.track(eventName, payload, this.getSpecialContext())
    }
  },
  trackRoute: async function (borrowerId, anonymousId = null, url = '', $axios) {
    let payload = {}
    try {
      let [route, query] = url.replace(/\#.*$/, '').split('?')
      let timingData = getTimingData()
      let data = {
        borrowerId,
        route,
        query
      }

      const isDebug = (window.LendioPerformanceLogging && window.LendioPerformanceLogging.debug) || env('environment') === 'dev' || env('environment') === 'test'
      data.segmentAnonymousId = isDebug ? testSegmentId : anonymousId
      if (!data.segmentAnonymousId) {
        // log.error(new Error('Failed to retrieve anonymous ID for route tracking'))
        log.warning('Failed to retrieve anonymous ID for route tracking')
      }
      payload = Object.assign(payload, timingData, data)
      return $axios.post(
        `${env('apiUrl')}/borrower-portal-page-views`,
        JSON.stringify(payload),
        { headers: { 'Content-Type': 'application/json'} }
      ).catch((error) => {
        if (error.response) {
          log.warning('Failed to post trackRoute: ', { error, payload })
          return
        }
        log.warning('Axios Error - BPPV:', { error, payload })
        return
      })
    } catch (err) {
      log.warning('Failed to post trackRoute: ', { error, payload })
    }
  },

  getSpecialContext: getSpecialContext,
  getSegmentAnonymousId: getSegmentAnonymousId,

  trackLogin: trackLogin,
}
