import { parseISO } from 'date-fns'
import env from '~/libs/env'

function oldAppComplete (date) {
  const parsedDate = parseISO(date)
  // 30 days
  let EXPIRATION_TIME = 30 * 24 * 60 * 60 * 1000
  return (new Date() - parsedDate) > EXPIRATION_TIME
}

export default {

  getNextRoute (borrower = {}) {
    if (env('useDocsOnlyFlow') === 'true') {
      return '/documents'
    }

    if (borrower.applicationComplete && !oldAppComplete(borrower.applicationComplete)) {
      return '/portal'
    }

    return `/resume-app`
  }
}
