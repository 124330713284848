import { differenceInMonths } from 'date-fns';
import get from "lodash/get";
import assign from "lodash/assign"

export const getSignupBorrowerFormData = async ({ answers, borrowerData, cobrandAffiliateId, $lendioCookies }) => {
  const [borrowerAnswers, borrowerCookies] = await Promise.all([
    getBorrowerAnswers({ answers }),
    getBorrowerCookies({ cobrandAffiliateId, $lendioCookies }),
  ])

  const combinedBorrowerData = assign(borrowerData, borrowerAnswers, borrowerCookies)
  let formData = new FormData()
  for (let key in combinedBorrowerData) {
    formData.append(key, combinedBorrowerData[key])
  }
  return formData
}

let calculateTimeInBusiness = (businessStartDate) => {
  const timeInBusiness = differenceInMonths(new Date(), new Date(get(businessStartDate, 'value')))

  if (isNaN(timeInBusiness)) {
    return 0
  }
  return timeInBusiness
}

export const getBorrowerAnswers = async ({ answers, isMarketplaceExperience = true }) => {
  const averageMonthlySales = answers.average_monthly_sales
  const businessStartDate = answers.business_start_date
  const creditScore = answers.creditScore
  const industry = answers.lendioIndustry

  /**
  * Mineral group calculation requires that a time in business is set and an average monthly sales is too.
  * Borrower creation used to not work when this value wasn't set, now the mineral group is just set to null.
  * This should be followed up on to see if we can remove this ugly bandaid.
  * Defaulting to 0 if not set.
  **/
  let timeInBusiness = calculateTimeInBusiness(businessStartDate)
  let averageSalesValue = get(averageMonthlySales, 'value', 0)

  let collectedAnswers = {
    average_monthly_sales: averageSalesValue,
    time_in_business: timeInBusiness,
    credit_score: get(creditScore, 'value'),
    industry: get(industry, 'value')
  }

  // Remove any values that are undefined before posting
  return Object.keys(collectedAnswers)
    .filter(key => collectedAnswers[key] !== undefined)
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: collectedAnswers[key]
      }
    }, {})
}

export const getBorrowerCookies = async ({ cobrandAffiliateId, $lendioCookies }) => {
  const cookies = $lendioCookies.getTrackingCookies()
  // Add affiliate from query param if present and missing from cookies
  if (cobrandAffiliateId && missingAffiliate(cookies)) {
    cookies['affiliate'] = cobrandAffiliateId
  }
  return cookies
}

const missingAffiliate = (cookies) => {
  return cookies &&
    (!cookies.affiliate || /^(null|undefined|0|NaN|false)$/i.exec(cookies.affiliate)) &&
    (!cookies.affiliateId || /^(null|undefined|0|NaN|false)$/i.exec(cookies.affiliateId))
}

export default { getSignupBorrowerFormData, getBorrowerAnswers, getBorrowerCookies }
