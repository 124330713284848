import { useAffiliateCustomizationStore } from '~/store/affiliate-customization';
import env from '~/libs/env'
import { localStorageService } from "~/libs/local-storage.service"
import { useRootStore } from '~/store/root'
import { useUserStore } from '~/store/user'
import { useIdentityStore } from '~/store/identity';

const MASS_ANSWER_REQUEST_SIZE = 40;


/**
 * Requires a store passed so it has access to the correct `$axios` in context
 * for any XHR requests. Helps manage the interface of saving answers between
 * local storage and the actual service.
 */
export class AnswerStorageService {
    constructor(pinia, $axios) {
      this.$axios = $axios
      this.$pinia = pinia
    }

    /**
     * Returns an array of answers from either the API or local storage, depending
     * on whether or not the user is authenticated.
     * @param {boolean} auth is user authenticated
     * @param {string[]} aliases field aliases
     * @returns {{alias: string, value: any}[]}
     */
    async getAnswers(auth, borrowerId, { aliases = [], allFields = false }) {
        if (auth && allFields) {
            const { data } = await this.$axios.get(`${env('apiUrl')}/answers/borrower/${borrowerId}`)
                .then(response => response.data)
                .catch(err => (console.error('Unable to get index of answers', err), { data: [] }))
            return data
        } else if (auth) {
            let chunks = Math.ceil(aliases.length / MASS_ANSWER_REQUEST_SIZE);
            const requests = [...Array(chunks)]
                .map((val, i) => {
                    return aliases.slice(i * MASS_ANSWER_REQUEST_SIZE, i * MASS_ANSWER_REQUEST_SIZE + MASS_ANSWER_REQUEST_SIZE - 1)
                })
                .map(chunkAliases => {
                    return this.$axios.get(`${env('apiUrl')}/answers/borrower/${borrowerId}/by-alias`, { params: { fields: chunkAliases } })
                        .then(response => response.data)
                        .catch(err => (console.error('Unable to load answers', chunkAliases, err), { data: [] }))
                })
            const { data } = await Promise.all(requests).then(responses => ({ data: responses.flatMap(r => r.data) }))
            return data
        }
        if (!process.client) {
            return []
        }
        let answers = localStorageService.getItem('answers')
        if (answers) {
            answers = JSON.parse(answers)
        }
        if (!answers || !Array.isArray(answers)) {
            return []
        }
        return answers
        // return aliases.length ? answers.filter(({alias}) => aliases.includes(alias)) : answers
    }

    async redirectUserLogin() {
      await useIdentityStore(this.$pinia).logout()
    }


    /**
     * Saves answers given either to the API or local storage, depending on whether
     * or not the user is authenticated.
     * @param {boolean} auth is user authenticated
     * @param {{[string]: any}} answers
     * @returns {boolean} successful
     */
    async saveAnswers(auth, answers, borrowerId) {
        if (auth) {
            const { data } = await this.$axios.post(`${env('apiUrl')}/answers/borrower/${borrowerId}`, answers)
                .then(response => response.data)
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        this.redirectUserLogin()
                    } else {
                        console.error('Unable to save answers', Object.keys(answers), err)
                    }
                    return { data: false }
                })

        }
        if (!process.client) {
            console.error('Tried to save answers during server-side render', new Error())
            return false
        }
        const storedAnswers = await this.getAnswers(auth, borrowerId, [])
        const aliasIndex = storedAnswers.reduce((carry, { alias }, idx) => (carry[alias] = idx, carry), {})
        for (let alias in answers) {
            const answer = { alias, value: answers[alias] }
            if (aliasIndex.hasOwnProperty(alias)) {
                storedAnswers.splice(aliasIndex[alias], 1, answer)
            } else {
                storedAnswers.push(answer)
            }
        }
        localStorageService.setItem('answers', JSON.stringify(storedAnswers))
        return true
    }

}

export default {
    AnswerStorageService
}
