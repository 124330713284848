export const dynamicAppPages = [
  'basic-info',
  'owner-info',
  'business-info',
  'documents',
  'submit'
]

export default function inDynamicApp (routeName) {
  let inDynamicApp = false
  dynamicAppPages.forEach((page) => {
    let pageRegex = new RegExp(`^${page}`)
    if (pageRegex.test(routeName)) {
      inDynamicApp = true
      return false
    }
  })
  return inDynamicApp
}
