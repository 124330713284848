// Wrapper for window.localStorage. Falls back on inMemoryStorage if
// browser doesn't support localStorage.
import StorageService from './storage.service'

class LocalStorageService extends StorageService {
  // Specify which type of browser storage we want.
  constructor() {
    super('localStorage')
  }
}

export let localStorageService = new LocalStorageService()
