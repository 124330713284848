export default {
  sba: {
    progressCalculationGetter: ['sbaProgress'],
    ctaPath: "/sba/tax-returns",
    notStarted: {
      badgeLabel: "Incomplete",
      isComplete: false,
      title: "It looks like you could qualify for an SBA loan.",
      button: "Start Application",
      body: "SBA loans are government-backed and often have better rates and longer payback terms. Answer a few more questions to see if you qualify for an SBA 7(a) loan."
    },
    started: {
      badgeLabel: "Incomplete",
      isComplete: false,
      title: "Your SBA application isn’t quite complete.",
      button: "Continue Application",
      body: "You have a few more steps to complete your SBA loan application. Jump back in where you left off and submit your application today.",
    },
    complete: {
      badgeLabel: "Complete",
      isComplete: true,
      title: "Enhanced Financing Application",
      button: "Edit Application",
      body: "Your enhanced financing application is now being reviewed. Reach out to your go-to expert if you need assistance.",
    }
  },
  marketplace: {
    progressCalculationGetter: ['marketplaceProgress'],
    ctaPath: "/resume-app",
    notStarted: {
      badgeLabel: "Incomplete",
      title: "Business Financing Application",
      button: "Start Application",
      body: "Start your basic financing application to receive funding for your small business.",
    },
    started: {
      badgeLabel: "Incomplete",
      title: "Business Financing Application",
      button: "Finish Application",
      body: "We need more information from you. Complete the rest of your application so we can find the best financing options for you.",
    },
    complete: {
      title: "Business Financing Application",
      button: "Edit Application",
      body: "You've finished your financing application! We are reviewing your file and will reach out soon.",
    }
  },
  renewal: {
    progressCalculationGetter: ['renewalProgress'],
    ctaPath: "/resume-app",
    notStarted: {
      badgeLabel: "Incomplete",
      title: "Business Financing Application",
      button: "Start Application",
      body: "Start your basic financing application to receive funding for your small business.",
    },
    started: {
      badgeLabel: "Incomplete",
      title: "Business Financing Application",
      button: "Continue Application",
      body: "We're missing information. Please complete the rest of your application so we can find the best financing options for you.",
    },
    complete: {
      title: "Business Financing Application",
      button: "Edit Application",
      body: "You've finished your financing application! We are reviewing your file and will reach out soon.",
    }
  },
}
