'use strict';

export function checkConfigBoolean(configValue, rootGetters = null, ranOnce = false) {
  const inverterRegExp = /^(\!?)(.+)$/
  if (Array.isArray(configValue) && !rootGetters) {
    // Support multiple store checks per "enabledP" via nested arrays
    if (Array.isArray(configValue[0]) && !ranOnce) {
      return configValue.every((val) => checkConfigBoolean.bind(this)(val, null, true))
    }

    let [storeId, accessorStr, ...params] = configValue
    const [_, invert, accessor] = inverterRegExp.exec(accessorStr)
    let func = this[storeId]
    if (typeof(func) !== 'function') {
      throw new Error(`${storeId} not defined in nav-list-config store.`)
    }
    let val = func(accessor)
    if (val instanceof Function) {
      val = val(...params)
    }
    if (val == undefined) {
      // console.warn(`NavListConfig: ${storeId}[${accessor}] returned undefined. Check that it exists or that it returns 'false' instead.`)
    }
    return invert ? !val : val
  }

  if (typeof configValue == 'boolean') {
    return configValue
  } else if (typeof configValue == 'string') {
    const [_, invert, getter] = inverterRegExp.exec(configValue) || []
    return getter && (invert ? !rootGetters[getter] : rootGetters[getter])
  } else if (configValue instanceof Array) {
    const [_, invert, getter] = inverterRegExp.exec(configValue[0]) || [],
      getterFunction = getter && rootGetters[getter]
    const val = getterFunction && getterFunction(...configValue.slice(1))
    return typeof getterFunction != 'undefined' && invert ? !val : val
  }
  return undefined
}

export default {
  checkConfigBoolean
}
