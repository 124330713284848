import { datadogRum } from '@datadog/browser-rum';
import get from 'lodash/get';
import set from 'lodash/set.js';

export default defineNuxtPlugin(() => {
  const env = useRuntimeConfig().public;
  if (/^(live|prod|production)$/i.exec(env.environment)) {
    datadogRum.init({
      beforeSend: (event) => {
        // remove email from view url
        const scrubUrls = ['view.url', 'resource.url', 'error.resource.url'];
        scrubUrls.forEach(path => {
          const url = get(event,path);
          if (url) {
            set(event, path, url.replace(/at=[^&]*/, "at=REDACTED"));
          }
        });
      },
      applicationId: '735e4b2a-db96-4679-8344-5a0c515c5f06',
      clientToken: 'pubf0032007534d5ef42c600fa00729741c',
      site: 'datadoghq.com',
      service: 'BorrowerPortal',
      env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog 
      // version: '1.0.0', 
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
  });
  }
})

