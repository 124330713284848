<template>
  <client-only>
    <slot></slot>
  </client-only>
</template>
<script>
export default {
  props: ['identifier'],
  data() {
    return {
      toast: null
    }
  },
  async mounted() {
    if (process.client) {
      const { default: Toast } = await import('bootstrap/js/dist/toast')
      let toastElement = document.getElementById(this.identifier)
      this.toast = new Toast(toastElement)
    }
  },
  methods: {
    async showToast() {
      if (!this.toast) {
        return
      }
      this.toast.show()
    }
  }
}
</script>

<style lang="scss">
@import "lendio-toasts";
</style>
