// This is the common parent class for local-storage.service and
// session-storage.service.
//
// THIS SHOULD NOT BE USED DIRECTLY.
//
// Wrapper for window.*Storage. Falls back on this.inMemoryStorage if
// browser doesn't support local or session storage.

const storagePolyfill = function (target = {}) {
  return new Proxy(target, {
    get: (target, symbol) => {
      switch (symbol) {
        case 'clear':
          return () => Object.keys(target).forEach(key => delete target[key]);
        case 'getItem':
          return (key) => target.hasOwnProperty(key) ? target[key] : null;
        case 'removeItem':
          return (key) => delete target[key];
        case 'setItem':
          return (key, val) => (target[key] = String(val), undefined);
        case 'key':
          return (i) => Object.keys(target)[i];
        case 'length':
          return Object.keys(target).length;
        default:
          return target.hasOwnProperty(symbol) ? target[symbol] : null;
      }
    },
    set: (target, string, value) => {
      target[string] = String(value);
    }
  });
};

export default class StorageService {
  // Default to the most common storage type.
  constructor (storageType = 'localStorage') {
    this.storageType = storageType;
    this.testMode = !process.client && !process.server;
  }

  // Are localStorage and sessionStorage supported by this client?
  isSupported() {
    if (typeof window !== 'undefined' && window[this.storageType]) {
      this.storage = window[this.storageType];
      return true;
    }
    // assume test mode if process.server is not set
    else if (this.testMode || (!process.server && typeof window !== 'undefined' && !window[this.storageType])) {
      this.storage = this.storage || storagePolyfill();
      return true;
    }
    return false;
  }

  clear() {
    if(!this.isSupported()) return;
    this.storage.clear();
  }

  getAllStorage() {
    if(!this.isSupported()) return;

    var values = [],
      keys = Object.keys(this.storage),
      i = keys.length;

    while (i--) {
      values.push(this.storage.getItem(keys[i]));
    }

    return values;
  }

  getItem(name) {
    if(!this.isSupported()) return;
    return this.storage.getItem(name);
  }

  removeItem(name) {
    if(!this.isSupported()) return;
    this.storage.removeItem(name);
  }

  removeItems(arrayOfNames) {
    if(!this.isSupported()) return;
    arrayOfNames.forEach(name => this.storage.removeItem(name));
  }

  setItem(name, value) {
    if(!this.isSupported()) return;
    this.storage.setItem(name, value);
  }

  key(index) {
    if(!this.isSupported()) return;
    return this.storage.key(index);
  }

  get length() {
    if(!this.isSupported()) return;
    return this.storage.length;
  }
}
