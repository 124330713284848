import Log from '~/libs/log/Log'
import buildRollbarConfig from '~/libs/log/rollbar.config.js'
import Rollbar from 'rollbar'

export default defineNuxtPlugin(async (nuxtApp) => {
  const $config = useRuntimeConfig().public
  const verbose = false

  let log

  try {
    const rollbarConfig = buildRollbarConfig($config)

    let rollbar = null
    if ($config.environment !== 'test') {
      rollbar = new Rollbar(rollbarConfig)
    }
    log = new Log(rollbar, verbose)
  } catch (e) {
    console.error('Log Instantiation Error', e)
  }

  nuxtApp.vueApp.config.errorHandler  = (err) => {
    if (verbose) {
      console.log('errorHandler', err)
    }
    log.error(err)
  }

  nuxtApp.vueApp.config.warnHandler  = (warning) => {
    if (verbose) {
      console.log('warnHandler', warning)
    }
    log.warning(warning)
  }

  nuxtApp.hook('app:error', (error) => {
    if (verbose) {
      console.log('app:error', error)
    }
    if (error.statusCode === 404) {
      console.error(error)
      return
    }
    log.error(error)
  })

  if (process.client) {
    window.log = log
    if ($config.debugEnabled === 'true') {
      console.log('assigned log to window', window.log)
    }
  } else {
    global.log = log
    if ($config.debugEnabled === 'true') {
      console.log('assigned log to global', global.log)
    }
  }

})
