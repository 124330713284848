import { defineStore } from 'pinia'

import env from '~/libs/env'
import get from 'lodash/get'

import { useBorrowerStore } from '~/store/borrower'
import { useRootStore } from '~/store/root'

const EXPERIMENTS = {
  DEFAULT: { ID: 12, NAME: 'DEFAULT' },
  AUTOMATION: { ID: 14, NAME: 'AUTOMATION' },
  CREDIT_CARDS: { ID: 13, NAME: 'CREDIT_CARDS' },
  NO_MATCHES: { ID: 15, NAME: 'NO_MATCHES' },
  CUPCAKE: { ID: 17, NAME: 'CUPCAKE' },
  RENEWAL: { ID: 'RENEWAL', NAME: 'RENEWAL' },
}

// The following experiments are applied if the borrower has no matches to marketplace products
const DNQ_EXPERIMENTS = [
  EXPERIMENTS.NO_MATCHES.ID,
  EXPERIMENTS.CREDIT_CARDS.ID,
]

/**
 * Returns the name in the Experiment mapping
 * @param {int} id experiment Id
 * @returns {string} name of experiment
 */
function experimentIdToName(id) {
  let name = null
  if (id) {
    Object.keys(EXPERIMENTS).some((key) => {
      if (EXPERIMENTS[key].ID == id) {
        name = EXPERIMENTS[key].NAME
        return true
      }
    })
  }
  return name
}

export const useExperienceStore = defineStore('experience', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  const rootStore = useRootStore()
  const borrowerStore = useBorrowerStore()

  let activeExperienceRequest = null

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const experimentId = ref(null)
  const loading = ref(null)

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  const currentExperimentName = computed(() => {
    if (!experimentId.value) return
    return experimentIdToName(experimentId.value)
  })

  const isJumpball = computed(() => {
    return currentExperimentName.value === EXPERIMENTS.DEFAULT.NAME
  })

  const isAutomation = computed(() => {
    return currentExperimentName.value === EXPERIMENTS.AUTOMATION.NAME
  })

  /**
   * Returns whether the present experimentId associated with the borrower app life cycle has them marked as DNQ
   * (meaning the app should be abridged or limited in some fashion).
   * @returns {Boolean}
   */
  const isDNQ = computed(() => {
    return DNQ_EXPERIMENTS.indexOf(+experimentId.value) > -1
  })

  const shouldExitEarly = computed(() => {
    return isDNQ.value
  })

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  async function getExperience({ forcedReload = false, silent = false, visitorId = null } = {}) {
    if (this.isSilverOrHigher()) {
      experimentId.value = 12
      return false
    }

    if (activeExperienceRequest || (!rootStore.authToken && !visitorId)) {
      return activeExperienceRequest
    }
    const borrowerId = get(borrowerStore, 'borrowerId')
    if (!borrowerId) {
      console.error(new Error('Borrower ID not found for experience check'))
      return null
    }
    if (currentExperimentName.value && !forcedReload) {
      return currentExperimentName.value
    }
    if (!silent) {
      loading.value = true
    }

    let route = `${env('apiUrl')}/borrower/${borrowerId}/experience`
    if ( rootStore.pwfSignupInProgress && visitorId ) {
      route = `${env('apiUrl')}/visitor/${visitorId}/borrower/${borrowerId}/experience`
    }

    activeExperienceRequest = $axios
        .post(route, null, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(({ data }) => {
          if (data && data.status === 'Success' && data.data) {
            experimentId.value = data.data.experimentId
          } else {
            experimentId.value = null
          }
          return currentExperimentName.value
        })
        .catch((err) => {
          return null
        })
        .finally(() => {
          loading.value = false
          activeExperienceRequest = null
        })

    return activeExperienceRequest
  }

  function forceGetExperience({ silent = false, visitorId = null } = {}) {
    if (!silent) {
      experimentId.value = null
    }

    return this.getExperience({ forcedReload: true, silent, visitorId })
  }

  function isSilverOrHigher() {
    const borrowerValues = borrowerStore?.borrowerValues

    if (!borrowerValues) {
      return false
    }

    const creditScore = borrowerValues.creditScore?.value
    const avgMonthlySales = borrowerValues.average_monthly_sales?.value
    const timeInBusiness = borrowerStore.getMonthsInBusiness

    return creditScore >= 500 && avgMonthlySales >= 8000 && timeInBusiness >= 6
  }

  return {
    //STATE
    experimentId,
    loading,

    //GETTERS
    currentExperimentName,
    isJumpball,
    isAutomation,
    isDNQ,
    shouldExitEarly,

    //ACTIONS
    forceGetExperience,
    getExperience,
    isSilverOrHigher,
  }
})
