import PusherService from '../libs/pusher-service'
import debounce from 'lodash/debounce'
import { useRootStore } from '~/store/root'
import { useDealsStore } from '~/store/deals'
import { useBorrowerStore } from '~/store/borrower'
import { useDocumentsStore } from '~/store/documents'
import { useContractsStore } from '~/store/contracts'
import { useApprovalsStore } from '~/store/approvals'
import { useBorrowerUserActionStore } from '~/store/borrower-user-actions'
import { useExperimentsStore } from '~/store/experiments'
import { useOfferOptionsStore } from '~/store/offer-options'

export default defineNuxtPlugin(() => {
  // Skip server side
  if (import.meta.server) return

  const nuxtApp = useNuxtApp()
  const { $pinia, $lendioCookies } = nuxtApp

  // Init Store Actions and Values
  const { getDealsWithOffers} = useDealsStore($pinia)
  const { injectDocument } = useDocumentsStore($pinia)
  const { borrowerId, getBorrower} = useBorrowerStore($pinia)
  const { getContracts, addContract } = useContractsStore($pinia)
  const { requestApprovals, getContractRequestsByDealId, getContractRequestsByBorrowerId } = useApprovalsStore($pinia)
  const { getActionItems } = useBorrowerUserActionStore($pinia)
  const { activeUserExperimentByName } = useExperimentsStore($pinia)
  const { getOfferOptions } = useOfferOptionsStore($pinia)
  const { getDocuments } = useDocumentsStore($pinia)

  if (!window.lendioPusher && useRootStore($pinia).isLoggedIn) {
    const fetchDeals = debounce(() => getDealsWithOffers(), 600)
    const fetchBorrower = debounce(async () => await getBorrower({ forceReload: true }), 600)
    const fetchApprovals = debounce(() => requestApprovals(true), 600)
    const fetchOfferOptions = debounce(() => getOfferOptions(true), 600)
    const fetchContracts = debounce(() => getContracts(), 600)
    const fetchContractRequests = debounce((dealId) => getContractRequestsByDealId(dealId), 600)
    const fetchContractRequestsByBorrower = debounce(() => getContractRequestsByBorrowerId(borrowerId), 600)
    const fetchUserActions = debounce(() => getActionItems(true), 600)
    const fetchNeededDocuments = debounce(() =>  getDocuments(true), 600)

    const documentHandler = () => {
      if (data.object && data.object.borrowerId === borrowerId && !data.object.deleted) {
        injectDocument(data.object)
      }
    }

    const handleContractEvent = (type, data) => {
      switch (type) {
        case 'create':
          addContract(data.contract)
          break;
        case 'delete':
          fetchApprovals()
          fetchContractRequests(data.contract.dealId)
          break;
      }
      fetchContracts()
    }

    const fetchContractRequestsForSelfServe = (deal) => {
      if (!deal || !activeUserExperimentByName('BP Loan Options Self Serve')) {
        return;
      }

      if (['offerAccepted', 'contractRequested', 'contractOut', 'contractIn', 'funding'].includes(deal?.status)) {
        fetchContractRequestsByBorrower()
      }
    }

    PusherService.connect($pinia, $lendioCookies)

    const handleModelChange = async (type, data) => {
      switch (data.resourceName) {
        case 'deal':
          fetchDeals()
          fetchApprovals()
          fetchOfferOptions()
          fetchContractRequestsForSelfServe(data.object)
          break;
        case 'borrower':
          fetchBorrower()
          break;
        case 'document':
          documentHandler()
          break;
        case 'approval':
          fetchApprovals()
          break;
        case 'contract':
          handleContractEvent(type, data)
          break;
        case 'userActions':
          fetchNeededDocuments()
          fetchUserActions()
          documentHandler()
          break;
        case 'offerOption':
          fetchOfferOptions()
          break;
      }
    }

    const BorrowerChannel = PusherService.getBorrowerChannel()

    if (BorrowerChannel) {
      // Borrower model update subscriptions
      BorrowerChannel.bind_global(handleModelChange)
    }
    window.lendioPusher = PusherService
  }
})
