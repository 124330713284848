import { defineStore } from 'pinia'

import get from 'lodash/get'

import { useBorrowerStore } from '~/store/borrower'
import { useDealsStore } from '~/store/deals'
import { useProgressStore } from '~/store/progress'
import { useExperimentsStore } from '~/store/experiments'
import { useRootStore } from '~/store/root'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'

import PROGRESS_CARDS_CONFIG from '~/libs/progress-cards-config'
import { compatibleDate } from '~/libs/date-helpers'
import { getAcceptedOffer } from '~/libs/offer-helper'


export const useProgressCardStore = defineStore('progressCard', () => {
  const borrowerStore = useBorrowerStore()
  const dealsStore = useDealsStore()
  const progressStore = useProgressStore()
  const experimentsStore = useExperimentsStore()
  const rootStore = useRootStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
const isSbaQualified = computed(() => {
  const sbaQualified = borrowerStore.borrowerValues.creditScore.value >= 650 && borrowerStore.borrowerValues.average_monthly_sales.value >= 20000 && borrowerStore.borrowerValues.timeInBusiness.value >= 24
  return sbaQualified
  },
)

const hasVisitedSBA = computed(() => {
  return progressStore.visitedRoutes.includes("/sba/tax-returns")
})
/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

    // Progress getters, pulled from the PROGRESS_CARDS_CONFIG and used to determine application progress for
    // the app cards used in /bp/portal and the mobile progress bar in the near future.
    const sbaProgress = computed(() => {
      const enabledRoutesLength = get(progressStore.orderedRouteLists, 'sba.length')
      const completedRoutesLength = get(progressStore.completedRouteLists, 'sba.length')

      if (enabledRoutesLength && completedRoutesLength) {
        return Math.floor((completedRoutesLength/enabledRoutesLength)*100)
      }
      return 0
    })

    const marketplaceProgress = computed(() => {
      const enabledRoutesLength = get(progressStore.orderedRouteLists, 'marketplace.length')
      const visitedRoutesLength = get(progressStore.visitedRouteLists, 'marketplace.length')

      if (enabledRoutesLength && visitedRoutesLength) {
        const marketplaceAppCompleted = get(borrowerStore, 'borrowerValues.marketplaceAppCompleted.value')
        const hasFundedTierOneDeals = get(dealsStore, 'hasFundedTierOneDeals')
        const hasActiveTierOneDeals = get(dealsStore, 'hasActiveTierOneDeals')
        if (marketplaceAppCompleted && (hasFundedTierOneDeals || hasActiveTierOneDeals)) {
          return 100
        }

        return Math.floor((visitedRoutesLength/enabledRoutesLength)*100)
      }
      return 0
    })

    const renewalProgress = computed(() => {
      const isRenewalAppComplete = progressStore.isRenewalAppComplete

      if (isRenewalAppComplete) {
        return 100
      }

      const enabledRoutesLength = get(progressStore.orderedRouteLists, 'renewal.length')
      const visitedRoutesLength = get(progressStore.visitedRouteLists, 'renewal.length')

      if (enabledRoutesLength && visitedRoutesLength) {
        const progress = Math.floor((visitedRoutesLength/enabledRoutesLength)*100)

        // reduce progress because renewal app still incomplete
        return progress < 100 ? progress : 90
      }
      return 0
    })

    // Returns the card details for the application based on the borrowers progress.
    const getProgressCardDetails = computed(() => {
      const progressGetters = {
        marketplaceProgress,
        sbaProgress,
        renewalProgress,
      }
      return ({ appName = 'marketplace' }) => {
        const appProgressAccessor = get(PROGRESS_CARDS_CONFIG, `[${appName}].progressCalculationGetter`)
        const [getter] = appProgressAccessor
        let appProgress
        if (typeof getter === 'string') {
          const _progress = get(progressGetters, `[${getter}].value`, null)
          if (_progress !== null) {
            appProgress = _progress
          }
        }

        let cardDetails = {}
        if (appProgress === 0) {
          cardDetails = get(PROGRESS_CARDS_CONFIG, `[${appName}].notStarted`)
        } else if (appProgress > 0 && appProgress < 100) {
          cardDetails = get(PROGRESS_CARDS_CONFIG, `[${appName}].started`)
        } else if (appProgress === 100) {
          cardDetails = get(PROGRESS_CARDS_CONFIG, `[${appName}].complete`)
        }

        if (appName === 'sba' && Array.isArray(cardDetails.body)) {
          let completed = progressStore.applications.find(app => app.type === 'sba').completed
          if (completed) {
            appProgress = 100
            cardDetails = get(PROGRESS_CARDS_CONFIG, `[${appName}].complete`)
          } else {
            cardDetails['body'] = cardDetails.body
          }
        }

        if (appName === 'renewal' && Array.isArray(cardDetails.body)) {
          let completed = progressStore.applications.find(app => app.type === 'renewal').completed
          if (completed) {
            appProgress = 100
            cardDetails = get(PROGRESS_CARDS_CONFIG, `[${appName}].complete`)
          } else {
            cardDetails['body'] = cardDetails.body
          }
        }

        cardDetails = {...cardDetails}

        return {
          ...cardDetails,
          progress: appProgress,
          ctaPath: get(PROGRESS_CARDS_CONFIG, `[${appName}].ctaPath`)
        }
      }
    })

    const sbaTypeApp = computed(() => {
      if (Array.isArray(progressStore.applications)) {
        return progressStore.applications.filter(app => !app.deleted).some(app => app.type === 'sba')
      }
      return false
    })

    const fundingSteps = computed(() => {
      const activeDeal = dealsStore.activeDeal
      const prevFundedDeals = dealsStore.prevFundedLoans

      const appProgress = prevFundedDeals?.length ? renewalProgress.value : marketplaceProgress.value
      const isAppComplete = appProgress === 100 || !affiliateCustomizationStore.isLendioTenant

      if (!activeDeal && !isAppComplete) {
        return 'APP_INCOMPLETE'
      }

      const hasAcceptedOffer = Boolean(getAcceptedOffer(activeDeal))

      if (hasAcceptedOffer) {
        if (activeDeal?.stage === 'funded') {
          return 'FUNDED'
        }
        if (['contractIn', 'funding'].includes(activeDeal?.status)) {
          return 'LOAN_PROCESSING'
        }
        if (['contractOut'].includes(activeDeal?.status)) {
          return 'FINALIZE_LOAN'
        }
        return 'CLOSING_REQUIREMENTS'
      }

      if (dealsStore.availableOptions?.length) {
        return 'OFFERS_PRESENTED'
      }

      const isSelfServe = Boolean(experimentsStore.activeUserExperimentByName('BP Loan Options Self Serve'))
      const isStandardBorrower = !isSelfServe && !rootStore.isEmbedded && !affiliateCustomizationStore.isIntelligentLendingAffiliate
      if (isStandardBorrower && ['offerReceived', 'offerToBorrower', 'offerDeclined'].includes(activeDeal?.status)) {
        return 'OFFERS_PRESENTED'
      }

      return 'UNDER_REVIEW'
    })

    const fundingTrackerSteps = computed(() => {
      const currentStep = fundingSteps.value
      const isRenewal = Boolean(dealsStore.prevFundedLoans?.length)
      return [
        {
          label: isRenewal && currentStep === 'APP_INCOMPLETE' ? 'Update Application' : 'Application',
          incompleteLabel: 'Application Incomplete',
          active: true,
        },
        {
          label: 'Under Review',
          active: currentStep !== 'APP_INCOMPLETE',
        },
        {
          label: 'Options Presented',
          active:
            currentStep === 'OFFERS_PRESENTED' ||
            currentStep === 'CLOSING_REQUIREMENTS' ||
            currentStep === 'FINALIZE_LOAN' ||
            currentStep === 'LOAN_PROCESSING' ||
            currentStep === 'FUNDED',
        },
        {
          label: 'Closing',
          active:
            currentStep === 'CLOSING_REQUIREMENTS' ||
            currentStep === 'FINALIZE_LOAN' ||
            currentStep === 'LOAN_PROCESSING' ||
            currentStep === 'FUNDED',
        },
        {
          label: 'Funded',
          active: currentStep === 'FUNDED',
        }
      ]
    })

    const showRenewalCard = computed(() => {
      let compareDate = new Date()
      const sevenDaysAgo = compareDate.getDate() - 7
      compareDate.setDate(sevenDaysAgo)

      const deals = get(dealsStore, 'deals', null)
      if (Array.isArray(deals) && deals.length > 0) {
        const fundedDeal = deals.find(deal => deal.stage === 'funded')
        const acceptedOfferDate = get(fundedDeal, 'acceptedOffer.accepted')
        if (acceptedOfferDate) {
          const fundedDealDate = new Date(compatibleDate(acceptedOfferDate))
          return fundedDealDate < compareDate
        }
      }

      return false
    })


    return {
      // STATE

      // GETTERS
      sbaProgress,
      marketplaceProgress,
      renewalProgress,
      getProgressCardDetails,
      sbaTypeApp,
      fundingSteps,
      showRenewalCard,
      isSbaQualified,
      hasVisitedSBA,
      fundingTrackerSteps,

      // ACTIONS
    }
})
