<template>
  <section>
    <BackNavAndMobileHeader
      header-title="Verify Information"
    />

    <div class="pt-3 alert-container bg-light">
      <div class="container">
        <div class="row mx-0"><Alert class="col-12 mx-0 px-0 mb-3" @created="canShowAlert = true" ref="alert"></Alert></div>
      </div>
    </div>
    <div v-if="loadingQuestions || componentQuestions.length || !initialized">
      <LoadingSpinner header="Getting questions"/>
    </div>

    <section class="pt-xl-5" v-else>
      <h3 class="mb-2 mt-xl-5 pt-md-2">Has anything changed since your last visit?</h3>
      <p class="pb-4 type-500">We have pulled information from your previous application.</p>

      <h5 class="text-truncate mb-5">Owner information</h5>
      <form name="renewal-verify" class="marketplace-content">
        <div class="form-section d-flex flex-wrap row">
          <div v-for="(question, index) in ownerQuestions" :key="question.alias"
            ref="form"
            class="form-question col-12"
            :class="{'col-md-6 justify-content-between' : question.id === 'user.first' || question.id === 'user.last'}"
          >
            <borrower-attribute
              :question="question"
              :update-index="index"
              :invalidity="invalidity"
              @invalidity="updateValidity(question.alias, $event)"
              @answer="updateQuestion">
            </borrower-attribute>
          </div>
        </div>
      </form>

      <QuestionNav
        :submitButton="{
          btnText: 'Continue',
          disabled: isInvalid,
        }"
        :submitting="submitting"
        @submit="saveQuestions"
        v-show="!loadingQuestions"
      />

    </section>
  </section>
</template>

<script>
import { mapState } from 'pinia'
import BorrowerAttribute from '~/components/borrowerAttributes/BorrowerAttribute.vue'
import Alert from '~/components/Alert.vue'
import LoadingSpinner from "~/components/marketplace/subComponents/LoadingSpinner"
import { renewalOwnerQuestions } from '~/libs/page-helper'

import QuestionNav from "~/components/marketplace/subComponents/QuestionNav"
import BackNavAndMobileHeader from '~/components/marketplace/subComponents/BackNavAndMobileHeader.vue'
import { useProgressStore } from '~/store/progress'
import { useRootStore } from '~/store/root'
import { useQuestionsStore } from '~/store/questions'
import { useBorrowerStore } from '~/store/borrower'
import { useExperienceStore } from '~/store/experience'

export default {
  name: 'Renewal',
  components: {
    Alert,
    LoadingSpinner,
    BorrowerAttribute,
    QuestionNav,
    BackNavAndMobileHeader
  },
  async mounted () {
    const borrowerStore = useBorrowerStore()
    await borrowerStore.getBorrower({ forceReload: true })

    await Promise.all([
      useRootStore().isLoggedIn ? useQuestionsStore().initializeRenewalInfoPage() : null,
      useQuestionsStore().getQuestions({ aliases: [], allFields: true }),
      useExperienceStore().forceGetExperience(),
    ])
    this.initialized = true

    try {
      this.$chatService.show();
    } catch (e) {
      console.warn(e); // throw console warning on exception
    }
  },
  data () {
    return {
      submitting: false,
      canShowAlert: undefined,
      dismissAlert: false,
      invalidity: {},
      initialized: false
    }
  },
  async setup () {
    definePageMeta({
      layout: "renewal",
      pageTransition: {
        name: 'mp-transition'
      },
      middleware: ['transitions-helper']
    });
  },
  computed: {
    ...mapState(useQuestionsStore, [
      'reviewQuestions',
      'componentQuestions',
      'loadingQuestions'
    ]),
    ownerQuestions () {
      const componentQuestions = this.componentQuestions
      return renewalOwnerQuestions.map((alias) => componentQuestions[alias])
    },
    isInvalid() {
      const invalid = Object.values(this.invalidity).every(v => v === false)
      return !process.client || !invalid
    },
  },
  methods: {
    updateValidity (key, isInvalid) {
      this.invalidity = {
        ...this.invalidity,
        [key]: isInvalid
      }
    },

    async saveQuestions () {
      // Need this to keep submit button disabled until next question appears.
      const progressStore = useProgressStore(this.$pinia)
      this.submitting = true
      // Ensure the app mode is updated to show you're in renewal mode
      await progressStore.updateApplication({ type: 'renewal' })
      const success = await useQuestionsStore(this.$pinia).saveAnswers()
      if (!success) {
        this.submitting = false
        return
      }
      await progressStore.incrementRoute({
        currentPath: useRoute().path,
        appName: 'renewal',
        forward: true
      })
    },
    updateQuestion({ attr, isInvalid }) {
      if (!attr || attr.alias === undefined || isInvalid) {
        return false
      }
      useQuestionsStore(this.$pinia).updateAnswer(attr)
    },
  }
}
</script>

<style scoped lang="scss">
  .alert-container {
    display:none;
  }
  .form-section {
    max-width: 365px;
  }
</style>
