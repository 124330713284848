import VisitorTracker from './visitor-tracker'

export default {
  /**
   * Functions that should always happen at login
   * -insert marketing record
   * -clear cookies to make room for new marketing record
   */
  onLogin (borrowerId, $lendioCookies) {
    if (borrowerId) {
      VisitorTracker.deleteCookiesForTrackedParams($lendioCookies)
    }
  },
}
