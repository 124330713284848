<template>
  <span class="spinner-border spinner-border-sm position-absolute start-0 ms-4 mt-1"></span>
</template>

<script>
export default {
  name: "ButtonLoadingSpinner",
}
</script>

<style lang="scss" scoped>
</style>
