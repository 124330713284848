import { defineStore } from "pinia";
import env from "~/libs/env";
import get from "lodash/get";

import { useBorrowerStore } from "~/store/borrower";
import {
  getDebtScheduleQuestionTemplate,
  parseDebtScheduleDBItems,
  constructDebtScheduleItem,
} from "~/libs/debt-schedule-helper";

export const useDebtScheduleStore = defineStore("debtSchedule", () => {
  const nuxtApp = useNuxtApp();
  const { $axios } = nuxtApp;

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

  const debtScheduleItems = ref([
    { questions: getDebtScheduleQuestionTemplate() },
  ]);
  const deleting = ref(false);

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  function addDebtScheduleItem() {
    debtScheduleItems.value.push({
      questions: getDebtScheduleQuestionTemplate(),
    });
  }

  async function deleteDebtScheduleItem(index) {
    deleting.value = true;
    const dsi = debtScheduleItems.value[index];
    if (dsi.id) {
      await this.deleteDebtItemById(dsi.id);
    }
    debtScheduleItems.value.splice(index, 1);
    if (!debtScheduleItems.value.length) {
      this.addDebtScheduleItem();
    }
    deleting.value = false;
  }

  async function deleteDebtItemById(id) {
    try {
      await $axios.delete(`${env("apiUrl")}/debt-schedule-items/${id}`);
    } catch (error) {
      console.error("Error deleting debt item:", error);
    }
  }
  async function getDebtScheduleItemsByBorrowerId(force = false) {
    const borrowerStore = useBorrowerStore();
    const fnName = "getDebtScheduleItemsByBorrowerId";
    if (!force && debtScheduleItems.value.length > 1) return;
    const borrowerId = borrowerStore.borrowerId;
    try {
      const response = await $axios.get(
        `${env("apiUrl")}/borrower/${borrowerId}/debt-schedule-items`
      );
      this.handleDebtScheduleItemsResponse(response, fnName);
    } catch (error) {
      console.error(`An error occurred with ${fnName}:`, error);
    }
  }

  async function postAllDebtScheduleItems() {
    const borrowerStore = useBorrowerStore();
    const fnName = "postAllDebtScheduleItems";
    const borrowerId = borrowerStore.borrowerId;
    if (!borrowerId) return;
    const dbArray = debtScheduleItems.value.map((debtScheduleItem) => {
      return constructDebtScheduleItem(debtScheduleItem, borrowerId);
    });
    try {
      const response = await $axios.post(
        `${env("apiUrl")}/borrower/${borrowerId}/debt-schedule-items`,
        dbArray
      );
      this.handleDebtScheduleItemsResponse(response, fnName);
      return true;
    } catch (error) {
      if (error.response && error.response.status !== 422) {
        console.error(`An error occurred with ${fnName}:`, error);
      }
      return false;
    }
  }

  function handleDebtScheduleItemsResponse(response, fnName) {
    if (response.status === 200) {
      const dsi = get(response, "data.data");
      if (dsi.length > 0) {
        const templateFormatted = parseDebtScheduleDBItems(dsi);
        debtScheduleItems.value = templateFormatted;
      }
    } else {
      console.error(`${fnName} request failed: `, response.statusText);
    }
  }

  async function generateDebtScheduleDocument() {
    const borrowerStore = useBorrowerStore();
    const borrowerId = borrowerStore.borrowerId;
    if (!borrowerId) return;
    try {
      const response = await $axios.get(
        `${env("apiUrl")}/borrower/${borrowerId}/debt-schedule`
      );
    } catch (error) {
      console.error(
        "An error occurred with generateDebtScheduleDocument:",
        error
      );
    }
  }

  return {
    //state
    debtScheduleItems,
    deleting,
    //actions
    addDebtScheduleItem,
    deleteDebtScheduleItem,
    deleteDebtItemById,
    getDebtScheduleItemsByBorrowerId,
    postAllDebtScheduleItems,
    handleDebtScheduleItemsResponse,
    generateDebtScheduleDocument,
  };
});
