import env from '~/libs/env';

export default class IntercomChatService {
    constructor() {
        loadScript();
    }

    /**
   * Shows chat component.
   */
  async show() {
    await loadScript();
    // window.Intercom('show');
  }

  async open() {
    await loadScript();
    window.Intercom('show');
  }

  /**
   * Hides chat component.
   */
  async hide() {
    await loadScript();
    window.Intercom('hide');
  }
}

let _loadScriptPromise = null;

function loadScript() {
    if (_loadScriptPromise) {
        return _loadScriptPromise
    }

    const cb = (res, rej) => {
        try {
            addScript()
            res()
        } catch (e) {
            console.warn(e);
            rej(e)
        }
    }
    _loadScriptPromise = new Promise(cb);

    return _loadScriptPromise
}

function addScript() {
    if (document.getElementById('intercom-snippet')) {
        return;
    }
    
    const intercomAppId = env('intercomAppId')
    const script = document.createElement('script');
    script.id = 'intercom-snippet';
    script.type = 'text/javascript';
    script.innerHTML = "(function(){var w=window;var ic=w.Intercom;if(typeof ic==='function'){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/" + intercomAppId + "';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();"
    document.head.appendChild(script);
}
