import { format } from 'date-fns'

// Set our min date to start of today.
let minDate = new Date()
minDate.setHours(0, 0, 0, 0)
minDate = format(minDate, 'yyyy-MM-dd')

export default {
  'construction': {
    fields: [
      {
        'name': 'Need',
        'type': 'select',
        'example': 'payroll',
        'options': [
          { label: 'Bridge Receivables', value: 'bridge receivables' },
          { label: 'Equipment Rental', value: 'equipment rental' },
          { label: 'Hire New Employees', value: 'hire new employees' },
          { label: 'Materials', value: 'materials' },
          { label: 'Mortgage/Rent', value: 'mortgage or rent' },
          { label: 'New Tools', value: 'new tools' },
          { label: 'Payroll', value: 'payroll' },
          { label: 'Purchase Equipment', value: 'purchase equipment' },
          { label: 'Rainy Day Fund', value: 'rainy day fund' },
          { label: 'Other', value: 'other' },
        ],
        'alias': 'funding-needs-options',
        'id': 'funding-needs-options',
        'value': '',
      },
      {
        'name': 'Amount',
        'type': 'currency',
        'example': '5000',
        'alias': 'funding-needs-amount',
        'id': 'funding-needs-amount',
        'value': '',
      },
      {
        'name': 'Date Needed By',
        'type': 'date',
        'example': format(new Date(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'alias': 'funding-needs-date-needed-by',
        'id': 'funding-needs-date-needed-by',
        'value': '',
      },
      {
        'name': 'ROI Amount',
        'type': 'currency',
        'example': '5000',
        'tooltip': 'ROI (Return on Investment) is a measure of how much profit you expect to make on the investment from this loan. Simply put if you were to receive this loan to fund these needs, how much additional profit would you experience as a result?',
        'alias': 'funding-needs-roi-amount',
        'id': 'funding-needs-roi-amount',
        'helpText': 'Optional',
        'mustAllowNull': true,
        'value': '',
      },
      {
        'name': 'ROI Timeframe',
        'type': 'date',
        'example': format(new Date(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'tooltip': 'Return on Investment (ROI) timeframe is the date in which you expect to realize the ROI amount from this investment. It could be the timeframe to complete a specific project or opportunity that was started because of these new funds, and additional profit was generated.',
        'alias': 'funding-needs-roi-timeframe',
        'id': 'funding-needs-roi-timeframe',
        'helpText': 'Optional',
        'mustAllowNull': true,
        'value': '',
      },
    ]
  },
  'healthcare': {
    fields: [
      {
        'name': 'Need',
        'type': 'select',
        'example': 'payroll',
        'options': [
          { label: 'Acquire New Practice', value: 'acquire new practice' },
          { label: 'Bridge Payments', value: 'bridge payments' },
          { label: 'Hire New Employees', value: 'hire new employees' },
          { label: 'Mortgage/Rent', value: 'mortgage or rent' },
          { label: 'Payroll', value: 'payroll' },
          { label: 'Purchase Equipment', value: 'purchase equipment' },
          { label: 'Purchase Real Estate', value: 'purchase real estate' },
          { label: 'Rainy Day Fund', value: 'rainy day fund' },
          { label: 'Remodel', value: 'remodel' },
          { label: 'Taxes/Fees', value: 'taxes or fees' },
          { label: 'Other', value: 'other' },
        ],
        'alias': 'funding-needs-options',
        'id': 'funding-needs-options',
        'value': '',
      },
      {
        'name': 'Amount',
        'type': 'currency',
        'example': '5000',
        'alias': 'funding-needs-amount',
        'id': 'funding-needs-amount',
        'value': '',
      },
      {
        'name': 'Date Needed By',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'alias': 'funding-needs-date-needed-by',
        'id': 'funding-needs-date-needed-by',
        'value': ''
      },
      {
        'name': 'ROI Amount',
        'type': 'currency',
        'example': '5000',
        'tooltip': 'ROI (Return on Investment) is a measure of how much profit you expect to make on the investment from this loan. Simply put if you were to receive this loan to fund these needs, how much additional profit would you experience as a result?',
        'alias': 'funding-needs-roi-amount',
        'id': 'funding-needs-roi-amount',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
      {
        'name': 'ROI Timeframe',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'tooltip': 'Return on Investment (ROI) timeframe is the date in which you expect to realize the ROI amount from this investment. It could be the timeframe to complete a specific project or opportunity that was started because of these new funds, and additional profit was generated.',
        'alias': 'funding-needs-roi-timeframe',
        'id': 'funding-needs-roi-timeframe',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
    ]
  },
  'restaurants': {
    fields: [
      {
        'name': 'Need',
        'type': 'select',
        'example': 'payroll',
        'options': [
          { label: 'Acquire a Business', value: 'acquire a business' },
          { label: 'Busy Season', value: 'busy season' },
          { label: 'Food & Supply Costs', value: 'food and supply costs' },
          { label: 'Hire New Employees', value: 'hire new employees' },
          { label: 'Liquor License', value: 'liquor license' },
          { label: 'Mortgage/Rent', value: 'mortgage or rent' },
          { label: 'Payroll', value: 'payroll' },
          { label: 'Purchase Equipment', value: 'purchase equipment' },
          { label: 'Remodel', value: 'remodel' },
          { label: 'Slow Season', value: 'slow season' },
          { label: 'Taxes/Fees', value: 'taxes or fees' },
          { label: 'Other', value: 'other' },
        ],
        'alias': 'funding-needs-options',
        'id': 'funding-needs-options',
        'value': '',
      },
      {
        'name': 'Amount',
        'type': 'currency',
        'example': '5000',
        'alias': 'funding-needs-amount',
        'id': 'funding-needs-amount',
        'value': '',
      },
      {
        'name': 'Date Needed By',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'alias': 'funding-needs-date-needed-by',
        'id': 'funding-needs-date-needed-by',
        'value': '',
      },
      {
        'name': 'ROI Amount',
        'type': 'currency',
        'example': '5000',
        'tooltip': 'ROI (Return on Investment) is a measure of how much profit you expect to make on the investment from this loan. Simply put if you were to receive this loan to fund these needs, how much additional profit would you experience as a result?',
        'alias': 'funding-needs-roi-amount',
        'id': 'funding-needs-roi-amount',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
      {
        'name': 'ROI Timeframe',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'tooltip': 'Return on Investment (ROI) timeframe is the date in which you expect to realize the ROI amount from this investment. It could be the timeframe to complete a specific project or opportunity that was started because of these new funds, and additional profit was generated.',
        'alias': 'funding-needs-roi-timeframe',
        'id': 'funding-needs-roi-timeframe',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
    ]
  },
  'ecommerce': {
    fields: [
      {
        'name': 'Need',
        'type': 'select',
        'example': 'payroll',
        'options': [
          { label: 'Bridge Receivables', value: 'bridge receivables' },
          { label: 'General Inventory', value: 'general inventory' },
          { label: 'Hire New Employees', value: 'hire new employees' },
          { label: 'Mortgage/Rent', value: 'mortgage or rent' },
          { label: 'New Website', value: 'new website' },
          { label: 'Payroll', value: 'payroll' },
          { label: 'Purchase Equipment', value: 'purchase equipment' },
          { label: 'Seasonality', value: 'seasonality' },
          { label: 'Warehousing Costs', value: 'warehousing costs' },
          { label: 'Warehousing Expansion', value: 'warehousing expansion' },
          { label: 'Other', value: 'other' },
        ],
        'alias': 'funding-needs-options',
        'id': 'funding-needs-options',
        'value': '',
      },
      {
        'name': 'Amount',
        'type': 'currency',
        'example': '5000',
        'alias': 'funding-needs-amount',
        'id': 'funding-needs-amount',
        'value': '',
      },
      {
        'name': 'Date Needed By',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'alias': 'funding-needs-date-needed-by',
        'id': 'funding-needs-date-needed-by',
        'value': '',
      },
      {
        'name': 'ROI Amount',
        'type': 'currency',
        'example': '5000',
        'tooltip': 'ROI (Return on Investment) is a measure of how much profit you expect to make on the investment from this loan. Simply put if you were to receive this loan to fund these needs, how much additional profit would you experience as a result?',
        'alias': 'funding-needs-roi-amount',
        'id': 'funding-needs-roi-amount',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
      {
        'name': 'ROI Timeframe',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'tooltip': 'Return on Investment (ROI) timeframe is the date in which you expect to realize the ROI amount from this investment. It could be the timeframe to complete a specific project or opportunity that was started because of these new funds, and additional profit was generated.',
        'alias': 'funding-needs-roi-timeframe',
        'id': 'funding-needs-roi-timeframe',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
    ]
  },
  'retail': {
    fields: [
      {
        'name': 'Need',
        'type': 'select',
        'example': 'payroll',
        'options': [
          { label: 'Bulk Discounts', value: 'bulk discounts' },
          { label: 'Bridge Receivables', value: 'bridge receivables' },
          { label: 'General Inventory', value: 'general inventory' },
          { label: 'Hire New Employees', value: 'hire new employees' },
          { label: 'Mortgage/Rent', value: 'mortgage or rent' },
          { label: 'New Website', value: 'new website' },
          { label: 'Payroll', value: 'payroll' },
          { label: 'Purchase Equipment', value: 'purchase equipment' },
          { label: 'Seasonality', value: 'seasonality' },
          { label: 'Warehousing Costs', value: 'warehousing costs' },
          { label: 'Warehousing Expansion', value: 'warehousing expansion' },
          { label: 'Other', value: 'other' },
        ],
        'alias': 'funding-needs-options',
        'id': 'funding-needs-options',
        'value': '',
      },
      {
        'name': 'Amount',
        'type': 'currency',
        'example': '5000',
        'alias': 'funding-needs-amount',
        'id': 'funding-needs-amount',
        'value': '',
      },
      {
        'name': 'Date Needed By',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'alias': 'funding-needs-date-needed-by',
        'id': 'funding-needs-date-needed-by',
        'value': '',
      },
      {
        'name': 'ROI Amount',
        'type': 'currency',
        'example': '5000',
        'tooltip': 'ROI (Return on Investment) is a measure of how much profit you expect to make on the investment from this loan. Simply put if you were to receive this loan to fund these needs, how much additional profit would you experience as a result?',
        'alias': 'funding-needs-roi-amount',
        'id': 'funding-needs-roi-amount',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
      {
        'name': 'ROI Timeframe',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'tooltip': 'Return on Investment (ROI) timeframe is the date in which you expect to realize the ROI amount from this investment. It could be the timeframe to complete a specific project or opportunity that was started because of these new funds, and additional profit was generated.',
        'alias': 'funding-needs-roi-timeframe',
        'id': 'funding-needs-roi-timeframe',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
    ]
  },
  'freightTrucking': {
    fields: [
      {
        'name': 'Need',
        'type': 'select',
        'example': 'payroll',
        'options': [
          { label: 'Bridge Receivables', value: 'bridge receivables' },
          { label: 'Hire New Employees', value: 'hire new employees' },
          { label: 'Payroll', value: 'payroll' },
          { label: 'Purchase New Trailer', value: 'purchase new trailer' },
          { label: 'Purchase New Truck', value: 'purchase new truck' },
          { label: 'Rainy Day Fund', value: 'rainy day fund' },
          { label: 'Repairs', value: 'repairs' },
          { label: 'Taxes/Fees', value: 'taxes or fees' },
          { label: 'Territory, Route, or Contract Expansion', value: 'territory, route, or contract expansion' },
          { label: 'Other', value: 'other' },
        ],
        'alias': 'funding-needs-options',
        'id': 'funding-needs-options',
        'value': '',
      },
      {
        'name': 'Amount',
        'type': 'currency',
        'example': '5000',
        'alias': 'funding-needs-amount',
        'id': 'funding-needs-amount',
        'value': '',
      },
      {
        'name': 'Date Needed By',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'alias': 'funding-needs-date-needed-by',
        'id': 'funding-needs-date-needed-by',
        'value': '',
      },
      {
        'name': 'ROI Amount',
        'type': 'currency',
        'example': '5000',
        'tooltip': 'ROI (Return on Investment) is a measure of how much profit you expect to make on the investment from this loan. Simply put if you were to receive this loan to fund these needs, how much additional profit would you experience as a result?',
        'alias': 'funding-needs-roi-amount',
        'id': 'funding-needs-roi-amount',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
      {
        'name': 'ROI Timeframe',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'tooltip': 'Return on Investment (ROI) timeframe is the date in which you expect to realize the ROI amount from this investment. It could be the timeframe to complete a specific project or opportunity that was started because of these new funds, and additional profit was generated.',
        'alias': 'funding-needs-roi-timeframe',
        'id': 'funding-needs-roi-timeframe',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
    ]
  },
  'realEstate': {
    fields: [
      {
        'name': 'Need',
        'type': 'select',
        'example': 'payroll',
        'options': [
          { label: 'Acquire New Property', value: 'acquire new property' },
          { label: 'Bridge Receivables', value: 'bridge receivables' },
          { label: 'Hire New Employees', value: 'hire new employees' },
          { label: 'Liquor License', value: 'liquor license' },
          { label: 'Materials', value: 'materials' },
          { label: 'Mortgage/Rent', value: 'mortgage or rent' },
          { label: 'New Tools', value: 'new tools' },
          { label: 'Payroll', value: 'payroll' },
          { label: 'Purchase Equipment', value: 'purchase equipment' },
          { label: 'Rainy Day Fund', value: 'rainy day fund' },
          { label: 'Rent Equipment', value: 'rent equipment' },
          { label: 'Other', value: 'other' },
        ],
        'alias': 'funding-needs-options',
        'id': 'funding-needs-options',
        'value': '',
      },
      {
        'name': 'Amount',
        'type': 'currency',
        'example': '5000',
        'alias': 'funding-needs-amount',
        'id': 'funding-needs-amount',
        'value': '',
      },
      {
        'name': 'Date Needed By',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'alias': 'funding-needs-date-needed-by',
        'id': 'funding-needs-date-needed-by',
        'value': '',
      },
      {
        'name': 'ROI Amount',
        'type': 'currency',
        'example': '5000',
        'tooltip': 'ROI (Return on Investment) is a measure of how much profit you expect to make on the investment from this loan. Simply put if you were to receive this loan to fund these needs, how much additional profit would you experience as a result?',
        'alias': 'funding-needs-roi-amount',
        'id': 'funding-needs-roi-amount',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
      {
        'name': 'ROI Timeframe',
        'type': 'date',
        'example': format(Date.now(), 'yyyy-MM-dd'),
        // Needs to be >= today to pass validation.
        'minDate': minDate,
        'tooltip': 'Return on Investment (ROI) timeframe is the date in which you expect to realize the ROI amount from this investment. It could be the timeframe to complete a specific project or opportunity that was started because of these new funds, and additional profit was generated.',
        'alias': 'funding-needs-roi-timeframe',
        'id': 'funding-needs-roi-timeframe',
        // 'helpText': 'Optional',
        // 'mustAllowNull': true,
        'value': '',
      },
    ]
  }
}
