export default [
  {
    regExp: /^\/signup.*/,
    redirect: '/basic-info'
  },
  {
    regExp: /^\/bluevine.*/,
    redirect: {
      path: '/basic-info',
      query: {
        affiliate: 839739886
      }
    }
  },
  {
    regExp: /^\/credibility-capital.*/,
    redirect: {
      path: '/basic-info',
      query: {
        affiliate: 3610136120,
        medium: 'Partner',
        source: 'credibilitycapital'
      }
    }
  },
  {
    regExp: /^\/fundbox.*/,
    redirect: {
      path: '/basic-info',
      query: {
        affiliate: 3929291253
      }
    }
  },
  {
    regExp: /^\/lendingclub.*/,
    redirect: {
      path: '/basic-info',
      query: {
        affiliate: 2243017355,
        medium: 'Partner',
        source: 'LendingClub'
      }
    }
  },
  {
    regExp: /^\/lendvantage.*/,
    redirect: {
      path: '/basic-info',
      query: {
        affiliate: 984412706
      }
    }
  },
  {
    regExp: /^\/staples-business-loans.*/,
    redirect: {
      path: '/basic-info',
      query: {
        affiliate: 36622824
      }
    }
  },
  {
    regExp: /^\/upsstore.*/,
    redirect: {
      path: '/basic-info',
      query: {
        affiliate: 777991122
      }
    }
  }
]
