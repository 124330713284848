import questionMap from './question-mapping.json'
import clone from 'lodash/clone.js'
import cloneDeep from 'lodash/cloneDeep.js'
import groupBy from 'lodash/groupBy.js'
import has from 'lodash/has.js'
import indexOf from 'lodash/indexOf.js'
import map from 'lodash/map.js'
import maxBy from 'lodash/maxBy.js'
import questionHelper from './questions-helper'

export function buildPages(attributes) {
  let groupedAttributes = groupBy(attributes, (attribute) => {
    return questionHelper.getPageId(attribute.alias)
  })

  let pages = map(groupedAttributes, (attributesArray, id) => {
    let maxedProducts = maxBy(attributesArray, 'affectedProducts')
    let affectedProducts = maxedProducts ? maxedProducts.affectedProducts : 0 // The about_your_business is not a normal attribute and doesn't contain affectedProducts
    let allAnswered = attributesArray.filter((attribute) => {
      return questionHelper.isAnswered(attribute)
    })

    return {
      id,
      attributes: attributesArray,
      directiveMapping: getDirectiveMapping(attributesArray[0]),
      mostAffectedProducts: affectedProducts,
      dependsOn: attributesArray[0].dependsOn
        ? questionHelper.getPageId(attributesArray[0].dependsOn)
        : undefined,
      answered:
        id === 'about_your_business'
          ? allAnswered.length > 0
          : allAnswered.length === attributesArray.length
    }
  })

  return pages
}

export function buildHierarchy(pages) {
  let indexedParentPages = {}

  pages.forEach((page) => {
    if (indexedParentPages[page.dependsOn] === undefined) {
      indexedParentPages[page.dependsOn] = []
    }
    indexedParentPages[page.dependsOn].push(page)
  })

  return indexedParentPages
}

export function aboutYourBusinessBools() {
  return [
    'hasInvoices',
    'nonprofit',
    'bankruptcy',
    'franchise',
  ]
}

export function getNewAlias(searchArr, newAlias, attribute) {
  const inArr = searchArr.indexOf(attribute.alias) > -1
  return inArr ? newAlias : attribute.alias
}

export function getDirectiveMapping(attribute) {
  let directiveMapping

  attribute = clone(attribute)
  const newAlias = getNewAlias(
    aboutYourBusinessBools(),
    'about_your_business',
    attribute
  )
  attribute.alias = newAlias

  if (
    typeof attribute === 'undefined' ||
    (!attribute.alias && !attribute.type)
  ) {
    return
  }

  /* Look for match of attribute's alias and return that */
  if (has(questionMap, attribute.alias)) {
    directiveMapping = cloneDeep(questionMap[attribute.alias])
  } else if (has(questionMap, attribute.type)) {
    /* If there's no match for the attribute's alias then return based on the type */
    directiveMapping = cloneDeep(questionMap[attribute.type])
  } else {
    console.error(
      'No Directive Mapping Found For: ',
      attribute.alias + ' ' + attribute.type
    )
    directiveMapping = {}
  }

  // If the directive mapping doesn't return a label set it for the attribute name
  if (!directiveMapping.label || directiveMapping.label === '') {
    directiveMapping.label = attribute.name
  }

  return directiveMapping
}

export function sortPages(pages, history = []) {
  let indexedParentPages = buildHierarchy(pages)
  let sortedPages = []
  _hierarchySort(indexedParentPages, undefined, sortedPages, history)
  return sortedPages
}

function _relevanceSort(pages, history = []) {
  let sortedPages = clone(pages)
  sortedPages.sort((a, b) => {
    // sort by special order
    if (a.specialOrder || b.specialOrder) {
      return (a.specialOrder || 0) - (b.specialOrder || 0)
    }

    // sort by history
    let historyIndexA = indexOf(history, a.id) + 1
    let historyIndexB = indexOf(history, b.id) + 1

    if (historyIndexA || historyIndexB) {
      if (historyIndexA < 1) {
        return 1
      }
      if (historyIndexB < 1) {
        return -1
      }
      return historyIndexA - historyIndexB
    }

    if (a.requiredOrder || b.requiredOrder) {
      return (a.requiredOrder || 0) - (b.requiredOrder || 0)
    }

    // sort by affected products
    return b.mostAffectedProducts - a.mostAffectedProducts
  })
  return sortedPages
}

function _hierarchySort(indexedParentPages, key, result, history = []) {
  if (indexedParentPages[key] === undefined) {
    return
  }

  var hierarchyLevel = _relevanceSort(indexedParentPages[key], history)
  hierarchyLevel.forEach((page) => {
    result.push(page)
    _hierarchySort(indexedParentPages, page.id, result, history)
  })
}

export default {
  buildPages,
  buildHierarchy,
  aboutYourBusinessBools,
  getNewAlias,
  getDirectiveMapping,
  sortPages,
}
