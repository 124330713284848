import get from 'lodash/get'
import env from '~/libs/env'
import { useRootStore } from '~/store/root'
import { useUserStore } from '~/store/user'

function _isValidDomain(string) {
  // ^(https?:\/\/)?: Allows the string to optionally start with http:// or https://.
  // ([a-zA-Z0-9-_]+\.)*: Matches subdomains (e.g., www. or subdomain.), which can occur zero or more times.
  // [a-zA-Z0-9][a-zA-Z0-9-_]+: Matches the main domain part, ensuring it starts with an alphanumeric character and can contain alphanumeric characters and hyphens.
  // \.[a-zA-Z]{2,11}?$: Matches the top-level domain (TLD), which must start with a dot and be between 2 and 11 alphabetic characters long.
  const validDomainStructure = /^(https?:\/\/)?([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
  return validDomainStructure.test(string)
}

async function _handle401 ({ config, error, cookies, rootStore, route }) {
  // Mobile only
  if (process.client && cookies.get('isMobile') && window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler('tokenHandler')
    return Promise.reject(error)
  }

  const isEmbeddedCookie = process.client && cookies.get('isEmbedded')
  const isEmbedded = get(rootStore, 'isEmbedded')
  const isEmbeddedUser = get(rootStore, 'authUser.isEmbedded')
  const isEmbeddedRoute = get(window, 'location.pathname', '').includes('embedded');

  // Embedded only
  if (isEmbedded || isEmbeddedUser || isEmbeddedCookie || isEmbeddedRoute) {
    throw createError({
      statusCode: 401,
      statusMessage: 'Unauthorized Action',
      data: {
        isEmbedded: true,
        location: 'Axios'
      },
      fatal: true
    })
  }

  // Ignore requests that were sent without an auth header
  if (!(get(config, 'headers.Authorization'))) {
    return
  }

  const errorMessage = get(error, 'response.data.errors[0].message')

  // Handle DNC-caused 401s separately
  if (errorMessage !== null && errorMessage == 'User is marked as do not contact') {
    return rootStore.logout({ $router: window.vueRouter, path: 'bp/under-review', afterLoginRedirect: true })

  // Log users out and redirect to login page except for specific excepted routes
  } else {
    return rootStore.logout({ path: route.path })
  }
}

export default defineNuxtPlugin (({ $axios, $pinia, $lendioCookies }) => {
  $pinia.use(() => ({ $axios }))

  const userStore = useUserStore($pinia)
  const rootStore = useRootStore($pinia)
  const route = useRoute()
  let thisConfig = null
  let inProgress401 = false

  // Add auth header to all requests
  $axios.interceptors.request.use((config) => {
    // Don't override auth header if they are explicitly set
    if (config.headers['Authorization']) {
      return config
    }

    const Authorization = userStore.getAuthHeader({
      acceptNoAuth: true,
      silent: false,
      requestUrl: config?.url ?? null
    })

    if (Authorization) {
      config.headers['Authorization'] = Authorization
    }

    thisConfig = config

    return config
  })

  // If using a lendio-jwt and making a server-side request to a lendio service,
  // we add origin and host headers to the request
  // and pass on specific headers from the original request
  if (process.server) {
    const nuxtRequestUrl = useRequestURL() ?? {}
    const nuxtHeaders = useRequestHeaders() ?? {}

    $axios.interceptors.request.use((config) => {
      const requestUrl = new URL(config.url)

      if ([env('apiUrl'), env('bpApiUrl'), env('identityServiceUrl')].some(url => url === requestUrl.origin)) {
        const origin = nuxtRequestUrl?.origin ?? null;
        const preservedHeaderTypes = [
          'x-forwarded-for',
          'cf-connecting-ip',
          'user-agent',
        ]

        if (!origin || !_isValidDomain(origin)) {
          log.error('Invalid or missing origin header for server-side request to a Lendio service', {
            url: config.url,
            origin,
            nuxtRequestUrl,
            nuxtHeaders,
          })
          throw new Error('Origin is invalid, not set, and/or cannot be inferred')
        }

        config.headers['origin'] = origin
        config.headers['host'] = requestUrl.host
        preservedHeaderTypes.forEach(type => {
          if (nuxtHeaders[type]) {
            config.headers[type] = nuxtHeaders[type] ?? null
          }
        })
        config.url = config.url.replace(requestUrl.origin, env('internalWebGatewayUrl'))
      }

      thisConfig = config

      return config
    })
  }

  // Handle 401 errors
  $axios.interceptors.response.use((response) => {
    return response
  }, error => {
    // If identity service, just pass on the error
    if (error?.request?.responseURL?.includes(env('identityServiceUrl'))) {
      return Promise.reject(error)
    }

    // Without a response, this means a network error has occurred
    if (!error?.response) {
      log.warning('Axios Network Error: ', error)
    }

    if (env('environment') != 'LIVE') {
      console.log('AXIOS error on', get(error, 'response.request.path'), error.stack)
    }

    if (!(get(error, 'response.status') === 401)) {
      return Promise.reject(error)
    }

    // Only need to handle the first 401
    if (inProgress401) {
      return
    }

    inProgress401 = true

    return _handle401({
      config: thisConfig,
      cookies: $lendioCookies,
      error,
      rootStore,
      route
    }).finally(() => {
      inProgress401 = false
    })
  })

  // Queue session extension on successful requests
  $axios.interceptors.response.use((response, ...args) => {
    // Only run on the client side
    if (process.server) {
      return response
    }

    if (!response) {
      return response
    }

    // Ignore the token refresh itself
    if (response.config.url.includes('/auth/token-refresh') || response.config.url.includes('/bp-re-auth')) {
      return response
    }

    // Ignore unauthenticated requests
    if (!response.config.headers['Authorization']) {
      return response
    }

    const lendioHosts = [
      'apiUrl',
      'bpApiUrl',
      'identityServiceUrl',
    ].map(url => {
      try {
        return new URL(env(url)).host
      } catch (e) {
        return null
      }
    }).filter(url => !!url)

    // Only attempt to refresh on requests to lendio services
    if (!lendioHosts.includes(new URL(response.config.url).host)) {
      return response
    }

    userStore.reauth()

    return response
  })
})
