import union from 'lodash/union.js'
import some from 'lodash/some.js'
import zipObject from 'lodash/zipObject.js'
import includes from 'lodash/includes.js'

const autoParams = [
  'capturepage',
  'landing_page',
  'referral_url'
]
const trackedParams = [
  'affiliate',
  'medium',
  'source',
  'campaign',
  'subid',
  'skipMarketingRecord',
  'adgroup',
  'term',
  'keywords',
  'interest',
]
const allParams = union(autoParams, trackedParams)

export default {

  deleteCookiesForTrackedParams ($lendioCookies) {
    let paramsToDelete = this.deletableCookies()
    paramsToDelete.forEach(param => {
      $lendioCookies.delete(param)
    })
  },
  deletableCookies () {
    let paramsToPreserve = ['skipMarketingRecord']
    return trackedParams.filter(
      param => !includes(paramsToPreserve, param)
    )
  },
  anyTrackedCookiesSet ($lendioCookies) {
    return some(allParams, (param) => {
      let cookieValue = $lendioCookies.get(param)
      return cookieValue && cookieValue !== 'NULL'
    })
  },
  trackedValues ($lendioCookies) {
    let values = zipObject(allParams, allParams.map((key) => {
      return $lendioCookies.get(key)
    }))
    values.capturepage = window.location.href
    return values
  }
}
