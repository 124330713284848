import { useCobrandStore } from '~/store/cobrand'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $pinia } = await useNuxtApp()
  try {
    await useCobrandStore($pinia).load(to)
  } catch (err) {
    if (err.response && err.response.status >= 500) {
      log.error('Error Loading Cobrand', err)
    } else if (!err.response) {
      log.error('Error Loading Cobrand', err)
    }
  }
})
