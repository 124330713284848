import { defineStore } from 'pinia'
import get from 'lodash/get'
import env from '~/libs/env'

import { useBorrowerStore } from '~/store/borrower'

export const useContractsStore = defineStore('contracts', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  const borrowerStore = useBorrowerStore()
   /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
const contracts = ref([])
const loaded = ref(false)

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
const contractsLoaded = computed(() => {
  return loaded.value
})
const hasContracts = computed(() => {
  return contracts.value.length > 0
})
const checkoutLinkForDeal = computed(() => {
  return (dealId) => {
    return contracts.value.find((contract) => {
      return contract.dealId === dealId && contract.typeId === 1
    })
  }
})
const contractForDeal = computed(() => {
  return (dealId) => {
    return contracts.value.find((contract) => {
      return contract.dealId === dealId
    })
  }
})

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
async function getContractsByBorrowerId(borrowerId) {
  const url = `${env('apiUrl')}/contracts/borrowers/${borrowerId}`
  const response = await $axios.get(url)
  const _contracts = get(response, 'data.data')
  contracts.value = _contracts
  loaded.value = true
}

async function getContracts() {
  const borrowerId = borrowerStore.borrowerId
  if (!borrowerId) return
  const response = await $axios.get(
    `${env('apiUrl')}/contracts/borrowers/${borrowerId}`
  )
  const _contracts = get(response, 'data.data')
  contracts.value = _contracts
  loaded.value = true
}

async function addContract(contract) {
  contracts.value.push(contract)
}

return {
  //STATE
  contracts,
  loaded,

  //GETTERS
  contractsLoaded,
  hasContracts,
  checkoutLinkForDeal,
  contractForDeal,

  //ACTIONS
  getContractsByBorrowerId,
  getContracts,
  addContract
}
})
