<template>
  <span>
    <slot></slot>
  </span>
</template>
<script>
export default {
  props: ['tooltipText'],
  async mounted() {
    if (process.client) {
      const { default: Tooltip } = await import('bootstrap/js/dist/tooltip')
      setTimeout(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .forEach(tooltipNode => new Tooltip(tooltipNode))
      }, 300) // This is meant to be a TEMPORARY fix to make tooltips work after anims.
    }
  },
}
</script>

<style lang="scss">
  @import "lendio-tooltips";
  $tooltip-max-width: 400px;
  .tooltip-inner {
    max-width: $tooltip-max-width;
    text-align: left;
  }
</style>
