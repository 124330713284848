import { defineStore } from 'pinia'
import { filterTextStipulations } from '~/libs/text-stipulations-filters'
import RequestsMock from '~/cypress/fixtures/requestsMock.json'

import env from '~/libs/env'

import { useBorrowerStore } from '~/store/borrower'
import { useRootStore } from '~/store/root'

export const useRequestsStore = defineStore('requests', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  const borrowerStore = useBorrowerStore()
  const rootStore = useRootStore()
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
const requests = ref([])
const loaded = ref(false)
const error = ref(null)
const duplicateRequests = ref([])

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
const displayedRequests = computed(() => {
  return requests.value.filter(
    (request) =>
      !duplicateRequests.value[request.displayName].find(
        (r) => r.id === request.id
      )
  )
})

const requestsForDealDocumentStipulations = computed(() => {
  const requestsDDS = requests.value
  return (dealId) => {
    return requestsDDS.filter((request) => {
      return request.type === 'documentRequest' && request.dealId === dealId
    })
  }
})

const requestsForDealTextStipulations = computed(() => {
  const requestsDTS = requests.value
  return (dealId) => {
    return filterTextStipulations(requestsDTS, dealId)
  }
})

const incompleteDocumentRequests = computed(() => {
  const requestsDR = requests.value
  return requestsDR.filter((request) => {
    return (
      request.status !== 'completed' && request.type === 'documentRequest'
    )
  })
})

const requestsLoaded = computed(() => {
  return loaded.value
})

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
function storeRequests(_requests) {
  duplicateRequests.value = _requests.reduce((group, request) => {
    if (!group[request.displayName]) {
      group[request.displayName] = []
    } else {
      group[request.displayName].push(request)
    }
    return group
  }, {})

  requests.value = _requests
}

function updateRequests(_requests) {
  requests.value = _requests.reduce((collection, request) => {
    const requestIndex = requests.value.findIndex((req) => {
      return req.id === request.id
    })
    collection.splice(requestIndex, 1, request)
    return collection
  }, requests.value)
}

async function getRequests() {
  loaded.value = false

  const res = await $axios
    .get(
      env('apiUrl') +
        `/borrower/${
          borrowerStore.borrowerId
        }/request`,
    )
    .catch((err) => {
      log.error('Error while getting requests', err)
      loaded.value = false
      error.value = err
      return
    })
  if (!res) {
    return {}
  } else {
    this.storeRequests(res.data.data)
    loaded.value = true
    return res.data.data
  }
}

async function updateRequest(request) {
  let _requests = [request]
    .concat(duplicateRequests.value[request.displayName])
    .map((duplicateRequest) => {
      return {
        ...request,
        id: duplicateRequest.id,
        dealId: duplicateRequest.dealId
      }
    })
  const res = await $axios
    .put(
      env('apiUrl') + '/request',
      JSON.stringify({
        borrowerId: request.borrowerId,
        _requests
      }),
      { headers: { 'Content-Type': 'application/json' } }
    )
    .catch((err) => {
      loaded.value = false
      error.value = err
      log.error('Error while updating requests', err)
      return
    })
  if (!res) {
    return {}
  } else {
    this.updateRequests(res.data.data)
    return res.data.data
  }
}

async function answerRequest(request) {
  const payload = {
    id: request.id,
    dealId: request.dealId,
    displayName: request.displayName,
    borrowerId: request.borrowerId,
    status: 'completed',
    value: request.value
  }

  return this.updateRequest(payload)
}

async function setRequestCompletedStatus(stateRequest) {
  requests.value = requests.value.map((request) => {
    if (stateRequest.request.id === request.id) {
      if (stateRequest.status) {
        request.status = 'completed'
      } else {
        request.status = null
      }
    }
    return request
  })
}

async function addAttachment({ request, documentId }) {
  const payload = {
    id: request.id,
    dealId: request.dealId,
    displayName: request.displayName,
    borrowerId: request.borrowerId,
    status: 'completed',
    documentId: documentId,
    completed: new Date().getTime()
  }

  return this.updateRequest(payload)
}

async function removeAttachment(request) {
  const payload = {
    id: request.id,
    dealId: request.dealId,
    displayName: request.displayName,
    borrowerId: request.borrowerId,
    status: 'published',
    documentId: null,
    completed: null
  }

  return this.updateRequest(payload)
}

async function completeAvailableDocumentRequests({ document }) {
  if (!document || !document.category || !document.id) {
    return
  }

  const request = incompleteDocumentRequests.value.find((request) => {
    return request.categoryId === document.category
  })

  if (request && request.status !== 'completed') {
    this.addAttachment({ request, documentId: document.id })
  }
}

return {
  //STATE
  requests,
  loaded,
  error,

  //GETTERS
  displayedRequests,
  requestsForDealDocumentStipulations,
  requestsForDealTextStipulations,
  incompleteDocumentRequests,
  requestsLoaded,

  //ACTIONS
  storeRequests,
  updateRequests,
  getRequests,
  updateRequest,
  answerRequest,
  setRequestCompletedStatus,
  addAttachment,
  removeAttachment,
  completeAvailableDocumentRequests,
}
})
