import { useRootStore } from '~/store/root'

export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp()
  const { authUser, isEmbedded } = useRootStore($pinia)

  const isEmbeddedUser = authUser?.isEmbedded
  const isEmbeddedPath = /\/embedded(\/|$)/.exec(to.path)

  // if the route is embedded, not /embedded/401, and user is not auth'd, throw error
  if (isEmbedded && isEmbeddedPath && !isEmbeddedUser) {
    throw createError({
      statusCode: 401,
      statusMessage: 'Unauthorized Action',
      data: {
        isEmbedded: true,
        location: 'Access Control'
      },
      fatal: true
    })
  }

  // if a non-embedded is trying to visit an embedded route, throw error
  if ((!isEmbedded || !isEmbeddedUser) && isEmbeddedPath) {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page not found.',
      data: {
        isEmbedded: false
      },
      fatal: true
    })
  }

  // if an embedded user is trying to visit a non-embedded route, throw error
  if ((isEmbedded || isEmbeddedUser) && !isEmbeddedPath) {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page not found.',
      data: {
        isEmbedded: true
      },
      fatal: true
    })
  }
})