import { defineStore } from 'pinia'

import get from 'lodash/get'

import { APPLICATIONS, DEAL_STATES } from '~/libs/portal-constants'
import { localStorageService } from '~/libs/local-storage.service'
import { normalizeOffer } from '~/libs/offer-helper'
import { compatibleDate } from '~/libs/date-helpers'
import { useBorrowerStore } from './borrower'
import { useDealsStore } from '~/store/deals'
import { format, addDays, isAfter } from 'date-fns'

// HELPER FUNCTIONS
function getNewestDeal(deals) {
  if (!deals.length) {
    return null
  }
  let dealsCopy = [...deals]
  return dealsCopy.sort((a, b) => {
    let aDate = new Date(compatibleDate(a['created']))
    let bDate = new Date(compatibleDate(b['created']))
    return bDate - aDate
  })[0]
}

function computeMarketplaceDealState(deal, borrowerValues) {
  if (deal) {
    const stageCheck = ['closing', 'funded'].includes(deal.stage)
    const underwritingCheck =
      deal.stage === 'underwriting' &&
      !['declined', 'pendingFundingManager'].includes(deal.status)
    const offersCheck = deal.offers.length > 0
    if (offersCheck && (stageCheck || underwritingCheck)) {
      return DEAL_STATES.HAS_DEALS_MP
    } else {
      return DEAL_STATES.PROCESSING_DEFAULT
    }
  } else {
    const appStarted = get(borrowerValues, 'marketplaceAppStarted.value')
    const appCompleted = get(borrowerValues, 'marketplaceAppCompleted.value')
    if (appCompleted) {
      return DEAL_STATES.PROCESSING_DEFAULT // This should be refactored to be multiple states as well
    } else if (!appStarted) {
      return DEAL_STATES.START_APPLICATION
    } else {
      return DEAL_STATES.INCOMPLETE
    }
  }
}
// END HELPER FUNCTIONS

export const useOffersStore = defineStore('offers', () => {
  const borrowerStore = useBorrowerStore()
  const dealsStore = useDealsStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
const appDeals = ref({[APPLICATIONS.MARKETPLACE]: []})
const currentApplication = ref(APPLICATIONS.MARKETPLACE)
const applicationTabs = ref(Object.values(APPLICATIONS))
const recentDealByApp = ref( {[APPLICATIONS.MARKETPLACE]: {
  deal: null,
  dealState: DEAL_STATES.LOADING
}
})
/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
const offers = computed(() => {
  return dealsStore.deals.filter(deal => {
    return deal.stage !== 'inactive'
  }).flatMap(deal => {
    return deal.offers.filter(offer => {
      const offerCreated = new Date(compatibleDate(offer.created))
      const expiredTime = addDays(offerCreated, 30)

      if (offer.amount > 0 && expiredTime && isAfter(expiredTime, new Date())) {
        return offer
      }
    }).flatMap(offer => normalizeOffer(offer, deal))
  })
})

const publishedOffers = computed(() => {
  const acceptedIds = dealsStore.deals
    .filter(deal => deal.stage !== 'inactive' && Boolean(deal.acceptedOffer))
    .map(deal => deal.acceptedOffer.id)
  const activeDealIds = dealsStore.deals
    .filter(deal => !['inactive', 'funded'].includes(deal.stage))
    .map(deal => deal.id)

  return offers.value.filter((offer) => (
    activeDealIds.includes(offer.dealId)
    && (Boolean(offer.publishedToBorrower) || acceptedIds.includes(offer.id))
  ))
})

const marketplaceDeals = computed(() => {
  return appDeals.value[APPLICATIONS.MARKETPLACE]
})

const marketplaceSelected = computed(() => {
  return currentApplication.value === APPLICATIONS.MARKETPLACE
})

const dnqOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.DNQ
})

const incompleteOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.INCOMPLETE
})

const startAppOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.START_APPLICATION
})

const loadingOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.LOADING
})

const mpDealsOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.HAS_DEALS_MP
})

const fundedOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.HAS_DEALS_FUNDED
})

const offerReceivedOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.HAS_DEALS_OFFER
})

const processingDefaultOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.PROCESSING_DEFAULT
})

const withdrawnOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.WITHDRAWN
})

const readyCapOfferState = computed(() => {
  return currentDealState.value === DEAL_STATES.READY_CAP
})

const currentDealObject = computed(() => {
  // Default
  let dealObject = { deal: null, dealState: DEAL_STATES.LOADING }
  // Marketplace
  if (marketplaceSelected.value) {
    dealObject = recentDealByApp.value[APPLICATIONS.MARKETPLACE]
  }
  return dealObject
})

const currentDeal = computed(() => {
  return currentDealObject.value.deal
})

const currentDealState = computed(() => {
  return currentDealObject.value.dealState
})

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  function setRecentDealByApp(payload) {
    const { app, dealObj } = payload
    const dealCopy = { ...recentDealByApp.value[app] }
    recentDealByApp.value[app] = Object.assign(dealCopy, dealObj)
  }

  // Order of these actions matters.
  async function setupOffersDisplay() {
    // Get the deals from api.
    await this.setupOfferPageDeals()

    // Populate the deal with the most recent borrower values.
    await this.setupCurrentDealStates()
  }

  async function setupOfferPageDeals() {
    // Setting option { root: true } lets us use root actions in
    // namespaced modules. See https://vuex.vuejs.org/api/#dispatch.
    await dealsStore.getDealsWithOffers(null)
    const deals = dealsStore.deals

    const currentMpDeal = getNewestDeal(deals)
    this.setRecentDealByApp({
      app: APPLICATIONS.MARKETPLACE,
      dealObj: { deal: currentMpDeal }
    })

    const dealsObj = {
      [APPLICATIONS.MARKETPLACE]: deals
    }
    appDeals.value = Object.assign({ ...appDeals.value }, dealsObj)
  }

  /**
   * Refresh the current deal with the most recent borrower values based on
   * the currentApplication type.
   */
  async function setupCurrentDealStates() {
    const { borrowerValues } = borrowerStore

    // Is a Marketplace app.
    if (currentApplication.value === APPLICATIONS.MARKETPLACE) {
      const mpDeal = recentDealByApp.value[APPLICATIONS.MARKETPLACE].deal
      const mpDealState = computeMarketplaceDealState(mpDeal, borrowerValues)
      this.setRecentDealByApp({
        app: APPLICATIONS.MARKETPLACE,
        dealObj: { dealState: mpDealState }
      })
    }
  }

  // NEW
  // async setupCurrentDealStates({ state, rootState, commit }) {
  //   const { borrowerValues } = borrowerStore.borrower
  //   const { recentDealByApp } = state

  //   // Get the deal by the currentApplication key (was hard-coded to
  //   // APPLICATION.MARKETPLACE before). computeDealState determines which
  //   // application and sends to the correct function.
  //   const mpDeal = recentDealByApp[state.currentApplication].deal
  //   const mpDealState = computeDealState(mpDeal, borrowerValues)

  //   commit('setRecentDealByApp', {
  //     app: state.currentApplication,
  //     dealObj: { dealState: mpDealState }
  //   })
  // },

  function setCurrentApplication(_currentApplication) {
    if (process.client) {
      localStorageService.setItem('currentApplication', _currentApplication)
    }
    currentApplication.value = _currentApplication
  }
  function addOffersTab(tab) {
    if (!applicationTabs.value.includes(tab)) {
      applicationTabs.value.push(tab)
    }
  }
  function removeOffersTab(tab) {
    applicationTabs.value = applicationTabs.value.filter((t) => t !== tab)
  }

  return {
    //STATE
    appDeals,
    currentApplication,
    applicationTabs,
    recentDealByApp,
    offers,

    //GETTERS
    marketplaceDeals,
    marketplaceSelected,
    dnqOfferState,
    incompleteOfferState,
    startAppOfferState,
    loadingOfferState,
    mpDealsOfferState,
    fundedOfferState,
    offerReceivedOfferState,
    processingDefaultOfferState,
    withdrawnOfferState,
    readyCapOfferState,
    currentDealObject,
    currentDeal,
    currentDealState,
    publishedOffers,

    //ACTIONS
    setRecentDealByApp,
    setupOffersDisplay,
    setupOfferPageDeals,
    setupCurrentDealStates,
    setCurrentApplication,
    addOffersTab,
    removeOffersTab
  }
})
