import { defineStore } from 'pinia'

// Legacy imports
import questionsHelper from '~/libs/questions-helper'
import { aboutYourBusinessBools } from '~/libs/dynamic-pages-service'
import {
  getAdditionalQuestions,
  getBusinessFinancialQuestions,
  getBusinessInfoQuestions,
  getOwnerCreditQuestions,
  getOwnerInfoQuestions,
  questionSort
} from '~/libs/page-helper'
// end Legacy imports

import env from '~/libs/env'
import { AnswerStorageService } from '~/libs/answer-storage.service.js'
import { localStorageService } from '~/libs/local-storage.service.js'
import { changeAnswer } from '~/libs/question-helpers'
import QUESTION_WIDGETS from '~/libs/question-widgets'
import { reduceAnswerList } from '~/libs/question-helpers'

import first from 'lodash/first.js'
import get from 'lodash/get.js'
import includes from 'lodash/includes.js'
import keyBy from 'lodash/keyBy.js'
import last from 'lodash/last.js'
import merge from 'lodash/merge.js'
import omit from 'lodash/omit.js'
import pick from 'lodash/pick.js'
import some from 'lodash/some.js'
import union from 'lodash/union.js'
import values from 'lodash/values.js'

import { MachineLearningService } from '~/libs/machine-learning-service'
import { getMineral } from '~/libs/mineral-calculation'
import {
  buildPages,
  getDirectiveMapping,
  sortPages
} from '~/libs/dynamic-pages-service'
import { lsUtils } from '~/libs/ls-utils-service'

import { useBorrowerStore } from '~/store/borrower'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useRootStore } from '~/store/root'
import { useUserStore } from '~/store/user'
import { useProgressStore } from '~/store/progress'

function _getReviewQuestions(
  blacklist,
  _historicIds,
  _indexedAttributes,
  _informationalQuestions
) {
  const historicQuestions = _historicIds
    .map((alias) =>
      alias === 'about_your_business' ? aboutYourBusinessBools() : alias
    ) /* Change about_your_business to actual aliases */
    .reduce(
      (a, current) =>
        a.concat(Array.isArray(current) ? [].concat(...current) : current),
      []
    ) /* Flatten array (array created inside by adding about your business aliases) */
    .filter(
      (alias) => _indexedAttributes[alias]
    ) /* Remove aliases that aren't found in the attributes */
    .map(
      (alias) => _indexedAttributes[alias]
    ) /* Convert aliases to actual attribute objects */

  const questions = [..._informationalQuestions, ...historicQuestions]
    .filter((q) => !includes(blacklist, q && q.alias))
    // temporary fix because some questions, like fund aspect, don't always comeback from the server.
    // So we need to filter out the {alias,value} objects that are merged in from storage from displaying
    .filter((q) => q && q.id)

  questionSort(questions)

  return questions
}

function isVisibleBasicInfoPage(
  page,
  lookup,
  _historicIds,
  askMineralQuestions,
  borrowerValues,
  includeAnswered = false,
  _singleForm = false
) {
  const pwfSignup = questionsHelper.getStorageItem('pwfSignup', null) || null
  const mineralQuestions = [
    'creditScore',
    'business_start_date',
    'average_monthly_sales'
  ]
  let results = page.attributes.map((attribute) => {
    if (askMineralQuestions && mineralQuestions.includes(attribute.alias)) {
      // If a borrower has started their application on a PWF, we don't want to show these two questions
      if (
        (attribute.alias === 'creditScore' ||
          attribute.alias === 'average_monthly_sales') &&
        (pwfSignup === true || pwfSignup === 'true')
      ) {
        return !get(borrowerValues, `${attribute.alias}.value`)
      } else {
        return true
      }
    }
    return (
      questionsHelper.dependencySatisfied(attribute, lookup) &&
      (includes(_historicIds, page.id) ||
        questionsHelper.isRelevantBasicInfoQuestion(
          lookup,
          attribute,
          false,
          includeAnswered,
          _singleForm
        ))
    )
  })

  return some(results)
}

function isRenewalVisiblePage(
  page,
  lookup,
  _historicIds,
  includeAnswered = false
) {
  let whitelistAttributes = ['financeAmount', 'loanPurpose', 'fundAspect']
  let results = page.attributes.map((attribute) => {
    return whitelistAttributes.includes(attribute.alias)
  })

  return some(results)
}

function initializeRouteParamAnswers(route) {
  let {
    creditScore,
    amountSeeking,
    averageMonthlySales,
    timeInBusiness,
    industry,
    lendioIndustry
  } = route.query

  const creditScoreParsed = parseInt(creditScore)
  creditScore = !isNaN(creditScoreParsed) ? creditScore : undefined
  timeInBusiness = isNaN(parseInt(timeInBusiness)) ? undefined : timeInBusiness
  industry = industry || undefined
  lendioIndustry = lendioIndustry || undefined
  // Remove anything that isnt a number or full stop for amount seeking
  amountSeeking = amountSeeking
    ? amountSeeking.replace(/[^.0-9]+/g, '')
    : undefined

  let creditScoreWasMapped = false
  if (creditScore) {
    let mappedCreditScore = lsUtils.optionIdToCreditScore(creditScore).max
    if (mappedCreditScore !== undefined) {
      creditScore = mappedCreditScore
      creditScoreWasMapped = true
    } else if (creditScoreParsed < 350 || creditScoreParsed > 850) {
      creditScore = undefined
    }
  }

  if (timeInBusiness !== undefined && creditScoreWasMapped) {
    let mappedTimeInBusiness =
      lsUtils.optionIdToTimeInBusiness(timeInBusiness).max
    if (mappedTimeInBusiness !== undefined) {
      timeInBusiness = mappedTimeInBusiness
    }
  }

  let businessStartDate

  if (timeInBusiness !== undefined) {
    const timeInBusinessParsed = parseInt(timeInBusiness)
    if (!isNaN(timeInBusinessParsed) && timeInBusinessParsed >= 0) {
      let date = new Date()
      // Subtract the time in business from the current date
      date.setMonth(date.getMonth() - timeInBusinessParsed)
      // Setting date to 5, thats what the borrower attribute currently returns
      date.setDate(5)

      businessStartDate = formatDate(date, 'yyyy-MM-dd')
    }
  }

  let paramAnswers = [
    {
      alias: 'financeAmount',
      value: amountSeeking
    },
    {
      alias: 'creditScore',
      value: creditScore
    },
    {
      alias: 'average_monthly_sales',
      value: averageMonthlySales
    },
    {
      alias: 'lendioIndustry',
      value: lendioIndustry || industry
    },
    {
      alias: 'business_start_date',
      value: businessStartDate
    }
  ]

  return paramAnswers.filter((answer) => {
    return answer.value !== undefined
  })
}

function requestRemainingFilterQuestions(borrowerId, answerData, axios) {
  if (borrowerId) {
    const appType = useProgressStore().currentApp.type
    let url = `${env('apiUrl')}/matching/questions/${borrowerId}`
    if (appType === 'sba-complete') {
      url += `?borrowerApplicationUpdate=false`
    }
    return axios.put(
      url,
      JSON.stringify(answerData),
      { headers: { 'Content-Type': 'application/json' } }
    )
  } else {
    return axios.post(
      `${env('apiUrl')}/matching/questions/remaining`,
      JSON.stringify(answerData),
      { headers: { 'Content-Type': 'application/json' } }
    )
  }
}

function requestInitialFilterQuestions(borrowerId, answerData, axios, visitorId = null) {
  if (borrowerId) {
    let borrowerUrl = `${env('apiUrl')}/matching/questions/${borrowerId}`
    if (visitorId) {
      borrowerUrl = `${env('apiUrl')}/visitor/${visitorId}/matching/questions/${borrowerId}`
    }
    return axios.get(borrowerUrl)
  } else {
    return axios.post(
      `${env('apiUrl')}/matching/questions`,
      JSON.stringify(answerData),
      { headers: { 'Content-Type': 'application/json' } }
    )
  }
}

function loadQuestions(fields, axios) {
  return axios.get(`${env('apiUrl')}/questions/by-alias`, {
    params: { fields }
  })
}

async function requestApplicationQuestionFilters(appType, axios) {
  return axios.get(
    `${env('apiUrl')}/application/${appType}/filters`
  ).then((response) => {
    if (!response) return
    if (response && response.status !== 200) return
    return get(response, 'data.data', get(response, 'data'))
  })
}

export const useQuestionsStore = defineStore('questions', () => {
  const nuxtApp = useNuxtApp()
  const { $axios, $pinia/*Passed to libs*/, $lendioCookies } = nuxtApp
  const $router = useRouter()

  const machineLearningService = new MachineLearningService($axios)

  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const borrowerStore = useBorrowerStore()
  const rootStore = useRootStore()
  const userStore = useUserStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/

  const fullyLoadedBank = ref(false)
  const questionBank = ref([...QUESTION_WIDGETS])
  const badAliases = ref({})
  // Separated to trigger getter updates
  const stateAnswers = ref([])
  const savingAnswers = ref(false)
  const loadingQuestions = ref(true)
  const persistedAnswers = ref([])
  const questionFilters = ref([])
  const getQuestionFiltersRequest = ref(null)

  // LEGACY FIELDS
  const filterQuestionsInitialized = ref(false)
  const indexedAttributes = ref({})
  const answers = ref({})
  const infoAnswers = ref({})
  const mineral = ref(null)
  const pages = ref([])
  const visiblePages = ref([])
  const infoQuestions = ref([])
  const filterQuestions = ref([])
  const mlWhitelist = ref([])
  const predictedCategories = ref(null)
  const historicIds = ref([])
  const currentPage = ref(null)
  const errorMsg = ref('')
  const historyLoaded = ref(false)
  const overallWhitelist = ref([])
  const pageBlacklist = ref([])
  const manualBasicInfoExclusionList = ['percentOwnership']
  const alwaysAskedAliases = ref([])

  const singleForm = ref(false) // deprecated?
  const questionReduction = ref(false) // deprecated?

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  /**
   * Returns the combination of the persisted answers and state answers,
   * favoring the state answers to maintain current state.
   *
   * @returns {{[alias: string]: any}}
   */
  const currentAnswers = computed(() => {
    const _answers = {
      ...persistedAnswers.value.reduce(reduceAnswerList, {}),
      ...stateAnswers.value.reduce(reduceAnswerList, {})
    }
    return _answers
  })

  /**
   * Returns any answers in state answers that do not exist or do not match
   * the value in persisted answers
   * @returns {{[alias: string]: any}}
   */
  const unsavedAnswers = computed(() => {
    return stateAnswers.value.reduce(reduceAnswerList, {})
  })

  /**
   * Returns only the persisted answers keyed by alias.
   *
   * @returns {{[alias: string]: any}}
   */
  const savedAnswers = computed(() => {
    const _answers = {
      ...persistedAnswers.value.reduce(reduceAnswerList, {})
    }
    return _answers
  })

  /**
   * Returns whether answers are dirty and should be saved
   *
   * @returns {boolean}
   */
  const areAnswersDirty = computed(() => {
    const _unsavedAnswers = unsavedAnswers.value
    return Object.keys(_unsavedAnswers).some((alias) => {
      return (
        (_unsavedAnswers[alias] && _unsavedAnswers[alias].toString()) !=
        (savedAnswers.value[alias] && savedAnswers.value[alias].toString())
      )
    })
  })

  /**
   * Returns an alias-indexed mapping of the questions in the question bank.
   *
   * Parses group field lists into their actual question definitions
   *
   * @returns {{[alias: string]: Question}}
   */
  const keyedQuestions = computed(() => {
    const definitions = questionBank.value.reduce((carry, question) => {
      carry[question.alias] = question
      return carry
    }, {})
    Object.keys(definitions).forEach((alias) => {
      let question = definitions[alias]
      if (question.fields) {
        const componentsBase = {
          components: question.fields
            .map((field) => {
              // Field overrides for component questions
              const isObject = field instanceof Object,
                alias = isObject ? field.alias : field,
                componentQuestion = questionBank.value.find(
                  (q) => q.alias == alias
                )
              return isObject
                ? Object.assign({}, componentQuestion, field)
                : componentQuestion
            })
            .filter(Boolean)
        }
        definitions[alias] = Object.assign(
          {},
          componentsBase.components.length == 1
            ? componentsBase.components[0]
            : {},
          componentsBase,
          question
        )
      }
    })
    return definitions
  })

  /**
   * Returns the component questions. These are populated with the values returned from the currentAnswers.
   *
   * @param {VuexState} state
   * @param {VuexGetters} getters
   * @returns {{[alias: string]: Question}} keyed questions with currentAnswers
   */
  const componentQuestions = computed(() => {
    const _keyedQuestions = keyedQuestions.value
    const _currentAnswers = currentAnswers.value
    const groupedQuestions = Object.keys(_keyedQuestions).filter(
      (alias) => _keyedQuestions[alias].fields
    )
    const _componentQuestions = Object.keys(_keyedQuestions)
      .map((alias) =>
        _currentAnswers.hasOwnProperty(alias)
          ? Object.assign({}, _keyedQuestions[alias], {
              value: _currentAnswers[alias]
            })
          : Object.assign({}, _keyedQuestions[alias])
      )
      .reduce((carry, q) => ((carry[q.alias] = q), carry), {})
    groupedQuestions.forEach((groupName) => {
      const group = _componentQuestions[groupName],
        components = (group.components = group.components.map(
          (componentQuestion) =>
            _currentAnswers.hasOwnProperty(componentQuestion.alias)
              ? Object.assign({}, componentQuestion, {
                  value: _currentAnswers[componentQuestion.alias]
                })
              : componentQuestion
        ))
      // set value for override widgets
      group.value = components.length == 1 ? components[0].value : group.value
    })
    return _componentQuestions
  })

  const appQuestionFilters = computed(() => {

    const authenticated = userStore.hasOptimusUser
    return questionFilters.value.reduce((filters, filter) => {
      filters[filter.appName] = filters[filter.appName] || []
      if (!authenticated && filter.afterAuth) {
        return filters
      }
      filters[filter.appName].push(filter)
      return filters
    }, {})
  })

  // LEGACY GETTERS

  /**
   * Returns questions that are relevant info questions, grouping address attributes
   * @returns {BorrowerValue[]}
   */
  const informationalQuestions = computed(() => {
    let _infoQuestions = values(pick(indexedAttributes.value, mlWhitelist.value))

    _infoQuestions = questionsHelper.groupAddressAttributes(_infoQuestions)
    _infoQuestions = applyQuestionCustomizations(_infoQuestions)
    return _infoQuestions.filter((q) =>
      questionsHelper.isRelevantInfoQuestion(
        indexedAttributes.value,
        q,
        historicIds.value
      )
    )
  })

  /**
   * Returns questions that should not be hidden, because they are dependency satisfied, or in a group that should display
   * @returns {BorrowerValue[]}
   */
  const displayableQuestions = computed(() => {
    const questions = values(indexedAttributes.value)
    return questionsHelper
      .groupAddressAttributes(questions)
      .filter((q) =>
        questionsHelper.isSatisfied(indexedAttributes.value, q, [])
      )
      .map((q) => q.alias)
  })

  /**
   * Returns informational questions filtered down to 'owner info' questions
   * @returns {BorrowerValue[]}
   */
  const ownerInfoQuestions = computed(() => {
    return getOwnerInfoQuestions(informationalQuestions.value)
  })

  /**
   * Returns informational questions filtered down to 'owner credit' questions
   * @returns {BorrowerValue[]}
   */
  const ownerCreditQuestions = computed(() => {
    return getOwnerCreditQuestions(informationalQuestions.value)
  })

  /**
   * Returns informational questions filtered down to 'business financial' questions
   * @returns {BorrowerValue[]}
   */
  const businessFinancialQuestions = computed(() => {
    let _questions = getBusinessFinancialQuestions(informationalQuestions.value)
    if (useProgressStore().currentApp.type === 'sba-complete') {
      _questions = _questions.filter(question => {
        return [
          'fundAspect', // not relevant
          'maxWeeksToFunds', // not relevant
        ].includes(question.alias) === false
      })
    }
    return _questions
  })

  /**
   * Returns informational questions filtered down to 'business info' questions
   * @returns {BorrowerValue[]}
   */
  const businessInfoQuestions = computed(() => {

    return getBusinessInfoQuestions(
      informationalQuestions.value,
      get(borrowerStore, 'borrower')
    )
  })

  /**
   * Returns all remaining informational questions
   * @returns {BorrowerValue[]}
   */
  const additionalQuestions = computed(() => {
    // filter out main signup questions and group the rest
    const filteredQuestions = getAdditionalQuestions(informationalQuestions.value).reduce((acc, question) => {
      if (questionsHelper.isMainSignupQuestion(question.alias)){
        return acc
      }
      if (question.groupOrder !== null && question.groupOrder > 1) {
        acc.groupedQuestions.push(question);
      } else {
        acc.questions.push(question);
      }
      return acc;
    }, { questions: [], groupedQuestions: [] });

    const questions = filteredQuestions.questions;
    const groupedQuestions = filteredQuestions.groupedQuestions;

    // sort questions separately to maintain correct display order
    groupedQuestions.sort((a, b) => a.groupOrder - b.groupOrder);
    questions.sort(function (a, b) {
      const aShortName = get(a, 'shortName')
      const bShortName = get(b, 'shortName')
      if (!aShortName || !bShortName) {
        return
      }
      // sort alphabetically
      return aShortName.localeCompare(bShortName)
    })

    if (groupedQuestions.length === 0) {
      return questions
    }

    const subQuestionsByGroup = groupedQuestions.reduce((acc, question) => {
      acc[question.group] = acc[question.group] ?? []
      acc[question.group].push(question)
      return acc
    }, {})

    // merge grouped sub questions
    return questions.reduce((acc, question) => {
      if (question.group && subQuestionsByGroup[question.group]) {
        acc.push(question, ...subQuestionsByGroup[question.group]);
      } else {
        acc.push(question);
      }
      return acc;
    }, []);
  })

  /**
   * This returns whether a question getter has any questions
   * @returns {() => boolean}
   */
    const hasQuestions = computed(() => {
      const allowedGetters = {
        businessFinancialQuestions,
        additionalQuestions,
      }
      return (getter) => {
        if (!allowedGetters[getter]) {
          log.error(`hasQuestions called with non-allowed getter: ${getter}`, { requestedGetter: getter })
          return false
        }
        return Boolean(allowedGetters[getter].value instanceof Array && allowedGetters[getter].value.length)
      }
    })

  /**
   * Returns all relevant informational questions, or answered questions, for the review step
   * @returns {BorrowerValue[]}
   */
  const reviewQuestions = computed(() => {
    return _getReviewQuestions(
      ['creditScore', 'user.email'],
      historicIds.value,
      indexedAttributes.value,
      informationalQuestions.value
    )
  })

  /**
   * Returns all relevant informational questions, or answered questions, for the profile step
   * @returns {BorrowerValue[]}
   */
  const profileQuestions = computed(() => {
    return _getReviewQuestions(
      ['creditScore'],
      historicIds.value,
      indexedAttributes.value,
      informationalQuestions.value
    )
  })

  /**
   * Count Pages
   * @returns {Number}
   */
  const numPages = computed(() => {
    return visiblePages.value.length
  })

  /**
   * Index of current page
   * @returns {Number}
   */
  const currentPageIndex = computed(() => {
    return currentPage.value
      ? visiblePages.value.findIndex((page) => page.id === currentPage.value.id)
      : -1
  })

  /**
   * Is this the first visible page?
   * @returns {Boolean}
   */
  const isFirstPage = computed(() => {
    return currentPageIndex.value > -1 ? currentPageIndex.value === 0 : false
  })

  /**
   * @returns {Boolean}
   */
  const isCurrentPageReady = computed(() => {
    return currentPage.value !== null
  })

  /**
   * Is this the last visible page?
   * @returns {Boolean}
   */
  const isLastPage = computed(() => {
    return currentPageIndex.value > -1
      ? currentPageIndex.value + 1 >= visiblePages.value.length
      : false
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  /**
   * Update a state answer. State answers should have validation
   * of the values checked before this new value is stored. This stores
   * the given answer in the `stateAnswers` array in the store.
   * @param {{alias: string, value: any}} answer
   */
  function updateAnswer({ alias, value }) {
    changeAnswer(stateAnswers.value, { alias, value })
  }

  /**
   * Update persisted answers in bulk. "Persisted" meaning there should be an
   * equivalent record in the database for these new values. This should
   * only be called when retrieving data/state in bulk from the API
   * @param {{alias: string, value: any}} answer
   */
  function bulkUpdatePersistedAnswers(_answers) {
    const indexes = persistedAnswers.value.reduce(
      (indexes, { alias }, idx) => ((indexes[alias] = idx), indexes),
      {}
    )
    _answers.forEach((answer) => {
      changeAnswer(persistedAnswers.value, {
        ...answer,
        idx:
          typeof indexes[answer.alias] == 'number'
            ? indexes[answer.alias]
            : -1
      })
    })
  }

  /**
   * Load the answers from API for the given aliases. Returns the current answers
   * getter values
   *
   * @param {{aliases: ?string[]}} payload
   * @returns {{[alias: string]: any}}
   */
  async function getAnswers({ aliases = [], allFields = false, quick = false } = {}) {
    if (
      quick &&
      !aliases.some((alias) => !savedAnswers.value.hasOwnProperty(alias))
    ) {
      return currentAnswers.value
    }
    const service = new AnswerStorageService($pinia, $axios)
    const data = await service.getAnswers(
      Boolean(rootStore.authUser),
      borrowerStore.borrowerId,
      { aliases, allFields }
    )
    this.bulkUpdatePersistedAnswers(data)
    return currentAnswers.value
  }

  /**
   * Clears the state answers, resetting to have no dirty values
   */
  function clearStateAnswers(_answers = null) {
    if (!_answers) {
      stateAnswers.value.length = 0
      // trigger change detection
      stateAnswers.value.pop()
    } else {
      stateAnswers.value = stateAnswers.value.filter(
        ({ alias }) => !_answers.hasOwnProperty(alias)
      )
    }
  }

  function areAnswersDirtyForAliases(_aliases) {
    const _unsavedAnswers = unsavedAnswers.value
    return Object.keys(_unsavedAnswers)
      .filter(alias => { return _aliases.includes(alias) })
      .some((alias) => {
        return (
          (_unsavedAnswers[alias] && _unsavedAnswers[alias].toString()) !=
          (savedAnswers.value[alias] && savedAnswers.value[alias].toString())
        )
      })
  }

  /**
   * Save answers in store and load current state from API after successful save
   * @param {{}} payload
   * @returns
   */
  async function saveAnswers({ answers: _answers = null, initialAuth = false } = {}) {
    const _unsavedAnswers = areAnswersDirty.value ? { ...unsavedAnswers.value } : {}
    _answers = _answers || _unsavedAnswers

    if (initialAuth) {
      const noAuthService = new AnswerStorageService(
        $pinia,
        $axios
      )
      const noAuthAnswers = await noAuthService.getAnswers(
        false,
        borrowerStore.borrowerId,
        { allFields: true }
      )
      _answers = {
        ...noAuthAnswers.reduce(
          (carry, { alias, value }) => ((carry[alias] = value), carry),
          {}
        ),
        ...savedAnswers.value,
        ..._answers
      }
    }
    // do not save answers over identical values (to protect from masked values saving over actual values)
    _answers = Object.keys(_answers)
      .filter(
        (alias) => initialAuth || _answers[alias] != savedAnswers.value[alias]
      )
      .reduce((carry, alias) => ((carry[alias] = _answers[alias]), carry), {})

    const attemptedToSaveKeys = Object.keys(_answers)

    if (!Object.keys(_answers).length) {
      return true
    }
    const service = new AnswerStorageService($pinia, $axios)
    savingAnswers.value = true
    const data = await service.saveAnswers(
      Boolean(rootStore.authUser),
      _answers,
      borrowerStore.borrowerId
    )
    if (data) {
      if (initialAuth) {
        localStorageService.removeItem('answers')
      }
      const aliases = Object.keys(_answers)
      await this.getAnswers({ aliases })
      this.clearStateAnswers(_answers)
    }
    savingAnswers.value = false

    return !areAnswersDirtyForAliases(attemptedToSaveKeys)
  }

  /**
   * Add questions to the questionBank
   *
   * @param {Question[]} questions
   */
  function addQuestions({ missingAliases = [], questions }) {
    const aliases = questions.reduce(
      (carry, { alias }) => ((carry[alias] = true), carry),
      {}
    )
    badAliases.value = {
      ...badAliases.value,
      ...missingAliases
        .filter((alias) => !aliases.hasOwnProperty(alias))
        .reduce((carry, alias) => ((carry[alias] = true), carry), {})
    }
    questions = applyQuestionCustomizations(questions);
    questionBank.value = [...questionBank.value, ...questions]
  }

  /**
   * apply active question customizations
   *
   * @param {Question[]} questions
   * @returns {BorrowerValue[]}
   */
  function applyQuestionCustomizations(questions = [])
  {
    return questions.filter(q => {
      const result = questionsHelper.filterByQuestionCustomizations(affiliateCustomizationStore, q)
      return result
    }).map(q => {
      return questionsHelper.applyQuestionCustomizations(affiliateCustomizationStore, q)
    })
  }

  /**
   * Retrieves the question definitions from api. Retrieves by alias or wholesale with the allFields flag
   * @param {?{ aliases: ?string[], allFields: ?boolean}} payload
   * @returns
   */
  async function getQuestions({ aliases = [], allFields = false } = {}) {
    // grab aliases without type overrides e.g. `alias` from `alias@type`
    aliases = aliases
      .map((a) => /^([^@]+)(?:@.*)?$/.exec(a)[1])
      .flatMap((a) => {
        if (!keyedQuestions.value.hasOwnProperty(a)) {
          return [a]
        }
        const question = keyedQuestions.value[a]
        if (question.fields) {
          if (question.dependsOn && question.dependsOn.includes('|')) {
            let fields = question.fields.map((f) =>
              typeof f == 'string' ? f : f.alias
            )
            let splitDependencies = question.dependsOn.split('|')
            let splitComma = splitDependencies.flatMap((a) => a.split(','))
            let filtered = [...new Set(splitComma)]
            return fields.concat(filtered.filter(Boolean))
          }
          return question.fields
            .map((f) => (typeof f == 'string' ? f : f.alias))
            .concat([question.dependsOn].filter(Boolean))
        } else if (question) {
          return [a, question.dependsOn].filter(Boolean)
        }
      })
    const missingAliases = aliases.filter(
      (alias) =>
        !keyedQuestions.value.hasOwnProperty(alias) &&
        !badAliases.value.hasOwnProperty(alias)
    )
    if (
      (!missingAliases.length && !allFields) ||
      (allFields && fullyLoadedBank.value)
    ) {
      await this.getAnswers({ aliases, allFields })
      loadingQuestions.value = false
      return keyedQuestions.value
    }
    let req
    if (missingAliases.length) {
      loadingQuestions.value = true
      req = $axios
        .get(`${env('apiUrl')}/questions/by-alias`, {
          params: { fields: missingAliases }
        })
        .then((response) => response.data.data)
        .catch(
          (err) => (console.error('Question retrieval failed', err), null)
        )
    } else if (allFields && !fullyLoadedBank.value) {
      loadingQuestions.value = true
      req = $axios
        .get(`${env('apiUrl')}/questions`)
        .then(
          (response) => ((fullyLoadedBank.value = true), response.data.data)
        )
        .catch(
          (err) => (console.error('Question retrieval failed', err), null)
        )
    }
    const [data] = await Promise.all([
      req,
      // pull persisted answers for returned questions
      this.getAnswers({ aliases, allFields })
    ])
    if (data && data.length) {
      this.addQuestions({ missingAliases, questions: data })
    }
    loadingQuestions.value = false
    return keyedQuestions.value
  }

  async function getQuestionFilters({ appName = 'marketplace' } = {}) {
    if (questionFilters.value[appName]) {
      return true
    }

    let data;
    if (getQuestionFiltersRequest.value) {
      data = await getQuestionFiltersRequest.value
    }

    else {
      getQuestionFiltersRequest.value = $axios
        .get(`${env('apiUrl')}/application/${appName}/filters`)
        .then((response) => {return get(response, 'data.data', get(response, 'data'))})
        .catch((err) => (console.error(err), { data: null }))

      data = await getQuestionFiltersRequest.value
      getQuestionFiltersRequest.value = null
    }

    this.setAppQuestionFilters(data)

    if (!data) return false
    questionFilters.value = questionFilters.value.concat(
      data.map((filter) => ({ ...filter, appName }))
    )

    return true
  }

  /**
   * Retrieves the answers from the legacy questions store.
   * @param {{}} payload
   * @returns
   */
  async function loadLegacyAnswers() {
    if (userStore.hasOptimusUser) {
      return await this.getAnswers({ allFields: true })
    }
    Object.keys(indexedAttributes.value)
      .filter((alias) =>
        indexedAttributes.value[alias].hasOwnProperty('value')
      )
      .forEach((alias) =>
        this.updateAnswer({
          alias,
          value: indexedAttributes.value[alias].value
        })
      )
    await this.saveAnswers()
  }

  // LEGACY ACTIONS

  /**
   * Make request for filter questions, submitting answerData if available.
   * Uses response to updates our index of questions, and track our current filter question aliases
   * @returns {Promise.<void>}
   */
  async function requestFilterQuestions(answerData, force = false) {
    let visitorId = null
    if (rootStore.pwfSignupInProgress) {
      if (!force) {
        return;
      }
      visitorId = rootStore.visitorId
    }

    try {
      // if we already have filter questions loaded, we just need to get the remaining questions
      const filterRes = filterQuestions.value.length
        ? await requestRemainingFilterQuestions(
            borrowerStore.borrowerId,
            answerData,
            $axios
          )
        : await requestInitialFilterQuestions(
            borrowerStore.borrowerId,
            answerData,
            $axios,
            visitorId
          )

      const data = get(filterRes, 'data')
      const status = get(filterRes, 'status')
      const errMsg = get(data, 'errors[0].message')

      if (errMsg) return Promise.reject(new Error(errMsg))
      if (status !== 200) return

      let loadedQuestionsIndexAttributes
      let _indexedAttributes
      if (singleForm.value) {
        const loadedQuestionsResponse = await loadQuestions(
          ['fundAspect'],
          $axios
        )
        loadedQuestionsIndexAttributes = keyBy(
          loadedQuestionsResponse.data.data,
          'alias'
        )
        _indexedAttributes = merge(
          keyBy(data.data, 'alias'),
          loadedQuestionsIndexAttributes
        )
      } else {
        _indexedAttributes = keyBy(data.data, 'alias')
      }

      this.mergeAttributes(_indexedAttributes)
      const filteredQuestions = union(
        Object.keys(_indexedAttributes),
        historicIds.value
      )
      filterQuestions.value = union(filterQuestions.value, filteredQuestions)

      if (rootStore.pwfSignupInProgress) {
        return
      }

      await this.requestPredictedCategories()
      this.updateMlWhitelist()
    } catch (err) {
      errorMsg.value = `Failed to get questions ${err.message}`
      throw err
    }
  }

  /**
   * Make request for info questions, update our index of questions, and track our current info question aliases
   * @returns {Promise.<void>}
   */
  async function requestInfoQuestions(force = false) {
    if (!force && rootStore.pwfSignupInProgress) {
      return
    }

    let visitorId = rootStore.pwfSignupInProgress ? rootStore.visitorId : null
    let url = `${env('apiUrl')}/matching/questions/${borrowerStore.borrowerId}/informational`

    if (visitorId) {
      url = `${env('apiUrl')}/visitor/${visitorId}/matching/questions/${borrowerStore.borrowerId}/informational`
    }

    await $axios
      .get(url)
      .then((res) => {
        const _indexedAttributes = keyBy(get(res, 'data.data'), 'alias')
        this.mergeAttributes(_indexedAttributes)
        infoQuestions.value = union(
          infoQuestions.value,
          Object.keys(_indexedAttributes)
        )
        this.requestPredictedCategories()
        this.updateMlWhitelist()
      })
      .catch((err) => {
        errorMsg.value = `Failed to get questions ${err.message}`
        throw err
      })
  }

  /**
   * Make request for document questions
   * @returns {Promise.DocumentR
   * // Check if documents can be skippedequirements}
   */
  async function requestDocumentQuestions() {

    let res = await $axios
      .get(
        `${env('apiUrl')}/matching/questions/${borrowerStore.borrowerId}/documents`
      )
      .catch((err) => {
        errorMsg.value = `Failed to get document questions: ${err.message}`
        throw err
      })
    return get(res, 'data.data')
  }

  /**
   * Get new list of predicted categories from ML endpoint
   */
  async function requestPredictedCategories() {
    if (!overallWhitelist.value.length) {
      let data;
      if (getQuestionFiltersRequest.value) {
        data = await getQuestionFiltersRequest.value
      }
      else {
        getQuestionFiltersRequest.value = this.requestApplicationQuestionFilters(
          'marketplace',
          $axios
        )
        data = await getQuestionFiltersRequest.value
      }
      getQuestionFiltersRequest.value = null
      this.setAppQuestionFilters(data)
    }
    const allowedAttributes = pick(
      indexedAttributes.value,
      overallWhitelist.value
    )
    const _predictedCategories =
      await machineLearningService.fetchFundingCategoryPredictionFromAttributes(
        env('apiUrl'),
        allowedAttributes
      )
    if (_predictedCategories) {
      predictedCategories.value = _predictedCategories
    }
  }

  /**
   * Update ML whitelist using predicted categories, along with our overall whitelist of questions
   */
  async function updateMlWhitelist() {

    if (!overallWhitelist.value.length) {
      let data
      if (getQuestionFiltersRequest.value) {
        data = await getQuestionFiltersRequest.value
      }
      else {
        getQuestionFiltersRequest.value = this.requestApplicationQuestionFilters(
          'marketplace',
          $axios
        )
        data = await getQuestionFiltersRequest.value
      }
      getQuestionFiltersRequest.value = null
      this.setAppQuestionFilters(data)
    }
    const allowedAttributes = pick(
      indexedAttributes.value,
      overallWhitelist.value
    )
    if (predictedCategories.value) {
      const highLikelihoodCategories =
        machineLearningService.getCategoriesAboveThreshold(
          0.1,
          predictedCategories.value.base_all
        )
      const filteredAttributes =
        machineLearningService.filterAttributesByAffectedCategories(
          allowedAttributes,
          highLikelihoodCategories,
          alwaysAskedAliases.value
        )
      return (mlWhitelist.value = Object.keys(filteredAttributes))
    }
    mlWhitelist.value = Object.keys(allowedAttributes)
  }

  /**
   * Post answers that we have in state
   * @param preserveInterest Prevents the post current borrower interest method from running and changing the user to marketplace
   */
  async function saveAnswersLegacy(preserveInterest = false) {
    if (
      useProgressStore().currentApp.type !== 'sba-complete'
      && !preserveInterest
      && get(rootStore, 'currentBorrowerInterest') !== 'marketplace'
    ) {
      const interest = 'marketplace'
      rootStore.postCurrentBorrowerInterest({ interest })
    }

    savingAnswers.value = true
    try {
      await this.requestFilterQuestions(Object.values(answers.value))
    } catch (err) {
      savingAnswers.value = false
      throw err
    }
    if (
      borrowerStore.borrowerId &&
      errorMsg.value === ''
    ) {
      // We don't need to perserve answers if we have a borrower.
      // Clearing answers prevents us from overriding borrower values if they get updated on the backend
      answers.value = {}
    }
    this.saveAnswersToStorage()
    savingAnswers.value = false
    mineral.value = getMineral(
      answers.value,
      borrowerStore.borrower
    )
  }

  async function updateMarketingOptIn(marketingSmsOptIn = false) {

    savingAnswers.value = true
    $axios
      .post(
        `${env('apiUrl')}/matching/questions/answers/${
          borrowerStore.borrowerId
        }`,
        JSON.stringify([
          { alias: 'marketingSmsOptIn', value: marketingSmsOptIn }
        ]),
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(async () => {
        await borrowerStore.getBorrower({
          forceReload: true
        })
      })
      .catch((err) => {
        console.log(err)
      })
    if (
      borrowerStore.borrowerId &&
      errorMsg.value === ''
    ) {
      // We don't need to perserve answers if we have a borrower.
      // Clearing answers prevents us from overriding borrower values if they get updated on the backend
      answers.value = {}
    }
    this.saveAnswersToStorage()
    savingAnswers.value = false
  }

  async function updateBorrowerMineral() {
    await borrowerStore.getBorrowerValues({ forceReload: true })
    const borrowerMineral = get(borrowerStore, 'borrower.mineralGroup')
    const _mineral = getMineral(
      {
        ...answers.value,
        ...Object.keys(currentAnswers.value).reduce(
          (carry, alias) =>
            (carry[alias] =
              ({ alias, value: currentAnswers.value[alias] }, carry)),
          {}
        )
      },
      borrowerStore
    )
    mineral.value = _mineral
    if (
      !borrowerMineral ||
      !_mineral ||
      borrowerMineral.toLocaleLowerCase() !== _mineral.toLocaleLowerCase()
    ) {
      $axios
        .put(
          `${env('apiUrl')}/borrower/${borrowerStore.borrowerId}/`,
          JSON.stringify({ mineralGroup: _mineral }),
          { headers: { 'Content-Type': 'application/json' } }
        )
        .then(async () => {
          await borrowerStore.getBorrower({ forceReload: true })
          await borrowerStore.getBorrowerValues({ forceReload: true })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  function setBasicInfoQuestionAttributes() {
    singleForm.value &&
    visiblePages.value.forEach((page) => {
      if (
        page.attributes &&
        page.attributes.length > 0 &&
        page.directiveMapping
      ) {
        page.attributes[0].name = page.directiveMapping.label
      }
      if (page.attributes && page.attributes.length > 0) {
        page.attributes[0].required = true
      }
    })
  }

  /**
   * Update local answers, and post answers
   * @param answers
   */
  async function submitFilterAnswers({ answers: _answers }) {
    // track page view
    this.viewedBasicInfoPage([currentPage.value.id])
    // save answers
    this.mergeAnswers(keyBy(_answers, 'alias'))
    !singleForm.value && (await this.saveAnswersLegacy())
    singleForm.value && this.saveAnswersToStorage()
    // recalculate pages
    this.recalculatePages()
    this.recalculateVisiblePages()
    singleForm.value && this.setBasicInfoQuestionAttributes()
  }

  /**
   * Update renewal answers
   * THIS MAY NOT BE NECESSARY
   * @param answers
   */
  async function submitRenewalAnswers({ answers: _answers }) {
    // track page view
    this.viewedBasicInfoPage([currentPage.value.id])
    // save answers
    this.mergeAnswers(keyBy(_answers, 'alias'))
    await this.saveAnswersLegacy()
    // recalculate renewal pages
    this.recalculateRenewalVisiblePages()
  }

  /**
   * Setup initial state for submit page
   */
  async function initializeSubmitPage() {
    loadingQuestions.value = true

    // load answers from history
    this.getAnswersFromStorage()
    this.getHistory()
    // load all questions
    await Promise.all([
      this.requestFilterQuestions(),
      this.requestInfoQuestions()
    ])
    loadingQuestions.value = false
  }

  /**
   * Setup initial state for info pages
   * THIS MAY NOT BE NECESSARY
   */
  async function initializeRenewalInfoPage() {
    loadingQuestions.value = true

    // load info questions
    await Promise.all([
      this.requestFilterQuestions(),
      this.requestInfoQuestions()
    ])
    loadingQuestions.value = false
  }

  /**
   * Setup initial state for info pages
   */
  async function initializeInfoPage({ forceReload = false } = {}) {
    if (loadingQuestions.value) {
      return
    }

    if (rootStore.pwfSignupInProgress) {
      if (!forceReload) return
    }

    loadingQuestions.value = true

    // load answers from history
    this.getAnswersFromStorage()
    this.getHistory()
    // load info questions
    await Promise.all([
      this.requestFilterQuestions(),
      this.requestInfoQuestions()
    ])
    loadingQuestions.value = false
  }

  /**
   * Renewals
   */

  async function initializeRenewalInfoPages({ route }) {

    loadingQuestions.value = true

    // load route params into answers
    const routeAnswers = keyBy(initializeRouteParamAnswers(route), 'alias')

    if (routeAnswers.business_start_date) {
      // Force the business start date question to show up with a prefilled answer
      questionsHelper.trackHistory(
        'basic-info',
        ['business_start_date'],
        borrowerStore.borrowerId
      )
    }

    this.mergeAnswers(routeAnswers)
    // load answers from history
    this.getAnswersFromStorage()
    this.getHistory()
    // submit our values to get updated affected products
    const preserveInterest = true
    await this.saveAnswersLegacy(preserveInterest)

    // submit our values to get updated affected products
    if (borrowerStore.borrowerId) {
      await this.requestInfoQuestions()
    }

    // recalculate pages
    await this.recalculatePages()
    await this.recalculateRenewalVisiblePages()
    // go to the first page that we should be going to (based on history, etc)
    this.setRenewalPage()
    // track page view
    // this.viewedBasicInfoPage([currentPage.value.id])

    loadingQuestions.value = false
  }

  /**
   * Setup initial state for basic info page
   */
  async function initializeBasicInfoPages({ route }) {

    loadingQuestions.value = true

    // load route params into answers
    const routeAnswers = keyBy(initializeRouteParamAnswers(route), 'alias')

    if (routeAnswers.business_start_date) {
      // Force the business start date question to show up with a prefilled answer
      questionsHelper.trackHistory(
        'basic-info',
        ['business_start_date'],
        borrowerStore.borrowerId
      )
    }

    this.mergeAnswers(routeAnswers)
    // load answers from history
    this.getAnswersFromStorage()
    this.getHistory()
    // submit our values to get updated affected products
    const preserveInterest = true
    await this.saveAnswersLegacy(preserveInterest)
    // submit our values to get updated affected products
    if (borrowerStore.borrowerId) {
      await this.requestInfoQuestions()
    }
    // recalculate pages
    await this.recalculatePages()
    this.recalculateVisiblePages()
    // go to the first page that we should be going to (based on history, etc)
    this.setFirstPage(visiblePages.value)
    // track page view
    this.viewedBasicInfoPage([currentPage.value.id])
    if (singleForm.value) {
      this.setBasicInfoQuestionAttributes()
    }
    loadingQuestions.value = false
  }

  /**
   * Group questions into pages, add any "special" pages, and sort by relevance
   */
  async function recalculatePages() {
    let _pages = buildPages(
      omit(pick(indexedAttributes.value, mlWhitelist.value), pageBlacklist.value)
    )

    // map filter questions to page ids
    const allowedFilterPages = filterQuestions.value
      .map((alias) => questionsHelper.getPageId(alias))

    _pages = _pages.filter((page) => includes(allowedFilterPages, page.id))

    const fundAspectAttribute = {
      id: 764,
      alias: 'fundAspect',
      type: 'select'
    }

    let specialPages = [
      {
        id: 'lendioIndustry',
        specialOrder: -6,
        mostAffectedProducts: 1,
        attributes: []
      },
      {
        id: 'financeAmount',
        specialOrder: -5,
        mostAffectedProducts: 1,
        attributes: []
      },
      {
        id: 'creditScore',
        specialOrder: -4,
        mostAffectedProducts: 1,
        attributes: []
      },
      {
        id: 'business_start_date',
        specialOrder: -3,
        mostAffectedProducts: 1,
        attributes: []
      },
      {
        id: 'average_monthly_sales',
        specialOrder: -2,
        mostAffectedProducts: 1,
        attributes: []
      },
      {
        id: 'maxWeeksToFunds',
        specialOrder: 100,
        mostAffectedProducts: 1,
        attributes: []
      },
      {
        id: 'fundAspect',
        specialOrder: 99,
        mostAffectedProducts: 1,
        directiveMapping: getDirectiveMapping(fundAspectAttribute),
        attributes: [fundAspectAttribute]
      }
    ]

    // Insert special pages
    if (specialPages.length) {
      specialPages.forEach((specialPage) => {
        const existingPageIndex = _pages.findIndex(
          (page) => page.id === specialPage.id
        )
        if (existingPageIndex >= 0) {
          const existingPage = _pages[existingPageIndex]
          const mergedPage = {
            ...specialPage,
            ...{
              ...existingPage,
              ...{ attributes: existingPage.attributes }
            }
          }
          _pages = [
            ..._pages.slice(0, existingPageIndex),
            mergedPage,
            ..._pages.slice(existingPageIndex + 1)
          ]
        } else {
          _pages = [..._pages, specialPage]
        }
      })
    }

    // filter out some stuff for sba-complete
    if (useProgressStore().currentApp.type === 'sba-complete') {
      _pages = _pages.filter(page => {
        return [
          'fundAspect',
          'maxWeeksToFunds',
        ].includes(page.id) === false
      })
    }

    pages.value = sortPages(_pages, historicIds.value)
  }

  /**
   * Filter pages down to only the ones that are in history, relevant, and dependency satisfied
   */
  function recalculateVisiblePages() {
    const borrowerValues = borrowerStore.borrowerValues
    const borrower = borrowerStore.borrower
    const askMineralQuestions =
      !borrower ||
      borrower.applicationComplete === null
        ? true
        : false

    let _visiblePages = pages.value.filter((page) => {
      return isVisibleBasicInfoPage(
        page,
        indexedAttributes.value,
        historicIds.value,
        askMineralQuestions,
        borrowerValues,
        false,
        singleForm.value
      )
    })

    if (questionReduction.value) { // deprecated?
      const reducedQuestions = [
        'financeAmount',
        'creditScore',
        'business_start_date',
        'average_monthly_sales',
        'lendioIndustry',
        'fundAspect'
      ]
      _visiblePages = _visiblePages.filter((question) => {
        return reducedQuestions.includes(question.id)
      })
    }

    visiblePages.value = _visiblePages
  }

  /**
   * Filter pages down to only the ones that are in history, relevant, and dependency satisfied
   */
  function recalculateRenewalVisiblePages() {
    const _visiblePages = pages.value.filter((page) => {
      return isRenewalVisiblePage(
        page,
        indexedAttributes.value,
        historicIds.value
      )
    })
    visiblePages.value = _visiblePages
  }

  /**
   * Go to next visible page
   * For renewals only
   */
  async function renewalNextPage() {
    let filterAlias = visiblePages.value.filter(function (page) {
      return page.id === 'fundAspect' || page.id === 'loanPurpose'
    })

    let loanPurpose = filterAlias[0]
    let fundAspect = filterAlias[1]

    if (currentPage.value.id === 'financeAmount') {
      return (currentPage.value = fundAspect)
    }
    if (currentPage.value.id === 'fundAspect') {
      return (currentPage.value = loanPurpose)
    }
    if (currentPage.value.id === 'loanPurpose') {
      $router.push('/renewal/documents')
    }
  }

  /**
   * Go to next visible page
   * For renewals only
   */
  async function renewalPreviousPage() {
    let filterAlias = visiblePages.value.filter(function (page) {
      return page.id === 'financeAmount' || page.id === 'fundAspect'
    })

    let financeAmount = filterAlias[0]
    let fundAspect = filterAlias[1]

    if (currentPage.value.id === 'loanPurpose') {
      return (currentPage.value = fundAspect)
    }
    if (currentPage.value.id === 'fundAspect') {
      return (currentPage.value = financeAmount)
    }
  }

  /**
   * Go to next visible page
   */
  function nextPage() {
    const _currentPageIndex = visiblePages.value.findIndex(
      (page) => page.id === currentPage.value.id
    )
    const nextPage = visiblePages.value[_currentPageIndex + 1]
    if (nextPage) {
      currentPage.value = nextPage
    }
  }

  /**
   * Go to previous visible page
   */
  function previousPage() {
    const _currentPageIndex = visiblePages.value.findIndex(
      (page) => page.id === currentPage.value.id
    )
    const nextPage = visiblePages.value[_currentPageIndex - 1]
    if (nextPage) {
      currentPage.value = nextPage
    }
  }

  /**
   * Get history and save it to state
   */
  function getHistory() {
    const history = questionsHelper.getHistory(
      'basic-info',
      borrowerStore.borrowerId
    )
    historicIds.value = history
    if (process.client) {
      historyLoaded.value = true
    }
  }

  /**
   * Take all visible pages, and determine the first one we should show on page load
   * @param visiblePages
   */
  function setFirstPage(_visiblePages) {
    let _currentPage
    // If there's history, get the last historic question
    if (historicIds.value.length) {
      let lastHistoricId = last(historicIds.value)
      _currentPage = _visiblePages.find((page) => {
        return page.id === lastHistoricId
      })
    }
    if (!_currentPage) {
      _currentPage = first(_visiblePages)
    }
    currentPage.value = _currentPage
  }

  /**
   * Makes id = 0 first page
   * Renewals only
   * @param pages
   */
  function setRenewalPage() {
    const _pages = [...pages.value]
    let _currentPage
    _currentPage = _pages.find((page) => {
      return page.id === 'financeAmount'
    })
    currentPage.value = _currentPage
  }

  /**
   * Track viewing a page in history
   */
  function viewedBasicInfoPage(pageIds) {

    questionsHelper.trackHistory(
      'basic-info',
      pageIds,
      borrowerStore.borrowerId
    )
    this.getHistory()
  }

  /**
   * Save answers from state into localStorage
   */
  function saveAnswersToStorage() {
    questionsHelper.setStorageItem('borrowerAnswers', null, answers.value)
  }

  /**
   * Pull answers from localStorage and set them into state
   */
  function getAnswersFromStorage() {
    const storageAnswers = questionsHelper.getStorageItem(
      'borrowerAnswers',
      null
    )
    const _infoAnswers = questionsHelper.getStorageItem('infoAnswers', null)
    // set answers in store
    if (storageAnswers) {
      this.mergeAnswers(storageAnswers)
    }

    if (_infoAnswers) {
      infoAnswers.value = _infoAnswers
      this.mergeAnswers(_infoAnswers)
    }
  }

  function mergeAttributes(newAttributes) {
    let adjustedAttributes = Object.keys(newAttributes).map((alias) => {
      const adjusted = { ...newAttributes[alias] }
      switch (adjusted.alias) {
        case 'business_start_date':
          adjusted.type = 'date-month-year'
      }
      switch (adjusted.type) {
        case 'date-month-year':
        case 'date-input':
          adjusted.value = /^(.+)T/.exec(adjusted.value)
            ? /^(.+)T/.exec(adjusted.value)[1]
            : adjusted.value
      }
      return adjusted
    })

    adjustedAttributes = applyQuestionCustomizations(adjustedAttributes)
    indexedAttributes.value = Object.assign(
      {},
      merge({}, indexedAttributes.value, keyBy(adjustedAttributes, 'alias'))
    )
  }

  function setAppQuestionFilters(payload) {
    overallWhitelist.value = payload.map((filter) => filter.fieldAlias)
    pageBlacklist.value = payload
      .filter((filter) => filter.afterAuth)
      .map((filter) => filter.fieldAlias)
    pageBlacklist.value = [...pageBlacklist.value, manualBasicInfoExclusionList]
    alwaysAskedAliases.value = payload
      .filter((filter) => filter.alwaysAsked)
      .map((filter) => filter.fieldAlias)
  }

  function mergeAnswers(newAnswers) {
    indexedAttributes.value = Object.assign(
      {},
      merge({}, indexedAttributes.value, newAnswers)
    )
    answers.value = merge({}, answers.value, newAnswers)
  }

  return {
    // STATE
    fullyLoadedBank,
    questionBank,
    badAliases,
    stateAnswers,
    savingAnswers,
    loadingQuestions,
    persistedAnswers,
    questionFilters,
    filterQuestionsInitialized,
    indexedAttributes,
    answers,
    infoAnswers,
    mineral,
    pages,
    visiblePages,
    infoQuestions,
    filterQuestions,
    mlWhitelist,
    predictedCategories,
    historicIds,
    currentPage,
    errorMsg,
    historyLoaded,
    overallWhitelist,
    pageBlacklist,
    alwaysAskedAliases,
    singleForm, // Deprecated?
    questionReduction, // Deprecated?

    // GETTERS
    currentAnswers,
    unsavedAnswers,
    savedAnswers,
    areAnswersDirty,
    keyedQuestions,
    componentQuestions,
    appQuestionFilters,
    informationalQuestions,
    hasQuestions,
    displayableQuestions,
    ownerInfoQuestions,
    ownerCreditQuestions,
    businessFinancialQuestions,
    businessInfoQuestions,
    additionalQuestions,
    reviewQuestions,
    profileQuestions,
    numPages,
    currentPageIndex,
    isFirstPage,
    isCurrentPageReady,
    isLastPage,

    // ACTIONS
    updateAnswer,
    bulkUpdatePersistedAnswers,
    clearStateAnswers,
    addQuestions,
    setBasicInfoQuestionAttributes,
    recalculateVisiblePages,
    recalculateRenewalVisiblePages,
    nextPage,
    previousPage,
    getHistory,
    setFirstPage,
    setRenewalPage,
    viewedBasicInfoPage,
    saveAnswersToStorage,
    getAnswers,
    getAnswersFromStorage,
    mergeAttributes,
    setAppQuestionFilters,
    mergeAnswers,
    requestApplicationQuestionFilters,
    saveAnswers,
    getQuestions,
    getQuestionFilters,
    loadLegacyAnswers,
    requestFilterQuestions,
    requestInfoQuestions,
    requestDocumentQuestions,
    requestPredictedCategories,
    updateMlWhitelist,
    saveAnswersLegacy,
    updateMarketingOptIn,
    updateBorrowerMineral,
    submitFilterAnswers,
    submitRenewalAnswers,
    initializeSubmitPage,
    initializeRenewalInfoPage,
    initializeInfoPage,
    initializeRenewalInfoPages,
    initializeBasicInfoPages,
    recalculatePages,
    renewalNextPage,
    renewalPreviousPage,
    requestInitialFilterQuestions,

  }
})
