import env from '~/libs/env'

export default {
    createBorrower(data, postUrl = '/borrower', returnToken = null, experience, $axios, tenantId) {
            // Do not use option ids for the post
        data.useOptions = false
        data.append('returnToken', returnToken)
        data.append('jobifyAttribution', true)
        data.append('tenantId', tenantId)

        if (!data.get('interest') || data.get('interest') === null) {
            data.append('interest', 'marketplace')
        }
        return $axios.post(`${env('apiUrl')}${postUrl}`, data)
            .then(res => {
                if (res.data.email) {
                    return res
                }

                let autoLoginUrl = null
                let borrowerId = null
                let borrower = null
                let lendioJwt = null
                if (res.data && res.data.data) {
                    autoLoginUrl = res.data.data.redirectUrl || null
                    borrower = res.data.data.borrower || null
                    borrowerId = res.data.data.borrower.id || null
                    lendioJwt = res.data.data.lendioJwt || null
                }
                if (!autoLoginUrl) {
                    throw new Error('Redirect url is undefined')
                }
                return { autoLoginUrl, borrower, borrowerId, lendioJwt }
            })
    }
}
