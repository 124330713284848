import validate from "/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import _010_45external_45redirects_45global from "/frontend/middleware/010.external-redirects.global.js";
import _011_45pre_45redirect_45global from "/frontend/middleware/011.pre-redirect.global.js";
import _012_45redirect_45to_45login_45global from "/frontend/middleware/012.redirect-to-login.global.js";
import _015_45legacy_45redirects_45global from "/frontend/middleware/015.legacy-redirects.global.js";
import _018_45embedded_45access_45control_45global from "/frontend/middleware/018.embedded-access-control.global.js";
import _020_45pusher_45global from "/frontend/middleware/020.pusher.global.js";
import _030_45business_45credit_45cards_45global from "/frontend/middleware/030.business-credit-cards.global.js";
import _040_45auth_45global from "/frontend/middleware/040.auth.global.js";
import _050_45headers_45global from "/frontend/middleware/050.headers.global.js";
import _070_45cobrand_45global from "/frontend/middleware/070.cobrand.global.js";
import _080_45user_45global from "/frontend/middleware/080.user.global.js";
import _090_45token_45expiration_45global from "/frontend/middleware/090.token-expiration.global.js";
export const globalMiddleware = [
  validate,
  _010_45external_45redirects_45global,
  _011_45pre_45redirect_45global,
  _012_45redirect_45to_45login_45global,
  _015_45legacy_45redirects_45global,
  _018_45embedded_45access_45control_45global,
  _020_45pusher_45global,
  _030_45business_45credit_45cards_45global,
  _040_45auth_45global,
  _050_45headers_45global,
  _070_45cobrand_45global,
  _080_45user_45global,
  _090_45token_45expiration_45global
]
export const namedMiddleware = {
  "app-config-page": () => import("/frontend/middleware/app-config-page.js"),
  "basic-info": () => import("/frontend/middleware/basic-info.js"),
  "check-403": () => import("/frontend/middleware/check-403.js"),
  "embedded-page": () => import("/frontend/middleware/embedded-page.js"),
  login: () => import("/frontend/middleware/login.js"),
  "resume-app": () => import("/frontend/middleware/resume-app.js"),
  "transitions-helper": () => import("/frontend/middleware/transitions-helper.js")
}