const ownersTemplate = {
  text: 'Owner',
  type: 'contacts',
  columnSize: 4,
  questions: [
      {
          "labelId": "owners.first",
          id: undefined,
          alias: 'owners.first',
          name: 'First Name',
          value: '',
          type: 'string'
      },
      {
          "labelId": "owners.last",
          id: undefined,
          alias: 'owners.last',
          name: 'Last Name',
          type: 'string',
          value: ''
      },
      {
          "labelId": "owners.email",
          id: undefined,
          alias: 'owners.email',
          name: 'Email',
          type: 'email',
          value: ''
      },
      {
          "labelId": "owners.percentOwnership",
          id: undefined,
          alias: 'owners.percentOwnership',
          name: '% of Ownership',
          type: 'percent',
          value: ''
      }
  ],
}

export { ownersTemplate }
