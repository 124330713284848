import flatten from 'lodash/fp/flatten.js'
import forOwn from 'lodash/forOwn.js'
import any from 'lodash/some.js'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy.js'
import jsonHelper from './json-helper'
import keyBy from 'lodash/keyBy.js'
import includes from 'lodash/includes.js'
import map from 'lodash/map.js'
import filter from 'lodash/filter.js'
import set from 'lodash/set.js'
import { localStorageService } from './local-storage.service'

export default {
  // blacklist ssn because if it is set, we don't get a return value later to populate form value
  blacklist: ['contact.ssn', 'federalstate_tax_id'],

  // Any questions you don't want to be filtered out by affected products
  // This way they can still be filtered by being already answered but not in history
  // Sort of a less strict 'always ask these questions'
  ignoresAffectedProductsList: ['lendioIndustry'],
  /**
   * Customizable borrowerAttribute / question values.  Keyed by question.alias, this
   * list allows customization values (registered within this object) to be applied
   * to a question object, overriding the default.  
   *
   * The "customizations" array is a list of objects with:
   *  - a 'customization' property -- a string value of the AffiliateCustomizations store getter (required) 
   * that returns your override value and 
   * - an 'overrideKey' property -- a string value of the "question" object property that you're
   * applying your override value to.
   * 
   * The "actions" object is keyed by an action verb whose value is the AffiliateCustomizations store getter (required)
   * that returns a boolean as to whether the action should be applied.  Currently, the only action verb active
   * is "remove" and can only be applied from the customization store when registered to a question here:
   */
  customizeableQuestionProperties: {
    authorizedOwner: {
      customizations: [
        {
          customization: 'authorizedOwnerTooltip',
          overrideKey: 'helpText'
        },
        {
          customization: 'authorizedOwnerName',
          overrideKey: 'name'
        }
      ]
    },
    percentOwnership: {
      customizations: [ 
        {
          customization: 'ownershipPercentageTooltip',
          overrideKey: 'helpText'
        }
      ]
    },
    beneficiaryOwners: {
      customizations: [
        {
          customization: 'beneficialOwnersTooltip',
          overrideKey: 'helpText'
        }
      ]
    },
    managingPartners: {
      customizations: [
        {
          customization: 'managingPartnersTooltip',
          overrideKey: 'helpText'
        },
        {
          customization: 'managingPartnersName',
          overrideKey: 'name'
        }
      ]
    },
    financeAmount: {
      actions: {
        remove: { customization: 'removeFinanceAmountQuestion' }
      }
    }
  },

  dependencySatisfied(question, lookup) {
    // True if there is no dependency
    if (!question.dependsOn) {
      return true
    }

    // lookup should be indexed by alias
    if (Array.isArray(lookup)) {
      lookup = keyBy(lookup, 'alias')
    }

    let dependency = lookup[question.dependsOn]
    // Add case for 'businessType' when 'entity_type' is depended on and not found
    if (!dependency && question.dependsOn === 'entity_type') {
      dependency = lookup['businessType']
    }
    if (!dependency) {
      // if the api didn't return the dependency we just won't ask the question
      // hopefully this won't happen, but we can't halt the user, show must go on.
      // console.log('could not find dependency ' + question.dependsOn + ' for ' + question.alias);
      return false
    }

    // make sure true/false is 1/0 for dependency expressions
    var value = dependency.value
    if (dependency.type === 'bool' && typeof value !== 'undefined') {
      value = +value // type cast boolean to to 1/0
    }

    return (
      this.isAnswered(dependency) &&
      new RegExp(question.dependsExpression, 'i').test(value) &&
      this.dependencySatisfied(dependency, lookup)
    )
  },
  getPageId(alias) {
    if (!alias) {
      throw new Error('Alias cannot be undefined!')
    }
    if (this.inMultiBoolQuestion(alias)) {
      return 'about_your_business'
    }
    if (this.inArAgingQuestion(alias)) {
      return 'ar_aging_questions'
    }
    return alias
  },
  inArAgingQuestion(question) {
    if (!question) {
      return false
    }

    let agingAliases = []

    return (
      includes(agingAliases, question.alias) || includes(agingAliases, question)
    )
  },
  inMultiBoolQuestion(question) {
    if (!question) {
      return false
    }

    let multiBoolAliases = [
      'hasInvoices',
      'nonprofit',
      'bankruptcy',
      'franchise',
    ]
    return (
      includes(multiBoolAliases, question.alias) ||
      includes(multiBoolAliases, question)
    )
  },
  isAnswered(question) {
    if (
      question.value !== null &&
      typeof question.value === 'object' &&
      question.type === 'address'
    ) {
      let returnValue = true
      forOwn(question.value, (val, key) => {
        if (
          key === 'street' ||
          key === 'zipId' ||
          key === 'city' ||
          key === 'stateId'
        ) {
          if (val === undefined || val === null || val === '') {
            returnValue = false
          }
        }
      }).value()
      return returnValue
    }
    return (
      question.value !== undefined &&
      question.value !== null &&
      question.value !== ''
    )
  },
  simpleIsAnswered(question) {
    return (
      question.value !== undefined &&
      question.value !== null &&
      question.value !== ''
    )
  },
  ignoresAffectedProducts(question) {
    return includes(this.ignoresAffectedProductsList, question.alias)
  },
  inBlacklist(question) {
    return includes(this.blacklist, question.alias)
  },
  inHistory(key, alias, borrowerId = '') {
    return (
      !!alias &&
      this.getHistory(key, borrowerId).indexOf(this.getPageId(alias)) !== -1
    )
  },
  isDocument(question) {
    return question.type === 'document'
  },
  isOptional(question) {
    return question.mustAllowNull === true
  },
  isRelevantBasicInfoQuestion(
    questions,
    question,
    includePotential = false,
    includeAnswered = false,
    singleForm = false
  ) {
    if (typeof question.alias === 'undefined') return false

    // same as info
    if (this.inBlacklist(question)) {
      return false
    }
    if (this.isDocument(question)) {
      return false
    }
    if (this.isOptional(question)) {
      return false
    }
    if (!includePotential) {
      if (!question.group && !this.dependencySatisfied(question, questions)) {
        return false
      }
    }

    if (singleForm) {
      if (!includePotential) {
        if (!this.dependencySatisfied(question, questions)) {
          return false
        }
      }
      if (
        !this.ignoresAffectedProducts(question) &&
        question.affectedProducts === 0
      ) {
        return false
      }
      return true
    }

    // differences from info ...
    // answered questions only filtered out when not in a group, to keep the group whole
    if (!includeAnswered) {
      if (this.isAnswered(question)) {
        return false
      }
    }
    // don't worry about keeping groups whole
    if (!includePotential) {
      if (!this.dependencySatisfied(question, questions)) {
        return false
      }
    }

    // questions affecting no products filtered out
    // unless it is ignored from the affected products list
    if (
      !this.ignoresAffectedProducts(question) &&
      question.affectedProducts === 0
    ) {
      return false
    }

    // but both routes return true otherwise
    return true
  },
  findNextRelevantQuestion(state, initialIndex = 0, ignoreAnswered = false) {
    for (let i = initialIndex; i < state.borrowerQuestions.length; i++) {
      // Check attributes on the page for relevancy
      const relevantAttrs = state.pages[i].attributes.map((attr) => {
        if (ignoreAnswered) {
          return this.isRelevantBasicInfoQuestion(
            state.borrowerQuestions,
            attr,
            false,
            true
          )
        } else {
          return this.isRelevantBasicInfoQuestion(state.borrowerQuestions, attr)
        }
      })
      const unansweredOrIgnored = ignoreAnswered || !state.pages[i].answered
      if (unansweredOrIgnored && any(relevantAttrs)) {
        // Set the current question to be that relevant and unanswered question
        return state.pages[i]
      }
    }
  },
  findNextRelevantHistoricQuestion(state, historicIds) {
    let nextHistoricPage
    historicIds.find((pageId) => {
      const potentialHistoricPage = state.pages.find((page) => {
        return page.id === pageId
      })

      if (
        potentialHistoricPage &&
        potentialHistoricPage.id &&
        potentialHistoricPage.attributes.length > 0
      ) {
        if (!state.dependencies[potentialHistoricPage.id]) {
          // If the potential page is not a dependency, its good to go
          nextHistoricPage = potentialHistoricPage
          return true
        } else {
          // If the potential page is one of the dependencies, check relevancy first
          const relevantAttrs = potentialHistoricPage.attributes.map((attr) => {
            return this.isRelevantBasicInfoQuestion(
              state.borrowerQuestions,
              attr,
              false,
              true
            )
          })

          if (any(relevantAttrs)) {
            nextHistoricPage = potentialHistoricPage
            return true
          }
        }
      }

      return false
    })

    return nextHistoricPage
  },
  getStorageItem(key, borrowerId) {
    borrowerId = borrowerId || ''

    let storageItem = localStorageService.getItem(key + borrowerId)
    if (!storageItem) {
      storageItem = localStorageService.getItem(key)
      if (storageItem && borrowerId) {
        localStorageService.setItem(key + borrowerId, storageItem)
        localStorageService.removeItem(key)
      }
    }

    if (jsonHelper.isJsonString(storageItem)) {
      return JSON.parse(storageItem)
    }
  },
  setStorageItem(key, borrowerId, data) {
    borrowerId = borrowerId || ''

    const storage = JSON.stringify(data)
    localStorageService.setItem(key + borrowerId, storage)
  },
  getHistory(key, borrowerId) {
    let localHistory = get(
      this.getStorageItem('questionHistory', borrowerId),
      key,
      []
    )
    if (localHistory.length > 0) {
      return localHistory
    }

    return []
  },
  removeQuestionHistory(borrowerId) {
    borrowerId = borrowerId || ''

    localStorageService.removeItem('questionHistory' + borrowerId)
  },
  trackHistory(key, pageIds, borrowerId) {
    if (!key || pageIds.length === 0) {
      return
    }
    borrowerId = borrowerId || ''
    let history = this.getHistory(key, borrowerId)
    pageIds.forEach((pageId) => {
      if (history.indexOf(pageId) === -1) {
        history.push(pageId)
      }
    })

    const oldHistoryRaw = localStorageService.getItem(
      'questionHistory' + borrowerId
    )
    const oldHistory = jsonHelper.isJsonString(oldHistoryRaw)
      ? JSON.parse(oldHistoryRaw) || {}
      : {}
    const newHistory = set(oldHistory, key, history)

    this.setStorageItem('questionHistory', borrowerId, newHistory)
    return get(newHistory, key, [])
  },
  groupQuestions(questions) {
    return map(
      groupBy(questions, (questions) => questions.group || questions.alias),
      (questions, name) => {
        return {
          name,
          components: questions.sort((a, b) => {
            return a.groupOrder - b.groupOrder
          })
        }
      }
    )
  },
  ungroupQuestions(groups) {
    return flatten(groups.map((group) => group.components))
  },
  extractGroup(questions, group) {
    return questions
      .filter((question) => question.group === group)
      .sort((a, b) => (a.groupOrder < b.groupOrder ? -1 : 1))
  },
  groupAddressAttributes(questions) {
    let filteredQuestions = questions.filter((question) => {
      switch (question.group) {
        case 'ownerAddress':
        case 'businessAddress':
          return false
        default:
          return true
      }
    })

    const ownerComponents = this.extractGroup(questions, 'ownerAddress')
    if (ownerComponents.length >= 4) {
      filteredQuestions = filteredQuestions.concat({
        type: 'address',
        id: 'ownerAddress',
        alias: 'ownerAddress',
        name: 'Owner Address',
        appSection: 'personalInfo',
        components: ownerComponents
      })
    }

    const businessComponents = this.extractGroup(questions, 'businessAddress')
    if (businessComponents.length >= 4) {
      filteredQuestions = filteredQuestions.concat({
        type: 'address',
        id: 'businessAddress',
        alias: 'businessAddress',
        name: 'Business Address',
        appSection: 'businessInfo',
        components: businessComponents
      })
    }

    return filteredQuestions
  },
  ungroupAddressAttributes(questions = []) {
    let filteredQuestions = questions.filter((question) => {
      switch (question.type) {
        case 'ownerAddress':
        case 'businessAddress':
          return false
        default:
          return true
      }
    })

    let ownerAddress = questions.find((q) => q.alias === 'ownerAddress')
    if (ownerAddress) {
      filteredQuestions = filteredQuestions.concat(ownerAddress.components)
    }

    let businessAddress = questions.find((q) => q.alias === 'businessAddress')
    if (businessAddress) {
      filteredQuestions = filteredQuestions.concat(businessAddress.components)
    }

    return filteredQuestions
  },
  isMainSignupQuestion(alias) {
    switch (alias) {
      case 'creditScore':
      case 'timeInBusiness':
      case 'financeAmount':
      case 'average_monthly_sales':
      case 'user.first':
      case 'user.last':
      case 'user.email':
      case 'borrower.name':
      case 'borrower.phone':
        return true
      default:
        return false
    }
  },
  isRelevantInfoQuestion(lookup, question, historicIds) {
    if (this.isDocument(question)) {
      return false
    }
    if (this.isOptional(question)) {
      return false
    }
    if (!this.isSatisfied(lookup, question, historicIds)) {
      return false
    }
    if (includes(historicIds, this.getPageId(question.alias))) {
      return false
    }
    return true
  },
  isRelevantInfoQuestionWithOptional(lookup, question, historicIds) {
    if (this.isDocument(question)) {
      return false
    }
    if (!this.isSatisfied(lookup, question, historicIds)) {
      return false
    }
    if (includes(historicIds, this.getPageId(question.alias))) {
      return false
    }
    return true
  },
  isSatisfied(lookup, question, historicIds) {
    if (!this.isRelevantGroup(lookup, question, historicIds)) {
      return false
    }
    return this.dependencySatisfied(question, lookup)
  },
  isRelevantGroup(lookup, question, historicIds) {
    let group = question.group

    if (!group) {
      return true
    }

    let groupArray = filter(lookup, (q) => {
      return q.group === question.group
    })

    if (groupArray.length < 1) {
      return false
    }

    // if any questions are missing from history, and dependency satisifed, it's relevant
    if (
      any(groupArray, (q) => {
        return (
          this.dependencySatisfied(q, lookup) &&
          !includes(historicIds, question.alias)
        )
      })
    ) {
      return true
    } else {
      return false
    }
  },
  // Takes a question object and, if a borrowerAttributeOverride customization is 
  // active for the question, overrides the default value with the customization value.
  applyQuestionCustomizations(customizationStore, question) {
    const customizableQuestionAliases = Object.keys(this.customizeableQuestionProperties)
    if (includes(customizableQuestionAliases, question.alias)) {
      const customizationInstructions = get(this.customizeableQuestionProperties, `${question.alias}.customizations`, null)

      if (Array.isArray(customizationInstructions)) {
        customizationInstructions.map(i => {
          // No getter, no customization
          const activeCustomization = customizationStore[i.customization]
          if (activeCustomization) {
            question[i.overrideKey] = activeCustomization
          }
        })
      }
    }

    return question
  },

  // Takes a question object and, if a borrowerAttributeOverride customization
  // has an action type "remove" set for the question, filters it from the questions store.
  filterByQuestionCustomizations(customizationStore, question) {
    // Let's enforce registration of the question here in addition to the affiliateCustomization.
    const customizableQuestionAliases = Object.keys(this.customizeableQuestionProperties)
    if (includes(customizableQuestionAliases, question.alias)) {
      const customizationActions = get(this.customizeableQuestionProperties, `${question.alias}.actions`, null)

      if (customizationActions) {
      // Has the action been registered for the question / attribute in question? If naw, then it's an automatic keeper.
        const removeCustomization = get(customizationActions, 'remove.customization', null)
        const activeRemoveCustomization = removeCustomization && customizationStore[removeCustomization]

          if (activeRemoveCustomization) {
            // Yaw
              return false;
          }
      }
    }
    // Naw
    return true;
  }
}
