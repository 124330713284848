import { defineStore } from 'pinia'

import { useBorrowerStore } from '~/store/borrower'

const SELF_SERVE_USER_ID = 8916469
const SELF_SERVE_RENEWAL_USER_ID = 9093160
const SYSTEM_USER_ID = 992232
const BLUEVINE_REP_ID = 4784555
const CRB_REP_ID = 4810256

export const useOwnershipStore = defineStore('ownership', () => {
  const borrowerStore = useBorrowerStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const selfServeUserId = ref(SELF_SERVE_USER_ID)
  const selfServeRenewalUserId = ref(SELF_SERVE_RENEWAL_USER_ID)
  const systemUserId = ref(SYSTEM_USER_ID)
  const fakeRepIds = ref([
    SYSTEM_USER_ID,
    SELF_SERVE_USER_ID,
    BLUEVINE_REP_ID,
    CRB_REP_ID
  ])

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/
  const isSelfServeLenderUser = computed(() => {
    const borrower = borrowerStore.borrower

    if (!borrower || !borrower.userId) {
      return false
    }

    const userId = borrower.userId

    return (
      userId == selfServeUserId.value || userId == selfServeRenewalUserId.value
    )
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/


  return {
    // STATE
    selfServeUserId,
    selfServeRenewalUserId,
    systemUserId,
    fakeRepIds,

    // GETTERS
    isSelfServeLenderUser,

    // ACTIONS
  }
})
