function hexToRgb(hex) {
  if (!hex || hex.length < 6) {
    return "0, 0, 0"
  }
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return "0, 0, 0"
  }

  var rgb = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
  return rgb.r + ", " + rgb.g + ", " + rgb.b;
}

export default defineNuxtPlugin(() => {
  const $config = useRuntimeConfig().public

  if (process.client) {
    let tenantCssExists = !!document.getElementById("tenant-css")

    if (!tenantCssExists && $config.useTenantTheme === "true") {
      let tenantcss = document.createElement("style")
      tenantcss.setAttribute("id", "tenant-css")
      tenantcss.type = "text/css"

      let squareEdges = $config.themeSquareButton === 'true' ? 'border-radius: 2px;' : ''
      let styles = `
      .btn-primary {
        background-color: #${$config.themeColorPrimary};
        border-color: #${$config.themeColorPrimary};
        ${squareEdges}
      }
      .btn-primary:hover, .btn-primary:focus {
        background-color: #${$config.themeColorButtonHover};
        border-color: #${$config.themeColorButtonHover};
      }
      .btn-primary:active.btn-primary:active {
        background-color: #${$config.themeColorButtonActive};
        border-color: #${$config.themeColorButtonActive};
      }
      .selection-pill--selected.selection-pill--selected {
        border-color: #${$config.themeColorButtonActive};
      }
      #dynamic-app-nav#dynamic-app-nav .active-tab {
        border-color: #${$config.themeColorButtonActive};
      }
      .spinner:before{
        border-top-color: #${$config.themeColorIcons};
      }
      a, .btn-link {
        color: #${$config.themeColorLink};
        text-decoration: ${$config.themeColorLink};
      }
      a:hover, .btn-link:hover{
        color: #${$config.themeColorLink};
      }
      .option-box.text-center.option-box--checked {
        border-color: #${$config.themeColorIcons};
      }
      .btn-primary.disabled, .btn-primary:disabled {
        background-color: #${$config.themeColorPrimary};
        border-color: #${$config.themeColorPrimary};
      }
      .fa-circle-question.fa-circle-question.fa-circle-question{
        color: #${$config.themeColorIcons};
      }
      .fa-circle-check, .fa-clock.fa-clock.fa-clock.fa-clock.fa-clock.fa-clock.fa-clock{
        color: #${$config.themeColorIcons};
      }
      .fa-check.fa-check {
        /* I really didnt want to use important, but theres cases that arent covered otherwise */
        color: #${$config.themeColorIcons} !important;
      }
      #progressBar.progress-bar.progress-bar {
        background-color: #${$config.themeColorIcons};
      }
      .form-check-input:checked ~ .form-check-label::after {
        background-color: #${$config.themeColorIcons};
        border-radius: 100%;
      }
      .form-control:focus {
        border-color: #${$config.themeColorIcons};
        box-shadow: 0 0 0 0.2rem rgba(${hexToRgb($config.themeColorIcons) + ", 0.25"});
      }
      `
      if (tenantcss.styleSheet) tenantcss.styleSheet.cssText = styles
      else tenantcss.appendChild(document.createTextNode(styles))

      document.getElementsByTagName("head")[0].appendChild(tenantcss)
    }
  }
})
