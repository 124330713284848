import { differenceInMonths } from 'date-fns'
import { parseISO } from 'date-fns'
import flow from 'lodash/flow.js'
import fppickBy from 'lodash/fp/pickBy.js'
import get from 'lodash/get'
import includes from 'lodash/includes.js'
import intersection from 'lodash/intersection.js'
import isNull from 'lodash/isNull.js'
import keys from 'lodash/keys.js'
import reduce from 'lodash/reduce.js'
import some from 'lodash/some.js'

import { unformattedNumber } from '~/libs/format'

export class MachineLearningService {
  constructor ($axios) {
    this.cache = {}
    this.$axios = $axios
  }

  cacheKey (key, value) {
    this.cache[key] = value
  }

  getCached (key) {
    return this.cache[key] || null
  }

  _prepAttributesForCategoryEndpoint (indexedAttributes) {
    const businessStartDate = get(indexedAttributes, 'business_start_date.value')
    const amountSeeking = get(indexedAttributes, 'financeAmount.value', null)
    const timeInBusiness = businessStartDate ? Math.abs(differenceInMonths(parseISO(businessStartDate), new Date())) : null
    const averageMonthlySales = get(indexedAttributes, 'average_monthly_sales.value', null)
    const creditScore = get(indexedAttributes, 'creditScore.value', null)

    if (some([amountSeeking, timeInBusiness, averageMonthlySales, creditScore].map(isNull))) {
      return null
    }

    return {
      creditScore: unformattedNumber(creditScore),
      timeInBusiness: unformattedNumber(timeInBusiness),
      'financeAmount': unformattedNumber(amountSeeking),
      'average_monthly_sales': unformattedNumber(averageMonthlySales)
    }
  }

  async fetchFundingCategoryPredictionFromBorrowerId (apiBaseUrl, borrowerId) {
    if (!borrowerId) {
      return null
    }

    const url = `${apiBaseUrl}/ml/loan-product/category/${borrowerId}`

    const { data } = await this.$axios.get(url)
    return data.data
  }

  fetchFundingCategoryPredictionFromAttributes (apiBaseUrl, attributes) {
    const data = this._prepAttributesForCategoryEndpoint(attributes)

    if (!data) {
      return null
    }

    return this.fetchFundingCategoryPrediction(apiBaseUrl, data)
  }

  async fetchFundingCategoryPrediction (apiBaseUrl, params) {
    const paramString = reduce(params, (result, value, key) => `${result}${key}=${value}&`, '?')
    const url = `${apiBaseUrl}/ml/loan-product/category${paramString}`

    const cached = this.getCached(paramString)
    if (cached) {
      return cached
    }

    const { data } = await this.$axios.get(url)

    this.cacheKey(paramString, data)

    return data
  }

  getCategoriesAboveThreshold (threshold, categories) {
    return flow(fppickBy(v => v >= threshold), keys)(categories)
  }

  filterAttributesByAffectedCategories (indexedAttributes, categories, ignoredAliases = []) {
    if (!indexedAttributes || !categories || !categories.length) {
      return indexedAttributes
    }

    return fppickBy(attr => {
      // Missing affectedCategories annotation
      if (!attr.affectedCategories) {
        return true
      }

      // Part of the aliases we always keep
      if (includes(ignoredAliases, attr.alias)) {
        return true
      }

      return !!intersection(attr.affectedCategories, categories).length
    })(indexedAttributes)
  }
}
