function convertHost(host) {
  if (host === 'localhost') {
    return '.lendio.com' // previous behavior for localhost
  }
  return '.' + host.split('.').slice(-2).join('.') // .lendio.com, .lendio.net, .thirdparty.com, etc
}

export function getCookieDomain(useRequestHeaders, $config) {
  let host
  if (process.server){
    host = useRequestHeaders(['host']).host
  } else {
    host = window.location.hostname
  }

  const cookieDomain = convertHost(host)

  // if numerical like ip address, likely internal health check, fallback to env var
  if (/\d+/.test(cookieDomain)) {
    return $config['cookieDomain']
  }

  return cookieDomain
}

export default { getCookieDomain }
