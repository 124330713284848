import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import env from '~/libs/env'

export const useFeatureFlagSettingsStore = defineStore('feature-flag-settings', () => {

  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp

  /*
    ███████ ████████  █████  ████████ ███████
    ██         ██    ██   ██    ██    ██
    ███████    ██    ███████    ██    █████
         ██    ██    ██   ██    ██    ██
    ███████    ██    ██   ██    ██    ███████
    STATE
  */
  const featureFlags = ref(null);
  const connecting = ref(false);
  const loading = ref(false);


  /*
     ██████  ███████ ████████ ████████ ███████ ██████  ███████
    ██       ██         ██       ██    ██      ██   ██ ██
    ██   ███ █████      ██       ██    █████   ██████  ███████
    ██    ██ ██         ██       ██    ██      ██   ██      ██
     ██████  ███████    ██       ██    ███████ ██   ██ ███████
    GETTERS
  */
  const featureFlagByName = computed(() => {
    const _featureFlags = featureFlags.value
    return (name) => {
      return _featureFlags?.find(ff => ff.name === name)
    }
  })

  const featureFlagIsEnabled = computed(() => {
    const _featureFlags = featureFlags.value
    return (name) => {
      const ff = _featureFlags?.find(ff => ff.name === name)
      return ff?.value?.enabled ?? false
    }
  })


  /*
     █████   ██████ ████████ ██  ██████  ███    ██ ███████
    ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
    ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
    ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
    ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
    ACTIONS
  */
  async function getActiveFeatureFlagSettings() {
    loading.value = true
    connecting.value = true
    let res = await $axios
      .get(env('apiUrl') + '/settings/active-feature-flags')
      .catch((err) => {
        return
      })
    if (!res) {
      return null
    } else {
      featureFlags.value = res.data.data
      return res.data.data
    }
  }

  return {
    // STATE
    featureFlags,

    // GETTERS
    featureFlagByName,
    featureFlagIsEnabled,

    // ACTIONS
    getActiveFeatureFlagSettings
  }
})
