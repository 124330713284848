import {stringify} from 'tiny-querystring'
import env from '~/libs/env'

function sanitizeBool (value) {
  return ['1', 1, 'true', true].includes(value)
}

export const returnUserHelpers = {
  defaultState () {
    return {
      borrowerIds: [],
      hsp: false,
      email: null,
      returnToken: null
    }
  },
  writeLocalStorage (returnUser) {
    window.localStorage.setItem('returnUser', JSON.stringify(returnUser))
  },
  readRouteParams (query) {
    const keys = Object.keys(query)
    const params = {}

    if (keys.includes('rt')) {
      params.returnToken = query.rt
    }

    if (keys.includes('e')) {
      params.email = query.e
      params.email = decodeURIComponent(params.email).replace(' ', '+')
    }

    if (keys.includes('hsp')) {
      params.hsp = query.hsp
      params.hsp = sanitizeBool(params.hsp)
    }

    return params
  },
  async apiReturnUser (params, $axios) {
    let data = {}
    // We need both an email and returnToken to uniquely identify
    // the user now that we have tenants.
    if (!params.email || !params.returnToken) return data

    let query = stringify({
      email: decodeURIComponent(params.email)
    })

    query += '&' + stringify({
      returnToken: decodeURIComponent(params.returnToken)
    })

    const url = `${env('apiUrl')}/return-user?${query}`

    const res = await $axios.get(url)
            .catch(err => {
              return err.response
            })
    if(!res || !res.data) {
      return {}
    } else {
      data = res.data.data
      if(data.hsp)
      data.hsp = sanitizeBool(data.hsp)

      if(!data.email && params.e) {
        data.email = params.e
      }

      data.statusCode = res.data.statusCode

      return data
    }
  }
}
