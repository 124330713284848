import { defineStore } from 'pinia'
import { ref, computed  } from 'vue'
// Because this store is called in a server/middleware we have to use direct imports
import env from '../libs/env.js'
import get from 'lodash/get.js'
import omitBy from 'lodash/omitBy.js'
import isNil from 'lodash/isNil.js'
import { getEmbeddedStyleProps } from '../libs/embedded.js'
import { useRootStore } from '../store/root.js'
import appNameFromRoute from '~/libs/app-name-from-route.js'

const CUSTOMIZATION_KEYS = {
  AFFILIATE: 'affiliate',
  APP_CONFIG: 'app_configuration',
  APP_HEAD: 'app_head',
  APPROVALS: 'approvals',
  APP_STYLES: 'app_styles',
  CREDIT_CHECK: 'credit_check',
  DOCUMENTS: 'documents',
  HOST_URLS: 'host_urls',
  CONTACT_INFO: 'contact_information',
  CHAT: 'chat_widget',
  NOTIFICATIONS: 'notifications',
  MP_TURNDOWN: 'marketplace_turndown',
  POWERED_BY_LENDIO: 'powered_by_lendio',
  REJECTION: 'rejection',
  SUPPORTED_APPLICATIONS: 'supported_applications',
  TENANT: 'tenant',
  PARTNER_TERMS: 'terms',
  MODERN_OFFER_ACCEPTANCE: 'modern_offer_acceptance',
  PREAPPROVAL_ONLY: 'preapproval_only',
  OFFERS_PAGE: 'offers_page'
}

export const useAffiliateCustomizationStore = defineStore('affiliateCustomization', () => {
  const rootStore = useRootStore()
  const nuxtApp = useNuxtApp()
  const { $axios, $lendioCookies } = nuxtApp
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const affiliateCustomizations = ref(null)
  const errorLoadingCreditAuthorizations = ref('<span>There was an error loading these authorizations. Please refresh to proceed.</span>')
  /*
    ██████  ███████ ████████ ████████ ███████ ██████  ███████
    ██       ██         ██       ██    ██      ██   ██ ██
    ██   ███ █████      ██       ██    █████   ██████  ███████
    ██    ██ ██         ██       ██    ██      ██   ██      ██
    ██████  ███████    ██       ██    ███████ ██   ██ ███████
    GETTERS
  */
  const appConfig = computed(() => {
      let appConfig = get(affiliateCustomizations.value, CUSTOMIZATION_KEYS.APP_CONFIG, null)
      return appConfig ? omitBy(appConfig, isNil) : null
  })

  const approvals = computed(() => {
    let approvals = get (affiliateCustomizations.value, CUSTOMIZATION_KEYS.APPROVALS, null)
    return approvals ? omitBy(approvals, isNil) : approvals
  })

  const appStyles = computed(() => {
    let appStyles = get(affiliateCustomizations.value, CUSTOMIZATION_KEYS.APP_STYLES, null)
    return appStyles ? omitBy(appStyles, isNil) : appStyles
  })

  const appNavLogo = computed(() => {
    const navLogoString = appStyles.value && appStyles.value.appNavLogo ? appStyles.value.appNavLogo : '';
    if (!navLogoString) {
      return `/images/logo-default-light.svg`;
    }
    if (navLogoString === 'default-light' || navLogoString === 'default-dark') {
      return `/images/logo-${navLogoString}.svg`;
    }
    return navLogoString;
  })

  const appNavLogoUrl = computed(() => {
    return get(appStyles.value, 'appNavLogoUrl', null)
  })

  const appNavPoweredByLendioText = computed(() => {
    return appStyles.value && appStyles.value.appNavPoweredByLendioText
      ? appStyles.value.appNavPoweredByLendioText
      : null
  })

  const appNavPoweredByLendioLogo = computed(() => {
    return appStyles.value && appStyles.value.appNavPoweredByLendioLogo
      ? `/images/logomark-circle-${appStyles.value.appNavPoweredByLendioLogo}.svg`
      : ''
  })

  const appStyleProps = computed(() => {
    return getEmbeddedStyleProps(appStyles.value)
  })

  const authorizedOwnerName = computed(() => {
    return get(borrowerAttributeOverrides.value, 'authorizedOwner.name.text', null);
  })

  const authorizedOwnerTooltip = computed(() => {
    return get(borrowerAttributeOverrides.value, 'authorizedOwner.tooltip.text', null);
  })

  const beneficialOwnersTooltip = computed(() => {
    return get(borrowerAttributeOverrides.value, 'beneficialOwners.tooltip.text', null);
  })

  const borrowerAttributeOverrides = computed(() => {
    return get(appConfig.value, 'borrowerAttributeOverrides', null)
  })

  const isIntelligentLendingAffiliate = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.AFFILIATE}.medium`, '') === "Intelligent Lending" &&
      get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.TENANT}.id`, 1) !== 1;
  })

  const documentsEnabled = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.DOCUMENTS}.enabled`, true)
  })

  const embeddedResubmissionPercentage = computed(() => {
    return typeof(get(appConfig.value, 'embeddedResubmission.percent', 0)) === 'number' ? get(appConfig.value, 'embeddedResubmission.percent', 0) : 0
  })

  const creditCheckEnabled = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CREDIT_CHECK}.enabled`, false)
  })

  const creditCheckShowSuccessPage = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CREDIT_CHECK}.pullAndShowSuccessPage`, false)
  })
  const creditCheckAuthorization = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CREDIT_CHECK}.authorization`, '')
  })

  const creditCheckMainImageTag = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CREDIT_CHECK}.mainImageTag`, '')
  })

  const creditCheckFailedToConnectTag = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CREDIT_CHECK}.failedToConnectTag`, null)
  })
  const creditCheckSuccessTag = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CREDIT_CHECK}.successTag`, null)
  })

  const pullAndShowSuccessPage = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CREDIT_CHECK}.pullAndShowSuccessPage`, null)
  })

  const getHostUrls = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.HOST_URLS}.urls`, [])
  })

  const hasAllOffersCard = computed(() => {
    return approvals.value?.hasAllOffersCard
      ? approvals.value.hasAllOffersCard
      : null
  })

  const contactInfoEnabled = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CONTACT_INFO}.enabled`, true)
  })

  const contactInfoPhone = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CONTACT_INFO}.phone`, null)
  })

  const contactInfoEmail = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CONTACT_INFO}.email`, null)
  })

  const contactInfoHours = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CONTACT_INFO}.hours`, null)
  })

  const chatEnabled = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.CHAT}.enabled`, true)
  })

  const preapprovalOnly = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.PREAPPROVAL_ONLY}.enabled`, false)
  })

  const removeFinanceAmountQuestion = computed(() => {
    const removeQuestionValue = get(borrowerAttributeOverrides.value, 'financeAmount.actions.remove', null);

    return ['true', true].includes(removeQuestionValue)
  })

  const supportedApplications = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.SUPPORTED_APPLICATIONS}.value`, ['all']);
  })

  const isSupportedApplication = computed(() => {
    const supportedApps = supportedApplications.value;
    return (app) => supportedApps.includes(app) || supportedApps.includes('all');
  })

  const iframeHeaderVisible = computed(() => {
    return appStyles.value && appStyles.value.iframeHeaderVisible ? appStyles.value.iframeHeaderVisible : null
  })

  const managingPartnersName = computed(() => {
    return get(borrowerAttributeOverrides.value, 'managingPartners.name.text', null);
  })

  const managingPartnersTooltip = computed(() => {
    return get(borrowerAttributeOverrides.value, 'managingPartners.tooltip.text', null);
  })

  const privacyPolicyHtml = computed(() => {
    return get(appConfig.value, 'privacyPolicy.html', false)
  })

  const privacyPolicyVisible = computed(() => {
    return get(appConfig.value, 'privacyPolicy.show', false)
  })

  const rejectionHeaderText = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.NOTIFICATIONS}.rejection.headerText`, 'Unfortunately, we are not able to match you with any financing at this time.')
  })

  const rejectionBodyText = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.NOTIFICATIONS}.rejection.bodyText`, 'Common reasons customers do not match with a financing option include lower credit score, less time in business, or lower revenue.')
  })

  const rejectionCtaText = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.NOTIFICATIONS}.rejection.ctaText`, 'Exit application')
  })

  const dnqExperience = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.OFFERS_PAGE}.doesNotQualifyExperience`, 'default');
  })

  const rejectionShowCta = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.NOTIFICATIONS}.rejection.showCta`, true)
  })

  const rejectionCtaUrl = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.NOTIFICATIONS}.rejection.ctaUrl`, null)
  })

  const poweredByLendioEnabled = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.POWERED_BY_LENDIO}.enabled`, true)
  })

  const tenantId = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.TENANT}.id`, 1)
  })

  const isLendioTenant = computed (()=> {
    return tenantId.value === 1
  })

  const ownershipPercentageTooltip = computed(() => {
    return get(borrowerAttributeOverrides.value, 'ownershipPercentage.tooltip.text', null);
  })

  const bpBaseUrl = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.TENANT}.appBaseUrl`, env('bpBaseUrl'))
  })

  const key = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.TENANT}.key`, '')
  })

  const headTitlePrefix = computed(() => {
    return get(
      affiliateCustomizations.value,
      `${CUSTOMIZATION_KEYS.APP_HEAD}.title`,
      env('useTenantTheme') === true ? env('tenantName') : 'Lendio'
    )
  })

  const useCustomFavicon = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.APP_HEAD}.favicon`, false)
  })

  const isSupportedPage = computed(() => {
    return (route) => {
      const appAgnosticPages = ['/resume-app', '/404', '/login']
      const _appNameFromRoute = appNameFromRoute(route)
      const supportedTypes = supportedApplications.value;
      return Boolean(appAgnosticPages.includes(route.path) || supportedTypes.includes('all') || supportedTypes.includes(_appNameFromRoute))
    }
  })

  const partnerTerms = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.PARTNER_TERMS}.html`, '')
  })

  const modernOfferAcceptanceEnabled = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.MODERN_OFFER_ACCEPTANCE}.enabled`, false)
  })

  const modernOfferAcceptanceHasRecommendedFlag = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.MODERN_OFFER_ACCEPTANCE}.enabledRecommendedFlag`, false)
  })

  const modernOfferAcceptanceHasLearnMorePage = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.MODERN_OFFER_ACCEPTANCE}.enabledLearnMorePage`, false)
  })

  const modernPortalLayout = computed(() => {
    return get(affiliateCustomizations.value, `${CUSTOMIZATION_KEYS.MODERN_OFFER_ACCEPTANCE}.enabledModernPortalLayout`, false)
  })

  /*
     █████   ██████ ████████ ██  ██████  ███    ██ ███████
    ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
    ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
    ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
    ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
    ACTIONS
    ! - - Actions calling other actions in the same store must use `this.actionName(...)`
    ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
    ! - - Computeds and refs will work fine, and should be called directly though.
  */

  /**
   * Retrieves a users affiliate customizations.
   * @returns affiliateCustomizations
   */
  async function getActiveAffiliateCustomizations() {
    const { authUser, isEmbedded } = rootStore
    if (!authUser || !isEmbedded) return
    if (affiliateCustomizations.value) return affiliateCustomizations.value

    await $axios.get(`${env('apiUrl')}/user/embedded/active-app-customizations`)
      .then((res) => {
        const customizations = get(res, 'data.data', {})
        affiliateCustomizations.value = customizations
      })
      .catch((err) => {
        // May want to setup an error state, currently embedded pages will not fully render on failed check for customizations
        console.error(`Error during getAffiliateCustomizationsByType: ${err}`)
      })

    return affiliateCustomizations.value
  }

  async function getNoAuthActiveAffiliateCustomizationsByHost(hostname) {
    if (affiliateCustomizations.value) {
      return affiliateCustomizations.value;
    }

    // A 404 response is often expected and we dont want to throw an error
    const axiosConfig = {
      validateStatus: function (status) {
        return status >= 200 && status < 300 || status === 404
      }
    }

    await $axios.get(`${env('apiUrl')}/affiliate/app-customizations?hostname=${hostname}`, axiosConfig)
      .then((res) => {
        affiliateCustomizations.value = get(res, 'data.data', {})
      })
      .catch((err) => {
        // todo handle error state
        // console.error('Error during getNoAuthActiveAffiliateCustomizationsByHost', err);
      });

    return affiliateCustomizations.value;
  }

  async function getNoAuthActiveAffiliateCustomizations(affiliateId = null) {
    const affiliateCustomizationsCookie = $lendioCookies.get('affiliateCustomizationsId');

    if (affiliateCustomizations.value) {
      return affiliateCustomizations.value;
    }

    if (!affiliateId && !affiliateCustomizationsCookie) {
      return;
    }

    affiliateId = affiliateId
      ? affiliateId
      : affiliateCustomizationsCookie;

    await $axios.get(`${env('apiUrl')}/affiliate/${affiliateId}/app-customizations`)
      .then((res) => {
        affiliateCustomizations.value = get(res, 'data.data', {})
      })
      .catch((err) => {
        //todo handle error state
        console.error('Error during getNoAuthActiveAffiliateCustomization', err);
      });

    return affiliateCustomizations.value;
  }

  // TO-DO: This is temporary to demo CCBank. Needs to be updated along side affiliateCustomization rework
  function getPartnerConsentTerms(consentType = '') {
    let _consentValue = 'Create an Account'
    if (consentType === 'esignature') {
      _consentValue = 'I agree'
    }

    const tenantDisplayNames = {
      7: 'Corner Bank',
      19: 'CC Bank'
    }
    let _companyName = ''
    if (tenantId.value && tenantDisplayNames[tenantId.value]) {
      _companyName = ` from, or on behalf of, ${tenantDisplayNames[tenantId.value]}`
    }
    const partnerConsent = `By checking this box and clicking “${_consentValue}”, I consent to receive recorded marketing phone calls and/or text messages${_companyName}, including autodialed and pre-recorded calls delivered using automated means. I acknowledge consent is not a condition of purchase or account creation and I may opt out at any time.`
    return partnerConsent
  }

  return {
    // State
    affiliateCustomizations,
    errorLoadingCreditAuthorizations,
    //Getters
    isIntelligentLendingAffiliate,
    appConfig,
    appNavLogo,
    appNavLogoUrl,
    appNavPoweredByLendioLogo,
    appNavPoweredByLendioText,
    approvals,
    appStyleProps,
    appStyles,
    authorizedOwnerName,
    authorizedOwnerTooltip,
    beneficialOwnersTooltip,
    borrowerAttributeOverrides,
    bpBaseUrl,
    creditCheckAuthorization,
    creditCheckEnabled,
    creditCheckFailedToConnectTag,
    creditCheckMainImageTag,
    creditCheckShowSuccessPage,
    creditCheckSuccessTag,
    documentsEnabled,
    embeddedResubmissionPercentage,
    getHostUrls,
    contactInfoEnabled,
    contactInfoPhone,
    contactInfoEmail,
    contactInfoHours,
    chatEnabled,
    hasAllOffersCard,
    headTitlePrefix,
    iframeHeaderVisible,
    isLendioTenant,
    isSupportedApplication,
    isSupportedPage,
    key,
    managingPartnersName,
    managingPartnersTooltip,
    ownershipPercentageTooltip,
    partnerTerms,
    poweredByLendioEnabled,
    preapprovalOnly,
    privacyPolicyHtml,
    privacyPolicyVisible,
    pullAndShowSuccessPage,
    rejectionBodyText,
    rejectionCtaText,
    rejectionCtaUrl,
    rejectionHeaderText,
    rejectionShowCta,
    dnqExperience,
    removeFinanceAmountQuestion,
    supportedApplications,
    tenantId,
    useCustomFavicon,
    modernOfferAcceptanceEnabled,
    modernOfferAcceptanceHasRecommendedFlag,
    modernOfferAcceptanceHasLearnMorePage,
    modernPortalLayout,

    //actions
    getActiveAffiliateCustomizations,
    getNoAuthActiveAffiliateCustomizations,
    getNoAuthActiveAffiliateCustomizationsByHost,
    getPartnerConsentTerms,
  }
})
