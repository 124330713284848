import { defineStore } from 'pinia'

import { localStorageService } from '~/libs/local-storage.service'
import { returnUserHelpers } from '~/libs/return-user'

import { useRootStore } from '~/store/root'

function sanitizeBool(value) {
  return ['1', 1, 'true', true].includes(value)
}

export const useReturnUserStore = defineStore('returnUser', () => {
  const nuxtApp = useNuxtApp()
  const { $axios } = nuxtApp
  const rootStore = useRootStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const returnUser = ref(null)

/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const returnUserForm = computed(() => {
    if (!returnUser.value) return null
    const { hsp, borrowerIds } = returnUser.value

    // whether from param or api, assume anyone with hsp=1 should login
    if (hsp || (borrowerIds && borrowerIds.length > 0)) return 'login'

    // if we don't have a borrowerIds or the returnToken was invalid/missing, setpass work work
    // everyone else, signup
    return 'signup'
  })

/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/

  async function getReturnUser({ route }) {
    let _returnUser = returnUserHelpers.defaultState()
    // bail if not client because local storage
    if (!process.client) return _returnUser

    let routeParams = returnUserHelpers.readRouteParams(route.query)

    if (Object.keys(routeParams).length) {
      const apiData = await returnUserHelpers.apiReturnUser(
        routeParams,
        $axios
      )
      if (apiData && apiData.borrowerIds && apiData.borrowerIds.length > 0) {
        // Just return the user data if successful or just not authorized otherwise we will just return an empty default returnUser
        _returnUser = Object.assign(_returnUser, routeParams, apiData)
      }
    } else {
      const lsData = localStorageService.getItem('returnUser')
      if (lsData) {
        _returnUser = JSON.parse(lsData)
      }
    }

    // set and return store state
    returnUser.value = _returnUser
    returnUserHelpers.writeLocalStorage(returnUser.value)

    return _returnUser
  }

  function returnUserHsp(hsp) {
    returnUser.value = Object.assign(
      {},
      returnUserHelpers.defaultState(),
      returnUser.value,
      { hsp: sanitizeBool(hsp) }
    )
    returnUserHelpers.writeLocalStorage(returnUser.value)
  }

  return {
    // STATE
    returnUser,

    // GETTERS
    returnUserForm,

    // ACTIONS
    getReturnUser,
    returnUserHsp,
  }
})
