<template>
  <div class="container w-100 tk-proxima-nova"
       :class="{'customized-affiliate': !!affiliateCustomizations}"
       :style="appStyleProps">
    <div class="row justify-content-center">
      <div class="card border-0 text-center px-0" style="width: 30rem">
        <NuxtImg
          :src="imgSource"
          width="400"
          height="400"
          class="card-img-top"
          :alt="`${error.statusCode} error`"
        />
        <div class="card-body text-center">
          <h6 class="type-400 fw-bolder">ERROR {{ error.statusCode }}</h6>
          <h3 class="p-2 p-lg-0 mb-1 mb-lg-2">{{ headerContent }}</h3>
          <p class="type-400 mb-5 pb-1 pb-lg-0">{{ bodyContent }}</p>
          <button @click="buttonData.method" :data-cy="buttonData.testId" type="button" :disabled="navigating" class="btn btn-primary btn-sm">
            <ButtonLoadingSpinner v-if="navigating"></ButtonLoadingSpinner>
            {{ buttonData.btnText }}
          </button>
          <div class="py-5 m-8 fw-bold" v-if="isDev">
            {{ error.message }}
          </div>
          <div class="py-5 m-8 fw-bold" v-if="isDev">
            Check console for more information
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import typeKitInit from '~/libs/typekit'
import { storeToRefs } from "pinia";
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization';
import { useRootStore } from '~/store/root';
import { useProgressStore } from '~/store/progress';

export default {
  setup(props) {
    const $config = useRuntimeConfig().public
    const isDev = $config.environment === 'dev'
    const navigating = ref(false)
    const { isEmbedded } = storeToRefs(useRootStore())
    const { affiliateCustomizations, appStyleProps } = storeToRefs(useAffiliateCustomizationStore())
    const { resumeApplicationRoute } = useProgressStore()

    function simNavigation() {
      navigating.value = true
      setTimeout(function() {
        navigating.value = false
      }, 5000)
    }


    if (!import.meta.client) {
      if (props.error.statusCode === 401) {
        log.warning(props.error)
      } else if (props.error.statusCode !== 404) {
        log.error(props.error)
      }
    }

    onMounted(() => {
      if (isDev) {
        console.log(props.error.message, props.error)
      }
      typeKitInit()
    })

    return {
      affiliateCustomizations,
      resumeApplicationRoute,
      appStyleProps,
      simNavigation,
      navigating,
      isEmbedded,
      isDev
    }
  },
  props: ['error'],
  computed: {
    imgSource() {
      if (this.error?.statusCode === 404) {
        return "/images/404_image.svg"
      }
      return "/images/500_image.svg"
    },
    headerContent() {
      if (this.isEmbedded) {
        if (this.error?.statusCode === 401) {
          return "Authentication failed"
        }
      }
      if (this.error?.statusCode === 404) {
        return "Oh no! Something went wrong."
      }
      return "Sorry, an unexpected error occured."
    },
    bodyContent() {
      if (this.isEmbedded) {
        if (this.error?.statusCode === 401) {
          return "We are unable to authenticate your user. Please close this window and refresh your browser to try again."
        }
        if (this.error?.statusCode === 404) {
          return "This page was not found. Please use the button below to resume your application."
        }
      }
      if (this.error?.statusCode === 404) {
        return "This page was not found. Check that your URL is spelled correctly or head to the home page."
      }
      return "We’re not exactly sure what happened, but our servers say something is wrong. We’re working on fixing the problem."
    },
    buttonData() {
      if (this.isEmbedded) {
        if (this.error?.statusCode === 401) {
          return {
            method: this.closeIFrame,
            testId: 'emb-error-401-btn',
            btnText: 'Close'
          }
        }
        if (this.error?.statusCode === 404) {
          return {
            method: this.resumeEmbeddedApp,
            testId: 'emb-error-404-btn',
            btnText: 'Resume Application'
          }
        }
        return {
            method: this.closeIFrame,
            testId: 'emb-error-btn',
            btnText: 'Close'
          }
      }
      return {
          method: this.resumeMPApp,
          testId: 'mp-error-btn',
          btnText: 'Resume Application'
        }
    },
  },
  methods: {
    resumeMPApp() {
      this.simNavigation()
      return navigateTo('/bp/resume-app', { external: true })
    },
    resumeEmbeddedApp() {
      this.simNavigation()
      return navigateTo(this.resumeApplicationRoute('embedded') || '/embedded')
    },
    closeIFrame() {
      this.simNavigation()
      const message = {
        source: 'BP',
        method: 'close',
        action: 'close',
      }
      window.parent.postMessage(message, '*')
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/styles/affiliate-customizations/affiliate-customizations-404-page';
@import 'lendio-spinners';

.card-body {
  margin-top: -42px;
  @include media-breakpoint-down(lg) {
    margin-top: -68px;
  }
}

.mh-100vh {
  min-height: 100vh;
}

#app {
  flex: 1 0 auto;
  padding-bottom: 80px;
}

// We have to override some styles with important to get higher precedence than the scoped styles in the footer
#base-footer {
  .base-footer-bottom {
    margin: 0 auto;
    max-width: 1200px;
    background-color: $background-100 !important;
  }

  .text-container {
    padding: 10px 0 !important;
  }
}

.customized-affiliate {
  @include affiliateCustom404();
}
</style>

<style lang="scss">
/* Sticky Footer */
html,
body,
#__nuxt,
#main-flex {
  /* Any container housing the flexboxes needs to be AT LEAST 100% height! */
  min-height: 100vh;
}
</style>
