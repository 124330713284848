<template>
  <div class="container col-12">
    <div class="col-12 col-lg-10 col-xl-8 mb-2">
      <h2>Option Configuration Tool</h2>
      <p class="lead">
        This is where you can adjust various aspects of your option. This can
        include things like amount, term, and payment frequency.
      </p>
      <PortalOfferBackButton></PortalOfferBackButton>
    </div>
    <div v-if="currentApproval && currentApproval.id"
      class="d-flex justify-content-between flex-wrap col-12 col-lg-10 col-xl-8"
    >
      <div class="col-12 col-md-8 mb-4">
        <ApprovalCard :approvalOrOffer="currentApproval" />
      </div>
      <div class="card col-12 col-md-3 px-2" style="height: fit-content;">
        <ProgressTracker
          :progressTrackerData="progressTracker"
          :orientationBreakpointWidth="767"
        ></ProgressTracker>
      </div>
    </div>
  </div>
</template>

<script>
import ApprovalCard from '~/components/portal/approvals/ApprovalCard'
import ProgressTracker from '~/components/portal/portalOffers/ProgressTracker'
import PortalOfferBackButton from "~/components/portal/portalOffers/PortalOfferBackButton"

import { mapState } from 'pinia'

import { useApprovalsStore } from '~/store/approvals'
import { useOptInsStore } from '~/store/opt-ins'
import { useRootStore } from '~/store/root'

/**
 * Adjust a single offer approval.
 *
 * This dynamic page expects a approvalID parameter:
 *   portal/offers/adjust/{approvalId}
 */
export default {
  name: 'OffersAdjust',
  async setup() {
    definePageMeta({
      name: 'Offers',
    })

    await useOptInsStore().getOptInOffers(true)
    if (process.browser) {
      await useApprovalsStore().requestApprovals()
    }
  },
  components: {
    ApprovalCard,
    PortalOfferBackButton,
    ProgressTracker
  },
  // Required, even if empty.
  data() {
    return {
      progressTrackerData: {},
      progressTracker: {
        title: 'Offer Progress Tracker',
        steps: [
          { label: 'Offers Received', done: true, active: false },
          { label: 'Configure Offer', done: false, active: true },
          { label: 'Claim Offer', done: false, active: false }
        ]
      }
    }
  },

  async beforeMount() {
    const store = this.$store
    // If no approvals yet exist, try loading state again here to be sure
    // they aren't in localStorage (e.g. user overrides).
    if (!useApprovalsStore(this.$pinia).approvals.length) {
      this.loadState(store)
    }
  },
  unmounted() {
    if (this.$route.name === 'portal-offers' || this.$route.name === 'portal-offers-2-confirmation') {
      return;
    }

    window.removeEventListener('resize', (event) => {
      this.determineTheStyleOfProgressTracker(event.target);
    });
  },
  // End lifecycle hooks.

  computed: {
    ...mapState(useApprovalsStore, {
      'currentApproval': 'getCurrentApproval'
    }),
    ...mapState(useRootStore, {
      authUser: (store) => store.authUser
    }),
  },

  methods: {
    // This is different than loadState in [offers].vue. We need less data
    // and ensure we only request approvals in the browser where it has access
    // to localStorage data (like user override test data).
    async loadState() {
      await useOptInsStore(this.$pinia).getOptInOffers(true)
      // Ensure we have approvals loaded in the store.
      // Only do this in the browser.
      if (process.browser) {
        await useApprovalsStore(this.$pinia).requestApprovals()
      }
    }
  }
}
</script>

<style scoped lang='scss'>

  @import "lendio-card";
</style>
