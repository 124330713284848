import { useRootStore } from "~/store/root"

export default defineNuxtPlugin((nuxtApp) => {
    const { $pinia } = nuxtApp
    const isEmbeddedRoute = useRoute().path.startsWith('/embedded')
    const { isEmbedded } = useRootStore($pinia)
    function validateEmbeddedLoaded() {
        if ((isEmbedded || isEmbeddedRoute) && window) {
            window.parent.postMessage({
                source: 'BP',
                method: 'iframeStylesReady',
                action: 'iframeStylesReady'
              }, "*")
        }
    }
    nuxtApp.hook('page:finish', () => {
        validateEmbeddedLoaded()
    })
    nuxtApp.hook('app:error', () => {
        validateEmbeddedLoaded()
      })
})
