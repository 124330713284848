import inDynamicApp from '~/libs/dynamic-app-pages'
import { checkAdobeTargetForRoute } from '~/libs/adobe-target'
import { useUserStore } from '~/store/user'
import { useRootStore } from '~/store/root'
import { useExperienceStore } from '~/store/experience'

const exceptionPages = [
  'basic-info',
  'documents',
  'documents/manual',
  'docsuccess'
]

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $pinia, $lendioCookies } = useNuxtApp()
  const rootStore = useRootStore($pinia)
  const userStore = useUserStore($pinia)
  const experienceStore = useExperienceStore($pinia)

  let token = await $lendioCookies.get('token')
  if (!token && rootStore.isLoggedIn) {
    await rootStore.destroyUser()
    userStore.clearAccessToken()
  }

  // Check adobe target tests
  checkAdobeTargetForRoute(to, $pinia)

  // Check for app route changes to ensure experience is current
  let isOnExceptionPage = (route) => {
    return exceptionPages.some(reg => (reg = new RegExp(reg), reg.test(route.name) || reg.test(route.path)))
  }
  if (rootStore.isLoggedIn && inDynamicApp(to.name) && !isOnExceptionPage(to)) {
    // Make sure the experience is set for _future_ route changes
    experienceStore.forceGetExperience({ silent: true })
  }
})
