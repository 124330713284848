import get from 'lodash/get'

import { useRootStore } from '~/store/root'
import { useBorrowerStore } from '~/store/borrower'
import { useProgressStore } from '~/store/progress'
import appNameFromRoute from '~/libs/app-name-from-route';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp()
  const $pinia = nuxtApp.$pinia

  const borrowerStore = useBorrowerStore($pinia)
  const progressStore = useProgressStore($pinia)
  const rootStore     = useRootStore($pinia)

  // Prevent server-side layer being cached in response
  if (process.server) {
    const res = nuxtApp.ssrContext.event.node.res
    res.setHeader('Cache-Control', 'max-age=0')
  }

  const redirectUrl = await rootStore.authenticate({ route: to, $pinia })
  if (redirectUrl) {
    return nuxtApp.runWithContext(() => navigateTo(redirectUrl))
  }

  if (get(rootStore, 'authUser.id')) {
    const appName = appNameFromRoute(to) ?? 'marketplace'

    await Promise.all([
      borrowerStore.getBorrowerValues(),
      progressStore.getVisitedRoutes(),
      progressStore.fetchApplications(),
      progressStore.executeRequiredActions({appName, initOnly: true}),
    ])
  }
})
