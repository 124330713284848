import analytics from '~/libs/analytics-service'

export default defineNuxtPlugin(() => {
    const $config = useRuntimeConfig().public

    if (process.client) {
        window.analytics = window.analytics || []
        if ($config.environment === 'LIVE' || $config.environment === 'test') {
            window.analytics.methods = ['identify', 'group', 'track', 'page', 'pageview', 'alias', 'ready', 'on', 'once', 'off', 'trackLink', 'trackForm', 'trackClick', 'trackSubmit']
            window.analytics.factory = function (t) {
                return function () {
                    var a = Array.prototype.slice.call(arguments)
                    a.unshift(t)
                    window.analytics.push(a)
                    return window.analytics
                }
            }
            for (var i = 0; i < window.analytics.methods.length; i++) {
                var key = window.analytics.methods[i]
                window.analytics[key] = window.analytics.factory(key)
            }
        } else {
            let methods = ['identify', 'track', 'trackLink', 'trackForm', 'trackClick', 'trackSubmit', 'pageview', 'ab', 'alias', 'ready']
            let createMock = (methodName) => {
                return () => {
                    if ($config.debugEnabled === 'true') {
                        console.log(methodName, arguments)
                    }
                }
            }
            for (let methodIndex = 0; methodIndex < methods.length; methodIndex++) {
                window.analytics[methods[methodIndex]] = createMock(methods[methodIndex])
            }
        }

        // This helpers object makes the helper functions available to the lsAnalytics
        // override provider in legacy BP.
        window.analyticsHelpers = window.analyticsHelpers || {}
        window.analyticsHelpers.getSpecialContext = analytics.getSpecialContext
        window.analyticsHelpers.getSegmentAnonymousId = analytics.getSegmentAnonymousId
    }
})
