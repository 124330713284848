const currentYear = new Date().getFullYear()

export default [
  {
    alias: 'widget.entity_type',
    type: 'boxSelect',
    name:"What type of entity is your business?",
    fields: [
      'entity_type'
    ],
    metadata: {
      columns: 2,
    }
  },

  {
    alias: 'widget.average_monthly_sales',
    type: 'boxSelect',
    name: "On average, how much revenue does your business generate each month?",
    fields: ['average_monthly_sales'],
    options: [
      {label: "$0",         value: "0.00", },
      {label: "$1–4K",      value: "4999.00", range: { min: 0.01, max: 4999 } },
      {label: "$5K–7K",     value: "7999.00", range: { min: 5000, max: 7999 }  },
      {label: "$8K–14K",    value: "14999.00", range: { min: 8000, max: 14999 }  },
      {label: "$15K–19K",   value: "19999.00", range: { min: 15000, max: 19999 }  },
      {label: "$20K–49K",   value: "49999.00", range: { min: 20000, max: 49999 }  },
      {label: "$50K–79K",   value: "79999.00", range: { min: 50000, max: 79999 }  },
      {label: "$80K–199K",  value: "199999.00", range: { min: 80000, max: 199999 }  },
      {label: "$200K+",     value: "200000.00", range: { min: 200000 }  }
    ]
  },

  {
    alias: 'widget.annual_personal_income',
    type: 'boxSelect',
    fields: ['annual_personal_income'],
    options: [
      {label: "Below $30K",      "value": "29999.00",  "range": {min: 0, max: 29999},},
      {label: "$30K - $50K",     "value": "50000.00",  "range": {min: 30000, max: 50000},},
      {label: "$50K - $75K",     "value": "75000.00",  "range": {min: 50001, max: 75000},},
      {label: "$75K - $100K",    "value": "100000.00", "range": {min: 75001, max: 100000},},
      {label: "$100K - $150K",   "value": "150000.00", "range": {min: 100001, max: 150000},},
      {label: "Above $150K",     "value": "150001.00", "range": {min: 150001},}
    ]
  },

  {
    alias: 'widget.annual_profits',
    type: 'boxSelect',
    fields: ['annual_profits'],
    options: [
      {label: "<$24K",   "value": "23999.00",  "range": {min: 0, max: 23999},},
      {label: "$24K+",   "value": "48000.00",  "range": {min: 24000, max: 48000},},
      {label: "$48K+",   "value": "72000.00",  "range": {min: 48001, max: 72000},},
      {label: "$72K+",   "value": "96000.00",  "range": {min: 72001, max: 96000},},
      {label: "$96K+",   "value": "120000.00", "range": {min: 96001, max: 120000},},
      {label: "$120K+",  "value": "180000.00", "range": {min: 120001, max: 180000},},
      {label: "$180K+",  "value": "240000.00", "range": {min: 180001, max: 240000},},
      {label: "$240K+",  "value": "240001.00", "range": {min: 240001}},
    ]
  },

  {
    alias: 'widget.creditScore',
    type: 'boxSelect',
    fields: ['creditScore'],
    options: [
      {label: "499 or below", "value": "499", "range": {min: 100, max: 499}},
      {label: "500 - 599", "value": "599", "range": {min: 500, max: 599}},
      {label: "600 - 649", "value": "649", "range": {min: 600, max: 649}},
      {label: "650 - 679", "value": "679", "range": {min: 650, max: 679}},
      {label: "680 - 719", "value": "719", "range": {min: 680, max: 719}},
      {label: "720 or above", "value": "850", "range": {min: 720, max: 990}}
    ]
  },

  {
    alias: "widget.loanPurpose",
    type: 'multiBoxSelect',
    name: "What purpose would your loan serve? Select all that apply",
    fields: [
      'loanPurpose'
    ],
  },

  {
    alias: "widget.fundAspect",
    type: 'boxSelect',
    name: "Which is most important to you?",
    fields: [
      'fundAspect'
    ]
  },

  {
    alias: 'widget.about_your_business',
    type: 'multiBoxSelect',
    name: "Select all that apply.",
    fields: [
      { alias: 'bankruptcy', name: 'Have a prior bankruptcy', icon: '/images/desaturating-icons/icon-bankruptcy.png' },
      { alias: 'nonprofit', name: 'Operate as a non-profit', icon: '/images/desaturating-icons/icon-non-profit.png' },
      { alias: 'hasInvoices', name: 'Invoice customers', icon: '/images/desaturating-icons/icon-invoice.png' },
      { alias: 'franchise', name: 'Operate as a franchise', icon: '/images/desaturating-icons/icon-franchise.png' },
    ],
    mustAllowNull: true,
    auxiliaryButton: {
      label: 'None apply',
      click: 'none-apply'
    }
  },
  {
    alias: 'widget.business_start_date',
    type: 'date-month-year',
    fields: [
      'business_start_date'
    ],
    auxiliaryButton: {
      label: 'I haven\'t started yet',
      click: 'date-now'
    }
  },

  // SBA Fields
  {
    alias: 'widget.currentYearBusinessTaxReturnSubmitted',
    fields: ['currentYearBusinessTaxReturnSubmitted'],
    name: 'Have you filed a business tax return for ' + (currentYear - 1),
  },
  {
    alias: 'widget.currentYearPersonalTaxReturnSubmitted',
    fields: ['currentYearPersonalTaxReturnSubmitted'],
    name: 'Have you filed a personal tax return for ' + (currentYear - 1),
  }
]
