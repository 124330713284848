import { defineStore, acceptHMRUpdate } from 'pinia'

import env from '~/libs/env'

export const useTenantStore = defineStore('tenant', () => {
  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

const isTenant = computed(() => {
  if (env('tenantName')) {
    return true
  } else return false
})
/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/
return {
  //STATE
  //GETTERS
  isTenant,
  //ACTIONS
}
})

// Fix Pinia HMR: https://pinia.vuejs.org/cookbook/hot-module-replacement.html
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useTenantStore, import.meta.webpackHot))
}
