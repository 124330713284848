import Cookie from 'cookie-universal'
import NuxtCookieService from '~/libs/nuxt-cookie-service'
import { getCookieDomain } from '~/libs/cookie-domain'

export default defineNuxtPlugin(({ $pinia }) => {
  const nuxtApp = useNuxtApp()
  const { $config } = nuxtApp
  const cookieDomain = getCookieDomain(useRequestHeaders, $config)

  let lendioCookies
  if (process.server) {
    const req = nuxtApp.ssrContext.event.node.req
    const res = nuxtApp.ssrContext.event.node.res
    lendioCookies = new NuxtCookieService(Cookie(req, res), cookieDomain, $pinia)
  } else {
    lendioCookies = new NuxtCookieService(Cookie(), cookieDomain, $pinia)
  }

  $pinia.use(() => ({ $lendioCookies: lendioCookies }))
  nuxtApp.provide('lendioCookies', lendioCookies)

  // add set-cookie headers before response
  if (process.server) {
    nuxtApp.hook('app:rendered', () => {
      nuxtApp.$lendioCookies.addResponseHeaders()
    })
  }

})
