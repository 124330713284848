export default defineNuxtPlugin(() => {
  const $config = useRuntimeConfig()

  if (process.server) {
    global.env = $config.public
    return
  }

  window.env = $config.public

  if ($config.debugEnabled === 'true') {
    console.log('assigned $config to window', window.env)
  }
})
