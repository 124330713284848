import includes from 'lodash/includes'
import get from 'lodash/get'

export const ownerCreditQuestions = [
  'ownerBirthDate',
  'contact.ssn'
]

export const ownerInfoQuestions = [
  'ownerAddress',
  'percentOwnership',
  'authorizedOwner',
]

export const businessFinancialQuestions = [
  'loanPurpose',
  'monthlyCharges',
  'accountsReceivableAmount',
  'annual_profits',
  'business_property_type_own_rent',
  'hasInvoices',
  'equipment_description',
  'nonprofit',
  'assetsValue',
  'hasDownPayment',
  'maxWeeksToFunds',
  'collateralTypes',
  'amexMerchantNumber',
  'amexMerchant',
  'leasemortgage_payment',
  'propertyOwnerName',
  'propertyOwnerPhone'
]

export const businessInfoQuestions = [
  'businessAddress',
  'entity_type',
  'federalstate_tax_id',
  'franchise',
  'lendioIndustry',
  'number_of_employees',
  'employee_benefits_offered',
  'number_of_business_locations',
  'business_location_type',
  'doingBusinessAsName',
  'primaryCustomer',
  'website'
]

export const renewalOwnerQuestions = [
  'user.first',
  'user.last',
  'contact.ssn',
  'percentOwnership',
  'ownerAddress',
  'ownerBirthDate'
]

export const renewalBusinessQuestions = [
  'borrower.name',
  'borrower.phone',
  'widget.entity_type',
  'widget.average_monthly_sales',
  'businessAddress',
  'businessDebtAmount',
  'monthlyDebtPayments'
]

export const renewalLoanRequirements = [
  'financeAmount',
  'widget.fundAspect',
  'widget.loanPurpose'
]

const singlePageBasicInfoQuestionsTestB = [
  'financeAmount',
  'lendioIndustry',
  'borrowerState',
  'percentOwnership',
  'business_start_date@date-month-year',
  'widget.average_monthly_sales',
  'widget.about_your_business',
  'bankruptcy_status@boxSelect',
  'bankruptcy_discharged_date',
  'widget.loanPurpose',
  'widget.entity_type',
  'widget.creditScore',
  'fundAspect@boxSelect',
]

const singlePageBasicInfoQuestionsTestC = [
  'financeAmount',
  'widget.creditScore',
  'business_start_date@date-month-year',
  'widget.average_monthly_sales',
  'lendioIndustry',
  'widget.about_your_business',
  'bankruptcy_status@boxSelect',
  'bankruptcy_discharged_date',
  'percentOwnership',
  'widget.loanPurpose',
  'borrowerState',
  'widget.entity_type',
  'fundAspect@boxSelect',
]

export const singlePageBasicInfoQuestions = {
  testB: singlePageBasicInfoQuestionsTestB,
  testC: singlePageBasicInfoQuestionsTestC
}



export const updatedInfoQuestions = [
  'financeAmount',
  'percentOwnership',
  'borrowerState',
  'lendioIndustry',
  'widget.creditScore',
  'business_start_date@date-month-year',
  'widget.average_monthly_sales',
  'widget.about_your_business',
  'bankruptcy_status@boxSelect',
  'bankruptcy_discharged_date',
  'widget.loanPurpose',
  'widget.entity_type',
  'widget.annual_personal_income',
  'widget.annual_profits',
  'fundAspect@boxSelect',
]

export const borrowerSummaryQuestions = [
  'lendioIndustry',
  'borrowerState',
  'financeAmount',
  'loanPurpose',
  'business_start_date@date-month-year',
  'widget.creditScore@select',
  'entity_type',
]

export const sortArray = [
  ...[
    'user.first',
    'user.last',
    'ownerAddress',
    'companyOfficer',
    'percentOwnership',
    'authorizedOwner',
    'veteran_status'
  ],
  ...ownerCreditQuestions,
  ...businessFinancialQuestions,
  ...businessInfoQuestions
]

export function questionSort (questions) {
  questions.sort(function (a, b) {
    const positionA = sortArray.indexOf(a.alias) > -1 ? sortArray.indexOf(a.alias) : 99
    const positionB = sortArray.indexOf(b.alias) > -1 ? sortArray.indexOf(b.alias) : 99
    return positionA - positionB
  })
}

export function getOwnerCreditQuestions (questionsArray) {
  let questions = questionsArray.filter((question) => includes(ownerCreditQuestions, question.alias))
  questionSort(questions)
  return questions
}

export function getOwnerInfoQuestions (questionsArray) {
  let questions = questionsArray.filter((question) => includes(ownerInfoQuestions, question.alias))
  questionSort(questions)
  return questions
}

export function getBusinessFinancialQuestions (questionsArray) {
  let questions = questionsArray.filter((question) => includes(businessFinancialQuestions, question.alias))
  questionSort(questions)
  return questions
}

export function getBusinessInfoQuestions (questionsArray, borrower) {
  let questions = questionsArray.filter((question) => includes(businessInfoQuestions, question.alias))
  questionSort(questions)

  // Make DBA Name optional and default to the borrower name
  questions = questions.map(question => {
    question = { ...question }
    if (question.alias === 'doingBusinessAsName') {
      question.name = `${question.name} (Optional)`
      question.value = question.value || get(borrower, 'name', '')
    }
    return question
  })
  return questions
}

export function getAdditionalQuestions (questionsArray) {
  const questions = questionsArray.filter((question) =>
    !includes([
      ...ownerInfoQuestions,
      ...ownerCreditQuestions,
      ...businessInfoQuestions,
      ...businessFinancialQuestions,
      'creditScore',
      'borrower.stateId',
      'businessType', // filter out covid-relief businessType question from MP application
    ], question.alias) &&
    !includes(['bankValues', 'creditFields'], question.group)
  )

  return questions
}

export function setInputsTabIndex() {
  /**
   * Ensure that the inputs have a tab index that allows for accessible filling out
   * without diving into other locations of the web page to start
   *
   * Tab indexes are relative, and setting 0 makes sure that all of these elements
   * end up being target-able and the dom will still inform order.
   */
  const inputs = document.getElementsByTagName('input')
  for (let x = 0; x < inputs.length; x++) {
    const el = document.getElementById(inputs[x].id)
    if (el) {
      el.tabIndex = 0
    }
  }

  const selects = document.getElementsByTagName('select')
  for (let y = 0; y < selects.length; y++) {
    const el = document.getElementById(selects[y].id)
    if (el) {
      el.tabIndex = 0
    }
  }
}

export default {
  borrowerSummaryQuestions,
  ownerInfoQuestions,
  ownerCreditQuestions,
  businessInfoQuestions,
  businessFinancialQuestions,
  renewalOwnerQuestions,
  renewalBusinessQuestions,
  renewalLoanRequirements,
  updatedInfoQuestions,
}
