import { isValid, addDays, addWeeks, addMonths, differenceInDays as diffInDays } from 'date-fns'

const SUPPORTED_FREQUENCY = ['daily', 'semiweekly', 'weekly', 'biweekly', 'semimonthly', 'monthly', 'bimonthly'];

const getNextPaymentDate = (date, frequency, iteration, dayOfMonth) => {
  switch (frequency) {
    case 'daily':
      return addDays(date, 1);
    case 'semiweekly':
      return addDays(date, (iteration % 2 === 0) ? 4 : 3);
    case 'weekly':
      return addWeeks(date, 1);
    case 'biweekly':
      return addWeeks(date, 2);
    case 'semimonthly':
      const addedMonth = addMonths(date, 1)
      if (iteration % 2 === 0 && dayOfMonth) {
        addedMonth.setDate(dayOfMonth);
        return addedMonth;
      }
      const daysInMonth = diffInDays(addedMonth, date)
      const halfMonth = Math.ceil(daysInMonth / 2)
      return addDays(date, halfMonth)
    case 'monthly':
      return addMonths(date, 1)
    case 'bimonthly':
      return addMonths(date, 2)
    default:
      throw new Error('Payment frequency is not valid');
  }
}

export default function(paybackAmount, startDate, frequency, amount) {
  if (!paybackAmount || !isValid(startDate) || !SUPPORTED_FREQUENCY.includes(frequency) || !amount) {
    return null;
  }

  const payments = []
  const dayOfMonth = startDate.getDate()
  let nextDate = startDate
  let remainingAmount = paybackAmount
  let i = 1;

  while (remainingAmount > 0) {
    const paymentAmount = Math.min(remainingAmount, amount);
    nextDate = getNextPaymentDate(nextDate, frequency, i, dayOfMonth);

    payments.push({
      entryDate: nextDate,
      amount: paymentAmount,
    });

    remainingAmount -= paymentAmount;
    i++;
  }

  return payments;
}
