import { useRootStore } from "~/store/root"

export default defineNuxtRouteMiddleware((to, from) => {
  const { $lendioCookies, $pinia } = useNuxtApp()
  const rootStore = useRootStore($pinia)
  const { isEmbedded } = rootStore
  const query = to.query

  if (import.meta.server) {
    const req = useNuxtApp()?.ssrContext?.event?.node?.req
    const cypressUser = req && req.headers['user-agent'] == 'lendioCypressTestUser'
    rootStore.$patch({ isCypressUser: cypressUser }) // set in root store
  }

  const isEmbeddedRoute = from.fullPath.includes('/embedded')
  const isEmbeddedCookie = $lendioCookies.get('isEmbedded')
  const isEmbeddedCheck = isEmbedded || isEmbeddedCookie || isEmbeddedRoute
  if (query && query.sso) {
    const token = $lendioCookies.get('token')
    if (!token) {
      if (isEmbeddedCheck) {
        throw createError({
          statusCode: 401,
          statusMessage: 'Unauthorized Action',
          data: {
            isEmbedded: true,
            location: 'User'
          },
          fatal: true
        })
      }

      return navigateTo(`/bp/login?r=${encodeURIComponent(to.fullPath)}`, { external: true })
    }
  }
})
