<template>
  <div class="container">
    <div class="row mx-0 mt-4 p-4 justify-content-center">
      <div class="col-12 col-md-8 col-lg-5 p-0">
        <template v-if="status === 'default'">
          <h3>Reset Password</h3>
          <p>Please enter your email and we'll send you reset instructions.</p>
          <form class="align-self-center app-page" name="resetPassword" v-on:submit.prevent="resetPassword">
            <div>
              <Alert class="mb-3" ref="alert"></Alert>
              <label for="email" class="control-label">Email</label>
              <div class="text-center pb-3">
                <input class="form-control required" :class="{'error': emailInvalid}" tabindex="1" data-cy="reset-email-input" id="r-email" name="email" type="email"
                      v-model="email" autofocus/>
              </div>
              <div class="buttons mt-2">
                <XButton
                    id="send"
                    class="btn-primary me-3"
                    data-cy="submit-reset-password"
                    :disabled="!email && sending"
                    :submitting="sending">
                  Send Instructions
                </XButton>
                <nuxt-link class="float-right mt-2 neutral-500" to="/login">Cancel</nuxt-link>
              </div>
            </div>
          </form>
        </template>
        <template v-if="status === 'sent'">
          <h3 id="confirmation">Instructions sent</h3>
          <p>You should receive an email in your inbox with reset password instructions shortly.</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { useIdentityStore } from '~/store/identity'
import XButton from '~/components/Form/Button'
import env from '~/libs/env'

export default {
  name: 'PasswordReset',
  async setup() {
    definePageMeta({
      layout: 'light',
    })
    const query = useRoute().query
    const router = useRouter()
    const identityStore = useIdentityStore()
    return {
      query,
      router,
      identityStore,
    }
  },
  data () {
    return {
      status: 'default',
      sending: false,
      email: '',
      redirect: '',
    }
  },
  async beforeCreate () {
    if (process.browser) {
      this.router.push(this.identityStore.resetPasswordUrl)
    }
  },
  components: {
    XButton
  },
  created () {
    this.email = this.query.e && this.query.e.replace(/ /g, '+')
    this.redirect = this.isMarketplaceExperience ? 'newpass?experience=marketplace' : ''
  },
  computed: {
    emailInvalid () {
      let emailRegex = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/
      if (!emailRegex.test(this.email)) {
        return true
      }
      return false
    }
  },
  methods: {
    async resetPassword () {
      if (this.sending) {
        return
      }

      if (this.emailInvalid) {
        this.showErrorAlert('Oops! That doesn\'t look like a valid email.');
        return
      }

      try {
        this.sending = true
        await this.$axios.post(`${env('apiUrl')}/reset-password`, JSON.stringify({
          email: this.email,
          redirect: this.redirect
        }), { headers: { 'Content-Type': 'application/json' } })
        this.status = 'sent'
      } catch (res) {
        let data = res.data
        let errors = data.errors
        if (errors) {
          let message = errors.map(err => err.message).join(', ')
          this.showErrorAlert(message);
        } else {
          log.error('Failed to send password reset email', res)
          this.showErrorAlert('Failed to send password reset email');
        }
      }
      this.sending = false
    },

    showErrorAlert(message) {
      if (this.$refs && this.$refs.alert) {
        this.$refs.alert.openDanger({content: message})
      }
    }
  }
}
</script>

<style lang="scss">
</style>
